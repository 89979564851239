import React, { Fragment, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import "./index.scss";
import DeletePermanentlyNotifications from "./deletePermanentlyNotifications";
import RestoreAllNotifications from "./restoreAllNotifications";
import { useDispatch, useSelector } from "react-redux";
import { GoSearch, GoInfo } from "react-icons/go";
import Loader from 'react-loader-spinner';

import {
  EmptyMyTrash,
  LoadMyNotifications,
} from "../../../../store/actions/inboxAction";
import {
  deletePermanently,
  emptyTrash,
  getAllNotification,
  getNotificationCount,
  moveToTrash,
  restoreTrash,
  notifSearch,
  notifSearchCount
} from "../../../../config/endpoints";
import { togggleLoad } from "../../../../store/actions/loadAction";
import { request } from "../../../../helper/request";
import { useAlert } from "react-alert";
import EmptyTrash from "./emptyTrash";
import {
  FaExchangeAlt,
  FaSearch,
  FaRedoAlt,
} from "react-icons/fa";

export default ({ userRole, activeDrawerItem }) => {
  // const { myNotifications, status } = useSelector(
  //   ({ InboxReducer }) => InboxReducer
  // );
  const [myNotifications, setMyNotifications] = useState([]);
  const dispatch = useDispatch();
  const [displayList, setDisplayList] = useState([]);
  const [role, setRole] = useState(userRole);
  const [currentPage, setCurrentPage] = useState(1);
  const [showTrashData, setShowTrashData] = useState(false);
  const [activeCategory, setActiveCategory] = useState("INBOX");
  const [selectedItems, setSelectedItems] = useState([]);
  const [loggedUser, setLoggedUse] = useState(null);
  const [filterBy, setFilterBy] = useState("subject");
  const [msgsCount, setMsgsCount] = useState({ INBOX: 0, TRASH: 0 });

  const [confirmDilog, setConfirmDilog] = useState(false);
  const [confirmDeletePermanently, setDeletePermanently] = useState(false);
  const [confirmDeleteEmptyTrash, setConfirmDeleteEmptyTrash] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const alert = useAlert();
  const [sortDirection, setSortDirection] = useState("asc");
  const [sort, setSort] = useState("");


  // Calculate the index range for the current page
  // const startIndex = (currentPage - 1) * itemsPerPage;


  
  // Searching data based on subject and received date
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const [itemsPerPage, setItemsPerPage] = useState(20);

  const apiNotificationsList = (uRole, isTrash, page, itemsPerFetch) => {
    setLoading(true);
    setCurrentPage(page);
    console.log("current page from api", page)
    const user = JSON.parse(sessionStorage.getItem("User"));
    const email = user?.email
    request("GET_AUTH", getAllNotification + email + `&role=${uRole}&isTrash=${isTrash}&page=${page}&itemsPerFetch=${itemsPerFetch}`).then((res) => {
      if (res.status === 0) {
        const payload = res.payload;
        // setMyNotifications(payload);
        // filterDisplayList(payload);
        // apiNotificationsCount(uRole);
        setDisplayList(payload);
        setLoading(false);
      } else {
        console.log("notifications error res", res);
        setLoading(false);
      }
    }).catch((e) => {
      console.log("error in apiNotificationsList");
      setLoading(false);
    })
  }

  const apiNotificationsCount = (uRole) => {
    const user = JSON.parse(sessionStorage.getItem("User"));
    const email = user?.email
    request("GET_AUTH", getNotificationCount + email + `&role=${uRole}`).then((res) => {
      if (res.status === 0) {
        setMsgsCount(res.payload);
        calculateTotalPages(activeCategory === "INBOX" ? res.payload.INBOX : res.payload.TRASH);
      } else {
        console.log("notifications count error res", res);
      }
    }).catch((e) => {
      console.log("error in apiNotificationsCount");
    })
  }

  const apiSearchNotifs = (uRole, isTrash, filter, search, page, itemsPerFetch) => {
    setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("User"));
    const email = user?.email
    setCurrentPage(page);
    request(
      "GET_AUTH",
      notifSearch +
      `?email=${email}&role=${uRole}&isTrash=${isTrash}&filter=${filter}&search=${search}&page=${page}&itemsPerFetch=${itemsPerFetch}`
    )
      .then((res) => {
        if (res.status === 0) {
          const payload = res.payload.reverse();
          setDisplayList(payload);
          setLoading(false);
        } else {
          console.log("error res", res);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("error in apiSearchNotifs");
        setLoading(false);
      });
  };

  const apiSearchNotifsCount = (uRole, filter, search) => {
    const user = JSON.parse(sessionStorage.getItem("User"));
    const email = user?.email
    request(
      "GET_AUTH",
      notifSearchCount +
      `?email=${email}&role=${uRole}&filter=${filter}&search=${search}`
    )
      .then((res) => {
        if (res.status === 0) {
          setMsgsCount(res.payload);
          calculateTotalPages(res.payload[activeCategory]);
        } else {
          console.log("error res", res);
        }
      })
      .catch((e) => {
        console.log("error in apiSearchNotifsCount");
      });
  };

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("User"));
    setLoggedUse(user);
  }, []);

  useEffect(() => {
    console.log("currentPage++++++++++++++", currentPage)
  }, [currentPage])

  useEffect(() => {
    setRole(userRole);
    apiNotificationsList(userRole, activeCategory === "TRASH", currentPage, itemsPerPage);
    apiNotificationsCount(userRole);
    console.log("Api calling from here")
  }, [userRole, activeDrawerItem]);

  useEffect(() => {
    setCurrentPage(1);
    setTimeout(() => {
      ReactTooltip.rebuild(); // Rebuild the tooltips after a short delay
    }, 0);
    setSelectedItems([]);
  }, [userRole, activeCategory]);

  useEffect(() => {
    // filterDisplayList(myNotifications);
  }, [searchQuery])

  useEffect(() => {
    const tp = calculateTotalPages(msgsCount[activeCategory]);
    if (tp > 0) {
      if (searchQuery.length === 0) {
        apiNotificationsList(role, activeCategory === "TRASH", tp > currentPage ? currentPage : tp, itemsPerPage);
      } else {
        apiSearchNotifs(role, activeCategory === "TRASH", filterBy, searchQuery, tp > currentPage ? currentPage : tp, itemsPerPage);
      }
    }
  }, [currentPage, itemsPerPage])

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setTimeout(() => {
      ReactTooltip.rebuild(); // Rebuild the tooltips after a short delay
    }, 0);
  };

  const onClickSearchbtn = () => {
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    apiSearchNotifs(role, activeCategory === "TRASH", filterBy, searchQuery, 1, itemsPerPage);
    apiSearchNotifsCount(role, filterBy, searchQuery);
  };

  const onClickXbtn = () => {
    setSearchQuery("");
    apiNotificationsList(role, activeCategory === "TRASH", currentPage, itemsPerPage);
    apiNotificationsCount(role);
  };

  const onClickDltPermanently = () => {
    if (selectedItems.length > 0) {
      setDeletePermanently(true);
    } else {
      alert.removeAll();
      alert.show("No item(s) is/are selected to restore");
    }
  };
  const onClickRestoreNotifications = () => {
    if (selectedItems.length > 0) {
      setConfirmDilog(true);
    } else {
      alert.removeAll();
      alert.show("No item(s) is/are selected to restore");
    }
    setConfirmDilog(true);
    setSearchQuery("");
  };

  const onClickConfirmEmptyTrash = () => {
    setConfirmDeleteEmptyTrash(true);
  };

  const filterDisplayList = (list) => {
    let tempList = list;
    if (searchQuery.trim().length === 0) {
      // do nothing
    } else {
      // filter
      tempList = list.filter(
        (x) => x[filterBy].toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    console.log("tempList=======================", list)
    setDisplayList(tempList);
  };

  const calculateTotalPages = (count) => {
    const pages = Math.ceil(count / itemsPerPage);
    setTotalPages(pages);
    return pages;
  }

  // sort alphabatically
  const sortAlphabetically = (sortBy, dataType) => {
    setSort(sortBy);
    const sortedList = [...displayList].sort((a, b) => {
      if (dataType === "date") {
        const dateA = new Date(a[sortBy]);
        const dateB = new Date(b[sortBy]);
        return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        const compareResult = a[sortBy].localeCompare(b[sortBy]);
        return sortDirection === "asc" ? compareResult : -compareResult;
      }
    });
    setDisplayList(sortedList);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const handleCategoryClick = (isTrash) => {
    setShowTrashData(isTrash);
    setCurrentPage(1);
    apiNotificationsList(role, isTrash, 1, itemsPerPage);
    calculateTotalPages(isTrash ? msgsCount.TRASH : msgsCount.INBOX);
  };

  const onCheckItem = (_e, _id) => {
    if (_e.target.checked) {
      const tempItems = [...selectedItems, _id];
      setSelectedItems(tempItems);
    } else {
      const tempItems = selectedItems.filter((item) => item !== _id);
      setSelectedItems(tempItems);
    }
  };

  // move selected items to trash api
  const onClickDelete = () => {
    if (selectedItems.length > 0) {
      dispatch(togggleLoad());
      request("POST_AUTH", moveToTrash, {
        notifications: selectedItems,
      })
        .then((res) => {
          dispatch(togggleLoad());
          if (res.status === 0) {
            alert.removeAll();
            alert.show("Moved to trash successfully.");
            apiNotificationsList(userRole, activeCategory === "TRASH", currentPage, itemsPerPage);
            apiNotificationsCount(role);
            setSelectedItems([]);
          } else if (res.status === 1) {
            alert.removeAll();
            alert.show(res.payload);
          } else {
            alert.removeAll();
            alert.show("Error moving to trash");
          }
        })
        .catch((e) => {
          dispatch(togggleLoad());
          alert.removeAll();
          alert.show("Error moving to trash" + e.message);
        });
    } else {
      alert.removeAll();
      alert.show("No item(s) is/are selected to delete");
    }
    setSelectedItems([]);
    setSearchQuery("");
  };

  // restore selected items api
  const onClickRestore = () => {
    if (selectedItems.length > 0) {
      dispatch(togggleLoad());
      request("POST_AUTH", restoreTrash, {
        trash: selectedItems,
      })
        .then((res) => {
          dispatch(togggleLoad());
          if (res.status === 0) {
            alert.removeAll();
            alert.show("Restored successfully.");
            apiNotificationsList(userRole, activeCategory === "TRASH", currentPage, itemsPerPage);
            apiNotificationsCount(role);
            setConfirmDilog(false);
            setSelectedItems([]);
          } else if (res.status === 1) {
            alert.removeAll();
            alert.show(res.payload);
          } else {
            alert.removeAll();
            alert.show("Error restroing.");
          }
        })
        .catch((e) => {
          dispatch(togggleLoad());
          alert.removeAll();
          alert.show("Error restoring" + e.message);
        });
    } else {
      alert.removeAll();
      alert.show("No item(s) is/are selected to restore");
    }
  };

  // delete permanantly from trash api
  const onClickDeletePermanetly = () => {
    if (selectedItems.length > 0) {
      dispatch(togggleLoad());
      request("POST_AUTH", deletePermanently, {
        trash: selectedItems,
      })
        .then((res) => {
          dispatch(togggleLoad());
          if (res.status === 0) {
            alert.removeAll();
            alert.show("Deleted permanently");
            apiNotificationsList(userRole, activeCategory === "TRASH", currentPage, itemsPerPage);
            apiNotificationsCount(role);
            setDeletePermanently(false);
            setSelectedItems([]);
          } else if (res.status === 1) {
            alert.removeAll();
            alert.show(res.payload);
          } else {
            alert.removeAll();
            alert.show("Error deleting permanently");
          }
        })
        .catch((e) => {
          dispatch(togggleLoad());
          alert.removeAll();
          alert.show("Error deleting permanently" + e.message);
        });
    } else {
      alert.removeAll();
      alert.show("No item(s) is/are selected to delete");
    }
  };

  // emptry trash api
  const onConfirmEmptyTrash = () => {
    dispatch(togggleLoad());
    const email = loggedUser?.email;
    const emptyTrashAPIURL = emptyTrash + `${email}&role=${role}`;
    request("GET_AUTH", emptyTrashAPIURL, {})
      .then((res) => {
        dispatch(togggleLoad());
        if (res.status === 0) {
          alert.removeAll();
          alert.show(res.payload);
          apiNotificationsList(userRole, activeCategory === "TRASH", 1, itemsPerPage);
          apiNotificationsCount(role);
          setConfirmDeleteEmptyTrash(false);
          setSelectedItems([]);
        } else if (res.status === 1) {
          alert.removeAll();
          alert.show(res.msg);
        } else {
          alert.removeAll();
          alert.show("Error Emptying trash");
        }
      })
      .catch((e) => {
        dispatch(togggleLoad());
        alert.removeAll();
        alert.show("Error Emptying trash" + e.message);
      });
  };

  // on click refresh button
  const onRefresh = () => {
    setSearchQuery("");
    apiNotificationsList(role, activeCategory === "TRASH", currentPage, itemsPerPage);
    apiNotificationsCount(role);
  };

  // on click select all
  const onSelectAll = (_e) => {
    if (_e.target.checked) {
      const tempList = [];
      displayList.map((item) => {
        tempList.push(item._id);
      });
      setSelectedItems([...tempList]);
    } else {
      setSelectedItems([]);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onClickSearchbtn(); // Call your search action function here
    }
  };



  // Calculate the range of data to display based on the current page
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentData = displayList.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="notification-container">
      <div className="sub-header">
        <div className="search-container">
          <div className="input-wrapper-inv">
            <GoSearch size={16} color="rgba(0,0,0,0.3)" />
            <input
              value={searchQuery}
              onChange={(e) => {
                const string = e.target.value;
                setSearchQuery(e.target.value);
              }}
              onKeyPress={handleKeyPress}
              className="input"
              placeholder={`Search here...`}
              type={"text"}
            />
            {searchQuery.length > 0 && (
              <button className="search-x-btn" onClick={onClickXbtn}>
                X
              </button>
            )}
            <button
              className={
                searchQuery.length === 0 ? "search-btn-dsbld" : "search-btn"
              }
              onClick={onClickSearchbtn}
              disabled={searchQuery.length === 0}
            >
            <FaSearch />
            </button>
            <select
              className="select"
              value={filterBy}
              onChange={(e) => setFilterBy(e.target.value)}
            >
              <option key="subject" value="subject">
                Subject
              </option>
              <option key="raffleTitle" value="raffleTitle">
                Raffle Title
              </option>
              {role === "participant" ?
                <option key="hostName" value="hostName">
                  Host Name
                </option> : null}
            </select>
          </div>
        </div>
        <div className="categories">
          <div className="page-name">NOTIFICATIONS</div>
          <div className="refresh-icon">
            <FaRedoAlt className="refresh-icon" onClick={onRefresh} />
          
          {Object.keys(msgsCount).map((item, i) => (
            <label
              onClick={async () => {
                console.log("item:::::::", item);
                setCurrentPage(1)
                setActiveCategory(item);
                handleCategoryClick(item === "INBOX" ? false : true);
              }}
              className={activeCategory === item ? "active2" : "inactive1"}
              key={i}
            >
              {`${item} (${msgsCount[item]})`}
            </label>
          ))}
          </div>
        </div>
      </div>
      {displayList.length > 0 ? (
        <div className="list-view">
          <table className="table-container">
            <thead className="table-head">
              <tr className="header-row">
                <th className="snum">#</th>
                <th className="check-box">
                  <input
                    type="checkbox"
                    checked={selectedItems.length === displayList.length}
                    onChange={(_e) => {
                      onSelectAll(_e);
                    }}
                  />
                </th>
                <th className="raff-title">
                  RAFFLE TITLE{" "}
                  <FaExchangeAlt
                    style={{
                      transform:
                        sort === "raffleTitle"
                          ? sortDirection === "asc"
                            ? "rotate(90deg)"
                            : "rotate(90deg) scaleX(-1)"
                          : "rotate(90deg)",
                    }}
                    onClick={() => sortAlphabetically("raffleTitle", "string")}
                  />
                </th>
                {role === "participant" ? (
                  <th className="host-name">
                    HOST NAME{" "}
                    <FaExchangeAlt
                      style={{
                        transform:
                          sort === "hostName"
                            ? sortDirection === "asc"
                              ? "rotate(90deg)"
                              : "rotate(90deg) scaleX(-1)"
                            : "rotate(90deg)",
                      }}
                      onClick={() => sortAlphabetically("hostName", "string")}
                    />
                  </th>
                ) : null}
                <th className="from">
                  FROM{" "}
                </th>
                <th className="sub">
                  SUBJECT{" "}
                  <FaExchangeAlt
                    style={{
                      transform:
                        sort === "subject"
                          ? sortDirection === "asc"
                            ? "rotate(90deg)"
                            : "rotate(90deg) scaleX(-1)"
                          : "rotate(90deg)",
                    }}
                    onClick={() => sortAlphabetically("subject", "string")}
                  />
                </th>
                <th className="time-stamp">
                  RECEIVED DATE{" "}
                  <FaExchangeAlt
                    style={{
                      transform:
                        sort === "receivedAt"
                          ? sortDirection === "asc"
                            ? "rotate(90deg)"
                            : "rotate(90deg) scaleX(-1)"
                          : "rotate(90deg)",
                    }}
                    onClick={() => sortAlphabetically("receivedAt", "date")}
                  />
                </th>
              </tr>
            </thead>
            <tbody className="table-body">
              {displayList.map((item, index) => (
                <tr key={index} className="tb-row">
                  <td className="td-snum">{((currentPage - 1) * itemsPerPage) + (index + 1)}</td>
                  <td className="td-check-box">
                    <input
                      type="checkbox"
                      checked={selectedItems.includes(item._id)}
                      onChange={(_e) => {
                        onCheckItem(_e, item._id);
                      }}
                    />
                  </td>
                  <td className="raff-title">{item.raffleTitle}</td>
                  {role === "participant" ? (
                    <td className="host-name">{item.hostName}</td>
                  ) : null}
                  <td className="from">Team Rafflerra</td>
                  <td
                    className="td-sub"
                    data-tip={item.message}
                    data-for={item._id}
                  >
                    {item.subject}
                  </td>
                  <td className="td-time-stamp">
                    {new Date(item.receivedAt).toLocaleString("en-US")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {displayList.map((item, index) => {
            return (
              <ReactTooltip
                key={index}
                effect="solid"
                className="notifsub"
                id={item._id}
                place="bottom"
                html={true}
                clickable
                leaveDelay={2000}
                interactive
              />
            );
          })}
        </div>
      ) : (
        !loading && <div className="no-items">
          <img
            className="img"
            src={require("../../../../../src/assets/img/notification.jpg")}
            alt="No Notifications to display"
          />
          <p style={{ paddingTop: "10px", fontFamily: "Roboto, sans-serif" }}>
            No Notifications
          </p>
        </div>
      )}
      {loading ?
        <div className="notif-loader">
          <Loader type="TailSpin" color="rgb(11, 70, 85)" width={60} />
        </div> : null
      }
      <div
        style={{
          display: confirmDeletePermanently ? "block" : "none",
          position: "absolute",
          zIndex: 9,
        }}
      >
        <DeletePermanentlyNotifications
          setDeletePermanently={setDeletePermanently}
          onProceed={onClickDeletePermanetly}
        />
      </div>
      <div
        style={{
          display: confirmDilog ? "block" : "none",
          position: "absolute",
          zIndex: 9,
        }}
      >
        <RestoreAllNotifications
          setConfirmDilog={setConfirmDilog}
          onProceed={onClickRestore}
        />
      </div>
      <div
        style={{
          display: confirmDeleteEmptyTrash ? "block" : "none",
          position: "absolute",
          zIndex: 9,
        }}
      >
        <EmptyTrash
          setConfirmDeleteEmptyTrash={setConfirmDeleteEmptyTrash}
          onProceed={onConfirmEmptyTrash}
        />
      </div>
      {!showTrashData && displayList.length > 0 && (
        <div className="btn-container">
          <button
            disabled={selectedItems.length === 0}
            className={selectedItems.length > 0 ? "dlt-btn" : "disablebtn"}
            onClick={onClickDelete}
          >
            DELETE
          </button>
        </div>
      )}
      {showTrashData && displayList.length > 0 && (
        <div className="trashbtns">
          <div>
            <button
              disabled={selectedItems.length === 0}
              onClick={onClickRestoreNotifications}
              className={
                selectedItems.length > 0 ? "restorebtn" : "disablebtn2"
              }
            >
              RESTORE
            </button>
          </div>
          <div>
            <button
              disabled={selectedItems.length === 0}
              onClick={onClickDltPermanently}
              className={
                selectedItems.length > 0 ? "restorebtn" : "disablebtn2"
              }
            >
              DELETE
            </button>
          </div>
          {displayList.length > 0 ? (
            <div>
              <button onClick={onClickConfirmEmptyTrash} className="restorebtn">
                EMPTY TRASH
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      <div className='notif-footer'>
        <div htmlFor='itemsperpage' className='itemsperpage'>
          Items per page
          <select
            id="itemsperpage"
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(e.target.value)}
          >
            <option key={10} value={10}>10</option>
            <option key={20} value={20}>20</option>
            <option key={30} value={30}>30</option>
            <option key={40} value={40}>40</option>
            <option key={50} value={50}>50</option>
          </select>
        </div>
        {msgsCount[activeCategory] > itemsPerPage && (
          <div className="pagination">
            {currentPage > 1 && (
              <button
                className="pagination-number"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                &lt;
              </button>
            )}
            <button
              className={`pagination-number ${currentPage === 1 ? "active" : ""}`}
              onClick={() => {
                console.log("currentPage::::::", currentPage)
                handlePageChange(1)
              }}

            >
              1
            </button>
            {currentPage > 3 && <span className="ellipsis">...</span>}

            {Array.from({ length: totalPages }).map((_, index) => {
              // Determine the range of pages to display
              const startPage = Math.max(2, currentPage - 2);
              const endPage = Math.min(totalPages - 1, startPage + 3);

              // Display page buttons within the range, excluding the first and last page buttons
              if (index + 1 >= startPage && index + 1 <= endPage && index + 1 !== totalPages) {
                return (
                  <button
                    key={index + 1}
                    className={`pagination-number ${currentPage === index + 1 ? "active" : ""
                      }`}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                );
              }

              return null;
            })}

            {currentPage < totalPages - 2 && <div className="ellipsis">...</div>}

            {/* Render the last page number button always */}
            <button
              className={`pagination-number ${currentPage === totalPages ? "active" : ""
                }`}
              onClick={() => handlePageChange(totalPages)}
            >
              {totalPages}
            </button>

            {currentPage < totalPages && (
              <button
                className="pagination-number"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                &gt;
              </button>
            )}
          </div>
        )}
        <div className="empty-container">
        </div>
      </div>
    </div>
  );
};
