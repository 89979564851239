const initialState = {
    myNotifications: [],
    trashed: [],
    restored: [],
    emptyMessage: null,
    permanantDeleteMsg: null,
    status: "",
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        
        //initial load of my notifications
        case "FETCHING_MY_NOTIFICATIONS":
            return { ...state, status: "FETCHING" }
        case "FETCHED_MY_NOTIFICATIONS":
            return { ...state, myNotifications: payload, status: "FETCHED" }
        case "FETCHED_MY_EMPTY":
            return { ...state, myNotifications: payload, status: "FETCHED_MY_EMPTY" }
        case "FETCH_MY_ERROR":
            return { ...state, status: "ERROR" }

        // empty trash
        case "EMPTYING_MY_TRASH":
            return { ...state, status: "FETCHING" }
        case "EMPTIED_MY_TRASH":
            return { ...state, emptyMessage: payload, status: "FETCHED" }
        case "EMPTY_ERROR":
            return { ...state, status: "ERROR" }

        // move selected notifications to trash
        case "MOVE_TO_TRASH":
            return { ...state, trashed: [...payload], status: "TRASHED" }

        // restore selected items to notifications
        case "RESTORE_TRASH":
            return {...state, restored: [...payload], status: "RESTORED"}

        // delete permanantly from trash and db
        case "DEL_TRASH":
            return {...state, permanantDeleteMsg: payload}
        
        default:
            return state;
    }
}