import React, { useState, useEffect } from 'react';
import './ConfirmOverride.scss';

export default ({
  setConfirmDilog,
  updatedInstances,
  onCancel,
  onProceed
}) => {
  return (
    <div className='Confirm-Override'>
      <div className='container1'>
        <div className='container1-child1'>
          <div className='container1-child1-child1'>
            <div className='header'>
              <div className='header-name'>
                <img className='del-cup' src={require("../../../assets/img/cup.png")} alt="Cup" />
                <p className='name'>CONFIRM UPDATE</p>
              </div>
              <button
                className='x-close-btn'
                onClick={() => {
                  setConfirmDilog(false);
                }}>
                X
              </button>
            </div>
            <div className='body'>
              <span>WOULD YOU LIKE TO KEEP THE CHANGES MADE TO FOLLOWING OCCURRENCES OF THIS SERIES?</span>
              <span style={{ display: "flex", justifyContent: "center" }}>
                <span>{"Instance(s): "}</span>
                {updatedInstances.map((element, index) => {
                  return (
                    <span key={index}>
                      {element}
                      {updatedInstances.length > 1 &&
                        index === 0 &&
                        ", "}
                      {index > 0 &&
                        updatedInstances.length - 1 !==
                        index &&
                        ", "}
                    </span>
                  )
                })}
              </span>
              <span>APPLY THESE CHANGES TO ALL OCCURRENCES</span>
            </div>
            <div className='footer'>
              <button
                className={"close-btn"}
                onClick={onCancel}>
                NO
              </button>
              <button
                className={"confirm-btn"}
                onClick={onProceed}>
                YES
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


