import React, { useEffect, useState } from 'react';
import './index.scss';
import { emailValidation, passwordValidation, nameValidation } from '../../../../helper/formValidation';
import { useAlert } from 'react-alert';
import { beneficiaryHost, updateUserName } from '../../../../config/endpoints';
import { request } from '../../../../helper/request';

export default () => {
    const alert = useAlert();
    const [user, setUser] = useState(null);
    const [beneficiary, setBeneficiary] = useState({
        "_id": "",
        "description": "",
        "poc": "",
        "contact": "",
        "address": "",
        "bank": "",
        "account": "",
        "ifsc": ""
    });

    useEffect(() => {
        let usr = JSON.parse(sessionStorage.getItem("User", null))
        setUser(usr);
        request("GET_AUTH", `${beneficiaryHost}/${usr._id}`, {}).then((res) => {
            if (res.status === 0) {
                if (res.payload !== null) {
                    setBeneficiary(res.payload);
                } else {
                    setBeneficiary(bene => ({ ...bene, "_id": usr._id }));
                }
            } else {
                setBeneficiary(bene => ({ ...bene, "_id": usr._id }));
            }
        }).catch((e) => {
            alert.removeAll();
alert.show(e);
        })
    }, []);

    const saveButtonClicked = () => {
        if (nameValidation(user['name']) && emailValidation(user['email']) &&
            Object.values(beneficiary).find(x => x.length === 0) === undefined) {
            let showmsg = true;
            request("POST_AUTH", beneficiaryHost, beneficiary).then((res) => {
                if (res.status === 0) {
                    if (showmsg) {
                        alert.removeAll();
alert.show("Beneficiary details updated successfully");
                        showmsg = false;
                    }
                } else {
                    alert.removeAll();
alert.show(res.payload);
                }
            }).catch((e) => {
                alert.removeAll();
alert.show(e);
            });
            request("POST_AUTH", updateUserName, {
                "_id": beneficiary._id,
                "name": user.name
            }).then((res) => {
                if (res.status === 0) {
                    if (showmsg) {
                        alert.removeAll();
alert.show("Beneficiary details updated successfully");
                    }
                } else {
                    //alert.removeAll();
alert.show(JSON.stringify(res.payload));
                }
            }).catch((e) => {
                alert.removeAll();
alert.show(e);
            });
        } else {
            alert.removeAll();
alert.show("All Fields are mandatory");
        }
    }

    return user !== null ? <div className="beneficiary_settings_view">
        {/* navigation bar */}
        <form>
            <label>Profile</label>
            <div>
                <div>
                    <label>Account type</label>
                    <input value={user.accountType} disabled />
                </div>
                <div>
                    <label>Email</label>
                    <input disabled value={user.email} />
                </div>
            </div>
            <div>
                <div>
                    <label>Beneficiary Name</label>
                    <input onChange={(e) => { let text = e.currentTarget.value; setUser(usr => ({ ...usr, "name": text })) }} value={user.name} />
                </div>
            </div>
            <div>
                <label>Beneficiary Description</label>
                <textarea onChange={(e) => { let text = e.currentTarget.value; setBeneficiary(bene => ({ ...bene, "description": text })) }} value={beneficiary.description}></textarea>
            </div>

            <label>Contact</label>
            <div>
                <div>
                    <label>POC</label>
                    <input onChange={(e) => { let text = e.currentTarget.value; setBeneficiary(bene => ({ ...bene, "poc": text })) }} value={beneficiary.poc} />
                </div>
                <div>
                    <label>Contact Number</label>
                    <input onChange={(e) => { let text = e.currentTarget.value; setBeneficiary(bene => ({ ...bene, "contact": text })) }} value={beneficiary.contact} />
                </div>
            </div>
            <div>
                <label>Address</label>
                <textarea onChange={(e) => { let text = e.currentTarget.value; setBeneficiary(bene => ({ ...bene, "address": text })) }} value={beneficiary.address}></textarea>
            </div>
            <label>Bank</label>
            <div>
                <div>
                    <label>Bank</label>
                    <input onChange={(e) => { let text = e.currentTarget.value; setBeneficiary(bene => ({ ...bene, "bank": text })) }} value={beneficiary.bank} />
                </div>
                <div>
                    <label>Account No.</label>
                    <input onChange={(e) => { let text = e.currentTarget.value; setBeneficiary(bene => ({ ...bene, "account": text })) }} value={beneficiary.account} />
                </div>
            </div>
            <div>
                <div>
                    <label>IFSC</label>
                    <input onChange={(e) => { let text = e.currentTarget.value; setBeneficiary(bene => ({ ...bene, "ifsc": text })) }} value={beneficiary.ifsc} />
                </div>
            </div>
        </form>
        <div onClick={saveButtonClicked}>SAVE</div>
    </div> : null
}