import React, { useEffect, useRef, useState } from "react";
import "./customizeRaffleTicket.scss";
import {
  SketchPicker,
  ChromePicker,
  SliderPicker,
  CompactPicker,
} from "react-color";
import RaffleTicket from "../../../assets/svg_components/raffle_ticket";
import firebase from "../../../helper/firebase";
import { togggleLoad } from "../../../store/actions/loadAction";
import { useDispatch, useSelector } from "react-redux";
// icons
import { FcDocument } from "react-icons/fc";
// alert
import { useAlert } from "react-alert";
import currencies from "../../../assets/currencies";
import { v4 as uuidv4 } from 'uuid';

const googleFonts = [
  "Arial",
  "Times New Roman",
  "Times",
  "Courier New",
  "Courier",
  "Verdana",
  "Georgia",
  "Palatino",
  "Garamond",
  "Bookman",
];

export default ({
  raffleData,
  theme,
  setTheme,
  seriesEdit,
  type,
  setRaffleFields,
}) => {
  const [activeItem, setActiveItem] = useState("background");
  const [raffleLogo, setRaffleLogo] = useState(null);
  const imgupRef = useRef();
  const dispatch = useDispatch();
  // alert
  const alert = useAlert();

  function checkTicketLimitPerUserValidity(input) {
    if (parseInt(input) < 1) {
      return "Please enter a number greater than or equal to 1";
    } else {
      return "";
    }
  }

  function handleTicketLimitPerUser(event) {
    const value = event.currentTarget.value;
    if (parseInt(raffleData.fee) > 0 || parseInt(raffleData.fee) <= 10) {
      setRaffleFields(
        { ...raffleData.extras, ticketLimitPerUser: parseInt(value) },
        "extras"
      );
    } else {
      setRaffleFields(
        { ...raffleData.extras, ticketLimitPerUser: 1 },
        "extras"
      );
    }
    const inputValidity = checkTicketLimitPerUserValidity(value);
    event.target.setCustomValidity(inputValidity);
    event.target.reportValidity();
  }
  const disablingTabs = raffleData.title.length === 0 || raffleData.longDescription.length === 0 || raffleData.shortDescription.length === 0

  const [raffleDrawAt, setRaffleDrawAt] = useState(raffleData.drawAt);
  useEffect(() => {
    if (raffleData.extras.seriesRaffle.recurrencePattern === "Daily") {
      let firstDraw = raffleData.extras.seriesRaffle.dailyPattern.days.sort((a, b) => new Date(a) - new Date(b))[0];
      setRaffleDrawAt(firstDraw);
      console.log("raffleDrawAt", raffleDrawAt);
    }
  }, [])

  return (
    <div className="customizeRaffleTicket">
      {/* content */}
      <div>
        {/* controlls */}
        <div>
          <div className="properties1" onClick={(event) => { event.preventDefault() }}
          // style={{
          //   opacity: !(!seriesEdit && type === "EDIT") ? 1 : 0.5
          // }}
          >
            <button
              onClick={() => {
                setActiveItem("background");
              }}
              className={activeItem === "background" ? "active" : ""}
            // disabled={type === "EDIT"}
            >
              Background
            </button>
            <button
              onClick={() => {
                setActiveItem("titleColor");
              }}
              className={activeItem === "titleColor" ? "active" : ""}
            // disabled={type === "EDIT"}
            >
              Title Text
            </button>
            <button
              onClick={() => {
                setActiveItem("textColor");
              }}
              // disabled={type === "EDIT"}
              className={activeItem === "textColor" ? "active" : ""}
            >
              Body Text
            </button>
            <button
              // style={{
              //   opacity: !(!seriesEdit && type === "EDIT") ? 1 : 0.5
              // }}
              onClick={() => {
                // !(!seriesEdit && type === "EDIT") && 
                if (!(type === "EDIT" &&
                  (
                    raffleData.status === "CANCEL" ||
                    raffleData.status === "COMPLETE" ||
                    raffleData.status === "DRAWN"
                  ))) {
                  imgupRef.current.click();
                }
              }}
            // disabled={type === "EDIT"}
            >
              Image/Video
            </button>
          </div>
          <div className="properties2">
            {/* <div>
                        <label>Font Family</label>
                        <select disabled={(activeItem !== "titleColor" && activeItem !== "textColor")} value={activeItem === "titleColor" ? theme.titleFontFamily : theme.textFontFamily} onChange={(e) => {
                            const font = e.currentTarget.value;
                            if (activeItem === "titleColor") {
                                setTheme(({ ...theme, titleFontFamily: font }))
                            } else {
                                setTheme(({ ...theme, textFontFamily: font }))
                            }
                        }}>
                            {googleFonts.map((font, index) => <option value={font} key={index}>{font}</option>)}
                        </select>
                    </div> */}
            <div>
              {/* <label>Font Size</label> */}
              {/* <input disabled={(activeItem !== "titleColor" && activeItem !== "textColor")} value={activeItem === "titleColor" ? theme.titleFontSize : theme.textFontSize} onChange={(e) => {
                            const size = e.currentTarget.value;
                            if (activeItem === "titleColor") {
                                setTheme(({ ...theme, titleFontSize: parseInt(size) }))
                            } else {
                                setTheme(({ ...theme, textFontSize: parseInt(size) }))
                            }
                        }} type="number" /> */}
            </div>
          </div>
          {/* compact color picker */}
          <div>
            <CompactPicker
              disabled={(type === "EDIT" &&
                (
                  raffleData.status === "CANCEL" ||
                  raffleData.status === "COMPLETE" ||
                  raffleData.status === "DRAWN"
                ))}
              width={"auto"}
              color={
                activeItem === "background" ? theme.background :
                  activeItem === "titleColor" ? theme.titleColor : theme.textColor
              }
              onChange={(e) => {
                console.log("e.hex::::::::::::::::::::::::::::::", e.hex);

                if (!(seriesEdit && type === "Edit")) {
                  if (activeItem === "titleColor") {
                    setTheme({ ...theme, titleColor: e.hex });
                  } else if (activeItem === "background") {
                    setTheme({ ...theme, background: e.hex });
                  } else {
                    setTheme({ ...theme, textColor: e.hex });
                  }
                }
              }}
              disable={type === "EDIT"}
            />
            {/* color picker */}
            <SliderPicker
              width={"auto"}
              color={
                activeItem === "background" ? theme.background :
                  activeItem === "titleColor" ? theme.titleColor : theme.textColor
              }
              onChange={(e) => {
                console.log("e.hex::::::::::::::::::::::::::::::", e.hex);
                if (!(seriesEdit && type === "Edit")) {
                  if (activeItem === "titleColor") {
                    setTheme({ ...theme, titleColor: e.hex });
                  } else if (activeItem === "background") {
                    setTheme({ ...theme, background: e.hex });
                  } else {
                    setTheme({ ...theme, textColor: e.hex });
                  }
                }
              }}
              disable={type === "EDIT"}
            />
          </div>
        </div>
        {/* live view */}
        <div>
          <div>Preview</div>
          <RaffleTicket
            raffleData={raffleData}
            onClick={() => { }}
            dates={{
              start: raffleData.participationOpens,
              end: raffleData.participationCloses,
              drawn: raffleDrawAt,
            }}
            id={raffleData.id}
            seriesId={raffleData.extras.seriesRaffle.seriesId}
            shortDescription={
              raffleData.shortDescription.length > 0
                ? raffleData.shortDescription
                : "<Short description shows on hover>"
            }
            image={raffleLogo}
            styles={theme}
            createdBy={raffleData.createdBy}
            title={raffleData.title}
          />
        </div>
        <div>
          <div className="crm_currency">
            <label htmlFor="currency-type">TICKET PRICE PER ENTRY</label>
            <div className="ticket-price">
              <div className="fee-switch">
                <input
                  type="radio"
                  disabled={
                    raffleData.status !== "CREATED" &&
                    !raffleData.extras.draft &&
                    type === "EDIT" &&
                    !seriesEdit || disablingTabs
                  }
                  checked={raffleData.fee === 0}
                  onChange={() => {
                    setRaffleFields(0, "fee");
                  }}
                />
                <label>FREE</label>
                &nbsp;&nbsp;&nbsp;
                <input
                  type="radio"
                  disabled={
                    // raffleData.status !== "CREATED" &&
                    // !raffleData.extras.draft &&
                    // type === "EDIT" &&
                    // !seriesEdit || disablingTabs
                    true
                  }
                  checked={raffleData.fee > 0}
                  onChange={() => {
                    setRaffleFields(1, "fee");
                  }}
                />
                <label>PAID</label>
              </div>
              <div className="currency-amount">
                {
                  raffleData.fee <= 0 ?
                    <select
                      disabled={
                        raffleData.fee <= 0 ||
                        (raffleData.status !== "CREATED" &&
                          !raffleData.extras.draft &&
                          type === "EDIT" &&
                          !seriesEdit || disablingTabs)
                      }
                      id="currency-type"
                      value={""}
                    >
                      <option>
                        {"<     null     >"}
                      </option>
                    </select>
                    :
                    <select
                      value={raffleData.feeCurrency}
                      id="currency-type"
                      disabled={
                        raffleData.status !== "CREATED" &&
                        !raffleData.extras.draft &&
                        type === "EDIT" &&
                        !seriesEdit || disablingTabs
                      }
                      // onClick={setCurrencySymbol(false)}
                      onLoad={console.log("DEVraj")}
                      onChange={(event) => {
                        console.log(event.target.value);
                        setRaffleFields(event.target.value, "feeCurrency");
                        const arr = [];
                        raffleData.prizeType.map((item, index) => {
                          if (item === "Money") {
                            arr.push(event.target.value);
                          } else {
                            arr.push("");
                          }
                        });
                        setRaffleFields(arr, "currency");
                      }}
                    >
                      {currencies.map((obj, index) => {
                        return (
                          <option key={index} value={obj.symbol}>
                            {obj.symbol + " (" + obj.code + ")"}
                          </option>
                        );
                      })}
                    </select>
                }
                <input
                  disabled={
                    raffleData.fee <= 0 ||
                    (raffleData.status !== "CREATED" &&
                      !raffleData.extras.draft &&
                      type === "EDIT" &&
                      !seriesEdit)
                  }
                  style={{ width: "100px" }}
                  type={raffleData.fee === 0 ? "text" : "number"}
                  id="enter-fee"
                  min={0}
                  value={raffleData.fee === 0 ? "0" : raffleData.fee}
                  onChange={(event) => {
                    const value = parseInt(event.target.value);
                    setRaffleFields(parseInt(event.target.value), "fee");
                    if (value <= 0) {
                      setRaffleFields(
                        { ...raffleData.extras, ticketLimitPerUser: 1 },
                        "extras"
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="ticket-limit">
            <label htmlFor="enter-fee" style={{ width: "160px" }}>
              TICKET LIMIT PER USER
            </label>
            <input
              disabled={raffleData.fee <= 0 || disablingTabs}
              id="input-field"
              min={1}
              max={10}
              type="number"
              value={raffleData.fee <= 0 ? 1 : raffleData.extras.ticketLimitPerUser}
              onChange={(e) => {
                handleTicketLimitPerUser(e);
              }}
              required
            />
          </div>
        </div>
      </div>
      <input
        type="file"
        onChange={async (e) => {
          dispatch(togggleLoad());
          const file = e.currentTarget.files[0];
          const newUuid = uuidv4();
          // if (
          //   type === "EDIT" &&
          //   raffle.type === "Series"
          //   // seriesEdit !== true
          // ) {
          // }
          raffleData.uuid = newUuid;
          raffleData.extras.imageUploaded = true;

          if (file.type == "video/mp4" ? file.size / 1024 / 1024 <= 10 : file.size / 1024 / 1024 <= 5) {
            const rafflePicRef = firebase
              .storage()
              .ref()
              .child("raffle_pics/" + raffleData.uuid);
            await rafflePicRef.put(file);
            rafflePicRef
              .getDownloadURL()
              .then((url) => {
                setRaffleLogo(url);
                dispatch(togggleLoad());
              })
              .catch((e) => {
                dispatch(togggleLoad());
              });
          } else {
            dispatch(togggleLoad());
            alert.removeAll();
            alert.show(file.type == "video/mp4" ? "File size must be less than 10MB" : "File size must be less than 5MB");
          }
        }}
        ref={imgupRef}
        accept="image/*,video/*"
        hidden
      />
    </div>
  );
};
