import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import "./index.scss";
import { joinRaffle, joinAsGuest, getRaffleById, getCheckSumHost, web_url, sendEmails as sendEmailsEndpoint, getUserByEmail, sendInvitation } from "../../config/endpoints";
import { request } from "../../helper/request";
import { useAlert } from "react-alert";
import Loader from "react-loader-spinner";
import cryptoJS from "crypto-js";
import paymentGateway from "../workSpace/models/paymentGateway";
import { dateFormatter, generateRandomCode, getDateStrWithTZ } from "../../helper/appService";
import PhoneInput from "react-phone-input-2";
import RaffleTicketViewModel from "../workSpace/drawerPages/hostedRaffles/RaffleTicketViewModel";
import SocialShareModel from "../workSpace/models/socialShareModel";
import ReactTooltip from "react-tooltip";
import RaffleTicket from '../../assets/svg_components/raffle_ticket';


const str_pass = "8isahnf9saipfsafnlkjaslfkj";

export default (props) => {
  const location = useLocation();
  const history = useHistory();
  const [isAuth, toggleAuth] = useState("NA");
  const [email, setEmail] = useState("");
  const alert = useAlert();
  const [raffleId, setRaffleId] = useState("");
  const [status, setStatus] = useState("");
  const [loading, toggleLoading] = useState(false);
  const [raffle, setRaffle] = useState(null);
  const [choosenRafflePrizes, setChoosenRafflePrizes] = useState([]);
  const [host, setHost] = useState({});
  const [activeTab, setActiveTab] = useState("reg-user");
  const [guestInfo, setGuestInfo] = useState({ fname: "", lname: "", email: "", mobile: "", })
  const [activeModel, setActiveModel] = useState("");
  const [socialShareLink, setSocialShareLink] = useState("");
  const [isregisteredUser, setIsRegisteredUser] = useState(false);
  const [paramsNum, setParamsNum] = useState(1);
  const [invLinkEmail, setInvLinkEmail] = useState("");


  // getRaffle details
  useEffect(() => {
    setActiveTab("reg-user");
    if (raffleId.length > 0) {
      toggleLoading(true);
      request("POST_AUTH", getRaffleById, { _id: raffleId })
        .then(async (res) => {
          const raffle = res.payload.raffle;
          const prizes = res.payload.prizes
          setRaffle(raffle);
          setChoosenRafflePrizes(prizes);
          const participantObj = await request("POST_AUTH", getUserByEmail, { email: raffle.createdBy });
          setHost(participantObj.payload[0])
          toggleLoading(false);
          if (new Date(raffle.participationOpens) > new Date()) {
            setStatus("Raffle is not open yet. Please check back after " +
            new Date(raffle.participationOpens).toLocaleString("en-US"));
          } else if (new Date(raffle.participationCloses) <= new Date()) {
            setStatus("Raffle is closed " +
            new Date(raffle.participationCloses).toLocaleString());
          }
        })
        .catch((e) => {
          toggleLoading(false);
          alert.removeAll();
          alert.show(e.message);
        });
      
    }
  }, [raffleId]);

  // validation page
  useEffect(() => {
    const decryptedStr = cryptoJS.AES.decrypt(
      location.search.split("?")[1],
      str_pass
    );
    const queryParams =
      location.search.split("?")[0] +
      "?" +
      decryptedStr.toString(cryptoJS.enc.Utf8);

    if (queryParams.split("&").length === 3) {
        const tempRaffleId = queryParams.split("&")[0].split("=")[1];
        const tempEmail = queryParams.split("&")[1].split("=")[1];
        const invId = queryParams.split("&")[2].split("=")[1];
        setParamsNum(3);
        sessionStorage.setItem("invitationId", invId);
        setRaffleId(tempRaffleId);
        setEmail(tempEmail);
        setInvLinkEmail(tempEmail);
        setGuestInfo({...guestInfo, email: tempEmail});
        toggleAuth(true);
    } else {
      if (queryParams.length > 0 && location.search.split("=").length === 2) {
        const tempRaffleId = location.search.split("=")[1];
        setTimeout(() => {
          setRaffleId(tempRaffleId);
        }, 200);
        toggleAuth(true);
      } else {
        toggleAuth(false);
      }
    }
  }, []);

  useEffect(() => {
    if (raffle !== null) {

      if (paramsNum === 1) {
        guestInfo.email = email;
        setGuestInfo(guestInfo);
      }

      const decryptedStr = cryptoJS.AES.decrypt(
        location.search.split("?")[1],
        str_pass
      );
      const queryParams =
        location.search.split("?")[0] +
        "?" +
        decryptedStr.toString(cryptoJS.enc.Utf8);

      if (
        queryParams.split("&").length === 3 &&
        email.length > 0 &&
        raffleId.length > 0
      ) {
        invLinkCLicked();
      }
    }
  }, [email, raffleId, raffle])

  // call back specific invitation
  // useEffect(() => {
  //   if (raffle !== null) {
  //     const decryptedStr = cryptoJS.AES.decrypt(
  //       location.search.split("?")[1],
  //       str_pass
  //     );
  //     const queryParams =
  //       location.search.split("?")[0] +
  //       "?" +
  //       decryptedStr.toString(cryptoJS.enc.Utf8);

  //     if (
  //       queryParams.split("&").length === 2 &&
  //       email.length > 0 &&
  //       raffleId.length > 0
  //     ) {
  //       joinClicked();
  //     }
  //   }
  // }, [email, raffleId, raffle]);

  const invLinkCLicked = () => {
    toggleLoading(true);
    request("POST_AUTH", getUserByEmail, { email: email })
      .then((response) => {
        if (response.payload.length > 0) {
          setIsRegisteredUser(true)
          alert.removeAll();
          alert.show("Log in to Join")
        } else {
          // user not exists // stay in join page // take user choice as guest or registered user
        } 
        toggleLoading(false);
      })
      .catch((e) => {
        console.log("OOPS! somthing went wrong", e.message);
        toggleLoading(false);
      });
  }

  const joinClicked =  () => {
    console.log("executing join clicked.")
    toggleLoading(true);
      request("POST_AUTH", joinRaffle, { uid: email, _id: raffleId })
      .then((response) => {
        if (response.status === 0) {
          // sessionStorage.setItem("User", JSON.stringify(user));
          setStatus("Joined successfully");
          setTimeout(() => {
            history.push("authentication");
          }, 2000);
        } else if (response.status === 2) {
          const cipher = cryptoJS.AES.encrypt(
            `id=${raffleId}&email=${email}`,
            str_pass
          ).toString();
          alert.removeAll();
          alert.show(response.msg)
        } else {
          setStatus(response.msg);
        }
        toggleLoading(false);
      })
      .catch((e) => {
        setStatus("OOPS! somthing went wrong");
        toggleLoading(false);
      });
  };

  const onSubmitGuest = async () => {

    console.log("guest info:::::::::::::::::", guestInfo);
    toggleLoading(true);
    // const guestName = `guest-${generateRandomCode(7)}`;
    const guestReqBody = {_id: raffleId, guestInfo: {...guestInfo}};
    request("POST_AUTH", joinAsGuest, guestReqBody)
      .then((response) => {
        if (response.status === 0) {
          // sessionStorage.setItem("User", JSON.stringify(user));
          alert.removeAll();
          alert.show("Joined successfully");
          setStatus("Joined as Guest successfully. Please chek your email for more information.")
        } else {
          alert.removeAll();
          alert.show(response.msg);
        }
        toggleLoading(false);
      })
      .catch((e) => {
        console.log("error in joining as guest");
        toggleLoading(false);
      });
  }

  const sendNewInv = async (raffleData, email) => {
    const invitationPayload = { from: host?.email,
      to: email,
      raffleInfo: {
        raffleId: raffleData._id,
        raffleTitle: raffleData.title,
        fee: raffleData.fee,
        feeCurrency: raffleData.feeCurrency,
        type: raffleData.type,
        isMultipleDraws: raffleData.extras.seriesRaffle.isMultipleRaffles,
      },
      participationOpens: new Date(raffleData.participationOpens),
      participationCloses: new Date(raffleData.participationCloses),
      drawAt: raffleData.type === "Instant" ? null : new Date(raffleData.drawAt),
      receivedAt: new Date(),
      isTrash: false,
      numTickets: 0,
      hostName: host?.name,
      isJoined: false,
      invitedTimes: 0,
    };
    const invResp = await request("POST_AUTH", sendInvitation, invitationPayload);
    if (invResp.status === 0) {
      sessionStorage.setItem("invitationId", invResp.payload._id);
    }
  } 

  const onRegisterToJoin = async () => {
    if (email.length === 0) {
      alert.removeAll()
      alert.show("Enter Email Address");
      return;
    } 

    if (email.length > 0) {
      // check if valid email is entered or not
      if (!email.includes('@') && !email.includes('.')) {
        alert.removeAll()
        alert.show(email + " is not a valid email Id");
        return;
      }
    }

    const userResp = await request("POST_AUTH", getUserByEmail, { email: email });
    if (userResp.payload.length > 0) {
      // exisiting user trying to Register and join
      alert.show("Email already registered. Click Login to join")
    } else  {
      sessionStorage.setItem("toJoinEmail", email);
      sessionStorage.setItem("joinChoice", "registerToJoin");
      if (paramsNum === 1) {
        await sendNewInv(raffle, email);
      }
      history.push("authentication/register");
    }
  }

  const onLoginToJoin = async () => {
    if (email.length === 0) {
      alert.removeAll()
      alert.show("Enter Email Address");
      return;
    } 

    if (email.length > 0) {
      // check if valid email is entered or not
      if (!email.includes('@') && !email.includes('.')) {
        alert.removeAll()
        alert.show(email + " is not a valid email Id");
        return;
      }
    }
    sessionStorage.setItem("toJoinEmail", email);
    props.setOpenPage("invitations");
    if (paramsNum === 1) {
      await sendNewInv(raffle, email);
    }
    history.push("authentication");
  }

  const sendEmails = async () => {
    toggleLoading(true);
    let error = "";
    if (!email.includes('@') && !email.includes('.')) {
        error = "Error enter valid email. " + email;
    }
    if (error.length === 0) {
        let link = web_url + "joinRaffle?id=" + raffleId + "&email=" + email;

        const mainLink = link.split("?")[0];
        const queryParams = link.split("?")[1];
        const cipherText = cryptoJS.AES.encrypt(queryParams, str_pass).toString();
        link = mainLink + "?" + cipherText;
        const participantObj = await request("POST_AUTH", getUserByEmail, { email: email });
        console.log(participantObj);
        const subject = `Join Our Raffle "${raffle.title}" Event - Win Big!`
        const text = `
        <div> 
          <p>Hello ${participantObj.payload[0]?.name || 'Participant'},</p>
          <p style="margin-bottom:10px">Exciting news! You're invited to our raffle:</p>
          <p style="margin-bottom:10px">🎉 Raffle Name: ${raffle.title}</p>
          <p>⏰ Open: ${getDateStrWithTZ(raffle.extras.timeZone, raffle.participationOpens)} (${new Date(raffle.participationOpens)})</p>
          <p>⏰ Close: ${getDateStrWithTZ(raffle.extras.timeZone, raffle.participationOpens)} (${new Date(raffle.participationCloses)})</p>
          ${raffle.type !== "Instant" ? 
            `<p style="margin-bottom:20px">🏆 Draw: ${getDateStrWithTZ(raffle.extras.timeZone, raffle.drawAt)} (${new Date(raffle.drawAt)})</p>` : ``
          }
          <p>
            Join the raffle <a style="margin-bottom:12px" href=${link}>here</a>, and Get your ticket(s) 
            for a shot at awesome prizes. Spread the word, and let's make this unforgettable!
          </p>
          <p> Ignore this email if you have already joined this raffle. </p>
          <p style="margin-bottom:10px">Best, Team Rafflera</p>
          <p style="margin-bottom:20px">
            For assistance, contact our support team at teamrafflerra@gmail.com.
          </p>
        </div>
        `;
        console.log(text);
        request("POST_AUTH", sendEmailsEndpoint, {
            mail: email, subject, text
        }).then((res) => { alert(res) }).catch((e) => {
            error = e.message;
        })
        if (error.length === 0) {
          setStatus("Invitation sent successfully");
          setTimeout(() => {
            history.push("authentication");
          }, 2000);
            alert.removeAll();
            alert.show("Invitation sent successfully");
        } else {
            alert.removeAll();
            alert.show(error);
        }
        toggleLoading(false);
    } else {
        alert.removeAll();
        alert.show(error);
        toggleLoading(false);
    }
  }

  const _renderAsGuestTab = () => {
    return(
      <form className="guest-tab-container"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmitGuest();
        }}>
        <input
          type="email"
          className="input-field"
          onChange={(e) => {
            // guestInfo.email = e.currentTarget.value;
            setGuestInfo({...guestInfo, email: e.target.value});
          }}
          value={guestInfo.email}
          placeholder="example@gmail.com"
        />
        <p>or</p>
        <PhoneInput
          disabled
          value={guestInfo.mobile}
          containerClass="conatiner_input"
          inputClass="_input"
          country={"us"}
          dropdownStyle={{ textAlign: "start" }}
          placeholder="Mobile number"
          className="input-field"
          onChange={(phone) => {
            // guestInfo.mobile = phone;
            setGuestInfo({...guestInfo, mobile: phone});
          }}
        />
        <button className="submit-btn" type="submit" 
          onSubmit={(e) => {
            e.preventDefault();
            onSubmitGuest();
          }}
        >
          JOIN
        </button>
      </form>
    )
  }
  const _renderAsRegUserTab = () => {
    return(
      <form className="reg-user-tab-container"
        onSubmit={(e) => {
          e.preventDefault();
          sendEmails();
        }}
      >
        <input
          type="email"
          required
          className="input-field"
          onChange={(e) => setEmail(e.currentTarget.value)}
          value={email}
          placeholder="example@gmail.com"
        />
        <button className="submit-btn" type="submit" 
          onSubmit={(e) => {
            e.preventDefault();
            sendEmails();
          }}
        >
          JOIN
        </button>
      </form>
    )
  }

  const _renderForm = () => {
    return (
      <form className="common-tab-container">
        <div className="inp-lbl-field">
          <label className="inp-lbl">Email Address</label>
          <input
            disabled={invLinkEmail.length > 0}
            type="email"
            required
            className="input-field"
            onChange={(e) => setEmail(e.currentTarget.value)}
            value={email}
            placeholder="example@gmail.com"
          />
        </div>
        <div className="btns-container">
          <button className={`submit-btn ${!isregisteredUser && paramsNum > 1 ? "disabled-btn" : ""}`} type="submit" 
            disabled={!isregisteredUser && paramsNum > 1}
            onClick={(e) => {
              e.preventDefault();
              onLoginToJoin();
            }}
          >
            Login to Join
          </button>
          <button className={`submit-btn ${isregisteredUser ? "disabled-btn" : ""}`} type="submit" 
            disabled={isregisteredUser}
            onClick={(e) => {
              e.preventDefault();
              onRegisterToJoin();
            }}
          >
            Register to Join
          </button>
          <button className={`submit-btn ${isregisteredUser ? "disabled-btn" : ""}`} type="submit" 
            disabled={isregisteredUser}
            onClick={(e) => {
              e.preventDefault();
              onSubmitGuest();
            }}
          >
            Join as Guest
          </button>
        </div>
      </form>
    )
  }

  const _renderGuestForm = () => {
    return (
      <form className="common-tab-container" onSubmit={(e) => {
        e.preventDefault();
        onSubmitGuest();
      }}>
        <div className="inp-lbl-field">
          <label className="inp-lbl">Email Address *</label>
          <input
            disabled={invLinkEmail.length > 0}
            type="email"
            required
            className="input-field"
            onChange={(e) => {
              guestInfo.email = e.currentTarget.value;
              setGuestInfo({...guestInfo});
            }}
            value={guestInfo.email}
            placeholder="example@gmail.com"
          />
        </div>
        <div className="inp-lbl-field">
          <label className="inp-lbl">First Name *</label>
          <input
            // disabled={invLinkEmail.length > 0}
            required
            className="input-field"
            onChange={(e) => {
              guestInfo.fname = e.currentTarget.value;
              setGuestInfo({...guestInfo});
            }}
            value={guestInfo.fname}
          />
        </div>
        <div className="inp-lbl-field">
          <label className="inp-lbl">Last Name *</label>
          <input
            // disabled={invLinkEmail.length > 0}
            required
            className="input-field"
            onChange={(e) => {
              guestInfo.lname = e.currentTarget.value;
              setGuestInfo({...guestInfo});
            }}
            value={guestInfo.lname}
          />
        </div>
        <div className="inp-lbl-field">
          <label className="inp-lbl">Phone Number</label>
          <PhoneInput
            value={guestInfo.mobile}
            containerClass="conatiner_input"
            inputClass="_input"
            country={"us"}
            dropdownStyle={{ textAlign: "start" }}
            placeholder="Mobile number"
            className="input-field"
            onChange={(phone) => {
              guestInfo.mobile = phone;
              setGuestInfo({...guestInfo});
            }}
          />
        </div>
        <div className="btns-container">
          <button className={`submit-btn ${isregisteredUser ? "disabled-btn" : ""}`} type="submit"
            onSubmit={(e) => {
              e.preventDefault();
              onSubmitGuest();
            }}
          >
            Join as Guest
          </button>
          <button className={`submit-btn disabled-btn`} type="submit" 
            disabled
            onClick={(e) => {
              e.preventDefault();
              onLoginToJoin();
            }}
          >
            Login to Join
          </button>
          <button className={`submit-btn disabled-btn`} type="submit" 
            disabled
            onClick={(e) => {
              e.preventDefault();
              onRegisterToJoin();
            }}
          >
            Register to Join
          </button>
        </div>
      </form>
    )
  }

  const _renderJoinPage = () => {
    return (
      <div className="joinPage">
        {/* header */}
          <div className="header">
            <img src={require("../../assets/img/logo.png")} className="logo-png"/>
            <label className="app-title">Rafflerra</label>
          </div>
        {/* body */}
        <div className="body">
          {/* container */}
          <div className="raffle-details">
            <label className="join-text"> Please join raffle by {host?.name} </label>
            <RaffleTicket
              raffleData={raffle}
              dates={{ start: raffle.participationOpens, end: raffle.participationCloses, drawn: raffle?.drawAt }}
              shortDescription={raffle.shortDescription}
              seriesId={raffle.extras.seriesRaffle.seriesId}
              id={raffle.id}
              title={raffle.title}
              fee={raffle.fee}
              feeCurrency={raffle.feeCurrency}
              image={null}
              createdBy={raffle.createdBy}
              styles={raffle.styles}
              onClick={() => {setActiveModel("ParticipantRaffleViewJoinModel")}}
            />
          </div>
          {status.length > 0 ? (
            _renderStatusPage()
          ) : (
            <>
              <div className="tabs-sheet">
                {/* <div className="tabs-btns-container">
                  <button className={`tab-btn ${activeTab === "reg-user" ? "active" : ""}`}
                    onClick={() => setActiveTab("reg-user")}>
                    Join as Registered User
                  </button>
                  <button className={`tab-btn ${activeTab === "guest" ? "active" : ""}`}
                    onClick={() => setActiveTab("guest")}>
                    Join as Guest
                  </button>
                </div> */}
                <div className="tabs-content">
                  {/* {activeTab === "guest" ? (_renderAsGuestTab()) : (_renderAsRegUserTab())} */}
                  {_renderGuestForm()}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const _renderModel = () => {
    if (activeModel.length > 0) {
      switch(activeModel) {
        case "SocialShareModel":
          return (
            <SocialShareModel
              onClose={() => setActiveModel("")}
              url={socialShareLink}
            />
          );
          case "ParticipantRaffleViewJoinModel":
            return (
              <RaffleTicketViewModel
                  userType={"participant"}
                  asParticipant={false}
                  asCatalogue={true}
                  choosenRaffle={raffle}
                  choosenRafflePrizes={choosenRafflePrizes}
                  toggleShareModel={(link) => {
                      setActiveModel("SocialShareModel");
                      setSocialShareLink(link);
                  }}
                  onClose={() => setActiveModel("")}
                  type={"VIEW"}
              />
            )
      }
    }
  }

  const _renderStatusPage = () => {
    return <div className="statusPage">{status}</div>;
  };

  return (
    <div className="joinRafflePage">
      {isAuth === true && raffle !== null ? (
        _renderJoinPage()
      ) : isAuth === false ? (
        <label className="not_auth_message">
          YOU ARE NOT AUTHORIZED TO VIEW THIS PAGE
        </label>
      ) : null}
      {loading ? (
        <div className="loadingspinner">
          <Loader type="TailSpin" color="rgb(11, 70, 85)" width={60} />
        </div>
      ) : null}
      {_renderModel()}
    </div>
  );
};
