import React, { useEffect, useState } from 'react';
import './index.scss';
import { GoSearch } from 'react-icons/go';
import { TiBookmark } from 'react-icons/ti';
import { LoadBeneficiaries, UpdateBeneficiaries, UpdateMyBeneficiaries } from '../../../../store/actions/beneficiaryAction';
import { useSelector, useDispatch } from 'react-redux';
import ShowBeneModel from '../../models/showBene';
import { request } from '../../../../helper/request';
import { updateBenesInUserHost } from '../../../../config/endpoints';


export default () => {
    const { beneficiaries, copyBeneficiaries, myBeneficiaries } = useSelector(benes => benes.BeneficiaryReducer);
    const dispatch = useDispatch();
    const [showBeneViewModel, toggleBeneViewModel] = useState(false);
    const [choosenBene, setChoosenBene] = useState(null);
    const [user, setUser] = useState(null)

    useEffect(() => {
        setUser(JSON.parse(sessionStorage.getItem("User")));
    }, []);

    const beneSearch = (e) => {
        let text = e.currentTarget.value.trim();
        if (text.length > 0) {
            let benes = copyBeneficiaries.filter(bene => bene.user[0].name.includes(text));
            dispatch(UpdateBeneficiaries(benes));
        } else {
            dispatch(UpdateBeneficiaries(copyBeneficiaries));
        }
    }

    const bookmarkButtonCLicked = async (toggled, _id, bene) => {
        let benes = user.benes;
        if (toggled) {
            //This has only beneficiary id's
            benes = benes.filter(x => x !== _id);
            sessionStorage.setItem("User", JSON.stringify({ ...user, "benes": benes }));
            setUser(usr => ({ ...usr, "benes": benes }));

            //updating my Beneficiaries state (removing item)
            let tempBeneficiaries = myBeneficiaries.filter(x => x._id !== _id);
            dispatch(UpdateMyBeneficiaries(tempBeneficiaries));

            await request("POST_AUTH", updateBenesInUserHost, { _id: user._id, benes });
        } else {
            //This has only beneficiary id's
            benes.push(_id);
            sessionStorage.setItem("User", JSON.stringify({ ...user, "benes": benes }));
            setUser(usr => ({ ...usr, "benes": benes }));

            //updating my Beneficiaries state(adding item)
            let tempBeneficiaries = [...myBeneficiaries, bene];
            dispatch(UpdateMyBeneficiaries(tempBeneficiaries));

            await request("POST_AUTH", updateBenesInUserHost, { _id: user._id, benes });;
        }
    }

    return <div className="host_bene_search">
        <div>
            <GoSearch size={16} color="rgba(0,0,0,0.3)" />
            <input onChange={beneSearch} placeholder="Search beneficiary by name..." />
        </div>
        <div>
            {
                beneficiaries.map((bene, i) => {
                    return <div className={bene.user[0].status === "InActive" ? "inactive" : ""} key={i}>
                        <div></div>
                        <div onClick={() => {
                            toggleBeneViewModel(true);
                            setChoosenBene(bene);
                        }
                        }>
                            <label>{bene.user[0].name}{bene.user[0].status === "Active" ? "" : " (This beneficiary not intersted to run a raffle at this moment)"} </label>
                            <label>{bene.description}</label>
                        </div>
                        <div>
                            {
                                user !== null ? user.benes.includes(bene._id) ?
                                    <TiBookmark onClick={() => bookmarkButtonCLicked(true, bene._id, bene)} color={"#E5583A"} /> :
                                    <TiBookmark onClick={() => bookmarkButtonCLicked(false, bene._id, bene)} /> : null
                            }
                        </div>
                    </div>
                })
            }
        </div>
        {
            showBeneViewModel ?
                <ShowBeneModel
                    onClose={() => toggleBeneViewModel(false)}
                    data={choosenBene}
                /> : null
        }
    </div>
}