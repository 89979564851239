import React, { useState, useEffect, Fragment } from "react";
import './index.scss';
import { GoSearch } from 'react-icons/go';
import { FaSortAmountDownAlt, FaSortAmountUpAlt, FaSortAlphaDown, FaSortAlphaDownAlt, FaSearch, FaList, FaTicketAlt, FaRegEye, FaRedoAlt } from 'react-icons/fa';
import { BsCalendar } from "react-icons/bs";
import { BiGridAlt } from "react-icons/bi";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import DatePicker from "react-datepicker";

import NoRafflesFound from '../../../../assets/NoRafflesFound';
import WorkspaceWelcome from '../../../../assets/WorkspaceWelcome';
import RaffleTicket from '../../../../assets/svg_components/raffle_ticket';
import { calRaffles, getRaffleById, mySearch, mySearchCount, partCount, partRaffles } from '../../../../config/endpoints';
import { request } from '../../../../helper/request';
import ReactTooltip from "react-tooltip";
import Loader from 'react-loader-spinner';

const sortOrderList = ["ASCENDING", "DESCENDING"];

const monthsList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const options = {
  hour: "2-digit",
  minute: "numeric",
  month: "2-digit",
  day: "2-digit",
  year: "numeric",
};

// calender view
const daysList = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export default ({ setActiveModel, setChoosenRaffle, setChoosenRafflePrizes, toRefreshSidePanel }) => {
  const [localRaffles, setLocalRaffles] = useState([]);
  const [localTempRaffles, setLocalTempRaffles] = useState([]);
  const [sortRaffle, setSortRaffle] = useState("ALL");
  const [sortRaffle3, setSortRaffle3] = useState("OPEN");
  const [sortBy, setSortBy] = useState("title");
  const [sortOrder, setSortOrder] = useState(null);
  const [sortRafflesList, setSortRafflesList] = useState({
    ALL: "0",
    ONGOING: "0",
    DRAWN: "0",
    COMPLETE: "0",
    WON: "0",
    CANCEL: "0",
    CLOSE: "0",
  })

  // list of raffle view
  const [raffleView, setRaffleView] = useState("Grid");
  // calender view
  const [currentDay, setCurrentDay] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(new Date());

  // search input
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState(0);

  const calculateTotalPages = (count) => {
    const pages = Math.ceil(count / itemsPerPage);
    setTotalPages(pages);
    return pages;
  }

  const apiParticipatedList = (email, status, page, itemsPerFetch) => {
    setLoading(true);
    setCurrentPage(page);
    request("GET_AUTH", partRaffles + `?email=${email}&status=${status}&page=${page}&itemsPerFetch=${itemsPerFetch}`).then((res) => {
      if (res.status === 0) {
        const payload = res.payload;
        setLocalRaffles(payload);
        setLocalTempRaffles(payload)
        setLoading(false);
      } else {
        console.log("error res", res);
        setLoading(false);
      }
    }).catch((e) => {
      console.log("error in apiParticipantedList");
      setLoading(false);
    })
  }

  const apiSearchList = (email, role, status, sortBy, search, page) => {
    setLoading(true);
    setCurrentPage(page);
    request("GET_AUTH", mySearch + `?email=${email}&role=${role}&lifeState=${status}&sortBy=${sortBy}&search=${search}&page=${page}`).then((res) => {
      if (res.status === 0) {
        const payload = res.payload;
        setLocalRaffles(payload);
        setLocalTempRaffles(payload)
        setLoading(false);
      } else {
        console.log("error res", res);
        setLoading(false);
      }
    }).catch((e) => {
      console.log("error in apiSearchList");
      setLoading(false);
    })
  }

  const apiSearchcount = (email, role, sortBy, search) => {
    request("GET_AUTH", mySearchCount + `?email=${email}&role=${role}&sortBy=${sortBy}&search=${search}`).then((res) => {
      if (res.status === 0) {
        setSortRafflesList(res.payload);
        calculateTotalPages(res.payload[sortRaffle]);
      } else {
        console.log("error res", res);
      }
    }).catch((e) => {
      console.log("error in apiSearchcount");
    })
  }

  const apiPartcount = (email) => {
    request("GET_AUTH", partCount + `?email=${email}`).then((res) => {
      if (res.status === 0) {
        setSortRafflesList(res.payload);
        calculateTotalPages(res.payload[sortRaffle]);
      } else {
        console.log("error res", res);
      }
    }).catch((e) => {
      console.log("error in apiPartcount");
    })
  }

  // to get hosted raffle of host email alone
  const apiCalenderList = (email, from, to, role) => {
    setLoading(true);
    request(
      "GET_AUTH",
      calRaffles + `?email=${email}&from=${from}&to=${to}&role=${role}`
    )
      .then((res) => {
        if (res.status === 0) {
          const payload = res.payload;
          setLocalRaffles(payload);
          setLocalTempRaffles(payload)
          setLoading(false);
        } else {
          console.log("error res", res);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("error in apiCalenderList");
        setLoading(false);
      });
  };

  //load raffles or to localRaffles
  useEffect(() => {
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    apiParticipatedList(email, sortRaffle, currentPage, itemsPerPage);
    apiPartcount(email);
  }, []);

  const sortingBySelectedOrder = (list, order, sortOption) => {
    let sortedList = [];
    if (order === "ASCENDING") {
      // do ascending
      if (sortOption === "drawAt") {
        sortedList = list.sort((a, b) => {
          let aDate = new Date(a?.drawAt);
          let bDate = new Date(b?.drawAt);
          if (!a.drawAt) {
            aDate = new Date(a.drawAt);
          }
          if (!b.drawAt) {
            bDate = new Date(b.drawAt);
          }
          return aDate - bDate;
        });
      } else if (sortOption === "participationOpens" || sortOption === "participationCloses") {
        sortedList = list.sort((a, b) => new Date(a[sortOption]) - new Date(b[sortOption]));
      } else {
        sortedList = list.sort((a, b) => a[sortOption].localeCompare(b[sortOption]));
        // arr.sort((a, b) => a.title.localeCompare(b.title));
      }
    } else {
      // do descending
      if (sortOption === "drawAt") {
        sortedList = list.sort((a, b) => {
          let aDate = new Date(a?.drawAt);
          let bDate = new Date(b?.drawAt);
          if (!a.drawAt) {
            aDate = new Date(a.drawAt);
          }
          if (!b.drawAt) {
            bDate = new Date(b.drawAt);
          }
          return bDate - aDate;
        });
      } else if (sortOption === "participationOpens" || sortOption === "participationCloses") {
        sortedList = list.sort((a, b) => new Date(b[sortOption]) - new Date(a[sortOption]));
      } else {
        sortedList = list.sort((a, b) => b[sortOption].localeCompare(a[sortOption]));
      }
    }
    setLocalRaffles(sortedList);
    return sortedList;
  }

  const onToggleLifeState = async (lifeCycleState) => {
    setSortRaffle(lifeCycleState);
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    calculateTotalPages(sortRafflesList[lifeCycleState]);
    if (searchInput.length === 0) {
      apiParticipatedList(email, lifeCycleState, 1, itemsPerPage);
    } else {
      apiSearchList(email, 'participant', lifeCycleState, sortBy, searchInput, 1);
    }
  }

  const onChangeSearch = (text) => {
    setSearchInput(text);
  }

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    const tp = calculateTotalPages(sortRafflesList[sortRaffle]);
    if (searchInput.length === 0) {
      apiParticipatedList(email, sortRaffle, tp > currentPage ? currentPage : tp, itemsPerPage);
    } else {
      apiSearchList(email, 'participant', sortRaffle, sortBy, searchInput, tp > currentPage ? currentPage : tp);
    }
  }, [currentPage, itemsPerPage]);

  // on click refresh button
  const onRefresh = () => {
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    toRefreshSidePanel();
    setSearchInput("");
    if (raffleView === "Calender") {
      // call from, to dates api
      const selectedDate = new Date(currentDay);
      const from = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        1,
        0,
        0,
        0,
        0
      ).toISOString();
      const to = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        0,
        23,
        59,
        0
      ).toISOString();
      apiCalenderList(email, from, to, 'participant');
    } else if (raffleView === "WeeklyCalender") {
      // call from, to dates api
      console.log("currentWeek::::::::::::::::::", currentWeek);
      const selectedDate = new Date(currentWeek);
      const from = new Date(selectedDate.setHours(0, 0, 0, 0)).toISOString();
      const to = new Date(
        new Date(from).getTime() + 7 * 24 * 3599999
      ).toISOString();
      apiCalenderList(email, from, to, 'participant');
    } else {
      apiParticipatedList(email, sortRaffle, currentPage, itemsPerPage);
      apiPartcount(email);
    }
  };

  const onClickXbtn = () => {
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    setSearchInput("");
    apiParticipatedList(email, sortRaffle, 1, itemsPerPage);
    apiPartcount(email);
  }

  const onClickSearchbtn = () => {
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    apiSearchList(email, 'participant', sortRaffle, sortBy, searchInput, 1);
    apiSearchcount(email, 'participant', sortBy, searchInput);
  }

  const onChangeView = (view) => {
    setRaffleView(view);
  };

  const onClickViewItem = (rff) => {
    setChoosenRaffle({ ...rff });
    setActiveModel("ParticipantRaffleViewModel");
  }

  useEffect(() => {
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    setLoading(true);
    if (raffleView === "Calender") {
      // call from, to dates api
      const selectedDate = new Date(currentDay);
      const from = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        1,
        0,
        0,
        0,
        0
      ).toISOString();
      const to = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        0,
        23,
        59,
        0
      ).toISOString();
      apiCalenderList(email, from, to, 'participant');
    } else if (raffleView === "WeeklyCalender") {
      // call from, to dates api
      console.log("currentWeek::::::::::::::::::", currentWeek);
      const selectedDate = new Date(currentWeek);
      const from = new Date(selectedDate.setHours(0, 0, 0, 0)).toISOString();
      const to = new Date(
        new Date(from).getTime() + 7 * 24 * 3599999
      ).toISOString();
      apiCalenderList(email, from, to, 'participant');
    } else {
      apiParticipatedList(email, sortRaffle, 1);
      apiPartcount(email);
    }
  }, [raffleView, currentDay, currentWeek]);

  const _renderTicketsView = () => {
    return (
      sortRafflesList[sortRaffle] === 0 ?
        <div className={"no_raffles"}>
          <NoRafflesFound />
        </div> :
        <div className='raffles_list'>
          {localRaffles.map((raffle, i) => {
            const email = JSON.parse(sessionStorage.getItem("User")).email;
            if (!("isTemplate" in raffle.extras && raffle.extras.isTemplate) && raffle.extras.active) {
              let ticksList = raffle.participants.filter(x => x.email === email);
              return (
                <RaffleTicket
                  key={i} // Use 'index' for the key to avoid duplicate keys.
                  raffleData={raffle}
                  dates={{ start: raffle.participationOpens, end: raffle.participationCloses, drawAt: raffle.drawAt }}
                  shortDescription={raffle.shortDescription}
                  id={raffle.id}
                  title={raffle.title}
                  participated={true}
                  ticketNo={ticksList}
                  image={null}
                  createdBy={raffle.createdBy}
                  styles={raffle.styles}
                  onClick={() => {
                    // setChoosenRaffle({ ...raffle, image: img });
                    // setActiveModel("ParticipantRaffleViewModel");
                    onClickAnyAction(raffle._id, "view")
                  }}
                />
              );
            }
          })}
        </div>
    );
  };

  const _renderListView = (Raffslist) => {
    return (
      <div className="raffs-list-view">
        <table className="raffs-table">
          <thead className="raffs-thead">
            <tr className="raffs-thead-tr">
              <th className="rth-snum">#</th>
              <th className="rth-title">Title</th>
              <th className="rth-t">Type</th>
              <th className="rth-rt">Recurrence</th>
              <th className="rth-oa">Opens At</th>
              <th className="rth-ca">Closes At</th>
              <th className="rth-da">Draw At</th>
              <th className="rth-ef">Entry Fee</th>
            </tr>
          </thead>
          <tbody className="raffs-tbody">
            {Raffslist.map((raffle, i) => {
              return (
                <tr className="raffs-tbody-tr" key={i}
                  onClick={() => {
                    // setChoosenRaffle({ ...raffle });
                    // setActiveModel("ParticipantRaffleViewModel");
                    onClickAnyAction(raffle._id, "view")
                  }}
                >
                  <td className="rtd-snum">{((currentPage - 1) * itemsPerPage) + (i + 1)}</td>
                  <td className="rtd-title"
                    data-tip data-for={"short_description" + i}>{(raffle.title.length > 16
                      ? raffle.title.substring(0, 16) + "..."
                      : raffle.title) + ` (${raffle.rafflerType})` +
                      (raffle.extras.seriesRaffle.isMultipleRaffles ? "" : "*")}
                    <ReactTooltip place="bottom" id={"short_description" + i}>
                      {raffle.shortDescription}
                    </ReactTooltip>
                  </td>
                  <td className="rtd-t">{raffle?.type}</td>
                  <td className="rtd-rt">{raffle.extras.seriesRaffle.recurrencePattern}</td>
                  <td className="rtd-oa">{new Date(raffle.participationOpens).toLocaleString('en-US', options)}</td>
                  <td className="rtd-ca">{new Date(raffle.participationCloses).toLocaleString('en-US', options)}</td>
                  <td className="rtd-da">
                    {raffle.status === "DRAWN" || raffle.status === "COMPLETE"
                      ? new Date(raffle.drawnAt).toLocaleString("en-US", options) :
                      raffle.type === "Instant"
                        ? "Draw Time On Demand" :
                        new Date(raffle.drawAt).toLocaleString("en-US", options)
                    }
                  </td>
                  <td className="rtd-ef">{raffle.fee} ({raffle.feeCurrency})</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  };

  const _renderPagination = () => {
    return (
      sortRafflesList[sortRaffle] > itemsPerPage && (raffleView === "Grid" || raffleView === "List") && <div className="part-pagination">
        {currentPage > 1 && (
          <button className="page-num"
            onClick={() => handlePageChange(currentPage - 1)}
          >
            &lt;
          </button>
        )}
        {currentPage >= 1 && (
          <button
            className={`page-num ${currentPage === 1 ? "active" : ""
              }`}
            onClick={() => handlePageChange(1)}>
            1
          </button>
        )}
        {currentPage > 3 && <span className="ellipsis">...</span>}
        {Array.from({ length: totalPages }).map((_, index) => {
          // Determine the range of pages to display
          const startPage = Math.max(2, currentPage - 2);
          const endPage = Math.min(totalPages - 1, startPage + 4);

          // Display page buttons within the range
          if (index + 1 >= startPage && index + 1 <= endPage) {
            return (
              <button
                key={index + 1}
                className={`page-num ${currentPage === index + 1 ? "active" : ""
                  }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            );
          }
          return null;
        })}
        {currentPage < totalPages - 2 && <div className="ellipsis">...</div>}

        {currentPage <= totalPages && (
          <button
            className={`page-num ${currentPage === totalPages ? "active" : ""
              }`}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </button>
        )}

        {currentPage < totalPages && (
          <button
            className="page-num"
            onClick={() => handlePageChange(currentPage + 1)}
          >
            &gt;
          </button>
        )}
      </div>
    );
  };

  const filterForCalView = (currentDate) => {
    let todayRaffle = [];
    if (sortRaffle3 === "ALL") {
      todayRaffle = localRaffles.filter(
        (x) =>
          ((x.type !== "Instant" &&
            new Date(x?.drawAt).toLocaleDateString() == currentDate) ||
            new Date(x.participationOpens).toLocaleDateString() ==
            currentDate ||
            new Date(x.participationCloses).toLocaleDateString() ==
            currentDate) &&
          !x.extras.isTemplate
      );
    }
    if (sortRaffle3 === "OPEN") {
      todayRaffle = localRaffles.filter(
        (x) =>
          new Date(x.participationOpens).toLocaleDateString() == currentDate &&
          !x.extras.isTemplate
      );
    }
    if (sortRaffle3 === "CLOSE") {
      todayRaffle = localRaffles.filter(
        (x) =>
          new Date(x.participationCloses).toLocaleDateString() == currentDate &&
          !x.extras.isTemplate
      );
    }
    if (sortRaffle3 === "DRAW") {
      todayRaffle = localRaffles.filter(
        (x) =>
          x.type !== "Instant" &&
          new Date(x?.drawAt).toLocaleDateString() == currentDate &&
          !x.extras.isTemplate
      );
    }
    return todayRaffle;
  };

  const onClickAnyAction = async (raffleId, choosenOp) => {
    setLoading(true);
    request("POST_AUTH", getRaffleById, { _id: raffleId })
      .then((res) => {
        if (res.status === 0) {
          const raff = res.payload.raffle;
          const raffPrizes = res.payload.prizes;
          setChoosenRaffle(raff);
          setChoosenRafflePrizes(raffPrizes)
          if (choosenOp === "view") {
            setChoosenRaffle({ ...raff });
            setActiveModel("ParticipantRaffleViewModel");
          }
          setLoading(false);
        } else {
          alert.removeAll();
          alert.show("Error loading raffle by id");
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const _renderCalenderView = () => {
    return (
      <div className="calender_view">
        {/* days in a week */}
        <div className="daysList">
          {daysList.map((_day, index) => (
            <div key={index}>{_day}</div>
          ))}
        </div>
        {/* days list */}
        <div className="datesList">
          {[...new Array(42).keys()]
            .map((x) => x + 1)
            .map((index, key) => {
              const tempDate = currentDay;
              tempDate.setDate(1);
              let startIndex = tempDate.getDay();
              if (startIndex === 0) {
                startIndex = 7;
              }
              currentDay.setMonth(currentDay.getMonth() + 1);
              currentDay.setDate(0);
              const endIndex = currentDay.getDate();
              if (index >= startIndex && index < endIndex + startIndex) {
                const cDate = index - startIndex + 1;
                const currentDate = new Date(
                  currentDay.getFullYear(),
                  currentDay.getMonth(),
                  cDate
                ).toLocaleDateString();

                let todayRaffle = filterForCalView(currentDate);

                return (
                  <div key={key}>
                    <div className="index">{cDate + ""}</div>
                    {todayRaffle.map((raff, index) => {
                      let redDot = false;
                      let greenDot = false;
                      let brownDot = false;
                      if (
                        raff.type === "Instant"
                      ) {
                        redDot =
                          new Date(
                            raff.participationCloses
                          ).toLocaleDateString() == currentDate;
                        greenDot =
                          new Date(
                            raff.participationOpens
                          ).toLocaleDateString() == currentDate;
                      } else {
                        brownDot =
                          new Date(raff.drawAt).toLocaleDateString() ==
                          currentDate;
                        redDot =
                          new Date(
                            raff.participationCloses
                          ).toLocaleDateString() == currentDate;
                        greenDot =
                          new Date(
                            raff.participationOpens
                          ).toLocaleDateString() == currentDate;
                      }
                      return (
                        <div data-tip data-for={raff._id} className="capsule" key={index}>
                          <span className="raff-title">
                            {raff.title.substring(0, 4)}
                          </span>
                          {sortRaffle3 === "OPEN" && (
                            <span className="dot greendot"></span>
                          )}
                          {sortRaffle3 === "CLOSE" && (
                            <span className="dot reddot"></span>
                          )}
                          {sortRaffle3 === "DRAW" && (
                            <span className="dot browndot"></span>
                          )}
                          {sortRaffle3 === "ALL" && greenDot && (
                            <span className="dot greendot"></span>
                          )}
                          {sortRaffle3 === "ALL" && redDot && (
                            <span className="dot reddot"></span>
                          )}
                          {sortRaffle3 === "ALL" && brownDot && (
                            <span className="dot browndot"></span>
                          )}
                          <span className="asterisk">
                            {raff.extras.seriesRaffle.isMultipleRaffles
                              ? ""
                              : "*"}
                          </span>
                        </div>
                      );
                    })}
                    {todayRaffle.map((raff, index) => {
                      const email = JSON.parse(sessionStorage.getItem("User")).email;
                      let ticksList = raff.participants.filter(x => x.email === email);
                      return (
                        <ReactTooltip
                          className="card_event_tooltip"
                          place="top"
                          id={raff._id}
                          clickable
                          key={index}
                        >
                          <RaffleTicket
                            key={index} // Use 'index' for the key to avoid duplicate keys.
                            raffleData={raff}
                            dates={{ start: raff.participationOpens, end: raff.participationCloses, drawAt: raff.drawAt }}
                            shortDescription={raff.shortDescription}
                            id={raff.id}
                            title={raff.title}
                            participated={true}
                            ticketNo={ticksList}
                            image={null}
                            createdBy={raff.createdBy}
                            styles={raff.styles}
                            onClick={() => {
                              onClickAnyAction(raff._id, "view")
                            }}
                          />
                        </ReactTooltip>
                      );
                    })}
                  </div>
                );
              } else {
                return <div key={key} className="none"></div>;
              }
            })}
        </div>
      </div>
    );
  };

  const _renderWeeklyView = () => {
    return (
      <div className="weekly_view">
        {/* days in a week */}
        <div className="weekly-columns">
          {[...new Array(7).keys()].map((x) => {
            const day = 24 * 60 * 60 * 1000 * x;
            const cdate = new Date(currentWeek.getTime() + day);
            let todayRaffle = filterForCalView(cdate.toLocaleDateString());
            return (
              <div className="day-column" key={x}>
                <div className="date-day">
                  <div className="date">{cdate.toLocaleDateString()}</div>
                  <div className="day">
                    {daysList[cdate.getDay() === 0 ? 6 : cdate.getDay() - 1]}
                  </div>
                </div>
                <div className="raffleslist-col">
                  {todayRaffle.map((raff, index) => {
                    let redDot = false;
                    let greenDot = false;
                    let brownDot = false;
                    if (
                      raff.type === "Instant"
                    ) {
                      redDot =
                        new Date(
                          raff.participationCloses
                        ).toLocaleDateString() == cdate.toLocaleDateString();
                      greenDot =
                        new Date(
                          raff.participationOpens
                        ).toLocaleDateString() == cdate.toLocaleDateString();
                    } else {
                      brownDot =
                        new Date(raff.drawAt).toLocaleDateString() ==
                        cdate.toLocaleDateString();
                      redDot =
                        new Date(
                          raff.participationCloses
                        ).toLocaleDateString() == cdate.toLocaleDateString();
                      greenDot =
                        new Date(
                          raff.participationOpens
                        ).toLocaleDateString() == cdate.toLocaleDateString();
                    }
                    return (
                      <div
                        data-tip
                        data-for={raff._id}
                        className="capsule"
                        key={index}
                      >
                        <span className="raff-title">
                          {raff.title.substring(0, 4)}
                        </span>
                        {sortRaffle3 === "OPEN" && (
                          <span className="dot greendot"></span>
                        )}
                        {sortRaffle3 === "CLOSE" && (
                          <span className="dot reddot"></span>
                        )}
                        {sortRaffle3 === "DRAW" && (
                          <span className="dot browndot"></span>
                        )}
                        {sortRaffle3 === "ALL" && greenDot && (
                          <span className="dot greendot"></span>
                        )}
                        {sortRaffle3 === "ALL" && redDot && (
                          <span className="dot reddot"></span>
                        )}
                        {sortRaffle3 === "ALL" && brownDot && (
                          <span className="dot browndot"></span>
                        )}
                        <span className="asterisk">
                          {raff.extras.seriesRaffle.isMultipleRaffles
                            ? ""
                            : "*"}
                        </span>
                      </div>
                    );
                  })}
                  {todayRaffle.map((raff, index) => {
                    const email = JSON.parse(sessionStorage.getItem("User")).email;
                    let ticksList = raff.participants.filter(x => x.email === email);
                    return (
                      <ReactTooltip
                        className="card_event_tooltip"
                        place="top"
                        id={raff._id}
                        clickable
                        key={index}
                      >
                        <RaffleTicket
                          key={index} // Use 'index' for the key to avoid duplicate keys.
                          raffleData={raff}
                          dates={{ start: raff.participationOpens, end: raff.participationCloses, drawAt: raff.drawAt }}
                          shortDescription={raff.shortDescription}
                          id={raff.id}
                          title={raff.title}
                          participated={true}
                          ticketNo={ticksList}
                          image={null}
                          createdBy={raff.createdBy}
                          styles={raff.styles}
                          onClick={() => {
                            onClickAnyAction(raff._id, "view")
                          }}
                        />
                      </ReactTooltip>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const _renderRaffles = () => {
    return (
      raffleView === "List" ? (
        _renderListView(localRaffles)
      ) : raffleView === "Calender" ? (
        _renderCalenderView()
      ) : raffleView === "WeeklyCalender" ? (
        _renderWeeklyView()
      ) : (
        _renderTicketsView()
      )
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onClickSearchbtn(); // Call your search action function here
    }
  };
  

  return <div className="participant_my_raffles">
    {/* Header */}
    <div>
      <div>
        <GoSearch size={16} color="rgba(0,0,0,0.3)" />
        <input
          onChange={(e) => {
            const text = e.currentTarget.value;
            onChangeSearch(text);
          }}
        
          placeholder={`Search here...`}
          type={(
            sortBy === "participationOpens" ||
            sortBy === "participationCloses" ||
            sortBy === "drawAt") ?
            'date' :
            'text'
          }
          onKeyDown={handleKeyDown}
          className={(
            sortBy === "participationOpens" ||
            sortBy === "participationCloses" ||
            sortBy === "drawAt") ? "participant-date-filter" : "participant-search-bar"}
          value={searchInput} />
        {searchInput.length > 0 && <button className='search-x-btn'
          onClick={onClickXbtn}>
          X
        </button>}
        <button className={searchInput.length === 0 ? 'search-btn-dsbld' : 'search-btn'}
          onClick={onClickSearchbtn}
          disabled={searchInput.length === 0}>
           <FaSearch />
        </button>
        <select onChange={(e) => {
          setSearchInput("");
          const option = e.currentTarget.value;
          setSortBy(option);
          setLocalRaffles([...localTempRaffles]);
          setSortOrder(null);
        }}>
          <option key="title" value="title">Title</option>
          <option key="rafflerType" value="rafflerType">Category</option>
          <option key="createdBy" value="createdBy">Hosted By</option>
          <option key="participationOpens" value="participationOpens">Open Date</option>
          <option key="participationCloses" value="participationCloses">Close Date</option>
          <option key="drawAt" value="drawAt">Draw Date</option>
          <option key="recurrencePattern" value="recurrencePattern">Recurrence</option>
        </select>
        <div className='sortorder-switch'>
          {
            sortOrderList.map((item, i) => (
              <label
                onClick={async () => {
                  setSortOrder(item);
                  sortingBySelectedOrder(localTempRaffles, item, sortBy);
                }}
                className={sortOrder === item ? "active" : ""}
                key={i}>
                <span>
                  {item === "ASCENDING"
                    ?
                    sortBy === "participationOpens" ||
                      sortBy === "participationCloses" ||
                      sortBy === "drawAt" ?
                      <FaSortAmountDownAlt /> :
                      <FaSortAlphaDown />
                    :
                    sortBy === "participationOpens" ||
                      sortBy === "participationCloses" ||
                      sortBy === "drawAt" ?
                      <FaSortAmountUpAlt /> :
                      <FaSortAlphaDownAlt />
                  }
                </span>
              </label>
            ))
          }
        </div>
      </div>
    </div>
    {/* Sort list of raffles */}
    {/* <div>
            <div className='life-state-row'>
                <button className="refresh-btn" onClick={onRefresh}>
                    <RefreshIcon className="refresh-icon" />
                </button>
                {
                    Object.keys(sortRafflesList).map((item, i) =>
                    (<label
                        onClick={async () => {
                            onToggleLifeState(item);
                        }}
                        className={sortRaffle === item ? "active" : ""}
                        key={i}>
                        {`${item} ${sortRafflesList[item] === "0" ? "" : "(" + sortRafflesList[item] + ")"}`}
                    </label>))
                }
            </div>
        </div> */}
    {/* Sort list of raffles */}
    <div className="tabs-container">
      {raffleView === "WeeklyCalender" || raffleView === "Calender" ? (
        <>
          <div className="view-tabs">
            <button className="refresh-btn" onClick={onRefresh}>
              <FaRedoAlt className="refresh-icon" />
            </button>
            <div className="text_weekly_monthly">
              <button
                onClick={() => onChangeView("WeeklyCalender")}
                className={raffleView === "WeeklyCalender" ? "active" : "inactive"}
              >
                WEEKLY
              </button>
              {window.innerWidth > 720 &&
                <button
                disabled={window.innerWidth < 720}
                onClick={() => {
                  onChangeView("Calender");
                }}
                className={raffleView === "Calender" ? "active" : (window.innerWidth < 720 ? "disable" : "inactive")}
              >
                MONTHLY
              </button>
              }
            </div>
          </div>
          <div className="cv-tabs-container">
            <Fragment>
              <div
                className={sortRaffle3 === "DRAW" ? "active" : ""}
                onClick={() => setSortRaffle3("DRAW")}
              >
                <div style={{ backgroundColor: "goldenrod" }}></div>
                <label>DRAW</label>
              </div>
              <div
                className={sortRaffle3 === "CLOSE" ? "active" : ""}
                onClick={() => setSortRaffle3("CLOSE")}
              >
                <div style={{ backgroundColor: "#FF5E5F" }}></div>
                <label>CLOSE</label>
              </div>
              <div
                className={sortRaffle3 === "OPEN" ? "active" : ""}
                onClick={() => setSortRaffle3("OPEN")}
              >
                <div style={{ backgroundColor: "#20E39C" }}></div>
                <label>OPEN</label>
              </div>
              <div
                className={sortRaffle3 === "ALL" ? "active" : ""}
                onClick={() => setSortRaffle3("ALL")}
              >
                <div
                  className="_all"
                  style={{ backgroundColor: "goldenrod" }}
                ></div>
                <div
                  className="_all"
                  style={{ backgroundColor: "#FF5E5F" }}
                ></div>
                <div
                  className="_all"
                  style={{ backgroundColor: "#20E39C" }}
                ></div>
                <label>ALL</label>
              </div>
            </Fragment>
          </div>
        </>
      ) : (
        <div className="ls-tabs">
          <button className="refresh-btn" onClick={onRefresh}>
            <FaRedoAlt className="refresh-icon" />
          </button>
          {Object.keys(sortRafflesList).map((item, i) => (
            <label
              onClick={async () => {
                // sortFilterRaffles(item);
                onToggleLifeState(item);
              }}
              className={sortRaffle === item ? "active" : ""}
              key={i}
            >
              {`${item} ${sortRafflesList[item] === "0"
                ? ""
                : "(" + sortRafflesList[item] + ")"
                }`}
            </label>
          ))}
        </div>
      )}

      <div className="cards_view">
        <FaTicketAlt
          onClick={() => {
            onChangeView("Grid");
            // sortFilterRaffles("OPEN");
          }}
          className={raffleView === "Grid" ? "active_view" : ""}
          size="23"
        />
        <BsCalendar
          onClick={() => {
            onChangeView(window.innerWidth < 720 ? "WeeklyCalender" : "Calender");
          }}
          className={raffleView === "WeeklyCalender" || raffleView === "Calender" ? "active_view" : ""}
          size="18"
        />
        <FaList onClick={() => {
          onChangeView("List");
        }}
          className={raffleView === "List" ? "active_view" : ""}
          size="18" />
      </div>
    </div>
    {/* legends */}
    <div className="cv-legend-container">
      {raffleView === "Calender" && (
        <div className="cv-controls-container">
          {/* month */}
          <div className="caret">
            <AiFillCaretLeft
              onClick={() => {
                let currentMonth = currentDay.getMonth();
                console.log(
                  "currentMonth::::::::::::::::::::::::::::",
                  currentMonth
                );
                if (currentMonth >= 0) {
                  // let newDate = new Date();
                  const newDate = new Date(
                    currentDay.getFullYear(),
                    currentDay.getMonth(),
                    1,
                    0,
                    0,
                    0,
                    0
                  );
                  newDate.setMonth(--currentMonth);
                  setCurrentDay(newDate);
                }
              }}
            />
            <p>{monthsList[currentDay.getMonth()]}</p>
            <AiFillCaretRight
              onClick={() => {
                let currentMonth = currentDay.getMonth();
                console.log(
                  "currentMonth::::::::::::::::::::::::::::",
                  currentMonth
                );
                if (currentMonth <= 11) {
                  // let newDate = new Date();
                  const newDate = new Date(
                    currentDay.getFullYear(),
                    currentDay.getMonth(),
                    1,
                    0,
                    0,
                    0,
                    0
                  );
                  newDate.setMonth(++currentMonth);
                  setCurrentDay(newDate);
                }
              }}
            />
          </div>
          <div className="caret">
            <AiFillCaretLeft
              onClick={() => {
                let currentYear = currentDay.getFullYear();
                if (currentYear >= 1920) {
                  let newDate = new Date();
                  newDate.setFullYear(--currentYear);
                  setCurrentDay(newDate);
                }
              }}
            />
            <p>{currentDay.getFullYear()}</p>
            <AiFillCaretRight
              onClick={() => {
                let currentYear = currentDay.getFullYear();
                let newDate = new Date();
                newDate.setFullYear(++currentYear);
                setCurrentDay(newDate);
              }}
            />
          </div>
        </div>
      )}
      {raffleView === "WeeklyCalender" && (
        <div className="weekly_view_controlls">
          <label className="datepicker-lable">Start date</label>
          <DatePicker
            selected={currentWeek}
            onChange={(date) => {
              setCurrentWeek(date);
            }}
          />
        </div>
      )}
    </div>
    {/* List of available raffles */}
    {_renderRaffles()}
    {raffleView !== "Grid" && (
      <div className="info">
        <span className="asterisk">*</span> Single raffle with multiple
        draws
      </div>
    )}
    {(raffleView === "Grid" || raffleView === "List") && <div className='part-footer'>
      <div htmlFor='itemsperpage' className='itemsperpage'>
        Items per page
        <select
          id="itemsperpage"
          value={itemsPerPage}
          onChange={(e) => setItemsPerPage(e.target.value)}
        >
          <option key={10} value={10}>10</option>
          <option key={20} value={20}>20</option>
          <option key={30} value={30}>30</option>
          <option key={40} value={40}>40</option>
          <option key={50} value={50}>50</option>
        </select>
      </div>
      {_renderPagination()}
      <div className='empty-container'></div>
    </div>}
    {loading ?
      <div className="notif-loader">
        <Loader type="TailSpin" color="rgb(11, 70, 85)" width={60} />
      </div> : null
    }
  </div>
}