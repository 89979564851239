import firebase from 'firebase';
import { environment } from '../config/endpoints';

var firebaseConfig = environment === "development" ?
    {
        apiKey: "AIzaSyDKazoJ7q4rxRlJTLPFx-u3I1QKc7I5Tbg",
        authDomain: "raffleradev.firebaseapp.com",
        databaseURL: "https://raffleradev.firebaseio.com",
        projectId: "raffleradev",
        storageBucket: "raffleradev.appspot.com",
        messagingSenderId: "19846670017",
        appId: "1:19846670017:web:d559e6f8413bcfd1875640"
    } : {
        apiKey: "AIzaSyDBfnJGQcjqHRCDxci0j9D15WAIR10CI1I",
        authDomain: "rafflera.firebaseapp.com",
        databaseURL: "https://rafflera.firebaseio.com",
        projectId: "rafflera",
        storageBucket: "rafflera.appspot.com",
        messagingSenderId: "675696344682",
        appId: "1:675696344682:web:d81b619c255f8bbc7e5413",
        measurementId: "G-NZ1XWSK7E4"
    };



// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;