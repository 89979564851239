import React, { useEffect, useState } from "react";
import "./monthlyRaffle.scss";
import {
  getDateStrWithTZ,
  getDateStrWithoutTZ,
  getMilliSecFromTime,
} from "../../../../helper/appService";
import DatePicker from "react-datepicker";
import { useAlert } from 'react-alert';

const day = 24 * 60 * 60 * 1000;

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const numDaysOfMonth = [
  { key: "1", value: 1 },
  { key: "2", value: 2 },
  { key: "3", value: 3 },
  { key: "4", value: 4 },
  { key: "5", value: 5 },
  { key: "6", value: 6 },
  { key: "7", value: 7 },
  { key: "8", value: 8 },
  { key: "9", value: 9 },
  { key: "10", value: 10 },
  { key: "11", value: 11 },
  { key: "12", value: 12 },
  { key: "13", value: 13 },
  { key: "14", value: 14 },
  { key: "15", value: 15 },
  { key: "16", value: 16 },
  { key: "17", value: 17 },
  { key: "18", value: 18 },
  { key: "19", value: 19 },
  { key: "20", value: 20 },
  { key: "21", value: 21 },
  { key: "22", value: 22 },
  { key: "23", value: 23 },
  { key: "24", value: 24 },
  { key: "25", value: 25 },
  { key: "26", value: 26 },
  { key: "27", value: 27 },
  { key: "28", value: 28 },
  { key: "29", value: 29 },
  { key: "30", value: 30 },
  { key: "31", value: 31 },
];

const months = [
  { name: 'January', days: 31 },
  { name: 'February', days: 28 },
  { name: 'March', days: 31 },
  { name: 'April', days: 30 },
  { name: 'May', days: 31 },
  { name: 'June', days: 30 },
  { name: 'July', days: 31 },
  { name: 'August', days: 31 },
  { name: 'September', days: 30 },
  { name: 'October', days: 31 },
  { name: 'November', days: 30 },
  { name: 'December', days: 31 },
];

const Monthly = ({
  raffle,
  choosenRaffle,
  setRaffle,
  setRaffleFields,
  freqStartinTz,
  freqEndinTz,
  getDatePartTZ,
  getTimeStrWithTZ,
  getFormattedDateTime,
  seriesEdit,
  type,
}) => {
  const alert = useAlert();

  const [remainingMonths, setRemainingMonths] = useState([]);
  const [availableInstance, setAvailableInstances] = useState();
  const [masterMonthsSelect, setMasterMonthsSelect] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);

  const calculateNumMonths = (startMonth, endMonth) => {
    const months = [];
    let currentDate = new Date(new Date(startMonth).setDate(1));

    while (currentDate <= endMonth) {
      months.push(new Date(currentDate));

      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    setAvailableInstances(months.length);

    // for master select
    const allDrawDtsWithTz = [];
    months.forEach((date) => {
      allDrawDtsWithTz.push(getDatePartTZ(date));
    })
    setMasterMonthsSelect(allDrawDtsWithTz);

    const groups = {};
    const selectedGroups = {};

    months.forEach((date) => {
      const year = date.getFullYear()
      if (!groups[year]) {
        groups[year] = [];
        selectedGroups[year] = [];
      }
      groups[year].push(new Date(date));
    });
    setSelectedMonths(selectedGroups);
    setRemainingMonths(groups);
  };

  const populateSelectInstances = async (days, startMonth, endMonth) => {
    const months = [];
    const currentDate = new Date(startMonth);
    const finalDate = new Date(endMonth);
    const drawTimeMs = getMilliSecFromTime(getTimeStrWithTZ(raffle.drawAt));

    while (currentDate <= finalDate) {
      months.push(new Date(currentDate));

      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    const selectedGroups = {};

    months.forEach((date) => {
      const year = date.getFullYear()
      if (!selectedGroups[year]) {
        selectedGroups[year] = [];
      }
    });

    days.forEach((x) => { //['08/19/2023, 3:00 PM EDT', '08/20/2023, 3:00 PM EDT', '08/21/2023, 3:00 PM EDT']
      const date = new Date(getDatePartTZ(new Date(x)).split(',')[0]); // get only date part of selected draw instance 
      const year = date.getFullYear()

      console.log("Object.keys(selectedGroups).includes(year)", Object.keys(selectedGroups))
      console.log(year);

      if (selectedGroups[year]) {
        const drawDttm = new Date(getDatePartTZ(new Date(x))).getTime() + drawTimeMs;
        selectedGroups[year].push(getDateStrWithTZ(raffle.extras.timeZone, drawDttm));
      }
    });

    console.log("selectedGroups from populateSelectInstances func==================", selectedGroups);

    setSelectedMonths(selectedGroups);
  };

  useEffect(() => {
    const startDateInTz = freqStartinTz(new Date(raffle.extras.seriesRaffle.frequencyRange.startDate), raffle.extras.timeZone);
    const endByDateInTz = freqEndinTz(new Date(raffle.extras.seriesRaffle.frequencyRange.endByDate), raffle.extras.timeZone);

    calculateNumMonths(new Date(startDateInTz), new Date(endByDateInTz));
  }, [
    raffle.extras.seriesRaffle.frequencyRange.startDate,
    raffle.extras.seriesRaffle.frequencyRange.endByDate,
    raffle.extras.timeZone,
    raffle.extras.seriesRaffle.isMultipleRaffles
  ]);

  useEffect(() => {
    if (raffle.extras.seriesRaffle.recurrencePattern === "Monthly" &&
      !raffle.extras.seriesRaffle.isMultipleRaffles) {
      let monthlyDraws = [...raffle.extras.seriesRaffle.monthlyPattern.days];
      monthlyDraws.sort((a, b) => new Date(a) - new Date(b));
      if (monthlyDraws.length !== 0) {
        const drawAtinTz = getDateStrWithTZ(raffle.extras.timeZone, new Date(raffle.drawAt));
        const singleRaffleStart = new Date(getDateStrWithTZ(raffle.extras.timeZone, monthlyDraws[0]));
        const firstSelectedDtinTz = getDateStrWithTZ(raffle.extras.timeZone, new Date(getDateofMonthday(monthlyDraws[0], raffle.extras.seriesRaffle.monthlyPattern.drawDay)));
        const lastSelectedDtinTz = getDateStrWithTZ(raffle.extras.timeZone, new Date(getDateofMonthday(monthlyDraws[monthlyDraws.length - 1], raffle.extras.seriesRaffle.monthlyPattern.drawDay)));
        const firstDrawDt = new Date(firstSelectedDtinTz.split(',')[0] + drawAtinTz.split(',')[1]);
        const lastDrawDt = new Date(lastSelectedDtinTz.split(',')[0] + drawAtinTz.split(',')[1])
        let openMonthlyTime = new Date(new Date(firstDrawDt).getTime() - 6 * day);
        console.log("openDailyTime::::::::::::::::::::::::::::::::::", openMonthlyTime);
        let closeMonthlyTime = new Date(new Date(lastDrawDt).getTime() - 5 * 60000);
        setRaffleFields(singleRaffleStart, "participationOpens");
        setRaffleFields(closeMonthlyTime, "participationCloses");
        setMonthDay(singleRaffleStart.getDate(), "openDay")
        setMonthDay(closeMonthlyTime.getDate(), "closeDay")
      }
    }
  }, [raffle.extras.seriesRaffle.monthlyPattern.days,
  raffle.extras.seriesRaffle.monthlyPattern.drawDay,
  raffle.drawAt])

  useEffect(() => {
    populateSelectInstances(raffle.extras.seriesRaffle.monthlyPattern.days,
      raffle.extras.seriesRaffle.frequencyRange.startDate,
      raffle.extras.seriesRaffle.frequencyRange.endByDate);

    //Initiating open time with current time incase of selection of current date as start
    const dateTime = new Date();
    const months = raffle.extras.seriesRaffle.monthlyPattern.days.sort((a, b) => new Date(a) - new Date(b));
    const monthStart = new Date(months[0]);
    if (monthStart.getMonth() === new Date().getMonth()) {
      setMonthDay(dateTime.getDate(), "openDay");
      dateTime.setSeconds(0, 0);
      if (monthStart.getDate() == raffle.extras.seriesRaffle.monthlyPattern.openDay) {
        const timeString = getTimeStrWithTZ(dateTime);
        const timeMill = getMilliSecFromTime(timeString);
        setMonthTimes(timeMill, "openTime");
        setRaffleFields(new Date(dateTime), "participationOpens");
      }
    }
  }, [raffle.extras.seriesRaffle.monthlyPattern.days])

  // to initialize the monthlyPattern.openTime/closeTime/drawTime with 12am, 11:55pm and 11:59pm
  useEffect(() => {
    setMonthTimes(0, "openTime");
    setMonthTimes(86100000, "closeTime");
    setMonthTimes(86340000, "drawTime");
  }, [])

  // To get date in the month based on selected day of month
  const getDateofMonthday = (ms, md) => {
    let dateOfMonthday = new Date(new Date(ms).setDate(parseInt(md)));
    return new Date(dateOfMonthday).toLocaleDateString("en-US", {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  }

  // To get time alone from a new Date()
  const getTimeofDate = (date) => {
    return new Date(date).toLocaleTimeString("en-US", {
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  // Week day selection function to set day to raffle
  const setMonthDay = (day, ocd) => {
    console.log(day)
    setRaffle((rff) => ({
      ...rff,
      extras: {
        ...rff.extras,
        seriesRaffle: {
          ...rff.extras.seriesRaffle,
          monthlyPattern: {
            ...rff.extras.seriesRaffle.monthlyPattern,
            [ocd === "openDay" ? "openDay" : ocd === "closeDay" ? "closeDay" : "drawDay"]: parseInt(day)
          },
        },
      },
    }));
  }

  // Open time, close time & draw time setting to monthlyPattern.open/close/draw times
  const setMonthTimes = (time, ocd) => {
    setRaffle((rff) => ({
      ...rff,
      extras: {
        ...rff.extras,
        seriesRaffle: {
          ...rff.extras.seriesRaffle,
          monthlyPattern: {
            ...rff.extras.seriesRaffle.monthlyPattern,
            [ocd === "openTime" ? "openTime" : ocd === "closeTime" ? "closeTime" : "drawTime"]: time
          },
        },
      },
    }));
  }

  const selectAllInstances = (checked) => {
    let toAddMasterMonths = masterMonthsSelect.filter(
      (x) => {
        const dayTime = new Date(x).toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        });
        const existingDayTimes = raffle.extras.seriesRaffle.monthlyPattern.days.map(x => new Date(x).toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }));
        return (
          existingDayTimes.indexOf(dayTime) === -1
        )
      }
    )

    let toRemoveMonthsChoosed = (type === "EDIT") ? choosenRaffle.extras.seriesRaffle.monthlyPattern.days.map(day => new Date(day).toISOString()) : [];

    if (checked) {
      setRaffle((rff) => ({
        ...rff,
        extras: {
          ...rff.extras,
          seriesRaffle: {
            ...rff.extras.seriesRaffle,
            monthlyPattern: {
              ...rff.extras.seriesRaffle
                .monthlyPattern,
              days: [
                ...rff.extras.seriesRaffle
                  .monthlyPattern.days,
                ...toAddMasterMonths,
              ],
            },
          },
        },
      }));
    } else {
      setRaffle((rff) => ({
        ...rff,
        extras: {
          ...rff.extras,
          seriesRaffle: {
            ...rff.extras.seriesRaffle,
            monthlyPattern: {
              ...rff.extras.seriesRaffle
                .monthlyPattern,
              days: type === "EDIT" ? toRemoveMonthsChoosed : [],
            },
          },
        },
      }));
    }
  }

  const selectAllCategoryInstances = (checked, year) => {
    const toAddDays = remainingMonths[year].filter(
      (x) => {
        const date = new Date(x);
        const dateString = date.toLocaleDateString();
        const monthlyPatternDateStrings =
          raffle.extras.seriesRaffle.monthlyPattern.days.map(
            (day) => {
              return new Date(day).toLocaleDateString();
            }
          );
        return (
          monthlyPatternDateStrings.indexOf(
            dateString
          ) === -1
        );
      }
    );

    console.log("toAddDays", toAddDays);

    const toRemoveDays =
      raffle.extras.seriesRaffle.monthlyPattern.days.filter(
        (x) => {
          return !remainingMonths[year].some(
            (y) =>
              new Date(y).toDateString() ===
              new Date(x).toDateString()
          );
        }
      );
    console.log("toRemoveDays", toRemoveDays);

    let toRemoveDaysChoosed = (type === "EDIT") ? choosenRaffle.extras.seriesRaffle.monthlyPattern.days.map(day => new Date(day).toISOString()) : [];
    console.log("toRemoveDaysChoosed", toRemoveDaysChoosed);
    let toRemoveDaysFromSelected = toRemoveDaysChoosed.filter(
      (x) => new Date(x).getFullYear() == year
    );
    console.log("toRemoveDaysFromSelected", toRemoveDaysFromSelected);
    let allRaffles = (type === "EDIT") ? raffle.extras.seriesRaffle.monthlyPattern.days.map(day => new Date(day)) : [];
    let otherYearRaffles = allRaffles?.filter((x) =>
      !(new Date(x).getFullYear() == year));
    console.log("otherYearRaffles", otherYearRaffles);
    let newDaysRem = [...otherYearRaffles, ...toRemoveDaysFromSelected];
    console.log("newDaysRem", newDaysRem)

    //updating dates to date part with time zones
    let addDays = toAddDays.map(
      (a) =>
        getDatePartTZ(a)
    );
    console.log("toAddDaysfor", addDays);
    if (checked) {
      setRaffle((rff) => ({
        ...rff,
        extras: {
          ...rff.extras,
          seriesRaffle: {
            ...rff.extras.seriesRaffle,
            monthlyPattern: {
              ...rff.extras.seriesRaffle
                .monthlyPattern,
              days: [
                ...rff.extras.seriesRaffle
                  .monthlyPattern.days,
                ...addDays,
              ],
            },
          },
        },
      }));
    } else {
      console.log(toRemoveDays);
      setRaffle((rff) => ({
        ...rff,
        extras: {
          ...rff.extras,
          seriesRaffle: {
            ...rff.extras.seriesRaffle,
            monthlyPattern: {
              ...rff.extras.seriesRaffle
                .monthlyPattern,
              days: type === "EDIT" ?
                newDaysRem :
                toRemoveDays,
            },
          },
        },
      }));
    }
  }

  const selectInstance = (checked, day) => {
    if (
      raffle.extras.seriesRaffle.monthlyPattern.days.findIndex(
        (x) => new Date(x).toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }) === new Date(day).toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })
      ) >= 0 &&
      !checked
    ) {
      setRaffle((rff) => ({
        ...rff,
        extras: {
          ...rff.extras,
          seriesRaffle: {
            ...rff.extras
              .seriesRaffle,
            monthlyPattern: {
              ...rff.extras
                .seriesRaffle
                .monthlyPattern,
              days: rff.extras.seriesRaffle.monthlyPattern.days.filter(
                (x) => new Date(x).toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                }) !== new Date(day).toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                })
              ),
            },
          },
        },
      }));
    } else {
      setRaffle((rff) => ({
        ...rff,
        extras: {
          ...rff.extras,
          seriesRaffle: {
            ...rff.extras
              .seriesRaffle,
            monthlyPattern: {
              ...rff.extras
                .seriesRaffle
                .monthlyPattern,
              days: [
                ...rff.extras
                  .seriesRaffle
                  .monthlyPattern.days,
                getDatePartTZ(day),
              ],
            },
          },
        },
      }));
    }
  }

  return (
    <div className="monthly-raff-container">
      <div className="raffle-month-time">
        <label style={{ fontWeight: "700", textAlign: "left" }}>
          RAFFLE DAY-TIME:
        </label>
        <label>
          OPEN
          <div className="month-day-picker">
            <select
              disabled={
                !raffle.extras.seriesRaffle.isMultipleRaffles ||
                type === "EDIT"
              }
              value={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                raffle.extras.seriesRaffle.monthlyPattern.days.length !== 0 ?
                  raffle.extras.seriesRaffle.monthlyPattern.openDay :
                  ""
                : raffle.extras.seriesRaffle.monthlyPattern.openDay}
              onChange={(_e) => {
                const monthStart = new Date(raffle.extras.seriesRaffle.monthlyPattern.days[0]);
                if ((_e.target.value < new Date().getDate()) &&
                  (new Date(monthStart).getMonth() == new Date().getMonth())
                ) {
                  alert.removeAll();
                  alert.show("Please select a valid time in the future")
                } else if ((new Date(raffle.participationOpens) > new Date(raffle.participationCloses) &&
                  (raffle.extras.seriesRaffle.monthlyPattern.closeDay == _e.target.value)) ||
                  (raffle.extras.seriesRaffle.monthlyPattern.closeDay < _e.target.value)
                ) {
                  alert.removeAll();
                  alert.show("Close time cannot be less than open time")
                } else {
                  setMonthDay(_e.target.value, "openDay")
                }
              }
              }
            >
              {numDaysOfMonth.map((day, index) => {
                return (
                  <option key={day.key} value={day.value}>
                    {day.key}
                  </option>
                );
              })}
            </select>
            <DatePicker
              dateFormat={
                !raffle.extras.seriesRaffle.isMultipleRaffles
                  ? "MM/dd/yyyy hh:mm aa"
                  : "hh:mm aa"
              }
              showTimeInput
              showTimeSelectOnly
              disabled={
                !raffle.extras.seriesRaffle.isMultipleRaffles ||
                type === "EDIT"
              }
              value={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                raffle.extras.seriesRaffle.monthlyPattern.days.length !== 0 ?
                  new Date(getFormattedDateTime(new Date(raffle.participationOpens))) :
                  ""
                : new Date(getFormattedDateTime(new Date(raffle.participationOpens)))}
              selected={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                raffle.extras.seriesRaffle.monthlyPattern.days.length !== 0 ?
                  new Date(getFormattedDateTime(new Date(raffle.participationOpens))) :
                  ""
                : new Date(getFormattedDateTime(new Date(raffle.participationOpens)))}
              onChange={(_e) => {
                const dateTime = _e;
                const monthStart = new Date(raffle.extras.seriesRaffle.monthlyPattern.days[0]);
                if (((new Date(dateTime) < new Date() &&
                  (raffle.extras.seriesRaffle.monthlyPattern.openDay == new Date().getDate())) ||
                  (raffle.extras.seriesRaffle.monthlyPattern.openDay < new Date().getDate())) &&
                  (new Date(monthStart).getMonth() == new Date().getMonth())
                ) {
                  alert.removeAll();
                  alert.show("Please select a valid time in the future")
                } else if ((new Date(dateTime) > new Date(raffle.participationCloses) &&
                  (raffle.extras.seriesRaffle.monthlyPattern.openDay == raffle.extras.seriesRaffle.monthlyPattern.closeDay)) ||
                  (raffle.extras.seriesRaffle.monthlyPattern.openDay > raffle.extras.seriesRaffle.monthlyPattern.closeDay)
                ) {
                  alert.removeAll();
                  alert.show("Close time cannot be less than open time")
                } else {
                  dateTime.setSeconds(0, 0);
                  const timeString = getTimeStrWithTZ(dateTime);
                  const timeMill = getMilliSecFromTime(timeString);
                  setMonthTimes(timeMill, "openTime");
                  setRaffleFields(new Date(dateTime), "participationOpens");
                }
              }}
            />
          </div>
        </label>
        <label>
          CLOSE
          <div className="month-day-picker">
            <select
              disabled={
                !raffle.extras.seriesRaffle.isMultipleRaffles ||
                type === "EDIT"
              }
              value={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                raffle.extras.seriesRaffle.monthlyPattern.days.length !== 0 ?
                  raffle.extras.seriesRaffle.monthlyPattern.closeDay :
                  ""
                : raffle.extras.seriesRaffle.monthlyPattern.closeDay}
              onChange={(_e) => {
                if ((new Date(raffle.participationCloses) < new Date(raffle.participationOpens) &&
                  (raffle.extras.seriesRaffle.monthlyPattern.openDay == _e.target.value)) ||
                  (raffle.extras.seriesRaffle.monthlyPattern.openDay > _e.target.value)
                ) {
                  alert.removeAll();
                  alert.show("Close time cannot be less than open time")
                } else if ((new Date(raffle.drawAt) < new Date(raffle.participationCloses) &&
                  (raffle.extras.seriesRaffle.monthlyPattern.drawDay == _e.target.value)) ||
                  (raffle.extras.seriesRaffle.monthlyPattern.drawDay < _e.target.value)
                ) {
                  alert.removeAll();
                  alert.show("Draw time cannot be less than close time")
                } else {
                  setMonthDay(_e.target.value, "closeDay")
                }
              }
              }
            >
              {numDaysOfMonth.map((day, index) => {
                return <option>{day.key}</option>;
              })}
            </select>
            <DatePicker
              dateFormat={
                !raffle.extras.seriesRaffle.isMultipleRaffles
                  ? "MM/dd/yyyy hh:mm aa"
                  : "hh:mm aa"
              }
              showTimeInput
              showTimeSelectOnly
              disabled={
                !raffle.extras.seriesRaffle.isMultipleRaffles ||
                type === "EDIT"
              }
              value={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                raffle.extras.seriesRaffle.monthlyPattern.days.length !== 0 ?
                  new Date(getFormattedDateTime(new Date(raffle.participationCloses))) :
                  ""
                : new Date(getFormattedDateTime(new Date(raffle.participationCloses)))}
              selected={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                raffle.extras.seriesRaffle.monthlyPattern.days.length !== 0 ?
                  new Date(getFormattedDateTime(new Date(raffle.participationCloses))) :
                  ""
                : new Date(getFormattedDateTime(new Date(raffle.participationCloses)))}
              onChange={(_e) => {
                const dateTime = _e;
                if ((new Date(dateTime) < new Date(raffle.participationOpens) &&
                  (raffle.extras.seriesRaffle.monthlyPattern.openDay == raffle.extras.seriesRaffle.monthlyPattern.closeDay)) ||
                  (raffle.extras.seriesRaffle.monthlyPattern.openDay > raffle.extras.seriesRaffle.monthlyPattern.closeDay)
                ) {
                  alert.removeAll();
                  alert.show("Close time cannot be less than open time")
                } else if ((new Date(dateTime) > new Date(raffle.drawAt) &&
                  (raffle.extras.seriesRaffle.monthlyPattern.drawDay == raffle.extras.seriesRaffle.monthlyPattern.closeDay)) ||
                  (raffle.extras.seriesRaffle.monthlyPattern.drawDay < raffle.extras.seriesRaffle.monthlyPattern.closeDay)
                ) {
                  alert.removeAll();
                  alert.show("Draw time cannot be less than close time")
                } else {
                  dateTime.setSeconds(0, 0);
                  const timeString = getTimeStrWithTZ(dateTime);
                  const timeMill = getMilliSecFromTime(timeString);
                  setMonthTimes(timeMill, "closeTime");
                  setRaffleFields(new Date(dateTime), "participationCloses");
                }
              }}
            />
          </div>
        </label>
        <label>
          DRAW
          <div className="month-day-picker">
            <select
              value={raffle.extras.seriesRaffle.monthlyPattern.drawDay}
              onChange={(_e) => {
                if ((new Date(raffle.drawAt) < new Date(raffle.participationCloses) &&
                  (raffle.extras.seriesRaffle.monthlyPattern.closeDay == _e.target.value)) ||
                  (raffle.extras.seriesRaffle.monthlyPattern.closeDay > _e.target.value)
                ) {
                  alert.removeAll();
                  alert.show("Draw time cannot be less than close time")
                } else {
                  setMonthDay(_e.target.value, "drawDay")
                }
              }}
            >
              {numDaysOfMonth.map((day, index) => {
                return <option>{day.key}</option>;
              })}
            </select>
            <DatePicker
              dateFormat="hh:mm aa"
              showTimeInput
              showTimeSelectOnly
              disabled={type === "EDIT"}
              value={new Date(getFormattedDateTime(new Date(raffle.drawAt)))}
              selected={new Date(getFormattedDateTime(new Date(raffle.drawAt)))}
              onChange={(_e) => {
                const dateTime = _e;
                if ((new Date(dateTime) < new Date(raffle.participationCloses) &&
                  (raffle.extras.seriesRaffle.monthlyPattern.closeDay == raffle.extras.seriesRaffle.monthlyPattern.drawDay)) ||
                  (raffle.extras.seriesRaffle.monthlyPattern.closeDay > raffle.extras.seriesRaffle.monthlyPattern.drawDay)
                ) {
                  alert.removeAll();
                  alert.show("Draw time cannot be less than close time")
                } else {
                  dateTime.setSeconds(0, 0);
                  const timeString = getTimeStrWithTZ(dateTime);
                  const timeMill = getMilliSecFromTime(timeString);
                  setMonthTimes(timeMill, "drawTime");
                  setRaffleFields(new Date(dateTime), "drawAt");
                }
              }}
            />
          </div>
        </label>
      </div>
      <div className="monthly-series-sub-raffle">
        <div className="monthly-available-instances">
          <div className="monthly-select-all"
            style={{
              fontWeight: 700,
              textAlign: "left",
              display: "flex",
              gap: "10px",
            }}
          >
            <span>AVAILABLE INSTANCES</span>
            <input
              type="checkbox"
              checked={
                masterMonthsSelect.length ===
                raffle.extras.seriesRaffle.monthlyPattern.days
                  .length
              }
              disabled={
                (!seriesEdit &&
                  type === "EDIT" &&
                  raffle.extras.seriesRaffle.isMultipleRaffles
                ) ||
                (type === "EDIT" &&
                  choosenRaffle.extras.seriesRaffle.monthlyPattern.days.length ===
                  masterMonthsSelect.length
                )
              }
              ref={(input) => {
                if (input) {
                  input.indeterminate =
                    masterMonthsSelect.length >
                    raffle.extras.seriesRaffle.monthlyPattern
                      .days.length &&
                    raffle.extras.seriesRaffle.monthlyPattern.days
                      .length !== 0;
                }
              }}
              onChange={(e) => { selectAllInstances(e.target.checked) }}
            />
            <span>
              Select All
            </span>
            <span>({availableInstance})</span>
          </div>
          <div className={"occurrences"}>
            {Object.keys(remainingMonths).map((year, index) => {
              return (
                <div className="select-all" key={index}>
                  <span>
                    <span>
                      {`Year ${year} (${remainingMonths[year].length})`}
                    </span>
                    <span>
                      <input
                        type="checkbox"
                        disabled={
                          (!seriesEdit && type === "EDIT" && raffle.extras.seriesRaffle.isMultipleRaffles) ||
                          (type === "EDIT" &&
                            remainingMonths[year].every(
                              (x) => {
                                const date = new Date(x);
                                const dateString =
                                  date.toLocaleDateString();
                                const monthlyPatternDateString =
                                  choosenRaffle.extras.seriesRaffle.monthlyPattern.days.map(
                                    (day) =>
                                      new Date(
                                        day
                                      ).toLocaleDateString()
                                  );
                                return monthlyPatternDateString.includes(
                                  dateString
                                );
                              }
                            )
                          )
                        }
                        checked={
                          raffle.extras.seriesRaffle.monthlyPattern.days.filter((x) => {
                            return new Date(x).getFullYear() == year;
                          }).length === remainingMonths[year].length
                        }
                        ref={(input) => {
                          if (input) {
                            input.indeterminate =
                              raffle.extras.seriesRaffle.monthlyPattern.days.filter((x) => {
                                return new Date(x).getFullYear() == year;
                              }).length <
                              remainingMonths[year].length &&
                              raffle.extras.seriesRaffle.monthlyPattern.days.filter((x) => {
                                return new Date(x).getFullYear() == year;
                              }).length !== 0;
                          }
                        }}
                        onChange={(e) => { selectAllCategoryInstances(e.target.checked, year) }}
                      />
                      {" Select all"}
                    </span>
                    <span>
                      {`(${remainingMonths[year].length})`}
                    </span>
                  </span>
                  <div className={"all-checkbox"}>
                    {remainingMonths[year].map((day, index) => {
                      return (
                        <div
                          className="each-checkbox"
                          key={index}
                        >
                          {/* <lable> */}
                          <input
                            type="checkbox"
                            disabled={
                              (type === "EDIT" && !seriesEdit && raffle.extras.seriesRaffle.isMultipleRaffles
                              ) ||
                              (type === "EDIT" &&
                                new Date(day).setHours(
                                  new Date(raffle.participationOpens).getHours(),
                                  new Date(raffle.participationOpens).getMinutes(), 0, 0,
                                ) < new Date())
                            }
                            checked={
                              raffle.extras.seriesRaffle.monthlyPattern.days.findIndex(
                                (x) =>
                                  new Date(
                                    x
                                  ).toLocaleDateString() ===
                                  new Date(
                                    day
                                  ).toLocaleDateString()
                              ) >= 0
                            }
                            onChange={(e) => { selectInstance(e.target.checked, day) }}
                          />
                          <p className="months-name">
                            {monthNames[new Date(day).getMonth()].substring(0, 3)}
                          </p>
                          <lable>
                            {raffle.extras.seriesRaffle.isMultipleRaffles ?
                              `[Open @ ${getDateofMonthday(new Date(day), raffle.extras.seriesRaffle.monthlyPattern.openDay)}, ${getTimeofDate(new Date(raffle.participationOpens))},
                              Close @ ${getDateofMonthday(new Date(day), raffle.extras.seriesRaffle.monthlyPattern.closeDay)}, ${getTimeofDate(new Date(raffle.participationCloses))},
                              Draw @ ${getDateofMonthday(new Date(day), raffle.extras.seriesRaffle.monthlyPattern.drawDay)}, ${getTimeofDate(new Date(raffle.drawAt))}]`
                              :
                              `[Draw @ ${getDateofMonthday(new Date(day), raffle.extras.seriesRaffle.monthlyPattern.drawDay)}, ${getTimeofDate(new Date(raffle.drawAt))}]`}
                          </lable>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="monthly-selected-instances-container">
          <label
            style={{
              fontWeight: 700,
              textAlign: "left",
              display: "flex",
              gap: "10px",
            }}
          >
            <span>SELECTED INSTANCES</span>
            <span>{`(${raffle.extras.seriesRaffle.monthlyPattern.days.length})`}</span>
          </label>
          <div className="monthly-selected-instances">
            {Object.keys(selectedMonths).map((year, index) => {
              // raffle.extras.seriesRaffle.monthlyPattern.days.sort(
              //   (a, b) => new Date(a) - new Date(b)
              // );
              return (
                <div key={index} className={"selected-hours"}>
                  <div className="selected-head">
                    {`Year ${year} (${selectedMonths[year].length})`}
                  </div>
                  {/* <br /> */}
                  {selectedMonths[year].length === 0 ? (
                    <label>No instances selected</label>
                  ) : (
                    <div className="selected-content">
                      {selectedMonths[year].map(
                        (day, index) => {
                          return (
                            <div className="selected-items" key={index}>
                              <span>#</span>
                              <p className="months-name">
                                {monthNames[new Date(day).getMonth()].substring(0, 3)}
                              </p>
                              <lable className="raff-times">
                                {raffle.extras.seriesRaffle.isMultipleRaffles ?
                                  `[Open @ ${getDateofMonthday(new Date(day), raffle.extras.seriesRaffle.monthlyPattern.openDay)}, ${getTimeofDate(new Date(raffle.participationOpens))},
                                  Close @ ${getDateofMonthday(new Date(day), raffle.extras.seriesRaffle.monthlyPattern.closeDay)}, ${getTimeofDate(new Date(raffle.participationCloses))},
                                  Draw @ ${getDateofMonthday(new Date(day), raffle.extras.seriesRaffle.monthlyPattern.drawDay)}, ${getTimeofDate(new Date(raffle.drawAt))}]`
                                  :
                                  `[Draw @ ${getDateofMonthday(new Date(day), raffle.extras.seriesRaffle.monthlyPattern.drawDay)}, ${getTimeofDate(new Date(raffle.drawAt))}]`}
                              </lable>
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Monthly;
