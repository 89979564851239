const initialState = {
    status: "",
    beneficiaries: [],
    copyBeneficiaries: [],
    myBeneficiaries: [],
    error_msg: ""
}

export default (state = initialState, { type, payload, payload_my_bene }) => {
    switch (type) {
        case "LOAD_BENEFICIARIES":
            return { ...state, "status": "PENDING" };
        case "LOADED_BENEFICIARIES":
            return { ...state, "status": "LOADED", beneficiaries: payload, copyBeneficiaries: payload, myBeneficiaries: payload_my_bene };
        case "ERROR_BENEFICIARIES":
            return { ...state, "status": "ERROR", error_msg: payload };

        case "UPDATE_BENEFICIARIES":
            return { ...state, "status": "UPDATED", beneficiaries: payload };

        case "UPDATE_MY_BENEFICIARIES":
            return { ...state, "status": "UPDATED", myBeneficiaries: payload };
        default:
            return state;
    }
}