import React, { useRef } from 'react';
import './showQrCodeModel.scss';
import QRCode from 'qrcode.react';
import { MdClose } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';

export default ({ link, onClose, title }) => {

    const downloadQR = () => {
        const qrRef = document.getElementById("qrRef");
        const pngUrl = qrRef.toDataURL("image/png").replace("image/png", "image/octet-stream");
        const a = document.createElement("a");
        a.href = pngUrl;
        a.download = `${Date.now()}.png`;
        a.click();
    }

    return <div id="qr_code_model">
        <div>
            <div>
                <img src={require('../../../assets/img/cup.png')} />
                <div className={window.innerWidth > 720 ? 'title-cup-container' : 'title-cup'}>
                    <p className='title' data-tip data-for={"title"}>
                        {title}
                    </p>
                    <ReactTooltip place="right" id={"title"}>
                            {title}
                    </ReactTooltip>
                </div>
                <label onClick={onClose}>X</label>
            </div>
            <QRCode className='qr-code' id={"qrRef"}  value={link} />
            <label onClick={downloadQR}>Download QR</label>
        </div>
    </div>
}