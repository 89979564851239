import React, { useState } from 'react';
import './activateDeactivateRaffle.scss';
// icons
import { AiFillWarning } from 'react-icons/ai';
// redux
import { useDispatch } from 'react-redux';
import { togggleLoad } from '../../../store/actions/loadAction';
import { ActivateDeactivateRaffle } from '../../../store/actions/raffleAction';
// api call
import { request } from '../../../helper/request';
//config
import { activateDeactivateRaffle } from '../../../config/endpoints';
// alert
import { useAlert } from 'react-alert';

export default ({ onClose, raffleData, onProceed, setRaffle }) => {
    const dispatch = useDispatch();
    const alert = useAlert();

    const { raffle, winners } = raffleData;

    // note message to mail
    const [note, setNote ] = useState("");

    const onproceed = () => {
        dispatch(togggleLoad());
        request("POST_AUTH", activateDeactivateRaffle, { ...raffle, note })
        .then((res) => {
            dispatch(togggleLoad());
            if (res.status === 0) {
                console.log("response for activate/deactivate:::::::::::::::::::::::::", res);
                if (res.payload.extras.active) {
                    alert.removeAll();
                    alert.show("Activated successfully");
                }
                else {
                    alert.removeAll();
                    alert.show("Deactivated successfully");
                }
                onProceed({ raffle: { ...raffle, extras: { ...raffle.extras, 'active': !raffle.extras.active } }, winners })
            } else {
                const msg = `${raffle.extras.active ? 'Error while deactivating the raffle' : 'Error while deactivating the raffle'}.`
                alert.removeAll();
                alert.show(msg);
            }
        }).catch((e) => {
            dispatch(togggleLoad());
            const msg = `${raffle.extras.active ? 'Error while deactivating the raffle' : 'Error while deactivating the raffle'}.`
            alert.removeAll();
            alert.show(msg);
        })
    }

    return <div className="activateDeactivateRaffle">
        <div>
            <div>
                <img src={require('../../../assets/img/cup.png')} />
                <div>Raffle Status</div>
                <label onClick={onClose}>X</label>
            </div>
            <div>
                <p>Do you want to continue?</p>
                <label>Note(optional)</label>
                <textarea onChange={(e) => setNote(e.currentTarget.value)} placeholder="Write here..."></textarea>
            </div>
            <div>
                <div onClick={onproceed}>PROCEED</div>
                <div onClick={onClose}>CLOSE</div>
            </div>
        </div>
    </div>
}