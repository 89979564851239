import React, { useRef, useState, useEffect, Fragment } from "react";
import "./createRaffleModel.scss";
import { useAlert } from "react-alert";
import { request } from "../../../helper/request";
import { activateDeactivateRaffle } from '../../../config/endpoints';
import {
  getRaffleBySeriesId,
  postRaffle,
  updateRaffle,
} from "../../../config/endpoints";
import {
  AddRaffle,
  UpdateRaffle,
  LoadMyRaffles,
} from "../../../store/actions/raffleAction";
import { togggleLoad } from "../../../store/actions/loadAction";
import CustomizeRaffleTicket from "../models/customizeRaffleTicket";
// date picker
import DatePicker from "react-datepicker";
import "react-datetime/css/react-datetime.css";

// redux
import { useDispatch, useSelector } from "react-redux";
// icons
import { HiOutlineArrowsExpand } from "react-icons/hi";
import { BiCollapse } from "react-icons/bi";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";
// text editor
import RichTextEditor from "react-rte";
// time zone
import momentTz, { invalid } from "moment-timezone";
// multi select
import { Multiselect } from "multiselect-react-dropdown";
// regions and countries
import RegionsList from "../../../assets/regions";
import CountriesList from "../../../assets/countries";
import currencies from "../../../assets/currencies";
import { FcDocument } from "react-icons/fc";
import { v4 as uuidv4 } from "uuid";
import ReactTooltip from "react-tooltip";
import ConfirmOverride from '../models/ConfirmOverride'

import { historyList } from '../../../config/endpoints';
import WeeklyRaffle from "./seriesRaffleComponents/weeklyRaffle";
import { getMilliSecFromTime } from "../../../helper/appService";
import MonthlyRaffle from "./seriesRaffleComponents/monthlyRaffle";
import YearlyRaffle from "./seriesRaffleComponents/yearlyRaffle";
import OnDemand from "./seriesRaffleComponents/onDemand";
import Prizes from "./prizes";

// sub tabs
const daysList = [
  { day: "Sunday", num: 0 },
  { day: "Monday", num: 1 },
  { day: "Tuesday", num: 2 },
  { day: "Wednesday", num: 3 },
  { day: "Thursday", num: 4 },
  { day: "Friday", num: 5 },
  { day: "Saturday", num: 6 },
];
const ageGroups = ["All", "Youth 13 & above", "Adults 18 & above", "Seniors"];
const genders = ["All", "Male", "Female", "Both"];
const religions = [
  "All",
  "Atheism/Agnosticism",
  "Bahá’í",
  "Buddhism",
  "Christianity",
  "Confucianism",
  "Druze",
  "Gnosticism",
  "Hinduism",
  "Islam",
  "Jainism",
  "Judaism",
  "Rastafarianism",
  "Shinto",
  "Sikhism",
  "Zoroastrianism",
  "Traditional African Religions",
  "African Diaspora Religions",
  "Indigenous American Religions",
];
const raffleDraw = ["Rafflerra Draw", "Rafflerra Stage"];

const day = 24 * 60 * 60 * 1000;
const week = 7 * day;
const month = 30 * day;
let durationOptions = [
  { displayTime: "3 min", time: 3 },
  { displayTime: "6 min", time: 6 },
  { displayTime: "10 min", time: 10 },
  { displayTime: "15 min", time: 15 },
  { displayTime: "30 min", time: 30 },
  { displayTime: "45 min", time: 45 },
  { displayTime: "1 hr", time: 60 },
  { displayTime: "2 hr", time: 120 },
  // { displayTime: "3 hr", time: 180 },
  // { displayTime: "4 hr", time: 240 },
  // { displayTime: "5 hr", time: 300 },
  // { displayTime: "6 hr", time: 360 },
  // { displayTime: "7 hr", time: 420 },
  // { displayTime: "8 hr", time: 480 },
  // { displayTime: "9 hr", time: 540 },
  // { displayTime: "10 hr", time: 600 },
  // { displayTime: "11 hr", time: 660 },
  // { displayTime: "12 hr", time: 720 },
];
const options = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric', // Use 24-hour format
  minute: 'numeric',
  second: 'numeric',
  timeZoneName: 'short',
}

export default ({
  onClose,
  customize,
  type, //toggle between new and update raffle
  choosenRaffle, //this holds the choosen raffle
  choosenRafflePrizes, 
  onCustomizeTheme,
  raffleData,
  createRaffleTemplate, //to find template or raffle
  onActivateDeactivate, //only edit form
  seriesEdit, //only exist in edit raffle form
  setSeriesEdit,
  onCreateOrUpdateHostedRaffs,
}) => {
  const dispatch = useDispatch();
  // const [winners, setWinners] = useState(3);
  const alert = useAlert();

  // sub tabs
  const [subTabs, setSubTabs] = useState([
    "RECURRENCE",
    "PARTICIPANTS/PRIZE(S)",
    "TICKET",
    "LANDSCAPE",
    "DRAW CEREMONY",
    "AUTO ACTIONS",
    "TERMS & CONDITIONS",
    "HISTORY",
  ]);
  const [activeSubTab, setActiveSubTab] = useState("");
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDurationTime, setSelectedDurationTime] = useState(3);

  const [selectedPrizes, setSelectedPrizes] = useState([]);
  const [applyToAllStatus, setApplyToAllStatus] = useState(true);

  const [raffle, setRaffle] = useState({
    id: new Date().getTime().toString(),
    uuid: uuidv4(),
    title: "",
    rafflerType: "Fun", //customize["Raffle Type"],
    type: "Instant", //customize["Raffle Type"],
    serviceType: "", //customize["Service Type"],
    announceType: "Rafflerra Draw", //Rafflera stage, rafflera draw
    // prizeType: ["Money", "Money", "Money"],
    beneficiary: "", //customize["Beneficiary"],
    status: "DRAFT",
    createdBy: JSON.parse(sessionStorage.getItem("User")).email,
    // updatedDTTM: new Date(),
    // numPrizes: ["", "", ""],
    minNumParticipants: 2,
    maxNumParticipants: 10,
    // currency: ["$", "$", "$"],
    fee: 0,
    feeCurrency: "$",
    shortDescription: "",
    longDescription: "",
    participationOpens: "",
    participationCloses: "",
    drawnAt: "",
    drawAt: "",
    announceTypeLocation: "",
    proportion: ["", "", 5],
    numTieres: 3,
    numSeries: 9,
    seriesDelay: 7,
    participants: [],
    // winners: [],
    private: true, //customize['Private'],
    policiesAgree: true,
    styles: {
      background: "#FF5DAE",
      textColor: "white",
      titleColor: "white",
      titleFontFamily: "Arial",
      textFontFamily: "Arial",
      titleFontSize: 15,
      textFontSize: 15,
    },
    autoActions: {
      allowAutoActions: false,
      checkTimePercent: 50,
      autoExtend: false,
      maxExtensions: 1,
      extDurationPercent: 25,
      autoActionAfterMaxExtns: "Draw",
      checkDateTime: new Date(null),
      extDurationInMs: 0,
      autoCancel: false,
      autoCancelDateTime: new Date(null),
    },
    extras: {
      imageUploaded: false,
      joinedEmailsCount: 0,
      ticketLimitPerUser: 1,
      extendedTimes: 0,
      disclosure: RichTextEditor.createEmptyValue(),
      isTemplate: false,
      active: true,
      draft: false,
      seriesRaffle: {
        isMultipleRaffles: true,
        imgVidData: null,
        numofDrawnTimes: [],
        numofCancelledDraws: [],
        seriesId: new Date().getTime(),
        sno: 0,
        occurrenceModified: false,
        recurrencePattern: "One Time",
        // dailyPattern: { activeItem: 'everynday', value: 1 },
        onDemandDraws: 1,
        hourlyPattern: [],
        dailyPattern: { days: [] },
        weeklyPattern: { days: [], openDay: 0, closeDay: 6, drawDay: 6, openTime: "", closeTime: "", drawTime: "" },
        monthlyPattern: { days: [], openDay: 1, closeDay: 28, drawDay: 28, openTime: "", closeTime: "", drawTime: "" },
        yearlyPattern: { days: [], openMonth: 0, closeMonth: 11, drawMonth: 11, openDay: 1, closeDay: 28, drawDay: 28, openTime: "", closeTime: "", drawTime: "" },
        frequencyRange: {
          startDate: new Date(),
          activeItem: "endby",
          endAfterCount: 10,
          endByDate: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
        },
        includeWinners: true,
      },
      timeZone: JSON.parse(sessionStorage.getItem("User")).extras.timeZone,
      // prizesDescription: {},
      landscape: {
        age: "ALL",
        gender: "ALL",
        religion: "ALL",
        country: "",
        region: "",
        keywords: "",
      },
    },
  });

  const [selectedDateTime, setSelectedDateTime] = useState(new Date());

  //Raffle period endby date
  const [selectEndbyPeriod, setSelectEndbyPeriod] = useState(
    new Date(raffle.extras.seriesRaffle.frequencyRange.endByDate)
  );

  // list of timezones
  const timeZonesList = momentTz.tz.names();
  // console.log("timeZonesList:::::::::::", timeZonesList)

  // list of countries
  const [countriesList, setCountriesList] = useState([]);

  // for loading templates and for series raffle to find prev and next occurence
  const { myRaffles, status } = useSelector(
    ({ RaffleReducer }) => RaffleReducer
  );

  const [localRaffles, setLocalRaffles] = useState([]);

  // templat state
  const [templateChoosen, setTemplateChoosen] = useState(null);

  // to expand disclosure textarea
  const [expandDisclosure, setExpandDisclosure] = useState(false);

  // to verify regex
  const [isValidBasicDetails, setIsValidBasicDetails] = useState(false);
  const [createAttempted, setCreateAttempted] = useState(false);

  // update the countries according to the region
  useEffect(() => {
    const region = raffle.extras.landscape.region;
    if (region.length > 0) {
      const sortedCountries = CountriesList.filter((x) =>
        region.includes(x.region)
      );
      setCountriesList(sortedCountries);
    }
    if (
      type === "EDIT" &&
      raffle.type === "Instant"
    ) {
      const duration =
        new Date(raffle.participationCloses).getTime() -
        new Date(raffle.participationOpens).getTime();
      const calcDuration = Math.ceil(duration / 60000);
      console.log(
        "calcDuration::::::::::::::::::::::::::::::::::::::",
        calcDuration
      );
      setSelectedDurationTime(calcDuration);
    }
    // apiHistoryList(raffle?._id)
  }, [raffle]);

  const calculateCloseDateTime = (startTime, duration) => {
    // use participationOpens as start date time
    let openDate = new Date(startTime);
    let closeDate = new Date(openDate.getTime() + duration * 60000);
    if (closeDate <= new Date()) {
      alert.removeAll();
      alert.show("Select valid duration");
    } else {
      setRaffleFields(closeDate.toString(), "participationCloses");
      setSelectedDurationTime(duration);
    }
    // return closeDate.toString();
  };

  useEffect(() => {
    let curDttm = new Date();
    curDttm.setSeconds(0, 0);
    curDttm.setMinutes(curDttm.getMinutes() + 2, 0, 0);
    setRaffleFields(curDttm, "participationOpens");
    calculateCloseDateTime(curDttm, selectedDurationTime);
  }, []);

  // if any template choosen
  // setting dates to raffle
  useEffect(() => {
    let today = new Date();
    let closeDate = new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000);
    let drawDate = new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000);
    closeDate.setHours(23, 59, 0, 0);
    drawDate.setHours(24, 0, 0, 0);
    // if (raffle.type === "Instant") {
    today.setSeconds(0, 0);
    today.setMinutes(today.getMinutes() + 2, 0, 0);
    closeDate = new Date(today.getTime() + selectedDurationTime * 60000);
    // }

    if (templateChoosen) {
      // setting dates; we are storing dates in template

      const rffle = {
        ...templateChoosen,
        participationOpens: today,
        participationCloses: closeDate,
        drawAt: drawDate,
      };
      console.log(templateChoosen.extras);
      delete rffle["_id"];
      setRaffle({
        ...rffle,
        extras: {
          ...rffle.extras,
          isTemplate: false,
          disclosure: RichTextEditor.createValueFromString(
            rffle.extras.disclosure,
            "html"
          ),
        },
      });
      // setWinners(templateChoosen.numPrizes.length);
    } else {
      setRaffle({
        id: new Date().getTime().toString(),
        uuid: uuidv4(),
        title: "",
        rafflerType: "Fun", //customize["Raffle Type"],
        type: raffle.type, //customize["Raffle Type"],
        serviceType: "", //customize["Service Type"],
        announceType: "Rafflerra Draw", //Rafflera stage, rafflera draw
        // prizeType: ["Money", "Money", "Money"],
        beneficiary: "", //customize["Beneficiary"],
        status: raffle.status,
        createdBy: JSON.parse(sessionStorage.getItem("User")).email,
        // updatedDTTM: new Date(),
        // numPrizes: ["", "", ""],
        minNumParticipants: 2,
        maxNumParticipants: 10,
        // currency: raffle.currency,
        fee: 0,
        shortDescription: raffle.shortDescription,
        feeCurrency: raffle.feeCurrency,
        longDescription: "",
        participationOpens: today,
        participationCloses: closeDate,
        drawAt: drawDate,
        drawnAt: "",
        announceTypeLocation: "",
        proportion: ["", "", 5],
        numTieres: 3,
        numSeries: 9,
        seriesDelay: 7,
        participants: [],
        // winners: [],
        private: true, //customize['Private'],
        policiesAgree: true,
        styles: {
          background: "#FF5DAE",
          textColor: "white",
          titleColor: "white",
          titleFontFamily: "Arial",
          textFontFamily: "Arial",
          titleFontSize: 15,
          textFontSize: 15,
        },
        autoActions: {
          allowAutoActions: raffle.autoActions.allowAutoActions,
          checkTimePercent: raffle.autoActions.checkTimePercent,
          autoExtend: raffle.autoActions.autoExtend,
          maxExtensions: raffle.autoActions.maxExtensions,
          extDurationPercent: raffle.autoActions.extDurationPercent,
          autoActionAfterMaxExtns: raffle.autoActions.autoActionAfterMaxExtns,
          checkDateTime: raffle.autoActions.checkDateTime,
          autoCancelDateTime: raffle.autoActions.autoCancelDateTime,
          extDurationInMs: raffle.autoActions.extDurationInMs,
          autoCancel: raffle.autoActions.autoCancel,
        },
        extras: {
          imageUploaded: raffle.extras.imageUploaded,
          joinedEmailsCount: raffle.extras.joinedEmailsCount,
          ticketLimitPerUser: 1,
          extendedTimes: raffle.extras.extendedTimes,
          disclosure: RichTextEditor.createEmptyValue(),
          isTemplate: false,
          active: true,
          draft: false,
          seriesRaffle: {
            isMultipleRaffles: true,
            imgVidData: raffle.extras.seriesRaffle.imgVidData,
            occurrenceModified: false,
            numofDrawnTimes: raffle.extras.seriesRaffle.numofDrawnTimes,
            numofCancelledDraws: raffle.extras.seriesRaffle.numofCancelledDraws,
            seriesId: new Date().getTime(),
            recurrencePattern: raffle.extras.seriesRaffle.recurrencePattern,
            onDemandDraws: raffle.extras.seriesRaffle.onDemandDraws,
            dailyPattern: { days: [] },
            // dailyPattern: { activeItem: 'everynday', value: 1 },
            hourlyPattern: raffle.extras.seriesRaffle.hourlyPattern,
            weeklyPattern: { days: [], openDay: 0, closeDay: 6, drawDay: 6, openTime: "", closeTime: "", drawTime: "" },
            monthlyPattern: { days: [], openDay: 1, closeDay: 28, drawDay: 28, openTime: "", closeTime: "", drawTime: "" },
            yearlyPattern: { days: [], openMonth: 0, closeMonth: 11, drawMonth: 11, openDay: 1, closeDay: 28, drawDay: 28, openTime: "", closeTime: "", drawTime: "" },
            frequencyRange: {
              startDate: new Date(),
              activeItem: "endby",
              endAfterCount: 10,
              endByDate: new Date(
                new Date().getTime() + 30 * 24 * 60 * 60 * 1000
              ),
            },
            includeWinners: true,
          },
          timeZone: raffle.extras.timeZone,
          // prizesDescription: raffle.extras.prizesDescription,
          landscape: {
            age: "ALL",
            gender: "ALL",
            religion: "ALL",
            country: "",
            region: "",
            keywords: "",
          },
        },
      });
    }
  }, [templateChoosen]);

  // load all templates
  useEffect(() => {
    if (myRaffles.length > 0) {
      let user = JSON.parse(sessionStorage.getItem("User"));
      let email = user.email;
      let filterdRaffles = myRaffles.filter((x) => x.createdBy === email);
      setLocalRaffles(filterdRaffles);
    }
  }, [myRaffles]);

  // checking is a template form; set first active sub tab
  useEffect(() => {
    setRaffle((rff) => ({
      ...rff,
      extras: { ...rff.extras, isTemplate: createRaffleTemplate },
    }));
    if (createRaffleTemplate) {
      setActiveSubTab(subTabs[1]);
    } else {
      setActiveSubTab(subTabs[0]);
    }
  }, [createRaffleTemplate]);

  // checking if series edit or occur edit
  useEffect(() => {
    if (type === "EDIT") {
      if (!seriesEdit) {
        setActiveSubTab(subTabs[0]);
      }
    }
  }, [seriesEdit]);

  //raffle update form or creat form
  useEffect(() => {
    if (type === "EDIT") {
      setRaffle({
        ...choosenRaffle,
        extras: {
          ...choosenRaffle.extras,
          disclosure: RichTextEditor.createValueFromString(
            choosenRaffle.extras.disclosure,
            "html"
          ),
        },
      });
      console.log("choosenRafflePrizes::::::::::::", choosenRafflePrizes);
      setSelectedPrizes(choosenRafflePrizes);
      // setWinners(choosenRaffle.numPrizes.length);
      setSelectEndbyPeriod(new Date(choosenRaffle.extras.seriesRaffle.frequencyRange.endByDate));
      setApplyToAllStatus(false);
    } else {
      // setting dates to raffle
      let today = new Date();
      let closeDate = new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000);
      let drawDate = new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000);
      closeDate.setHours(23, 59, 0, 0);
      drawDate.setHours(24, 0, 0, 0);
      // if (raffle.type === "Instant") {
      today.setMinutes(today.getMinutes() + 2);
      closeDate = new Date(today.getTime() + selectedDurationTime * 60000);
      // }
      setRaffle((rff) => ({
        ...rff,
        participationOpens: today,
        participationCloses: closeDate,
        drawAt: drawDate,
      }));
    }

    // check if visting form multiple times
    if (raffleData !== null) {
      setRaffle(raffleData.raffle);
      // setWinners(raffleData.winners);
    }
  }, []);

  //change the subtabs based on the seriesEdit; shows only in edit raffle with seriesEdit false
  // useEffect(() => {
  //   if (seriesEdit || type === "NEW") {
  //     setSubTabs((tabs) => tabs.filter((x) => x !== "RAFFLE DATES"));
  //   }
  // }, []);

  useEffect(() => {
    console.log(raffle);
  }, [raffle]);

  const setRaffleFields = (value, field) => {
    setRaffle((raff) => ({ ...raff, [field]: value }));
  };

  const validPrizes = () => {
    let isValid = false;
    let inValidInstances = [];
    if (selectedPrizes.length > 0) {
      for (const prizeObj of selectedPrizes) {
        if (prizeObj.numPrizes.filter((x) => x.length === 0).length > 0) {
          inValidInstances.push(prizeObj.drawInsId);
        }
      }
    }
    console.log("inValidInstances::::::::::::::", inValidInstances);
    if (inValidInstances.length === 0) {
      isValid = true
    } else {
      alert.removeAll();
      alert.show(`Please provide Winners and Prizes for draw instances ${inValidInstances.join(', ')}`);
    }
    return isValid;
  }

  const saveRaffle = () => {
    const prizeValid = validPrizes();
    console.log(prizeValid);
    if (raffle.title.trim().length === 0) {
      alert.removeAll();
      alert.show("Please provide Raffle Name");
    } else if (raffle.shortDescription.trim().length === 0) {
      alert.removeAll();
      alert.show("Please provide Raffle Brief Description");
    } else if (raffle.longDescription.trim().length === 0) {
      alert.removeAll();
      alert.show("Please provide Raffle Detailed Description");
    } else if (!prizeValid) {
      // alert.removeAll();
      // alert.show("Please provide Winners and Prizes");
      console.log("Please provide Winners and Prizes");
    } else if (raffle.minNumParticipants.toString().trim().length === 0) {
      alert.removeAll();
      alert.show("Please provide minimum number of participants");
    } else if (raffle.maxNumParticipants.toString().trim().length === 0) {
      alert.removeAll();
      alert.show("Pleased provide maximum number of participants");
    } else if (raffle.fee.toString().trim().length === 0) {
      alert.removeAll();
      alert.show("Pleased provide fee to participate" + raffle.fee);
    } else if (
      raffle.shortDescription.length === 0 ||
      raffle.longDescription.length === 0
    ) {
      alert.removeAll();
      alert.show("Please provide short/long description");
    } else if (
      raffle.participationOpens.toString().length === 0 &&
      raffle.participationCloses.length === 0
    ) {
      alert.removeAll();
      alert.show("Please provide participation open/close date/time");
    } 
    // else if (winners > raffle.maxNumParticipants) {
    //   alert.removeAll();
    //   alert.show("Winners must be less than maximum number of participants");
    // } 
    else if (
      type === "NEW" &&
      ((raffle.extras.seriesRaffle.hourlyPattern.length === 0 &&
        raffle.extras.seriesRaffle.recurrencePattern === "Hourly") ||
        (raffle.extras.seriesRaffle.dailyPattern.days.length === 0 &&
          raffle.extras.seriesRaffle.recurrencePattern === "Daily") ||
        (raffle.extras.seriesRaffle.weeklyPattern.days.length === 0 &&
          raffle.extras.seriesRaffle.recurrencePattern === "Weekly") ||
        (raffle.extras.seriesRaffle.monthlyPattern.days.length === 0 &&
          raffle.extras.seriesRaffle.recurrencePattern === "Monthly"))
    ) {
      alert.removeAll();
      alert.show("Please select the available instances");
    } else if (
      raffle.rafflerType === "Fundraising" &&
      raffle.proportion.find((x) => x.toString().length === 0) !== undefined
    ) {
      alert.removeAll();
      alert.show("Please provide the proportion of the winning amount");
    } else if (
      raffle.rafflerType === "Tierd" &&
      raffle.numTieres.toString().trim().length === 0
    ) {
      alert.removeAll();
      alert.show("Please provide no. tieres");
    } else if (
      raffle.rafflerType === "Series" &&
      raffle.numSeries.toString().trim().length === 0 &&
      raffle.seriesDelay.toString().trim().length > 0
    ) {
      alert.removeAll();
      alert.show("Please provide no. series/ series delay");
    } else if (raffle.type !== "Series" && type === "NEW" &&
      new Date() > new Date(raffle.participationOpens)) {
      alert.removeAll();
      alert.show("Please select future dates for raffle.");
    } else {
      let today = new Date();
      let open = new Date(raffle.participationOpens);
      let close = new Date(raffle.participationCloses);
      let draw = new Date(raffle.drawAt);
      let seriesUneligible = raffle.extras.seriesRaffle.recurrencePattern !== "Weekly" &&
        raffle.extras.seriesRaffle.recurrencePattern !== "Monthly" &&
        raffle.extras.seriesRaffle.recurrencePattern !== "Yearly"

      if (
        type === "NEW" &&
        open.getTime() + 24 * 60 * 60 * 1000 < today.getTime()
        && seriesUneligible
      ) {
        alert.removeAll();
        alert.show("Raffle open date must be greater than today");
      } else if (close.getTime() < open.getTime() && seriesUneligible) {
        alert.removeAll();
        alert.show("Raffle close date must be greater than open date");
      } else if (
        draw.getTime() < close.getTime() &&
        raffle.type === "Series" &&
        raffle.extras.seriesRaffle.isMultipleRaffles && seriesUneligible
      ) {
        alert.removeAll();
        alert.show("Raffle draw date must be greater than raffle close date");
      } else {
        //form valid
        //raffle edit form
        if (type === "EDIT") {
          updateARaffle({
            ...raffle,
            status: raffle.status === "DRAFT" ? "CREATED" : raffle.status,
            extras: { ...raffle.extras, draft: false },
          });
        } else {
          // raffle.extras.seriesRaffle.frequencyRange.startDate = new Date(
          //   raffle.extras.seriesRaffle.frequencyRange.startDate.setMilliseconds(
          //     0
          //   )
          // );
          addNewRaffle({
            ...raffle,
            status: raffle.extras.draft ? "DRAFT" : "CREATED",
          });
        }
      }
    }
  };

  const [confirmDilog, setConfirmDilog] = useState(false);

  const saveDraftasDraft = (raff) => {
    request("POST_AUTH", updateRaffle, {
      raffle: { ...raff, extras: { ...raff.extras, draft: true } },
    }).then((res) => {
      dispatch(togggleLoad());
      if (res.status === 0) {
        onClose();
        dispatch(UpdateRaffle(res.payload));
        // dispatch(LoadMyRaffles());
        alert.removeAll();
        alert.show("Raffle Draft saved successfully");
        onCreateOrUpdateHostedRaffs(res.payload);
      } else if (res.status === 1) {
        alert.removeAll();
        alert.show(res.payload);
      } else {
        alert.removeAll();
        alert.show("Error while updating raffle");
      }
    });
  };

  const [rafflesInSeries, setRafflesInSeries] = useState([]);
  const apiGetRaffleBySeriesId = (seriesId) => {
    // setLoading(true);
    request(
      "GET_AUTH",
      getRaffleBySeriesId + `?seriesId=${seriesId}`
    )
      .then((res) => {
        if (res.status === 0) {
          const payload = res.payload.reverse();
          setRafflesInSeries(payload);
          // setLoading(false);
        } else {
          console.log("error res", res);
          // setLoading(false);
        }
      })
      .catch((e) => {
        console.log("error in apiGetRaffleBySeriesId");
        // setLoading(false);
      });
  };

  const [updatedInstances, setUpdatedInstances] = useState([]);

  const updateRaffleLists = async (raff) => {
    let prevSeriesRafflesList = [];
    let nextSeriesRafflesList = [];
    let updatedOccurrences = [];
    let filteredNextRafflesList = [];
    let rafflesInSeries = [];

    const res = await request(
      "GET_AUTH",
      getRaffleBySeriesId + `?seriesId=${raff.extras.seriesRaffle.seriesId}`
    )
    if (res.status === 0) {
      const payload = res.payload;
      console.log("seriesPayload:::", payload);
      if (seriesEdit === true) {
        rafflesInSeries = payload;
        prevSeriesRafflesList = payload.filter(
          (x) =>
            new Date(x.participationOpens) <= new Date()
        );
        nextSeriesRafflesList = payload.filter(
          (x) =>
            new Date(x.participationOpens) > new Date()
        );
        updatedOccurrences = payload.filter(
          (x) =>
            x.extras.seriesRaffle.occurrenceModified === true
        );
        let snoArr = updatedOccurrences.map((obj) => obj.extras.seriesRaffle.sno);
        console.log(snoArr);
        setUpdatedInstances(snoArr);
        filteredNextRafflesList = nextSeriesRafflesList.filter(
          (obj1) => !snoArr.includes(obj1.extras.seriesRaffle.sno)
        );
      }
    } else {
      console.log("error res", res);
      // setLoading(false);
    }

    console.log("prevSeriesRafflesList:::", prevSeriesRafflesList)
    console.log("nextSeriesRafflesList:::", nextSeriesRafflesList)
    console.log("updatedOccurrences:::", updatedOccurrences)
    console.log("filteredNextRafflesList:::", filteredNextRafflesList)
    console.log("rafflesInSeries:::", rafflesInSeries)

    return {
      prevList: [...prevSeriesRafflesList],
      nextList: [...nextSeriesRafflesList],
      updatedList: [...updatedOccurrences],
      filteredList: [...filteredNextRafflesList],
      rafflesInSeries: [...rafflesInSeries],
    };
  };

  const updateARaffle = async (raff) => {
    // raff.updatedDTTM = new Date();
    dispatch(togggleLoad());
    if (raff.extras.draft) {
      // a draft is being updated and saved as draft again
      saveDraftasDraft(raff);
    } else {
      if (seriesEdit === false) {
        raff.extras.seriesRaffle.occurrenceModified = true;
      }
      let { prevList, nextList, updatedList, filteredList, rafflesInSeries } =
        await updateRaffleLists(raff);
      console.log(updatedList);
      if (updatedList.length > 0) {
        dispatch(togggleLoad());
        setConfirmDilog(true);
      } else {
        updateExtension(raff, rafflesInSeries, nextList);
      }
    }
  };

  const onOverride = async () => {
    let raff = { ...raffle };
    let { prevList, nextList, updatedList, filteredList, rafflesInSeries } = await
      updateRaffleLists(raff);
    updateExtension(raff, rafflesInSeries, nextList);
    setConfirmDilog(false);
    dispatch(togggleLoad());
  };
  const onKeepChanges = async () => {
    let raff = { ...raffle };
    let { prevList, nextList, updatedList, filteredList, rafflesInSeries } = await
      updateRaffleLists(raff);
    updateExtension(raff, rafflesInSeries, filteredList);
    setConfirmDilog(false);
    dispatch(togggleLoad());
  };

  const updateExtension = (
    raff,
    prevSeriesRafflesList,
    nextSeriesRafflesList
  ) => {
    request("POST_AUTH", updateRaffle, {
      raffle: {
        ...raff,
        extras: {
          ...raff.extras,
          draft: false,
          disclosure: raff.extras.disclosure.toString("html"),
        },
      },
      seriesEdit,
      prevSeriesRafflesList,
      nextSeriesRafflesList,
      prizes: selectedPrizes,
    })
      .then((res) => {
        dispatch(togggleLoad());
        setSeriesEdit(false);
        if (res.status === 0) {
          const rffle = {
            ...raff,
            extras: {
              ...raff.extras,
              draft: false,
              disclosure: raff.extras.disclosure.toString("html"),
            },
          };
          alert.removeAll();
          alert.show(
            `Raffle ${raff.extras.draft ? "created" : "updated"} successfully`
          );
          console.log(res.payload);
          onClose();
          // dispatch(LoadMyRaffles());
          onCreateOrUpdateHostedRaffs(res.payload);
        } else if (res.status === 1) {
          alert.removeAll();
          alert.show("res.payload");
        } else {
          alert.removeAll();
          alert.show("Error while updating raffle");
        }
      })
      .catch((e) => {
        dispatch(togggleLoad());
        alert.removeAll();
        alert.show("Error creating raffle");
      });
  };

  const addNewRaffle = (raff) => {
    dispatch(togggleLoad());
    // makeing sure drawAt and drawnAt properties are deleted before saving new instant raffle
    if (raff.type === "Instant" || raff.extras.seriesRaffle.recurrencePattern === "On Demand") {
      delete raff.drawnAt;
      delete raff.drawAt;
    } else {
      delete raff.drawnAt;
    }


    console.log("raff::::::::::::::::::::::::::::::::::::::", raff);
    let _updatedRaffle = {
      ...raff,
      status: raff.extras.draft ? "DRAFT" : raff.status,
      extras: {
        ...raff.extras,
        disclosure: raff.extras.disclosure
          ? raff.extras.disclosure.toString("html")
          : raff.extras.disclosure,
      },
    };

    request("POST_AUTH", postRaffle, {raffle: _updatedRaffle, prizes: selectedPrizes})
      .then((res) => {
        dispatch(togggleLoad());
        if (res.status === 0) {
          alert.removeAll();
          _updatedRaffle.extras.draft
            ? alert.show("Raffle saved as draft")
            : alert.show("Raffle created successfully");
          onClose();
          onCreateOrUpdateHostedRaffs(res.payload);
        } else if (res.status === 1) {
          alert.removeAll();
          alert.show(res.payload);
        } else {
          alert.removeAll();
          alert.show("Error creating raffle" + JSON.stringify(res.payload));
        }
      })
      .catch((e) => {
        dispatch(togggleLoad());
        alert.removeAll();
        alert.show("Error creating raffle" + e.message);
      });
  };

  const saveasTemplate = () => {
    let data = { ...raffle };
    delete data["_id"];
    dispatch(togggleLoad());
    request("POST_AUTH", postRaffle, {
      ...data,
      title: data.title + " template",
      extras: {
        ...data.extras,
        seriesRaffle: {
          ...data.extras.seriesRaffle,
          recurrencePattern: "One Time",
        },
        isTemplate: true,
        disclosure: data.extras.disclosure.toString("html"),
      },
    })
      .then((res) => {
        dispatch(togggleLoad());
        console.log(res);
        if (res.status === 0) {
          dispatch(AddRaffle(res.payload));
          alert.removeAll();
          alert.show("Template saved successfully");
          onClose();
          onCreateOrUpdateHostedRaffs(res.payload);
        } else {
          alert.removeAll();
          alert.show("Error saving template" + JSON.stringify(res.payload));
        }
      })
      .catch((e) => {
        dispatch(togggleLoad());
        alert.removeAll();
        alert.show("Error saving template" + e.message);
      });
  };

  const disablingTabs = raffle.title.length === 0 || raffle.longDescription.length === 0 || raffle.shortDescription.length === 0
  const _renderSubTabs = () => {
    const shouldShowHistoryTab = type === "EDIT";
    const updatedSubTabs = shouldShowHistoryTab
      ? subTabs // Show all tabs if type is "EDIT"
      : subTabs.filter((tab) => tab !== "HISTORY");
    return (
      <div className="sub_tabs">
        {updatedSubTabs.map((tab, index) => {
          if (raffle.extras.isTemplate) {
            if (tab !== "RECURRENCE" && tab !== "RAFFLE DATES") {
              return (
                <button
                  disabled={disablingTabs}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveSubTab(tab);
                  }}
                  className={`${tab === activeSubTab ? "active" : ""}${isValidBasicDetails ? "" : " sub-tabs-disabled"}`}
                  key={index}
                  style={{
                    flex: 1,
                    textAlign: 'center',
                    fontSize: "13px",
                    fontWeight: "600",
                    cursor: "pointer",
                    padding: "10px 0px",
                    borderRadius: "6px",
                    border: '0px',
                    color: "black"
                  }}
                  data-tip
                  data-for={"tabs" + index}
                >
                  <span data-tip-disable={false} data-tip={"Please fill above fields"}>
                    <button
                      disabled={disablingTabs}
                      className="tabs"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      data-tip={"Please fill above fields"}
                    >
                      {tab}
                    </button>
                  </span>
                  <ReactTooltip />
                </button>
              );
            }
          }
          else {
            if (tab !== "RAFFLE DATES") {
              return (
                <button
                  disabled={disablingTabs}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveSubTab(tab);
                    if (tab === "HISTORY" && type === "EDIT") {
                      apiHistoryList(raffle?._id)
                    }
                  }}
                  className={`${tab === activeSubTab ? "active" : ""}${isValidBasicDetails ? "" : " sub-tabs-disabled"}`}
                  key={index}
                  style={{
                    flex: 1,
                    textAlign: 'center',
                    fontSize: "13px",
                    fontWeight: "bold",
                    cursor: "pointer",
                    padding: "10px 0px",
                    borderRadius: "6px",
                    border: '0px',
                    color: "black"
                  }}
                >
                  <span data-tip={"Please fill above fields"}>
                    <button
                      disabled={disablingTabs}
                      className={disablingTabs ? "disabled-tabs" : "tabs"}
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      data-tip-disable={disablingTabs ? false : true}
                      data-tip={"Please fill above fields"}
                    >
                      {tab}
                    </button>
                  </span>
                  <ReactTooltip />
                </button>
              );
            }
          }
        }
        )}
      </div>
    );
  };

  function checkTicketLimitPerUserValidity(input) {
    if (parseInt(input) < 1) {
      return "Please enter a number greater than or equal to 1";
    } else {
      return "";
    }
  }

  function handleTicketLimitPerUser(event) {
    const value = event.currentTarget.value;
    if (parseInt(raffle.fee) > 0 && parseInt(raffle.fee) <= 10) {
      setRaffle((rff) => ({
        ...rff,
        extras: {
          ...rff.extras,
          ticketLimitPerUser: parseInt(value),
        },
      }));
    } else {
      setRaffle((rff) => ({
        ...rff,
        extras: {
          ...rff.extras,
          ticketLimitPerUser: 1,
        },
      }));
    }
    const inputValidity = checkTicketLimitPerUserValidity(value);
    event.target.setCustomValidity(inputValidity);
    event.target.reportValidity();
  }

  const removeSpacesAtEnd = () => {
    let trimmedValue = raffle.title.trimEnd();
    if (trimmedValue !== raffle.title) {
      setRaffleFields(trimmedValue, "title");
    }
  }

  const onChangeType = (option) => {
    let tempRaffleAttr = {};

    if (option === "Series") { // default to hourly
      const openDate = new Date(raffle.participationOpens);
      const drawDate = new Date(raffle.participationOpens);
      tempRaffleAttr = {
        participationCloses: openDate,
        drawAt: drawDate,
      };
      setFreqRange(
        "Hourly",
        raffle.extras.seriesRaffle.frequencyRange.startDate
      );
    } else if (option === "Regular") { // One time
      let closeDate = new Date(
        new Date().getTime() + 6 * 24 * 60 * 60 * 1000
      );
      let drawDate = new Date(
        new Date().getTime() + 7 * 24 * 60 * 60 * 1000
      );
      closeDate.setHours(23, 59, 0, 0);
      drawDate.setHours(0, 0, 0, 0);
      tempRaffleAttr = {
        participationCloses: closeDate,
        drawAt: drawDate,
      };
    } else { // instant raffle
      let curDttm = new Date();
      calculateCloseDateTime(
        curDttm.toString(),
        selectedDurationTime
      );
    }
    setRaffle((rff) => ({
      ...rff,
      type: option,
      ...tempRaffleAttr,
      extras: {
        ...rff.extras,
        seriesRaffle: {
          ...rff.extras.seriesRaffle,
          recurrencePattern:
            option === "Series" ? "Hourly" : "One Time",
        },
      },
    }));
  }

  const _renderBaseDettails = () => {
    return (
      <Fragment>
        <div>
          <div className="crm_title">
            <label>NAME</label>
            <input
              disabled={
                (type === "EDIT" &&
                  (raffle.status === "OPEN" ||
                    raffle.status === "CLOSE" ||
                    raffle.status === "CANCEL" ||
                    raffle.status === "COMPLETE" ||
                    raffle.status === "DRAWN")
                ) ||
                (type === "EDIT" &&
                  !seriesEdit &&
                  raffle.type === "Series" &&
                  raffle.extras.seriesRaffle.isMultipleRaffles
                ) ||
                (type === "EDIT" &&
                  seriesEdit &&
                  (new Date(new Date(raffle.extras.seriesRaffle.hourlyPattern[0]).getTime() - 60 * 60000) < new Date() ||
                    new Date(raffle.extras.seriesRaffle.dailyPattern.days[0]).setHours(
                      new Date(raffle.participationOpens).getHours(),
                      new Date(raffle.participationOpens).getMinutes(),
                      0, 0
                    ) < new Date())
                )
              }
              className="raffle-name"
              value={raffle.title}
              onChange={(e) => {
                let value = e.target.value.replace(/^( )+|( )+$/g, ' ')
                value = value.trimStart();
                setRaffleFields(value, "title");
              }}
              onBlur={removeSpacesAtEnd}
              placeholder="Raffle Name"
              autoComplete="off"
              maxLength={50}
            />
            {createAttempted && raffle.title?.length <= 0 && (
              <p className="error-message">Required</p>
            )}
          </div>

          <div className="raffle-category">
            <label>CATEGORY</label>
            <select
              disabled={
                type === "EDIT" &&
                (
                  raffle.status === "OPEN" ||
                  raffle.status === "CLOSE" ||
                  raffle.status === "CANCEL" ||
                  raffle.status === "COMPLETE" ||
                  raffle.status === "DRAWN"
                )
              }
              value={raffle.rafflerType}
              onChange={(e) => {
                let option = e.currentTarget.value;
                setRaffle((rff) => ({ ...rff, rafflerType: option }));
              }}
            >
              <option key="Fun" value="Fun">
                Fun Raffle
              </option>
              <option key="Promotional" value="Promotional">
                Promotional Raffle
              </option>
              <option key="Gift Exchange" value="Gift Exchange">
                Gift Exchange Raffle
              </option>
            </select>
          </div>

          <div className="type">
            <label>TYPE</label>
            <select
              disabled={
                type === "EDIT"
              }
              value={raffle.type}
              onChange={(e) => {
                let option = e.currentTarget.value;
                onChangeType(option);
              }}
            >
              <option key="Instant" value="Instant">
                Instant
              </option>
              <option key="Regular" value="Regular">
                Regular
              </option>
              <option key="Series" value="Series">
                Series
              </option>
            </select>
          </div>

          {/* if create raffle form */}
          {/* {!createRaffleTemplate && (
            <div className="raffle-template">
              <label style={{ width: "180px" }}>TEMPLATE</label>
              <select
                disabled={
                  type === "EDIT" &&
                  (
                    raffle.status === "OPEN" ||
                    raffle.status === "CLOSE" ||
                    raffle.status === "CANCEL" ||
                    raffle.status === "COMPLETE" ||
                    raffle.status === "DRAWN"
                  )
                }
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  if (value !== "none") {
                    const choosenTemplate = localRaffles.find(
                      (x) => x._id == value
                    );
                    setTemplateChoosen(choosenTemplate);
                  } else {
                    setTemplateChoosen(null);
                  }
                }}
              >
                <option value="none">None</option>
                {localRaffles.map((lRaffle, index) => {
                  if (lRaffle.extras.isTemplate && lRaffle.extras.active) {
                    return (
                      raffle.rafflerType === lRaffle.rafflerType && (
                        <option key={index} value={lRaffle._id}>
                          {lRaffle.title}
                        </option>
                      )
                    );
                  }
                })}
              </select>
            </div>
          )} */}
        </div>
        <div className="brief_description">
          <label>BRIEF DESCRIPTION</label>
          <input
          maxLength={125}
            disabled={
              type === "EDIT" &&
              (
                raffle.status === "CLOSE" ||
                raffle.status === "CANCEL" ||
                raffle.status === "COMPLETE" ||
                raffle.status === "DRAWN"
              )
            }
            value={raffle.shortDescription}
            onChange={(e) =>
              setRaffleFields(e.currentTarget.value, "shortDescription")
            }
            placeholder="Write here..."
          />
        </div>
        <div className="detailed_description">
          <label>DETAILED DESCRIPTION</label>
          <textarea
            disabled={
              type === "EDIT" &&
              (
                raffle.status === "CLOSE" ||
                raffle.status === "CANCEL" ||
                raffle.status === "COMPLETE" ||
                raffle.status === "DRAWN"
              )
            }
            value={raffle.longDescription}
            onChange={(e) =>
              setRaffleFields(e.currentTarget.value, "longDescription")
            }
            placeholder="Write down your Long description here..."
          />
        </div>
      </Fragment>
    );
  };

  const _renderDisclosure = () => {
    return (
      <div className="t-and-c">
        <div className="disclosure">
          <RichTextEditor
            disabled={
              (type === "EDIT" &&
                (
                  raffle.status === "OPEN" ||
                  raffle.status === "CLOSE" ||
                  raffle.status === "CANCEL" ||
                  raffle.status === "COMPLETE" ||
                  raffle.status === "DRAWN"
                )
              ) || disablingTabs ||
              (type === "EDIT" &&
                seriesEdit &&
                (new Date(new Date(raffle.extras.seriesRaffle.hourlyPattern[0]).getTime() - 60 * 60000) < new Date() ||
                  new Date(raffle.extras.seriesRaffle.dailyPattern.days[0]).setHours(
                    new Date(raffle.participationOpens).getHours(),
                    new Date(raffle.participationOpens).getMinutes(),
                    0, 0
                  ) < new Date())
              )
            }
            className={
              expandDisclosure ? "expand_disclosure" : "disclosure-TandC"
            }
            value={raffle.extras.disclosure}
            onChange={(value) =>
              setRaffleFields({ ...raffle.extras, disclosure: value }, "extras")
            }
            disable={disablingTabs || type === "EDIT"}
            placeholder="Write here..."
          ></RichTextEditor>
          {expandDisclosure ? (
            <BiCollapse
              className="collapse_icon"
              onClick={() => setExpandDisclosure(false)}
            />
          ) : (
            <HiOutlineArrowsExpand
              className="expand_icon"
              onClick={() => setExpandDisclosure(true)}
            />
          )}
        </div>
      </div>
    );
  };

  const [raffHist, setRaffHist] = useState();
  const [raffEvent, setRaffEvent] = useState();

  const apiHistoryList = (raffId) => {
    // setLoading(true);
    request("GET_AUTH", historyList + `?raffle_id=${raffId}`).then((res) => {
      if (res.status === 0) {
        const payload = res.payload;
        setRaffHist(payload.history);
        setRaffEvent(payload.informationList);
        // setLoading(false);
      } else {
        console.log("error res", res);
        // setLoading(false);
      }
    }).catch((e) => {
      console.log("error in apiHistoryList");
      // setLoading(false);
    })
  }

  const _renderRaffleHistory = () => {
    return (
      <>
        {(
          <div className="raffle-history-container">
            <table className="table">
              <thead className="table-header">
                <tr>
                  <th>#</th>
                  <th>TIME STAMP</th>
                  <th>EVENT</th>
                  <th>EVENT DESCRIPTION</th>
                </tr>
              </thead>
              <tbody className="history-body">
                {
                  raffHist?.events.map((event, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{new Date(event.dttm).toLocaleString("en-US", {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: 'numeric', // Use 24-hour format
                        minute: 'numeric',
                      })}</td>
                      <td>{event.event_code}</td>
                      <td>
                        {
                          raffEvent?.raffle_events_info.find(eve => eve.ev_code === event.event_code
                          ).ev_desc
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        )}
      </>
    )
  }


  const _renderAutoActions = () => {
    const isInstant = raffle.type === "Instant";
    const isHourly = raffle.extras.seriesRaffle.recurrencePattern === "Hourly";
    const isDaily = raffle.extras.seriesRaffle.recurrencePattern === "Daily";
    return (
      <div className="auto-actions">

        <div className="miniParticipationCheckbox">
          <input
            type="checkbox"
            id="minParticipation"
            checked={raffle.autoActions.allowAutoActions}
            disabled={
              new Date(raffle.participationOpens) < new Date() &&
              type === "EDIT" || disablingTabs || isInstant ||
              !raffle.extras.seriesRaffle.isMultipleRaffles
            }
            onChange={(e) => {
              e.persist();
              setRaffle((rff) => ({
                ...rff,
                autoActions: {
                  ...rff.autoActions,
                  allowAutoActions: e.target.checked,
                  autoExtend: isHourly ? false : e.target.checked,
                  autoCancel: isHourly ? e.target.checked : false,
                },
              }));
            }}
          />
          <label htmlFor="minParticipation">
            {" "}
            If minimum participant(s)
            <input
              id="input-field"
              disabled={!raffle.autoActions.allowAutoActions || disablingTabs}
              min={2}
              type="number"
              value={raffle.minNumParticipants}
              onChange={handleInputChange}
              onBlur={handleBlur}
              required
            />
            is not met by
            <select
              name="autoAction"
              id="timePeriod"
              disabled={!raffle.autoActions.allowAutoActions || disablingTabs}
              value={raffle.autoActions.checkTimePercent}
              onChange={(e) => {
                let option = parseInt(e.target.value);
                setRaffle((rff) => ({
                  ...rff,
                  autoActions: {
                    ...rff.autoActions,
                    checkTimePercent: option,
                  },
                }));
              }}
            >
              <option key="50" value={50}>
                50%
              </option>
              <option key="60" value={60}>
                60%
              </option>
              <option key="70" value={70}>
                70%
              </option>
              <option key="80" value={80}>
                80%
              </option>
              <option key="90" value={90}>
                90%
              </option>
            </select>{" "}
            of raffle period:
          </label>
        </div>
        <div className="extensionAndCancel">
          <div className="checkbox-label">
            <input
              id="extension"
              type="radio"
              name="autoAction"
              checked={raffle.autoActions.autoExtend}
              disabled={!raffle.autoActions.allowAutoActions || disablingTabs}
              onChange={(e) => {
                e.persist();
                console.log(e.target.checked);
                setRaffle((rff) => ({
                  ...rff,
                  autoActions: {
                    ...rff.autoActions,
                    autoExtend: e.target.checked,
                    autoCancel: !e.target.checked,
                  },
                }));
              }}
            />
            <label>Allow</label>
            <div className="max-extensions">
              <div className="extension">
                {/* <label>CANCEL/DRAW AFTER MAX EXTENSIONS</label> */}
                <select
                  disabled={
                    !raffle.autoActions.allowAutoActions ||
                    raffle.autoActions.autoCancel || disablingTabs
                    // || new Date(raffle.participationOpens) < new Date()
                  }
                  value={raffle.autoActions.maxExtensions}
                  onChange={(e) => {
                    let option = parseInt(e.target.value);
                    setRaffle((rff) => ({
                      ...rff,
                      autoActions: {
                        ...rff.autoActions,
                        maxExtensions: option,
                      },
                    }));
                  }}
                >
                  <option key="1" value={1}>
                    1
                  </option>
                  <option key="2" value={2}>
                    2
                  </option>
                  <option key="3" value={3}>
                    3
                  </option>
                </select>
              </div>
            </div>
            <label>extension(s) by</label>
            <div className="extension">
              {/* <label htmlFor="extension">EXTENSION DURATION</label> */}
              <select
                value={raffle.autoActions.extDurationPercent}
                disabled={
                  !raffle.autoActions.allowAutoActions ||
                  raffle.autoActions.autoCancel || disablingTabs
                  // || new Date(raffle.participationOpens) < new Date()
                }
                onChange={(e) => {
                  let option = parseInt(e.target.value);
                  setRaffle((rff) => ({
                    ...rff,
                    autoActions: {
                      ...rff.autoActions,
                      extDurationPercent: option,
                    },
                  }));
                }}
              >
                <option key="5" value={5}>
                  5%
                </option>
                <option key="10" value={10}>
                  10%
                </option>
                <option key="25" value={25}>
                  25%
                </option>
                <option key="50" value={50}>
                  50%
                </option>
                {/* <option key="75" value={75}>
                  75%
                </option>
                <option key="100" value={100}>
                  100%
                </option> */}
              </select>
            </div>
            <label>of raffle period. After extension(s)</label>
            <div className="extension">
              {/* <label htmlFor="after-extension">Afetr Extension(s)</label> */}
              <select
                id="after-extension"
                value={raffle.autoActions.autoActionAfterMaxExtns}
                disabled={
                  !raffle.autoActions.allowAutoActions ||
                  raffle.autoActions.autoCancel || disablingTabs
                  // || new Date(raffle.participationOpens) < new Date()
                }
                onChange={(e) => {
                  let option = e.currentTarget.value;
                  setRaffle((rff) => ({
                    ...rff,
                    autoActions: {
                      ...rff.autoActions,
                      autoActionAfterMaxExtns: option,
                    },
                  }));
                }}
              >
                <option value="Draw">DRAW</option>
                <option value="Cancel">CANCEL</option>
              </select>
            </div>
            <label>the raffle.</label>
          </div>
          <div className="checkbox-label">
            <input
              id="cancel"
              type="radio"
              name="autoAction"
              disabled={!raffle.autoActions.allowAutoActions || disablingTabs
                // || new Date(raffle.participationOpens) < new Date()
              }
              checked={raffle.autoActions.autoCancel}
              onChange={(e) => {
                e.persist();
                console.log(e.target.checked);
                setRaffle((rff) => ({
                  ...rff,
                  autoActions: {
                    ...rff.autoActions,
                    autoExtend: !e.target.checked,
                    autoCancel: e.target.checked,
                  },
                }));
              }}
            />
            <label htmlFor="cancel">Cancel.</label>
          </div>
          {/* <div className="extend-note">
            Note: Extension allowed if minimum participation not met at 80% of total raffle period. No further extensions if minimum participants joined before 80%, even if there are remaining maximum extensions.
          </div> */}
        </div>

      </div>
    );
  };

  const _renderRaffleTicket = () => {
    return (
      <div className="raffle_ticket">
        <CustomizeRaffleTicket
          seriesEdit={seriesEdit}
          raffleData={raffle}
          theme={raffle.styles}
          setTheme={(thm) => setRaffle((rff) => ({ ...rff, styles: thm }))}
          type={type}
          setRaffleFields={(v, f) => setRaffleFields(v, f)}
        />
      </div>
    );
  };

  const _renderRaffleCeremony = () => {
    return (
      <div className="raffle_ceremony">
        <div>
          {/* <select disabled={true} name="announceType" onChange={onchange}>
                    <option>Rafflerra Draw</option>
                    <option>Rafflerra Stage</option>
                </select>
                <select disabled={true}>
                    <option>Customize Ceremony</option>
                </select> */}
          {raffleDraw.map((rdType, index) => {
            return (
              <div>
                <label>
                  <input
                    disabled={rdType === "Rafflerra Stage" || disablingTabs}
                    checked={rdType === "Rafflerra Draw"}
                    type="radio"
                  />
                  {rdType}
                </label>
                {/* customize rafflerra cermony */}
                {/* <div>Customize Ceremony</div> */}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const _renderRaffleDates = () => {
    let seriesRafflesList = null;
    let prevRaffle = null;
    let nextRaffle = null;
    if (type === "EDIT") {
      seriesRafflesList = myRaffles.filter(
        (x) =>
          x.extras.seriesRaffle.seriesId === raffle.extras.seriesRaffle.seriesId
      );
      seriesRafflesList = seriesRafflesList.sort(
        (a, b) =>
          new Date(b.participationOpens) - new Date(a.participationOpens)
      );
      prevRaffle = seriesRafflesList.find(
        (x) => raffle.extras.seriesRaffle.sno - 1 === x.extras.seriesRaffle.sno
      );
      nextRaffle = seriesRafflesList.find(
        (x) => raffle.extras.seriesRaffle.sno + 1 === x.extras.seriesRaffle.sno
      );
    }

    return (
      <div className="raffle_dates">
        {!createRaffleTemplate && (
          <div>
            <div>
              <div>
                <label>OPEN</label>
                <input
                  type="datetime-local"
                  id="datetime"
                  name="datetime"
                  value={selectedDateTime}
                  max={
                    type === "EDIT"
                      ? nextRaffle
                        ? new Date(nextRaffle.participationOpens)
                        : null
                      : null
                  }
                  min={
                    type === "EDIT"
                      ? prevRaffle
                        ? new Date(prevRaffle.drawAt)
                        : new Date()
                      : new Date()
                  }
                  selected={new Date(raffle.participationOpens)}
                  onChange={(event) => {
                    let dateTime = event.target.value;
                    setRaffleFields(dateTime, "participationOpens");
                    setSelectedDateTime(dateTime.target.value);
                  }}
                />
              </div>
              <div>
                <label>CLOSE</label>
                <DatePicker
                  minDate={new Date(raffle.participationOpens)}
                  maxDate={
                    type === "EDIT"
                      ? nextRaffle
                        ? new Date(nextRaffle.participationOpens)
                        : null
                      : null
                  }
                  dateFormat="MM/dd/yyyy kk:mm"
                  selected={new Date(raffle.participationCloses)}
                  onChange={(dateTime) => {
                    let tempDate = new Date(dateTime);
                    tempDate.setHours(11);
                    tempDate.setMinutes(59);
                    setRaffleFields(dateTime, "participationCloses");
                  }}
                />
              </div>
              <div>
                <label>DRAW</label>
                <DatePicker
                  minDate={new Date(raffle.participationCloses)}
                  maxDate={
                    type === "EDIT"
                      ? nextRaffle
                        ? new Date(nextRaffle.participationOpens)
                        : null
                      : null
                  }
                  dateFormat="MM/dd/yyyy kk:mm"
                  selected={
                    raffle.type === "Instant"
                      ? new Date()
                      : new Date(raffle.drawAt)
                  }
                  onChange={(dateTime) => {
                    let tempDate = new Date(dateTime);
                    tempDate.setHours(12);
                    tempDate.setMinutes(0);
                    setRaffleFields(dateTime, "drawAt");
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleInputChange = (event) => {
    let inputValue = parseInt(event.target.value);
    setRaffleFields(inputValue, "minNumParticipants");
    if (inputValue > raffle.maxNumParticipants) {
      setRaffleFields(inputValue, "maxNumParticipants");
    }
  };

  const handleBlur = () => {
    if (isNaN(raffle.minNumParticipants) || raffle.minNumParticipants < 2) {
      setRaffleFields(2, "minNumParticipants");
      alert.removeAll();
      alert.show("Please enter a value greater than or equal to 2");
    }
  };

  const onBlurMaxParticipants = () => {
    if (
      isNaN(raffle.maxNumParticipants) ||
      raffle.maxNumParticipants < raffle.minNumParticipants
    ) {
      setRaffleFields(raffle.minNumParticipants, "maxNumParticipants");
      alert.removeAll();
      alert.show(
        `Please enter a value greater than or equal to ${raffle.minNumParticipants}`
      );
    }
  };

  const onCheckUnlimited = (event) => {
    console.log(event.target.checked);
    if (event.target.checked) {
      setRaffleFields("Ꝏ", "maxNumParticipants");
    } else {
      if (raffle.minNumParticipants < 10) {
        setRaffleFields(10, "maxNumParticipants");
      } else {
        setRaffleFields(raffle.minNumParticipants, "maxNumParticipants");
      }
    }
  };


  const _renderRafflePrizes = (InputField) => {
    return (
      <div className="raffle_prizes">
        <div className="participants-section">
          <label className="title">PARTICIPANTS</label>
          <div>
            <div className="crm_noofparticipants">
              <label>MAX PARTICIPANTS</label>
              <input
                disabled={
                  raffle.maxNumParticipants === "∞" ||
                  raffle.maxNumParticipants === "Ꝏ" ||
                  (type === "EDIT" && (
                    raffle.status === "OPEN" ||
                    raffle.status === "CLOSE" ||
                    raffle.status === "CANCEL" ||
                    raffle.status === "COMPLETE" ||
                    raffle.status === "DRAWN"
                  )) || disablingTabs
                }
                type={
                  raffle.maxNumParticipants === "∞" ||
                    raffle.maxNumParticipants === "Ꝏ"
                    ? "text"
                    : "number"
                }
                min={raffle.minNumParticipants}
                value={raffle.maxNumParticipants}
                onChange={(e) => {
                  let inputValue = parseInt(e.target.value);
                  setRaffleFields(inputValue, "maxNumParticipants");
                }}
                onBlur={onBlurMaxParticipants}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "11px",
              }}
            >
              <div>
                <input
                  type="checkbox"
                  id="check-box"
                  disabled={
                    (type === "EDIT" && (
                      raffle.status === "OPEN" ||
                      raffle.status === "CLOSE" ||
                      raffle.status === "CANCEL" ||
                      raffle.status === "COMPLETE" ||
                      raffle.status === "DRAWN"
                    )) || disablingTabs
                  }
                  checked={
                    raffle.maxNumParticipants === "∞" ||
                    raffle.maxNumParticipants === "Ꝏ"
                  }
                  onChange={onCheckUnlimited}
                />
                <label htmlFor="check-box" style={{ paddingLeft: "5px" }}>
                  UNLIMITED PARTICIPANTS
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="prizes-section">
          <label className="title">PRIZES</label>
          <div className="num-of-winners">
            <label>NO. OF WINNERS</label>
            <input
              type="number"
              disabled={
                (type === "EDIT" && (
                  raffle.status === "OPEN" ||
                  raffle.status === "CLOSE" ||
                  raffle.status === "CANCEL" ||
                  raffle.status === "COMPLETE" ||
                  raffle.status === "DRAWN"
                )) || disablingTabs
              }
              min={1}
              max={100}
              maxLength={3}
              value={winners}
              onChange={onChangeNumWinners}
              onBlur={onBlurNumWinners}
            />
          </div>
          <div className="winners-list">
            {[...new Array(winners || 0).keys()].map((winner, i) => {
              return (
                <div key={i}>
                  #{i + 1}
                  <select
                    value={raffle.prizeType[i]}
                    disabled={
                      (type === "EDIT" && (
                        raffle.status === "OPEN" ||
                        raffle.status === "CLOSE" ||
                        raffle.status === "CANCEL" ||
                        raffle.status === "COMPLETE" ||
                        raffle.status === "DRAWN"
                      )) || disablingTabs
                    }
                    onChange={(e) => {
                      const type = e.currentTarget.value;
                      const prizeTypes = raffle.prizeType.map((x, j) =>
                        i === j ? type : x
                      );
                      // ['Money', 'Product', 'Money']
                      const currencyTypes = [];
                      prizeTypes.map((ptype, i) => {
                        if (ptype === "Money") {
                          currencyTypes.push(raffle.feeCurrency);
                        } else {
                          currencyTypes.push("");
                        }
                      });
                      setRaffle((raff) => ({
                        ...raff,
                        prizeType: prizeTypes,
                        currency: currencyTypes,
                      }));
                    }}
                  >
                    <option>Money</option>
                    <option>Product</option>
                    <option>Service</option>
                  </select>
                  {raffle.prizeType[i] === "Money" ? (
                    <select
                      disabled={raffle.fee > 0 || disablingTabs ||
                        (type === "EDIT" && (
                          raffle.status === "OPEN" ||
                          raffle.status === "CLOSE" ||
                          raffle.status === "CANCEL" ||
                          raffle.status === "COMPLETE" ||
                          raffle.status === "DRAWN"
                        ))}
                      style={{ width: "160px" }}
                      value={raffle.currency[i]}
                      onChange={(e) => {
                        const type = e.currentTarget.value;
                        const currencies = raffle.currency.map((x, j) =>
                          i === j ? type : x
                        );
                        setRaffle((raff) => ({
                          ...raff,
                          currency: currencies,
                        }));
                      }}
                    >
                      {currencies.map((obj, index) => {
                        return (
                          <option key={index} value={obj.symbol}>
                            {obj.name + " (" + obj.symbol + ")"}
                          </option>
                        );
                      })}
                    </select>
                  ) : null}
                  <input
                    required
                    disabled={
                      (type === "EDIT" && (
                        raffle.status === "OPEN" ||
                        raffle.status === "CLOSE" ||
                        raffle.status === "CANCEL" ||
                        raffle.status === "COMPLETE" ||
                        raffle.status === "DRAWN"
                      )) || disablingTabs
                    }
                    type={raffle.prizeType[i] === "Money" ? "number" : "text"}
                    value={raffle.numPrizes[i]}
                    onChange={(e) => {
                      let text = e.currentTarget.value;
                      setRaffleFields(
                        raffle.numPrizes.map((x, j) => (j === i ? text : x)),
                        "numPrizes"
                      );
                    }}
                    key={i}
                    placeholder={"Prize " + (i + 1)}
                    min={1}
                  />
                  <input
                    disabled={
                      (type === "EDIT" && (
                        raffle.status === "OPEN" ||
                        raffle.status === "CLOSE" ||
                        raffle.status === "CANCEL" ||
                        raffle.status === "COMPLETE" ||
                        raffle.status === "DRAWN"
                      )) || disablingTabs
                    }
                    onChange={(e) => {
                      const text = e.currentTarget.value;
                      setRaffle((rff) => ({
                        ...rff,
                        extras: {
                          ...rff.extras,
                          prizesDescription: {
                            ...rff.extras.prizesDescription,
                            [i]: text,
                          },
                        },
                      }));
                    }}
                    value={
                      raffle.extras.prizesDescription &&
                        typeof raffle.extras.prizesDescription[i] !== "undefined"
                        ? raffle.extras.prizesDescription[i]
                        : ""
                    }
                    placeholder="Short Description"
                  />
                </div>
              );
            })}
          </div>
        </div> */}
        <Prizes 
          raffle={raffle} // for raffle information
          selectedInstances={
            raffle.extras.seriesRaffle.recurrencePattern === "Hourly" ? 
            raffle.extras.seriesRaffle.hourlyPattern : 
            raffle.extras.seriesRaffle.recurrencePattern === "Daily" ? 
            raffle.extras.seriesRaffle.dailyPattern.days :
            []
          }
          selectedPrizes={selectedPrizes} 
          setSelectedPrizes={setSelectedPrizes}
          applyToAllStatus={applyToAllStatus} 
          setApplyToAllStatus={setApplyToAllStatus}
        />
      </div>
    );
  };

  const [remainingHours, setRemainingHours] = useState([]);
  const [remainingDays, setRemainingDays] = useState([]);
  const [minEndPeriodDate, setMinEndPeriodDate] = useState(
    raffle.extras.seriesRaffle.frequencyRange.startDate
  );
  const [maxDrawDate, setMaxDrawDate] = useState(null);
  const [availableInstances, setAvailableInstances] = useState(0);

  const [hoursMasterSelect, setHoursMasterSelect] = useState([]);
  // to create selected master hours object
  const masterSelectHours = (hours) => {
    const selectGroup = {};
    if (hours.length > 0) {
      hours.forEach((date) => {
        const dayKey = getDateStrWithTZ(new Date(date)).split(", ")[0];
        selectedHours[dayKey].push(date);
        setSelectedHours(selectedHours);
      });
    } else {
      const keys = Object.keys(selectedHours);
      keys.forEach(k => {
        selectedHours[k] = []
        setSelectedHours(selectedHours);
      })
    }
  }

  const calculateNumHours = (startDate, endDate) => {
    let hours = [];
    // if (startDate.getTime() - new Date().getTime() <= 2 * 60 * 60 * 1000) {
    startDate.setHours(startDate.getHours() + 2);
    // }
    const diffInMs = endDate - startDate;
    const diffInHrs = Math.round(diffInMs / (1000 * 60 * 60));

    for (let i = 0; i <= diffInHrs; i++) {
      const date = new Date(startDate.getTime() + i * 60 * 60 * 1000);
      // date.setMinutes(0, 0, 0);
      let hourlyObj = { date: setMinutesToZero(getDateStrWithTZ(new Date(date))), checked: false };
      if (type === "EDIT") {
        let raffleHours = raffle.extras.seriesRaffle.hourlyPattern.map(hour => new Date(hour).getTime());
        if (raffleHours.includes(new Date(hourlyObj.date).getTime()) || new Date(hourlyObj.date) > new Date(new Date().getTime() + 60 * 60000)) {
          hours.push(hourlyObj);
        }
      } else {
        hours.push(hourlyObj);
      }
    }
    setAvailableInstances(hours.length);
    setHoursMasterSelect(hours);

    const result = hours.reduce((acc, obj) => {
      const date = obj.date.split(", ")[0]; // Get the date as a string
      if (!acc[date]) {
        acc[date] = {}; // Create an array for the date if it doesn't exist yet
        acc[date].checked = false;
        acc[date].hours = [];
      }
      acc[date].hours.push(obj); // Add the hour to the array for the date
      return acc;
    }, {});
    const result2 = hours.reduce((acc, obj) => {
      const date = obj.date.split(", ")[0]; // Get the date as a string
      if (!acc[date]) {
        acc[date] = raffle.extras.seriesRaffle.hourlyPattern.filter(
          (x) => getDateStrWithTZ(new Date(x)).split(", ")[0] === date
        );
      }
      return acc;
    }, {});
    console.log(result);
    setRemainingHours(result);
    setSelectedHours(result2);
  };

  const [selectedHours, setSelectedHours] = useState({});

  const [selectedDays, setSelectedDays] = useState({});

  const [datesMasterSelect, setDatesMasterSelect] = useState([]);

  const calculateNumDays = (startDate, endDate) => {
    // startDate: "07/27/2023, 3:55 AM EDT"
    // endByDate: "08/26/2023, 3:55 AM EDT"
    const dates = [];
    const currentDate = new Date(startDate);
    const drawTimeMs = getMilliSecFromTime(getTimeStrWithTZ(raffle.drawAt));

    while (currentDate <= endDate) {
      if (type === "EDIT") {
        const raffleDays = raffle.extras.seriesRaffle.dailyPattern.days.map(day => {
          return new Date(day).toLocaleDateString();
        })
        if (
          raffleDays.includes(new Date(currentDate).toLocaleDateString()) ||
          (raffle.extras.seriesRaffle.isMultipleRaffles ?
            new Date(currentDate) > new Date() :
            new Date(currentDate).getDate() > new Date().getDate() + 1)
        ) {
          dates.push(new Date(currentDate));
        }
      } else {
        // if (!raffle.extras.seriesRaffle.isMultipleRaffles) {
        //   if (new Date(currentDate).getDate() > new Date().getDate() + 1) {
        //     dates.push(new Date(currentDate));
        //   }
        // } else {
        if (new Date(currentDate) > new Date()) {
          dates.push(new Date(currentDate));
        }
        // }
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    setAvailableInstances(dates.length);
    console.log("dates.length for setAvailableInstances", dates.length);

    // for master select
    const allDrawDtsWithTz = [];
    dates.forEach((date) => {
      const drawDttm = new Date(getDatePartTZ(date)).getTime() + drawTimeMs;
      allDrawDtsWithTz.push(getDateStrWithTZ(drawDttm));
    })
    setDatesMasterSelect(allDrawDtsWithTz);

    const groups = {};
    const selectedGroups = {};

    dates.forEach((date) => {
      const weekStart = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - date.getDay(),
        date.getHours(),
        date.getMinutes(),
      );
      const weekEnd = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - date.getDay() + 7,
        date.getHours(),
        date.getMinutes(),
      );

      const weekKey = getDatePartTZ(weekStart);

      if (!groups[weekKey]) {
        groups[weekKey] = [];
      }

      if (!selectedGroups[weekKey]) {
        selectedGroups[weekKey] = [];
      }

      if (date >= weekStart && date <= weekEnd) {
        const drawDttm = new Date(getDatePartTZ(date)).getTime() + drawTimeMs;
        groups[weekKey].push(getDateStrWithTZ(drawDttm));
      }
    });

    console.log("groups:::::::::::::::::::::", groups);
    console.log("selectedGroups:::::::::::::::::::::", selectedGroups);

    setSelectedDays(selectedGroups);
    setRemainingDays(groups);
  };

  useEffect(() => {
    if (raffle.extras.seriesRaffle.recurrencePattern === "Hourly") {
      calculateNumHours(
        new Date(raffle.extras.seriesRaffle.frequencyRange.startDate),
        new Date(raffle.extras.seriesRaffle.frequencyRange.endByDate)
      );
    }

    if (raffle.extras.seriesRaffle.recurrencePattern === "Daily") {
      // OCDofTargetTz(raffle.extras.timeZone);
      // const startDateInTz = getDateStrWithTZ(new Date(raffle.extras.seriesRaffle.frequencyRange.startDate))
      // const endByDateInTz = getDateStrWithTZ(new Date(raffle.extras.seriesRaffle.frequencyRange.endByDate));
      const startDateInTz = freqStartinTz(new Date(raffle.extras.seriesRaffle.frequencyRange.startDate), raffle.extras.timeZone);
      const endByDateInTz = freqEndinTz(new Date(raffle.extras.seriesRaffle.frequencyRange.endByDate), raffle.extras.timeZone);
      calculateNumDays(
        new Date(startDateInTz),
        new Date(endByDateInTz)
      );
    }

  }, [
    raffle.extras.seriesRaffle.frequencyRange.startDate,
    raffle.extras.seriesRaffle.frequencyRange.endByDate,
    raffle.extras.timeZone,
    raffle.extras.seriesRaffle.isMultipleRaffles,
    raffle.drawAt
  ]);

  useEffect(() => {
    if (raffle.extras.seriesRaffle.recurrencePattern === "Hourly" && !raffle.extras.seriesRaffle.isMultipleRaffles) {
      let hourDraws = [...raffle.extras.seriesRaffle.hourlyPattern];
      hourDraws.sort((a, b) => new Date(a) - new Date(b));
      if (hourDraws.length !== 0) {
        let openHourlyTime = new Date(new Date(hourDraws[0]).getTime() - 60 * 60000);
        let closeHourlyTime = new Date(new Date(hourDraws[hourDraws.length - 1]).getTime() - 5 * 60000);
        setRaffleFields(openHourlyTime, "participationOpens");
        setRaffleFields(closeHourlyTime, "participationCloses");
      }
    } else if (raffle.extras.seriesRaffle.recurrencePattern === "Daily" &&
      !raffle.extras.seriesRaffle.isMultipleRaffles) {
      let dailyDraws = [...raffle.extras.seriesRaffle.dailyPattern.days];
      dailyDraws.sort((a, b) => new Date(a) - new Date(b));
      if (dailyDraws.length !== 0) {
        const drawAtinTz = getDateStrWithTZ(new Date(raffle.drawAt));
        const firstSelectedDtinTz = getDateStrWithTZ(dailyDraws[0]);
        const lastSelectedDtinTz = getDateStrWithTZ(dailyDraws[dailyDraws.length - 1]);
        const lastDrawDt = new Date(lastSelectedDtinTz.split(',')[0] + drawAtinTz.split(',')[1])
        const openDailyTime = new Date(firstSelectedDtinTz).setHours(0, 0, 0, 0);
        let closeDailyTime = new Date(new Date(lastDrawDt).getTime() - 5 * 60000);
        setRaffleFields(openDailyTime, "participationOpens");
        setRaffleFields(closeDailyTime, "participationCloses");
      }
    }
  }, [raffle.extras.seriesRaffle.hourlyPattern,
  raffle.extras.seriesRaffle.dailyPattern.days,
  raffle.drawAt])

  const onChangeDrawAt = (_e) => {
    const dateTime = _e;
    dateTime.setSeconds(0, 0);
    options.timeZone = raffle.extras.timeZone;
    const now = new Intl.DateTimeFormat('en-US', options).format(new Date());
    const selectedDttm = new Date(dateTime.toLocaleString('en-US') + ` ${now.split(" ")[3]}`);
    console.log("selectedDttm in onChangeDrawAt::::", selectedDttm);
    if (
      selectedDttm <=
      new Date(raffle.participationCloses) &&
      raffle.extras.seriesRaffle.isMultipleRaffles
    ) {
      alert.removeAll();
      alert.show(
        "Please select a valid time greater than close time."
      );
    } else if (selectedDttm.getHours() === 0) {
      alert.removeAll();
      alert.show(
        "Please have a minimum of one hour raffle open period before first draw."
      );
    } else {
      setRaffleFields(selectedDttm, "drawAt");
    }
    if (
      raffle.extras.seriesRaffle.dailyPattern.days
        .length > 0
    ) {
      let raffleDays =
        raffle.extras.seriesRaffle.dailyPattern.days.map(
          (date) => {
            const dtPart = getDatePartTZ(new Date(date));
            const tmPart = getMilliSecFromTime(getTimeStrWithTZ(selectedDttm));
            const newDate = new Date(dtPart).getTime() + tmPart;
            return getDateStrWithTZ(new Date(newDate));
          }
        );
      setRaffle((rff) => ({
        ...rff,
        extras: {
          ...rff.extras,
          seriesRaffle: {
            ...rff.extras.seriesRaffle,
            dailyPattern: {
              ...rff.extras.seriesRaffle.dailyPattern,
              days: [...raffleDays],
            },
          },
        },
      }));
      setDatesMasterSelect([...raffleDays]);
    }
  }

  const masterSelectDays = async (days, startDate, endDate) => {
    console.log("executing masterselectdays===========================================================>>>>>>>>>>>>>>>>")

    const dates = [];
    const currentDate = new Date(startDate);
    const drawTimeMs = getMilliSecFromTime(getTimeStrWithTZ(raffle.drawAt));

    while (currentDate <= new Date(endDate)) {
      if (new Date(currentDate) > new Date()) {
        dates.push(new Date(currentDate));
      }
      // }
      currentDate.setDate(currentDate.getDate() + 1);
    };

    const selectedGroups = {};

    dates.forEach((date) => {
      const weekStart = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - date.getDay(),
        date.getHours(),
        date.getMinutes(),
      );

      const weekKey = getDatePartTZ(weekStart);

      if (!selectedGroups[weekKey]) {
        selectedGroups[weekKey] = [];
      }

    });

    days.forEach((x) => { //['08/19/2023, 3:00 PM EDT', '08/20/2023, 3:00 PM EDT', '08/21/2023, 3:00 PM EDT']
      const date = new Date(getDatePartTZ(new Date(x)).split(',')[0]); // get only date part of selected draw instance
      const weekStart = new Date( //Sun Aug 20 2023 00:30:00 GMT+0530
        date.getFullYear(), // 2023
        date.getMonth(), // 7
        date.getDate() - date.getDay(), // 20 - 0
        date.getHours(), // 00
        date.getMinutes(), // 30
      );
      const weekKey =
        `${getLocalDatePart(weekStart)},${getDatePartTZ(weekStart).split(",")[1]}`;

      if (Object.keys(selectedGroups).includes(weekKey)) {
        const drawDttm = new Date(getDatePartTZ(new Date(x))).getTime() + drawTimeMs;
        selectedGroups[weekKey].push(getDateStrWithTZ(drawDttm));
      }
    });

    console.log("selectedGroups from masterSelectDays func==================", selectedGroups);

    setSelectedDays(selectedGroups);
  };

  useEffect(() => {
    // console.log("executing masterSelectDays while editing.")
    if (raffle.type === "Series" && raffle.extras.seriesRaffle.recurrencePattern === "Daily") {
      masterSelectDays(raffle.extras.seriesRaffle.dailyPattern.days,
        raffle.extras.seriesRaffle.frequencyRange.startDate,
        raffle.extras.seriesRaffle.frequencyRange.endByDate);
    }
  }, [raffle.extras.seriesRaffle.dailyPattern.days])

  // To calculate weekly raffle occurrence count
  // const countWeekdays = (startDate, endDate) => {
  //   let count = 0;
  //   let currentDate = new Date(startDate.setMilliseconds(0));
  //   let endperiod = new Date(endDate.setMilliseconds(0));

  //   // Loop through all dates between the start and end date
  //   while (currentDate <= endperiod) {
  //     if (
  //       currentDate.getDay() ===
  //       raffle.extras.seriesRaffle.weeklyPattern.dayIndex
  //     ) {
  //       count++;
  //     }
  //     currentDate.setDate(currentDate.getDate() + 1);
  //   }
  //   if (
  //     startDate.getDay() === raffle.extras.seriesRaffle.weeklyPattern.dayIndex
  //   ) {
  //     count -= 1;
  //   }
  //   let recurValue = raffle.extras.seriesRaffle.weeklyPattern.value;
  //   if (recurValue > 1) {
  //     count = Math.round(count / recurValue);
  //   }
  //   console.log(
  //     "selectEndbyPeriod:::::::::::::::::::::::::::",
  //     selectEndbyPeriod
  //   );
  //   setSelectEndbyPeriod(endDate);
  //   setRaffle((rff) => ({
  //     ...rff,
  //     extras: {
  //       ...rff.extras,
  //       seriesRaffle: {
  //         ...rff.extras.seriesRaffle,
  //         frequencyRange: {
  //           ...rff.extras.seriesRaffle.frequencyRange,
  //           endAfterCount: count,
  //           endByDate: endDate,
  //         },
  //       },
  //     },
  //   }));
  //   return count;
  // };

  // useEffect(() => {
  //   if (raffle.extras.seriesRaffle.recurrencePattern === "Weekly") {
  //     countWeekdays(
  //       raffle.extras.seriesRaffle.frequencyRange.startDate,
  //       raffle.extras.seriesRaffle.frequencyRange.endByDate
  //     );
  //   }
  // }, [raffle.extras.seriesRaffle.weeklyPattern.value]);

  // To calculate daily raffle occurrence count
  // const countdays = (startDate, endDate) => {
  //   let count = 0;
  //   const dailyEndAfter = Math.round(
  //     (endDate.getTime() - startDate.getTime()) / day + 1
  //   );
  //   console.log(
  //     "selectEndbyPeriod:::::::::::::::::::::::::::",
  //     selectEndbyPeriod
  //   );
  //   setSelectEndbyPeriod(endDate);
  //   setRaffle((rff) => ({
  //     ...rff,
  //     extras: {
  //       ...rff.extras,
  //       seriesRaffle: {
  //         ...rff.extras.seriesRaffle,
  //         frequencyRange: {
  //           ...rff.extras.seriesRaffle.frequencyRange,
  //           endAfterCount: dailyEndAfter,
  //           endByDate: endDate,
  //         },
  //       },
  //     },
  //   }));
  //   return dailyEndAfter;
  // };

  // // To calculate monthly raffle occurrence count
  // const countMonths = (startDate, endDate) => {
  //   let count = 0;
  //   const monthEndAfter = Math.round(
  //     (endDate.getTime() - startDate.getTime()) / month
  //   );
  //   // setSelectEndbyPeriod(endDate);
  //   setRaffle((rff) => ({
  //     ...rff,
  //     extras: {
  //       ...rff.extras,
  //       seriesRaffle: {
  //         ...rff.extras.seriesRaffle,
  //         frequencyRange: {
  //           ...rff.extras.seriesRaffle.frequencyRange,
  //           endAfterCount: monthEndAfter,
  //           endByDate: endDate,
  //         },
  //       },
  //     },
  //   }));
  //   return monthEndAfter;
  // };

  useEffect(() => {
    let startDate = raffle.extras.seriesRaffle.frequencyRange.startDate;

    if (raffle.extras.seriesRaffle.recurrencePattern === "On Demand") {
      setRaffleFields(new Date(new Date(raffle.participationOpens).getTime() + day), "participationCloses");
    }
    setRaffle((rff) => ({
      ...rff,
      extras: {
        ...rff.extras,
        seriesRaffle: {
          ...rff.extras.seriesRaffle,
          isMultipleRaffles: raffle.extras.seriesRaffle.recurrencePattern !== "On Demand",
        },
      }
    }));

    if (raffle.extras.seriesRaffle.recurrencePattern === "Hourly") {
      setFreqRange(
        "Hourly",
        startDate
      );
      setMinEndPeriodDate(startDate);
    }

    if (raffle.extras.seriesRaffle.recurrencePattern === "Daily") {
      dailyPrerequisites(raffle.extras.seriesRaffle.frequencyRange.startDate,
        raffle.extras.seriesRaffle.frequencyRange.endByDate,
        raffle.extras.timeZone);
      // set open, close, draw
      if (type === "NEW") {
        OCDofTargetTz(raffle.extras.timeZone);
      }
    }

    if (raffle.extras.seriesRaffle.recurrencePattern === "Weekly") {
      console.log("weekly in create raffle modal is called")
      weeklyPrerequisites(raffle.extras.seriesRaffle.frequencyRange.startDate,
        raffle.extras.timeZone);
      if (type === "NEW") {
        OCDofTargetTz(raffle.extras.timeZone);

        // To set open, close and draw week days based on frequency
        const startForOpen = new Date(raffle.extras.seriesRaffle.frequencyRange.startDate).getDay();
        const endForOpen = new Date(new Date(raffle.extras.seriesRaffle.frequencyRange.startDate).getTime() + 6 * day).getDay();
        setRaffle((rff) => ({
          ...rff,
          extras: {
            ...rff.extras,
            seriesRaffle: {
              ...rff.extras.seriesRaffle,
              weeklyPattern: {
                ...rff.extras.seriesRaffle.weeklyPattern,
                openDay: startForOpen,
                closeDay: endForOpen,
                drawDay: endForOpen,
              },
            },
          },
        }));
      }
    }

    if (raffle.extras.seriesRaffle.recurrencePattern === "Monthly") {
      monthlyPrerequisites(raffle.extras.seriesRaffle.frequencyRange.startDate,
        raffle.extras.timeZone);
      if (type === "NEW") {
        OCDofTargetTz(raffle.extras.timeZone);
      }
    }

    if (raffle.extras.seriesRaffle.recurrencePattern === "Yearly") {
      yearlyPrerequisites(raffle.extras.seriesRaffle.frequencyRange.startDate,
        raffle.extras.timeZone);
      if (type === "NEW") {
        OCDofTargetTz(raffle.extras.timeZone);
      }
    }
  }, [
    raffle.extras.seriesRaffle.recurrencePattern,
    raffle.extras.seriesRaffle.frequencyRange.startDate,
  ]);

  const calPrizeInstances = (selectedInstances) => {
    let tempPrizes = [...selectedPrizes];
    if (selectedInstances.length > selectedPrizes.length) {
      [...new Array(selectedInstances.length - selectedPrizes.length || 0).keys()].forEach((num) => {
        const prizeObj = {
          drawInsId: selectedPrizes.length + num + 1,
          seriesId: raffle.extras.seriesRaffle.seriesId,
          prizeType: applyToAllStatus && selectedPrizes.length > 0 ? selectedPrizes[0].prizeType : ["Money", "Money", "Money"],
          currency: applyToAllStatus && selectedPrizes.length > 0 ? selectedPrizes[0].currency : ["$", "$", "$"],
          numPrizes: applyToAllStatus && selectedPrizes.length > 0 ? selectedPrizes[0].numPrizes : ["", "", ""],
          prizesDescription: applyToAllStatus && selectedPrizes.length > 0 ? selectedPrizes[0].prizesDescription : ["", "", ""],
          winners: [],
        }
        tempPrizes.push(prizeObj);
        
      })
      setSelectedPrizes([...tempPrizes]);
    } else if (selectedInstances.length < selectedPrizes.length) {
      tempPrizes = selectedPrizes.filter(obj => obj.drawInsId <= selectedInstances.length);
      setSelectedPrizes([...tempPrizes]);
    } else {
      setSelectedPrizes([...selectedPrizes]);
    }
  }

  // TO SORT ITEMS INSIDE HOURLYPATTERN ARAY
  useEffect(() => {
    raffle.extras.seriesRaffle.hourlyPattern.sort(
      (a, b) => new Date(a) - new Date(b)
    );
    if (selectedPrizes && raffle.extras.seriesRaffle.recurrencePattern === "Hourly") {
      calPrizeInstances([...raffle.extras.seriesRaffle.hourlyPattern])
    }
  }, [raffle.extras.seriesRaffle.hourlyPattern]);

  const setFreqRange = (pattern, startDate) => {
    if (type === "NEW") {
      clearOutSeriesData();
    }
    if (pattern === "Hourly") {
      let end = new Date(new Date(startDate).getTime() + 24 * 60 * 60 * 1000);
      console.log(
        "selectEndbyPeriod:::::::::::::::::::::::::::",
        selectEndbyPeriod
      );
      setSelectEndbyPeriod(end);
      setRaffle((rff) => ({
        ...rff,
        extras: {
          ...rff.extras,
          seriesRaffle: {
            ...rff.extras.seriesRaffle,
            frequencyRange: {
              ...rff.extras.seriesRaffle.frequencyRange,
              endByDate: end,
            },
          },
        },
      }));
    }
  };

  const clearOutSeriesData = () => {
    setRaffle((rff) => ({
      ...rff,
      extras: {
        ...rff.extras,
        seriesRaffle: {
          ...rff.extras.seriesRaffle,
          hourlyPattern: [],
          dailyPattern: { days: [] },
          weeklyPattern: { days: [], openDay: 0, closeDay: 6, drawDay: 6, openTime: "", closeTime: "", drawTime: "" },
          monthlyPattern: { days: [], openDay: 1, closeDay: 28, drawDay: 28, openTime: "", closeTime: "", drawTime: "" },
          yearlyPattern: { days: [], openMonth: 0, closeMonth: 11, drawMonth: 11, openDay: 1, closeDay: 28, drawDay: 28, openTime: "", closeTime: "", drawTime: "" },
        },
      },
    }));
  };

  const getFormattedDateTime = (date) => {
    const timeZone = raffle.extras.timeZone; // Replace with the selected country's time zone

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "numeric",
      hour12: true,
      timeZone: timeZone,
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);

  };

  const getDateStrWithTZ = (date) => {
    const timeZone = raffle.extras.timeZone; // Replace with the selected country's time zone

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: timeZone,
      timeZoneName: 'short',
    };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
  }

  const OCDofTargetTz = (tz) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZoneName: 'short',
      timeZone: tz
    }
    const now = new Intl.DateTimeFormat('en-US', options).format(new Date());
    const spltBySpc = now.split(" ");
    const openinTz = `${spltBySpc[0]} 12:00 AM ${spltBySpc[1]}`;
    const closeinTz = `${spltBySpc[0]} 11:55 PM ${spltBySpc[1]}`;
    const drawinTz = `${spltBySpc[0]} 11:59 PM ${spltBySpc[1]}`;
    console.log("openinTz, closeinTz, drawinTz:::::::::::::::", openinTz, closeinTz, drawinTz);
    setRaffleFields(
      new Date(openinTz),
      "participationOpens"
    );
    setRaffleFields(
      new Date(closeinTz),
      "participationCloses"
    );
    setRaffleFields(
      new Date(drawinTz),
      "drawAt"
    );
  }

  const freqStartinTz = (start, tz) => { //07/27/2023, GMT+5:30
    const startDateInTz = getDateStrWithTZ(start);

    setRaffle((rff) => ({
      ...rff,
      extras: {
        ...rff.extras,
        seriesRaffle: {
          ...rff.extras.seriesRaffle,
          frequencyRange: {
            ...rff.extras.seriesRaffle.frequencyRange,
            startDate: startDateInTz,
          },
        },
      },
    }));
    return startDateInTz;
  }

  const freqEndinTz = (endBy, tz) => {
    const endByDateInTz = getDateStrWithTZ(endBy);
    setRaffle((rff) => ({
      ...rff,
      extras: {
        ...rff.extras,
        seriesRaffle: {
          ...rff.extras.seriesRaffle,
          frequencyRange: {
            ...rff.extras.seriesRaffle.frequencyRange,
            endByDate: endByDateInTz,
          },
        },
      },
    }));
    return endByDateInTz;
  }

  const dailyPrerequisites = (start, endBy, tz) => {
    const startDateInTz = freqStartinTz(new Date(start), tz);
    const endByDateInTz = freqEndinTz(new Date(new Date(start).getTime() + 30 * 24 * 60 * 60 * 1000), tz);
    console.log("startDateInTz, endByDateInTz:::::::::::::::::::::::::", startDateInTz, endByDateInTz);
    setMinEndPeriodDate(new Date(startDateInTz));
    setSelectEndbyPeriod(new Date(startDateInTz).getTime() + 30 * 24 * 60 * 60 * 1000);
  }

  const weeklyPrerequisites = (start, tz) => {
    const startDateInTz = freqStartinTz(new Date(start), tz);
    const endByDateInTz = freqEndinTz(new Date(new Date(start).getTime() + 182 * 24 * 60 * 60 * 1000), tz);
    console.log("startDateInTz, endByDateInTz:::::::::::::::::::::::::", startDateInTz, endByDateInTz);
    setMinEndPeriodDate(new Date(startDateInTz));
    setSelectEndbyPeriod(new Date(startDateInTz).getTime() + 182 * 24 * 60 * 60 * 1000);
  }

  const monthlyPrerequisites = (start, tz) => {
    const startDateInTz = freqStartinTz(new Date(start), tz);
    const endByDateInTz = freqEndinTz(new Date(new Date(start).getTime() + 365 * 24 * 60 * 60 * 1000), tz);
    console.log("startDateInTz, endByDateInTz:::::::::::::::::::::::::", startDateInTz, endByDateInTz);
    setMinEndPeriodDate(new Date(startDateInTz));
    setSelectEndbyPeriod(new Date(startDateInTz).getTime() + 365 * 24 * 60 * 60 * 1000);
  }

  const yearlyPrerequisites = (start, tz) => {
    const startDateInTz = freqStartinTz(new Date(start), tz);
    const endByDateInTz = freqEndinTz(new Date(new Date(start).getTime() + 5 * 365 * 24 * 60 * 60 * 1000), tz);
    console.log("startDateInTz, endByDateInTz:::::::::::::::::::::::::", startDateInTz, endByDateInTz);
    setMinEndPeriodDate(new Date(startDateInTz));
    setSelectEndbyPeriod(new Date(startDateInTz).getTime() + 5 * 365 * 24 * 60 * 60 * 1000);
  }

  const getDatePartTZ = (date) => {
    const timeZone = raffle.extras.timeZone; // Replace with the selected country's time zone

    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      timeZone: timeZone,
      timeZoneName: 'short',
    };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
  }

  const getLocalDatePart = (date) => {

    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
  }

  function setMinutesToZero(dString) {
    // "07/24/2023, 7:40 AM EDT" or "07/24/2023, 7:40 AM GMT+5:30"
    const splitByComma = dString.split(", "); // ["07/24/2023", "7:40 AM GMT+5:30"]
    const splitTimeByM = splitByComma[1].split("M "); // ["7:40 A", "GMT+5:30"]
    const splitTimeBycolon = splitTimeByM[0].split(":"); // ["7", "40 A"]
    const splitMinBySpace = splitTimeBycolon[1].split(" ") // ["40", "A"]
    const outputDString = `${splitByComma[0]}, ${splitTimeBycolon[0]}:00 ${splitMinBySpace[1]}M ${splitTimeByM[1]}`
    return outputDString;
  }

  const getTimeStrWithTZ = (date) => {
    const timeZone = raffle.extras.timeZone; // Replace with the selected country's time zone

    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: timeZone,
    };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
  }

  const onChangeTz = (tz) => {
    setRaffle((rff) => ({
      ...rff,
      extras: { ...rff.extras, timeZone: tz },
    }));
    clearOutSeriesData();
  }

  const onChangeStart = (_e) => {
    // setSelectedDateTime(new Date(event.target.value))
    const currentDate = new Date();
    const dateTime = _e;
    console.log("START dateTime::::::::::::::::::::", dateTime);
    dateTime.setSeconds(0, 0);
    options.timeZone = raffle.extras.timeZone;
    const now = new Intl.DateTimeFormat('en-US', options).format(new Date());
    const selectedDttm = new Date(dateTime.toLocaleString('en-US') + ` ${now.split(" ")[3]}`);
    setFreqRange(
      raffle.extras.seriesRaffle.recurrencePattern,
      selectedDttm
    );
    if (raffle.extras.seriesRaffle.recurrencePattern === "Monthly") {
      selectedDttm.setHours(0, 0, 0);
    }
    console.log("START selectedDttm::::::::::::::::::::", selectedDttm)
    if (selectedDttm < new Date(now) &&
      (raffle.extras.seriesRaffle.recurrencePattern !== "Daily" &&
        raffle.extras.seriesRaffle.recurrencePattern !== "Weekly" &&
        raffle.extras.seriesRaffle.recurrencePattern !== "Monthly" &&
        raffle.extras.seriesRaffle.recurrencePattern !== "Yearly")
    ) {
      setRaffle((rff) => ({
        ...rff,
        extras: {
          ...rff.extras,
          seriesRaffle: {
            ...rff.extras.seriesRaffle,
            frequencyRange: {
              ...rff.extras.seriesRaffle.frequencyRange,
              startDate:
                raffle.extras.seriesRaffle.frequencyRange
                  .startDate,
            },
          },
        },
      }));
      alert.removeAll();
      alert.show("Please select a valid time in the future.");
    } else {
      setRaffle((rff) => ({
        ...rff,
        extras: {
          ...rff.extras,
          seriesRaffle: {
            ...rff.extras.seriesRaffle,
            frequencyRange: {
              ...rff.extras.seriesRaffle.frequencyRange,
              startDate: selectedDttm,
            },
          },
        },
      }));
      //for daily datetimes calc
      //for open time
      const openHrs = new Date(
        raffle.participationOpens
      ).getHours();
      const openMins = new Date(
        raffle.participationOpens
      ).getMinutes();
      let openTime = new Date(selectedDttm);
      openTime.setHours(openHrs, openMins, 0, 0);
      setRaffleFields(openTime, "participationOpens");
      //for close time
      const closeHrs = new Date(
        raffle.participationCloses
      ).getHours();
      const closeMins = new Date(
        raffle.participationCloses
      ).getMinutes();
      let closeTime = new Date(selectedDttm);
      closeTime.setHours(closeHrs, closeMins, 0, 0);
      setRaffleFields(closeTime, "participationCloses");
      //for draw time
      const drawHrs = new Date(raffle.drawAt).getHours();
      const drawMins = new Date(raffle.drawAt).getMinutes();
      let drawTime = new Date(selectedDttm);
      drawTime.setHours(drawHrs, drawMins, 0, 0);
      setRaffleFields(drawTime, "drawAt");
    }
  }
  const onChangeEnd = (_e) => {
    const dateTime = _e;
    console.log("END dateTime::::::::::::::::::::", dateTime);
    dateTime.setSeconds(0, 0);
    options.timeZone = raffle.extras.timeZone;
    const now = new Intl.DateTimeFormat('en-US', options).format(new Date());
    const selectedDttm = new Date(dateTime.toLocaleString('en-US') + ` ${now.split(" ")[3]}`);
    clearOutSeriesData();
    if (raffle.extras.seriesRaffle.recurrencePattern === "Monthly") {
      selectedDttm.setHours(0, 0, 0);
    }
    console.log("END selectedDttm::::::::::::::::::::", selectedDttm)
    if (selectedDttm < new Date(now)) {
      alert.removeAll();
      alert.show("Please select a valid time in the future.");
    } else if (selectedDttm <
      new Date(raffle.extras.seriesRaffle.frequencyRange.startDate)
    ) {
      alert.removeAll();
      alert.show(
        "Please select a valid time greater than start time."
      );
    } else if (
      raffle.extras.seriesRaffle.recurrencePattern ===
      "Hourly" && selectedDttm.getTime() >
      new Date(
        raffle.extras.seriesRaffle.frequencyRange.startDate
      ).getTime() +
      7 * day
    ) {
      alert.removeAll();
      alert.show(
        "Please select a valid time less than one week from start time."
      );
    } else {
      setRaffle((rff) => ({
        ...rff,
        extras: {
          ...rff.extras,
          seriesRaffle: {
            ...rff.extras.seriesRaffle,
            frequencyRange: {
              ...rff.extras.seriesRaffle.frequencyRange,
              endByDate: selectedDttm,
            },
          },
        },
      }));
      setSelectEndbyPeriod(selectedDttm);
    }
  }

  const _renderRaffleRecurrenceOptions = () => {
    // To filter past time from time picker
    const filterPassedTime = (time) => {
      const currentDate = new Date();
      const selectedDate = new Date(time);
      return currentDate.getTime() < selectedDate.getTime();
    };
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let selectionStart = selectedDateTime;
    selectionStart.setHours(0, 0, 0, 0);

    return (
      <div className={raffle.type === "Instant" && raffle.type === "Regular" ? "raffle_recurrence" : "raffle_recurrence_options"}>
        <div
          style={
            raffle.extras.seriesRaffle.recurrencePattern === "One Time"
              // || raffle.type === "Instant"
              ? { maxWidth: 130, minWidth: 100 }
              : { minWidth: 100 }
          }
        >
          <div>
            {/* left */}
            <div
              style={
                raffle.extras.seriesRaffle.recurrencePattern === "One Time"
                  // || raffle.type === "Instant"
                  ? { borderRight: 0 }
                  : {}
              }
            >
              {[
                // "Instant",
                "On Demand",
                "One Time",
                "Hourly",
                "Daily",
                "Weekly",
                "Monthly",
                "Yearly",
              ].map((_temp, index) => {
                let disabled = (type === "EDIT" && !raffle.extras.draft) || disablingTabs
                if (raffle.type === "Series") {
                  disabled = disabled || _temp === "One Time" || _temp === "On Demand" || _temp === "Weekly" || _temp === "Monthly" || _temp === "Yearly"
                } else if (raffle.type === "Instant") {
                  disabled = true;
                } else if (raffle.type === "Regular") {
                  disabled = disabled || !(_temp === "One Time" || _temp === "On Demand")
                }
                return (
                  <div key={index}>
                    <input
                      disabled={disabled}
                      onChange={(e) => {
                        setRaffle((rff) => ({
                          ...rff,
                          extras: {
                            ...rff.extras,
                            seriesRaffle: {
                              ...rff.extras.seriesRaffle,
                              recurrencePattern: _temp,
                              hourlyPattern: [],
                              dailyPattern: {
                                ...rff.extras.seriesRaffle.dailyPattern,
                                days: [],
                              },
                              weeklyPattern: {
                                ...rff.extras.seriesRaffle.weeklyPattern,
                                days: [],
                              },
                              monthlyPattern: {
                                ...rff.extras.seriesRaffle.monthlyPattern,
                                days: [],
                              },
                              yearlyPattern: {
                                ...rff.extras.seriesRaffle.yearlyPattern,
                                days: [],
                              },
                            },
                          },
                        }));
                      }}
                      checked={
                        raffle.extras.seriesRaffle.recurrencePattern === _temp
                      }
                      type="radio"
                    />
                    <label>{_temp}</label>
                  </div>
                );
              })}
            </div>
          </div>
          <label className="title">FREQUENCY</label>
        </div>
        {raffle.type === "Instant" && (
          <div>
            <div className="instant-date-container">
              <div className="instant-time-zone">
                <label>TIME ZONE</label>
                <select
                  disabled={
                    (type === "EDIT" && (
                      raffle.status === "OPEN" ||
                      raffle.status === "CLOSE" ||
                      raffle.status === "CANCEL" ||
                      raffle.status === "COMPLETE" ||
                      raffle.status === "DRAWN"
                    )) || disablingTabs
                  }
                  value={raffle.extras.timeZone}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    onChangeTz(value);
                  }}
                >
                  {timeZonesList.map((tzone, index) => {
                    return (
                      <option key={index} value={tzone}>
                        {tzone}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="instant-open">
                {/* paste the old code if anything goes wrong */}
                <label>OPENS AT</label>
                <DatePicker
                  popperPlacement="bottom-right"
                  className="instant-opensAt"
                  disabled={
                    (type === "EDIT" &&
                      !raffle.extras.draft &&
                      (
                        raffle.status === "OPEN" ||
                        raffle.status === "CLOSE" ||
                        raffle.status === "CANCEL" ||
                        raffle.status === "COMPLETE" ||
                        raffle.status === "DRAWN"
                      )) ||
                    disablingTabs
                  }
                  value={getFormattedDateTime(new Date(raffle.participationOpens))}
                  style={{ marginBottom: "12px" }}
                  selected={new Date(getFormattedDateTime(new Date(raffle.participationOpens)))}
                  dateFormat={"MM/dd/yyyy hh:mm aa"}
                  minDate={new Date()}
                  fixedHeight
                  // popperPlacement={"top"}
                  showTimeInput
                  showTimeSelectOnly
                  timeCaption="Time"
                  timeFormat="hh:mm aa"
                  filterTime={
                    selectionStart.getTime() === today.getTime()
                      ? filterPassedTime
                      : null
                  }
                  onChange={(_e) => {
                    const dateTime = _e;
                    dateTime.setSeconds(0, 0);
                    options.timeZone = raffle.extras.timeZone;
                    const now = new Intl.DateTimeFormat('en-US', options).format(new Date());
                    const selectedDttm = new Date(dateTime.toLocaleString('en-US') + ` ${now.split(" ")[3]}`);
                    console.log("now:::::::::::::", now);
                    console.log("selectedDttm:::::::::::::", selectedDttm);

                    if (selectedDttm < new Date(now)) {
                      alert.removeAll();
                      alert.show("Please select a valid time in the future.");
                    } else {
                      setRaffleFields(selectedDttm, "participationOpens");
                      let closeDate = new Date(
                        selectedDttm.getTime() + selectedDurationTime * 60000
                      );
                      setRaffleFields(closeDate, "participationCloses");
                    }
                  }}
                />
              </div>
              <div className="instant-duration">
                <label>DURATION</label>
                <select
                  disabled={
                    (type === "EDIT" && (
                      raffle.status === "CLOSE" ||
                      raffle.status === "CANCEL" ||
                      raffle.status === "COMPLETE" ||
                      raffle.status === "DRAWN"
                    ) &&
                      !raffle.extras.draft) || disablingTabs
                  }
                  value={selectedDurationTime}
                  onChange={(e) => {
                    // setSelectedDurationTime(e.target.value);
                    calculateCloseDateTime(
                      raffle.participationOpens,
                      e.target.value
                    );
                  }}
                >
                  {durationOptions.map((durationItem, index) => {
                    return (
                      <option key={index} value={durationItem.time}>
                        {durationItem.displayTime}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="instant-close">
                <label>CLOSES AT</label>
                <DatePicker
                  value={getFormattedDateTime(new Date(raffle.participationCloses))}
                  selected={new Date(raffle.participationCloses)}
                  dateFormat={"MM/dd/yyyy hh:mm aa"}
                  disabled
                />
              </div>
            </div>
            <label className={window.innerWidth > 720 ? "title" : "raffle-dates-title"}>{"RAFFLE DATES"}</label>
          </div>
        )}
        {raffle.type === "Series" && (
          <div className={raffle.type === "Series" ? "series-period-container" : ""}>
            <div >
              <div>
                <label>TIME ZONE</label>
                <select
                  disabled={type === "EDIT" || disablingTabs || raffle.type !== "Series"}
                  value={raffle.extras.timeZone}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    onChangeTz(value);
                  }}
                >
                  {timeZonesList.map((tzone, index) => {
                    return (
                      <option key={index} value={tzone}>
                        {tzone}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <label>START</label>
                <DatePicker
                popperPlacement="bottom"
                  disabled={type === "EDIT" || disablingTabs || raffle.type !== "Series"}
                  dateFormat={
                    raffle.extras.seriesRaffle.recurrencePattern === "Daily" ||
                      raffle.extras.seriesRaffle.recurrencePattern === "Weekly" ?
                      "MM/dd/yyyy" :
                      raffle.extras.seriesRaffle.recurrencePattern === "Monthly" ?
                        "MMM/yyyy" :
                        raffle.extras.seriesRaffle.recurrencePattern === "Yearly" ?
                          "yyyy" :
                          "MM/dd/yyyy hh:mm aa"
                  }
                  showMonthYearPicker={raffle.extras.seriesRaffle.recurrencePattern === "Monthly"}
                  showYearPicker={raffle.extras.seriesRaffle.recurrencePattern === "Yearly"}
                  selected={new Date(getFormattedDateTime(new Date(raffle.extras.seriesRaffle.frequencyRange.startDate)))}
                  value={new Date(getFormattedDateTime(new Date(raffle.extras.seriesRaffle.frequencyRange.startDate)))}
                  showTimeInput={
                    raffle.extras.seriesRaffle.recurrencePattern ===
                      "Hourly" ||
                      (raffle.extras.seriesRaffle.recurrencePattern ===
                        "Daily" &&
                        !raffle.extras.seriesRaffle.isMultipleRaffles)
                      ? true
                      : false
                  }
                  minDate={new Date()}
                  onChange={(event) => { onChangeStart(event); }}
                />
              </div>
              <div>
                <label>END</label>
                <DatePicker
                  popperPlacement="bottom"
                  style={{ float: "top" }}
                  disabled={
                    type === "EDIT" || disablingTabs || raffle.type !== "Series"
                  }
                  minDate={minEndPeriodDate}
                  maxDate={
                    raffle.extras.seriesRaffle.recurrencePattern === "Hourly" ?
                      new Date(
                        new Date(
                          raffle.extras.seriesRaffle.frequencyRange.startDate
                        ).getTime() + 7 * day
                      ) :
                      raffle.extras.seriesRaffle.recurrencePattern === "Daily" ?
                        new Date(
                          new Date(
                            raffle.extras.seriesRaffle.frequencyRange.startDate
                          ).getTime() + 30 * day
                        ) : null
                  }
                  selected={new Date(getFormattedDateTime(new Date(selectEndbyPeriod)))}
                  value={new Date(getFormattedDateTime(new Date(selectEndbyPeriod)))}
                  dateFormat={
                    raffle.extras.seriesRaffle.recurrencePattern === "Daily" ||
                      raffle.extras.seriesRaffle.recurrencePattern === "Weekly" ?
                      "MM/dd/yyyy" :
                      raffle.extras.seriesRaffle.recurrencePattern === "Monthly" ?
                        "MMM/yyyy" :
                        raffle.extras.seriesRaffle.recurrencePattern === "Yearly" ?
                          "yyyy" :
                          "MM/dd/yyyy hh:mm aa"
                  }
                  showMonthYearPicker={raffle.extras.seriesRaffle.recurrencePattern === "Monthly"}
                  showYearPicker={raffle.extras.seriesRaffle.recurrencePattern === "Yearly"}
                  showTimeInput={
                    raffle.extras.seriesRaffle.recurrencePattern ===
                      "Hourly" ||
                      (raffle.extras.seriesRaffle.recurrencePattern ===
                        "Daily" &&
                        !raffle.extras.seriesRaffle.isMultipleRaffles)
                      ? true
                      : false
                  }
                  onChange={(event) => { onChangeEnd(event); }}
                />
              </div>
            </div>
            <label className="title">SERIES PERIOD</label>
          </div>
        )}
        <div className={raffle.type === "Series" ? "frequency-right" : ""}>
          {raffle.type === "Series" && (
              <div className="series-raffles">
                <div className="raffle-type">
                  <label className="series-type" style={{ fontWeight: "700", textAlign: "left" }}>
                    SERIES TYPE:
                  </label>
                  <label
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    <input
                      disabled={type === "EDIT" || disablingTabs}
                      type="radio"
                      checked={raffle.extras.seriesRaffle.isMultipleRaffles}
                      onChange={() => {
                        setRaffle((rff) => ({
                          ...rff,
                          extras: {
                            ...rff.extras,
                            seriesRaffle: {
                              ...rff.extras.seriesRaffle,
                              isMultipleRaffles: true,
                              hourlyPattern: [],
                              dailyPattern: {
                                ...rff.extras.seriesRaffle.dailyPattern,
                                days: []
                              },
                              weeklyPattern: {
                                ...rff.extras.seriesRaffle.weeklyPattern,
                                days: []
                              },
                              monthlyPattern: {
                                ...rff.extras.seriesRaffle.monthlyPattern,
                                days: []
                              },
                            },
                          },
                        }));
                        masterSelectHours([]);
                      }}
                    />
                    {" Multiple raffles with respective draws"}
                  </label>
                  {/* <br />
                      <br /> */}
                  <label
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    <input
                      disabled={type === "EDIT" || disablingTabs}
                      type="radio"
                      checked={!raffle.extras.seriesRaffle.isMultipleRaffles}
                      onChange={() => {
                        setRaffle((rff) => ({
                          ...rff,
                          extras: {
                            ...rff.extras,
                            seriesRaffle: {
                              ...rff.extras.seriesRaffle,
                              isMultipleRaffles: false,
                              hourlyPattern: [],
                              dailyPattern: {
                                ...rff.extras.seriesRaffle.dailyPattern,
                                days: []
                              },
                              weeklyPattern: {
                                ...rff.extras.seriesRaffle.weeklyPattern,
                                days: []
                              },
                              monthlyPattern: {
                                ...rff.extras.seriesRaffle.monthlyPattern,
                                days: []
                              },
                            },
                          },
                          autoActions: {
                            ...rff.autoActions,
                            allowAutoActions: false,
                            autoExtend: false,
                            autoCancel: false
                          },
                        }));
                        masterSelectHours([]);

                      }}
                    />

                    {" Single raffle with multiple draws"}
                  </label>
                </div>

                {!(raffle.extras.seriesRaffle.recurrencePattern === "Hourly" &&
                  raffle.extras.seriesRaffle.isMultipleRaffles) &&
                  raffle.extras.seriesRaffle.recurrencePattern !== "Monthly" &&
                  raffle.extras.seriesRaffle.recurrencePattern !== "Weekly" &&
                  raffle.extras.seriesRaffle.recurrencePattern !== "Yearly" &&
                  (
                    <div className="open-close-draw">
                      <label style={{ fontWeight: "700", textAlign: "left" }}>
                        RAFFLE TIME:
                      </label>
                      <label>
                        OPEN
                        <DatePicker
                        popperPlacement="left"
                        style={{float: "top"}}
                          selected={
                            !raffle.extras.seriesRaffle.isMultipleRaffles ?
                              raffle.extras.seriesRaffle.hourlyPattern.length !== 0 ||
                                raffle.extras.seriesRaffle.dailyPattern.days.length !== 0 ?
                                new Date(getFormattedDateTime(new Date(raffle.participationOpens))) :
                                ""
                              : new Date(getFormattedDateTime(new Date(raffle.participationOpens)))
                          }
                          value={
                            !raffle.extras.seriesRaffle.isMultipleRaffles ?
                              raffle.extras.seriesRaffle.hourlyPattern.length !== 0 ||
                                raffle.extras.seriesRaffle.dailyPattern.days.length !== 0 ?
                                new Date(getFormattedDateTime(new Date(raffle.participationOpens))) :
                                ""
                              : new Date(getFormattedDateTime(new Date(raffle.participationOpens)))
                          }
                          disabled={
                            !raffle.extras.seriesRaffle.isMultipleRaffles ||
                            type === "EDIT"
                          }
                          showTimeInput
                          showTimeSelectOnly
                          dateFormat={
                            !raffle.extras.seriesRaffle.isMultipleRaffles
                              ? "MM/dd/yyyy hh:mm aa"
                              : "hh:mm aa"
                          }
                          onChange={(_e) => {
                            const dateTime = _e;
                            dateTime.setSeconds(0, 0);
                            options.timeZone = raffle.extras.timeZone;
                            const now = new Intl.DateTimeFormat('en-US', options).format(new Date());
                            const selectedDttm = new Date(dateTime.toLocaleString('en-US') + ` ${now.split(" ")[3]}`);
                            setRaffleFields(selectedDttm, "participationOpens");
                          }}
                        />
                      </label>
                      <label>
                        CLOSE
                        <DatePicker
                          style={{ float: "top" }}
                          selected={
                            !raffle.extras.seriesRaffle.isMultipleRaffles ?
                              raffle.extras.seriesRaffle.hourlyPattern.length !== 0 ||
                                raffle.extras.seriesRaffle.dailyPattern.days.length !== 0 ?
                                new Date(getFormattedDateTime(new Date(raffle.participationCloses))) :
                                ""
                              : new Date(getFormattedDateTime(new Date(raffle.participationCloses)))
                          }
                          value={
                            !raffle.extras.seriesRaffle.isMultipleRaffles ?
                              raffle.extras.seriesRaffle.hourlyPattern.length !== 0 ||
                                raffle.extras.seriesRaffle.dailyPattern.days.length !== 0 ?
                                new Date(getFormattedDateTime(new Date(raffle.participationCloses))) :
                                ""
                              : new Date(getFormattedDateTime(new Date(raffle.participationCloses)))
                          }
                          disabled={
                            !raffle.extras.seriesRaffle.isMultipleRaffles ||
                            type === "EDIT"
                          }
                          showTimeInput
                          showTimeSelectOnly
                          dateFormat={
                            !raffle.extras.seriesRaffle.isMultipleRaffles
                              ? "MM/dd/yyyy hh:mm aa"
                              : "hh:mm aa"
                          }
                          onChange={(_e) => {
                            const dateTime = _e;
                            dateTime.setSeconds(0, 0);
                            options.timeZone = raffle.extras.timeZone;
                            const now = new Intl.DateTimeFormat('en-US', options).format(new Date());
                            const selectedDttm = new Date(dateTime.toLocaleString('en-US') + ` ${now.split(" ")[3]}`);
                            if (
                              selectedDttm <=
                              new Date(raffle.participationOpens)
                            ) {
                              alert.removeAll();
                              alert.show(
                                "Please select a valid time greater than start time."
                              );
                            } else if (
                              selectedDttm >=
                              new Date(raffle.drawAt)
                            ) {
                              alert.removeAll();
                              alert.show(
                                "Please select a valid time less than draw time."
                              );
                            } else {
                              setRaffleFields(selectedDttm, "participationCloses");
                            }
                          }}
                        />
                      </label>
                      {raffle.extras.seriesRaffle.recurrencePattern !==
                        "Hourly" &&
                        <label>
                          {!raffle.extras.seriesRaffle.isMultipleRaffles
                            ? "DRAW(<DAILY>@)"
                            : "DRAW"}
                          <DatePicker
                            style={{ float: "top" }}
                            selected={new Date(getFormattedDateTime(new Date(raffle.drawAt)))}
                            value={new Date(getFormattedDateTime(new Date(raffle.drawAt)))}
                            disabled={type === "EDIT"}
                            showTimeInput
                            showTimeSelectOnly
                            dateFormat={"hh:mm aa"}
                            onChange={(_e) => {
                              onChangeDrawAt(_e);
                            }}
                          />
                        </label>
                      }
                    </div>
                  )}

                {/* {raffle.extras.seriesRaffle.recurrencePattern === "Monthly" && (
                    <div>
                    <label style={{ fontWeight: "700", textAlign: "left" }}>
                        RAFFLE TIME:
                      </label>
                      <div>
                      <label>OPEN</label>
                      <select>
                      {numDaysOfMonth.map((day, index) => {
                          return <option>{day}</option>
                        })}
                      </select>
                      <input type="time" />
                      </div>
                      <div>
                      <label>CLOSE</label>
                      <select>
                      {numDaysOfMonth.map((day, index) => {
                          return <option>{day}</option>
                        })}
                      </select>
                      <input type="time" />
                      </div>
                      <div>
                      <label>DRAW</label>
                      <select>
                      {numDaysOfMonth.map((day, index) => {
                          return <option>{day}</option>
                        })}
                      </select>
                      <input type="time" />
                      </div>
                    </div>
                  )} */}

                {raffle.extras.seriesRaffle.recurrencePattern ===
                  "Hourly" && (
                    //HOURLY
                    <div className="series-sub-raffle">
                      <div className="available-instances">
                        <div className="hourly-available-instances" >
                          <span>{`AVAILABLE INSTANCES`}</span>
                          <span>
                            <input
                              type="checkbox"
                              checked={
                                hoursMasterSelect.length ===
                                raffle.extras.seriesRaffle.hourlyPattern.length
                              }
                              disabled={
                                (!seriesEdit &&
                                  type === "EDIT" &&
                                  raffle.extras.seriesRaffle.isMultipleRaffles
                                ) ||
                                (type === "EDIT" &&
                                  choosenRaffle.extras.seriesRaffle.hourlyPattern.length ===
                                  hoursMasterSelect.length
                                ) ||
                                disablingTabs || raffle.type !== "Series"
                              }
                              ref={(input) => {
                                if (input) {
                                  input.indeterminate =
                                    hoursMasterSelect.length >
                                    raffle.extras.seriesRaffle.hourlyPattern.length &&
                                    raffle.extras.seriesRaffle.hourlyPattern.length !== 0;
                                }
                              }}
                              onChange={(_e) => {
                                let masterHours = hoursMasterSelect.map(hourObj => new Date(hourObj.date));
                                let toAddMasterHours = masterHours.filter(
                                  (x) => {
                                    const hourTime = new Date(x).getTime();
                                    const existingHourTimes = raffle.extras.seriesRaffle.hourlyPattern.map(x => new Date(x).getTime());
                                    return (
                                      existingHourTimes.indexOf(hourTime) === -1
                                    )
                                  }
                                ).filter(y => !(new Date(y) < new Date(new Date().getTime() + 60 * 60000))) //to disable past hours in edit

                                let toRemoveHoursChoosed = (type === "EDIT") ? choosenRaffle.extras.seriesRaffle.hourlyPattern.map(hour => new Date(hour).toISOString()) : [];

                                if (_e.target.checked) {
                                  setRaffle((rff) => ({
                                    ...rff,
                                    extras: {
                                      ...rff.extras,
                                      seriesRaffle: {
                                        ...rff.extras.seriesRaffle,
                                        hourlyPattern: [
                                          ...rff.extras.seriesRaffle
                                            .hourlyPattern,
                                          ...toAddMasterHours,
                                        ],
                                      },
                                    },
                                  }));
                                  masterSelectHours(toAddMasterHours);
                                  // setSelectedHours(toAddSelectedHours);
                                } else {
                                  setRaffle((rff) => ({
                                    ...rff,
                                    extras: {
                                      ...rff.extras,
                                      seriesRaffle: {
                                        ...rff.extras.seriesRaffle,
                                        hourlyPattern: type === "EDIT" ? toRemoveHoursChoosed : [],
                                      },
                                    },
                                  }));
                                  masterSelectHours([]);
                                  if (type === "EDIT") {
                                    masterSelectHours(toRemoveHoursChoosed);
                                  }
                                  // setSelectedHours(toRemoveSelectedHours);
                                }
                              }}
                            />
                            {" Select All"}
                          </span>
                          <span>({availableInstances})</span>
                        </div>
                        <div className={"occurrences"}>
                          {Object.keys(remainingHours).map((dayObj, index1) => {
                            let allHours = remainingHours[dayObj].hours.map(
                              (obj) => obj.date
                            );
                            let toAddHours = allHours.filter(
                              (x) => {
                                const hourTime = new Date(x).getTime();
                                const existingHourTimes = raffle.extras.seriesRaffle.hourlyPattern.map(x => new Date(x).getTime());
                                return (
                                  existingHourTimes.indexOf(hourTime) === -1
                                )
                              }
                            ).filter(y => !(new Date(y) < new Date(new Date().getTime() + 60 * 60000))) //to disable past hours in edit
                            let toRemoveHours = raffle.extras.seriesRaffle.hourlyPattern.filter(
                              (x) =>
                                !(
                                  getDateStrWithTZ(new Date(x)).split(", ")[0] === dayObj
                                )
                            );

                            //to sustain already selected instances in edit
                            let toRemoveHoursChoosed = (type === "EDIT") ? choosenRaffle.extras.seriesRaffle.hourlyPattern.map(hour => new Date(hour).toISOString()) : [];
                            let toRemoveHoursFromSelected = toRemoveHoursChoosed.filter(
                              (x) =>
                              (
                                getDateStrWithTZ(new Date(x)).split(", ")[0] === dayObj
                              )
                            );
                            let allRaffles = (type === "EDIT") ? raffle.extras.seriesRaffle.hourlyPattern.map(hour => new Date(hour)) : [];
                            let otherDayObjRaffles = allRaffles?.filter((x) =>
                              !(
                                getDateStrWithTZ(new Date(x)).split(", ")[0] === dayObj
                              ));
                            let newHoursRem = [...otherDayObjRaffles, ...toRemoveHoursFromSelected];
                            return (
                              <div key={index1} className={"select-all"}>
                                <span>
                                  <span className="date-header">
                                    {`Day ${index1 + 1}: ${dayObj}`}
                                  </span>
                                  <span>
                                    <input
                                      type="checkbox"
                                      checked={
                                        raffle.extras.seriesRaffle.hourlyPattern.filter(
                                          (x) =>
                                            getDateStrWithTZ(new Date(x)).split(", ")[0] === dayObj
                                        ).length ===
                                        remainingHours[dayObj].hours.length
                                      }
                                      disabled={
                                        (!seriesEdit && type === "EDIT" && raffle.extras.seriesRaffle.isMultipleRaffles) ||
                                        (type === "EDIT" &&
                                          choosenRaffle.extras.seriesRaffle.hourlyPattern.filter(
                                            (x) =>
                                              getDateStrWithTZ(new Date(x)).split(", ")[0] === dayObj
                                          ).length ===
                                          remainingHours[dayObj].hours.length
                                        ) ||
                                        disablingTabs || raffle.type !== "Series"
                                      }
                                      ref={(input) => {
                                        if (input) {
                                          input.indeterminate =
                                            raffle.extras.seriesRaffle.hourlyPattern.filter(
                                              (x) =>
                                                getDateStrWithTZ(new Date(x)).split(", ")[0] === dayObj
                                            ).length <
                                            remainingHours[dayObj].hours
                                              .length &&
                                            raffle.extras.seriesRaffle.hourlyPattern.filter(
                                              (x) =>
                                                getDateStrWithTZ(new Date(x)).split(", ")[0] === dayObj
                                            ).length !== 0;
                                        }
                                      }}
                                      onChange={(e) => {
                                        remainingHours[dayObj].checked =
                                          e.target.checked;
                                        setRemainingHours(remainingHours);
                                        setRaffle((rff) => ({
                                          ...rff,
                                          extras: {
                                            ...rff.extras,
                                            seriesRaffle: {
                                              ...rff.extras.seriesRaffle,
                                              hourlyPattern: remainingHours[
                                                dayObj
                                              ].checked
                                                ? [
                                                  ...rff.extras.seriesRaffle
                                                    .hourlyPattern,
                                                  ...toAddHours,
                                                ]
                                                :
                                                (type === "EDIT" ?
                                                  newHoursRem
                                                  : toRemoveHours)
                                            },
                                          },
                                        }));
                                        if (remainingHours[dayObj].checked) {
                                          masterSelectHours(toAddHours);
                                        } else {
                                          selectedHours[dayObj] = [];
                                          setSelectedHours(selectedHours);
                                          if (type === "EDIT") {
                                            masterSelectHours(toRemoveHoursFromSelected);
                                          }
                                        }
                                      }}
                                    />
                                    {" Select All"}
                                  </span>
                                  <span>
                                    {`(${remainingHours[dayObj].hours.length})`}
                                  </span>
                                </span>
                                {/* <br /> */}
                                <div className={"all-checkbox"}>
                                  {remainingHours[dayObj].hours.map(
                                    (hourObj, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className={"each-checkbox"}
                                        >
                                          <input
                                            disabled={
                                              (type === "EDIT" && !seriesEdit && raffle.extras.seriesRaffle.isMultipleRaffles
                                              ) ||
                                              (type === "EDIT" && new Date(hourObj.date) < new Date(new Date().getTime() + 60 * 60000))
                                              || disablingTabs || raffle.type !== "Series"
                                            }
                                            checked={
                                              raffle.extras.seriesRaffle.hourlyPattern.findIndex(
                                                (x) =>
                                                  new Date(x).getTime() ===
                                                  new Date(
                                                    hourObj.date
                                                  ).getTime()
                                              ) >= 0
                                            }
                                            type="checkbox"
                                            onChange={(e) => {
                                              if (
                                                raffle.extras.seriesRaffle.hourlyPattern.findIndex(
                                                  (x) =>
                                                    new Date(x).getTime() ===
                                                    new Date(
                                                      hourObj.date
                                                    ).getTime()
                                                ) >= 0 &&
                                                !e.target.checked
                                              ) {
                                                setRaffle((rff) => ({
                                                  ...rff,
                                                  extras: {
                                                    ...rff.extras,
                                                    seriesRaffle: {
                                                      ...rff.extras
                                                        .seriesRaffle,
                                                      hourlyPattern:
                                                        rff.extras.seriesRaffle.hourlyPattern.filter(
                                                          (x) =>
                                                            new Date(x).getTime() != new Date(hourObj.date).getTime()
                                                        ),
                                                    },
                                                  },
                                                }));
                                                selectedHours[
                                                  getDateStrWithTZ(new Date(hourObj.date)).split(", ")[0]
                                                ] = selectedHours[
                                                  getDateStrWithTZ(new Date(hourObj.date)).split(", ")[0]
                                                ].filter(
                                                  (x) => new Date(x).getTime() != new Date(hourObj.date).getTime()
                                                );
                                                console.log("hourObj.date::", hourObj.date)
                                                console.log("selectedHours::", selectedHours)
                                                console.log(selectedHours[
                                                  getDateStrWithTZ(new Date(hourObj.date)).split(", ")[0]
                                                ])
                                                setSelectedHours(selectedHours);
                                              } else {
                                                setRaffle((rff) => ({
                                                  ...rff,
                                                  extras: {
                                                    ...rff.extras,
                                                    seriesRaffle: {
                                                      ...rff.extras
                                                        .seriesRaffle,
                                                      hourlyPattern: [
                                                        ...rff.extras
                                                          .seriesRaffle
                                                          .hourlyPattern,
                                                        hourObj.date,
                                                      ],
                                                    },
                                                  },
                                                }));
                                                selectedHours[
                                                  getDateStrWithTZ(new Date(hourObj.date)).split(", ")[0]
                                                ].push(hourObj.date);
                                                setSelectedHours(selectedHours);
                                              }
                                              // raffle.extras.seriesRaffle.hourlyPattern.sort((a, b) => new Date(a) - new Date(b))
                                            }}
                                          />
                                          {/* <span>{index + 1}</span> */}
                                          {raffle.extras.seriesRaffle
                                            .isMultipleRaffles ? (
                                            <label>
                                              {`
                                                    Open @ ${getTimeStrWithTZ(new Date(
                                                new Date(hourObj.date).getTime() - 60 * 60000))},
                                                    Close @ ${getTimeStrWithTZ(new Date(
                                                  new Date(hourObj.date).getTime() - 5 * 60000))},
                                                    Draw @ ${getTimeStrWithTZ(new Date(hourObj.date))}
                                                  `}
                                            </label>
                                          ) : (
                                            <label>
                                              Draw @{" "}
                                              {getTimeStrWithTZ(new Date(hourObj.date))}
                                            </label>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="selected-instances">
                        <label className="selected-instances-title">
                          {`SELECTED INSTANCES (${raffle.extras.seriesRaffle.hourlyPattern.length})`}
                        </label>
                        <div className="instances-content">
                          {Object.keys(selectedHours).map((dayObj, index2) => {
                            selectedHours[dayObj].sort(
                              (a, b) => new Date(a) - new Date(b)
                            );
                            return (
                              <div key={index2} className={"selected-hours"}>
                                <div className="selected-head">
                                  {`Day ${index2 + 1}: ${dayObj} (${selectedHours[dayObj].length
                                    })`}
                                </div>
                                {/* <br /> */}
                                {selectedHours[dayObj].length === 0 ? (
                                  <label>No instances selected</label>
                                ) : (
                                  <div className="selected-content">
                                    {selectedHours[dayObj].map(
                                      (hourObj, index) => {
                                        return (
                                          <div key={index}>
                                            {raffle.extras.seriesRaffle
                                              .isMultipleRaffles ?
                                              (
                                                <label>
                                                  {`
                                                        Open @ ${getTimeStrWithTZ(new Date(
                                                    new Date(hourObj).getTime() - 60 * 60000))},
                                                        Close @ ${getTimeStrWithTZ(new Date(
                                                      new Date(hourObj).getTime() - 5 * 60000))},
                                                        Draw @ ${getTimeStrWithTZ(new Date(hourObj))}
                                                      `}
                                                </label>
                                              ) : (
                                                <label>
                                                  Draw @{" "}
                                                  {getTimeStrWithTZ(new Date(hourObj))}
                                                </label>
                                              )
                                            }
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                          {/* {raffle.extras.seriesRaffle.hourlyPattern.map(
                                (hourObj, index) => (
                                  <label>
                                    {`#${index < 9 ? "0" : ""}${index + 1} 
                                    Open @ ${new Date(
                                      new Date(hourObj).getTime() - 60 * 60000).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", })},
                                      Close @ ${new Date(new Date(hourObj).getTime() - 5 * 60000).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", })},
                                      Draw @ ${new Date(hourObj).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", })}`}
                                  </label>
                                )
                              )} */}
                        </div>
                      </div>
                    </div>
                  )}

                {raffle.extras.seriesRaffle.recurrencePattern === "Daily" && (
                  //DAILY
                  <div className="series-sub-raffle">
                    <div className="available-instances">
                      <div className="daily-available-instances"
                        style={{
                          fontWeight: 700,
                          textAlign: "left",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <span>{`AVAILABLE INSTANCES`}</span>
                        <span>
                          <input
                            type="checkbox"
                            checked={
                              datesMasterSelect.length ===
                              raffle.extras.seriesRaffle.dailyPattern.days
                                .length
                            }
                            disabled={
                              (!seriesEdit &&
                                type === "EDIT" &&
                                raffle.extras.seriesRaffle.isMultipleRaffles
                              ) ||
                              // (type === "EDIT" &&
                              //   !raffle.extras.seriesRaffle.isMultipleRaffles &&
                              //   new Date(raffle.participationOpens) < new Date()
                              // ) ||
                              (type === "EDIT" &&
                                choosenRaffle.extras.seriesRaffle.dailyPattern.days.length ===
                                datesMasterSelect.length
                              )
                            }
                            ref={(input) => {
                              if (input) {
                                input.indeterminate =
                                  datesMasterSelect.length >
                                  raffle.extras.seriesRaffle.dailyPattern
                                    .days.length &&
                                  raffle.extras.seriesRaffle.dailyPattern.days
                                    .length !== 0;
                              }
                            }}
                            onChange={(_e) => {
                              console.log("datesMasterSelect on change master select all::::::::::", datesMasterSelect)
                              let toAddMasterDays = datesMasterSelect.filter(
                                (x) => {
                                  const dayTime = new Date(x).toLocaleDateString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",
                                  });
                                  const existingDayTimes = raffle.extras.seriesRaffle.dailyPattern.days.map(x => new Date(x).toLocaleDateString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",
                                  }));
                                  return (
                                    existingDayTimes.indexOf(dayTime) === -1
                                  )
                                }
                              )
                              // .filter(y => !(new Date(y) < new Date(new Date().getTime() + 60 * 60000))) //to disable past hours in edit

                              let toRemoveDaysChoosed = (type === "EDIT") ? choosenRaffle.extras.seriesRaffle.dailyPattern.days.map(day => new Date(day).toISOString()) : [];

                              if (_e.target.checked) {
                                setRaffle((rff) => ({
                                  ...rff,
                                  extras: {
                                    ...rff.extras,
                                    seriesRaffle: {
                                      ...rff.extras.seriesRaffle,
                                      dailyPattern: {
                                        ...rff.extras.seriesRaffle
                                          .dailyPattern,
                                        days: [
                                          ...rff.extras.seriesRaffle
                                            .dailyPattern.days,
                                          ...toAddMasterDays,
                                        ],
                                      },
                                    },
                                  },
                                }));
                              } else {
                                setRaffle((rff) => ({
                                  ...rff,
                                  extras: {
                                    ...rff.extras,
                                    seriesRaffle: {
                                      ...rff.extras.seriesRaffle,
                                      dailyPattern: {
                                        ...rff.extras.seriesRaffle
                                          .dailyPattern,
                                        days: type === "EDIT" ? toRemoveDaysChoosed : [],
                                      },
                                    },
                                  },
                                }));
                              }
                            }}
                          />
                          {" Select All"}
                        </span>
                        <span>({availableInstances})</span>
                      </div>
                      <div className={"occurrences"}>
                        {Object.keys(remainingDays).map((weekObj, index) => {
                          const toAddDays = remainingDays[weekObj].filter(
                            (x) => {
                              const date = new Date(x);
                              const dateString = date.toLocaleDateString();
                              const dailyPatternDateStrings =
                                raffle.extras.seriesRaffle.dailyPattern.days.map(
                                  (day) => {
                                    return new Date(day).toLocaleDateString();
                                  }
                                );
                              return (
                                dailyPatternDateStrings.indexOf(
                                  dateString
                                ) === -1
                              );
                            }
                          );

                          const toRemoveDays =
                            raffle.extras.seriesRaffle.dailyPattern.days.filter(
                              (x) => {
                                return !remainingDays[weekObj].some(
                                  (y) =>
                                    new Date(y).toDateString() ===
                                    new Date(x).toDateString()
                                );
                              }
                            );
                          let toRemoveDaysChoosed = (type === "EDIT") ? choosenRaffle.extras.seriesRaffle.dailyPattern.days.map(day => new Date(day).toISOString()) : [];
                          let toRemoveDaysFromSelected = toRemoveDaysChoosed.filter(
                            (x) =>
                            (
                              new Date(
                                new Date(x).getFullYear(),
                                new Date(x).getMonth(),
                                new Date(x).getDate() -
                                new Date(x).getDay()
                              ).toLocaleDateString(
                                "en-US",
                                {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                                }
                              ) === weekObj
                            )
                          );
                          let allRaffles = (type === "EDIT") ? raffle.extras.seriesRaffle.dailyPattern.days.map(day => new Date(day)) : [];
                          let otherWeekObjRaffles = allRaffles?.filter((x) =>
                            !(
                              new Date(
                                new Date(x).getFullYear(),
                                new Date(x).getMonth(),
                                new Date(x).getDate() -
                                new Date(x).getDay()
                              ).toLocaleDateString(
                                "en-US",
                                {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                                }
                              ) === weekObj
                            ));
                          let newDaysRem = [...otherWeekObjRaffles, ...toRemoveDaysFromSelected];

                          //updating draw times to use in single raffle
                          toAddDays.forEach(
                            (a) =>
                              new Date(
                                new Date(a).setHours(
                                  new Date(raffle.drawAt).getHours(),
                                  new Date(raffle.drawAt).getMinutes(),
                                  0,
                                  0
                                )
                              )
                          );
                          return (
                            <div className="select-all" key={index}>
                              <span>
                                <span>
                                  {`Week ${index + 1}: ${weekObj.split(',')[0]} - ${getDatePartTZ(new Date(new Date(weekObj).getTime() + 6 * 24 * 60 * 60000)).split(',')[0]}`}
                                </span>
                                <span>
                                  <input
                                    type="checkbox"
                                    disabled={
                                      (!seriesEdit && type === "EDIT" && raffle.extras.seriesRaffle.isMultipleRaffles) ||
                                      (type === "EDIT" &&
                                        remainingDays[weekObj].every(
                                          (x) => {
                                            const date = new Date(x);
                                            const dateString =
                                              date.toLocaleDateString();
                                            const dailyPatternDateString =
                                              choosenRaffle.extras.seriesRaffle.dailyPattern.days.map(
                                                (day) =>
                                                  new Date(
                                                    day
                                                  ).toLocaleDateString()
                                              );
                                            return dailyPatternDateString.includes(
                                              dateString
                                            );
                                          }
                                        )
                                      )
                                    }
                                    checked={
                                      raffle.extras.seriesRaffle.dailyPattern.days.filter((x) => {
                                        const date = new Date(getDatePartTZ(new Date(x)).split(',')[0]); // get only date part of selected draw instance
                                        const weekStart = new Date( //Sun Aug 20 2023 00:30:00 GMT+0530
                                          date.getFullYear(), // 2023
                                          date.getMonth(), // 7
                                          date.getDate() - date.getDay(), // 20 - 0
                                          date.getHours(), // 00
                                          date.getMinutes(), // 30
                                        );
                                        const weekKey =
                                          `${getLocalDatePart(weekStart)},${getDatePartTZ(weekStart).split(",")[1]}`;
                                        return weekKey === weekObj;
                                      }).length === remainingDays[weekObj].length
                                    }
                                    ref={(input) => {
                                      if (input) {
                                        input.indeterminate =
                                          raffle.extras.seriesRaffle.dailyPattern.days.filter((x) => {
                                            const date = new Date(getDatePartTZ(new Date(x)).split(',')[0]); // get only date part of selected draw instance
                                            const weekStart = new Date(
                                              date.getFullYear(), // 2023
                                              date.getMonth(), // 7
                                              date.getDate() - date.getDay(), // 20 - 0
                                              date.getHours(), // 00
                                              date.getMinutes(), // 30
                                            );
                                            const weekKey =
                                              `${getLocalDatePart(weekStart)},${getDatePartTZ(weekStart).split(",")[1]}`;
                                            return weekKey === weekObj;
                                          }).length <
                                          remainingDays[weekObj].length &&
                                          raffle.extras.seriesRaffle.dailyPattern.days.filter((x) => {
                                            const date = new Date(getDatePartTZ(new Date(x)).split(',')[0]); // get only date part of selected draw instance
                                            const weekStart = new Date(
                                              date.getFullYear(), // 2023
                                              date.getMonth(), // 7
                                              date.getDate() - date.getDay(), // 20 - 0
                                              date.getHours(), // 00
                                              date.getMinutes(), // 30
                                            );
                                            const weekKey =
                                              `${getLocalDatePart(weekStart)},${getDatePartTZ(weekStart).split(",")[1]}`;
                                            return weekKey === weekObj;
                                          }).length !== 0;
                                      }
                                    }}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setRaffle((rff) => ({
                                          ...rff,
                                          extras: {
                                            ...rff.extras,
                                            seriesRaffle: {
                                              ...rff.extras.seriesRaffle,
                                              dailyPattern: {
                                                ...rff.extras.seriesRaffle
                                                  .dailyPattern,
                                                days: [
                                                  ...rff.extras.seriesRaffle
                                                    .dailyPattern.days,
                                                  ...toAddDays,
                                                ],
                                              },
                                            },
                                          },
                                        }));
                                      } else {
                                        console.log(toRemoveDays);
                                        setRaffle((rff) => ({
                                          ...rff,
                                          extras: {
                                            ...rff.extras,
                                            seriesRaffle: {
                                              ...rff.extras.seriesRaffle,
                                              dailyPattern: {
                                                ...rff.extras.seriesRaffle
                                                  .dailyPattern,
                                                days: type === "EDIT" ?
                                                  newDaysRem :
                                                  toRemoveDays,
                                              },
                                            },
                                          },
                                        }));
                                      }
                                    }}
                                  />
                                  {" Select all"}
                                </span>
                                <span>
                                  {`(${remainingDays[weekObj].length})`}
                                </span>
                              </span>
                              <div className={"all-checkbox"}>
                                {remainingDays[weekObj].map((day, index) => {
                                  return (
                                    <div
                                      className="each-checkbox"
                                      key={index}
                                    >
                                      {/* <lable> */}
                                      <input
                                        type="checkbox"
                                        disabled={
                                          (type === "EDIT" && !seriesEdit && raffle.extras.seriesRaffle.isMultipleRaffles
                                          ) ||
                                          (type === "EDIT" && !raffle.extras.seriesRaffle.isMultipleRaffles &&
                                            new Date(day).getTime() - new Date(raffle.participationOpens).getTime() < 24 * 60 * 60 * 1000
                                          ) ||
                                          (type === "EDIT" &&
                                            new Date(day).setHours(
                                              new Date(raffle.participationOpens).getHours(),
                                              new Date(raffle.participationOpens).getMinutes(), 0, 0,
                                            ) < new Date())
                                        }
                                        checked={
                                          raffle.extras.seriesRaffle.dailyPattern.days.findIndex(
                                            (x) =>
                                              new Date(
                                                x
                                              ).toLocaleDateString() ===
                                              new Date(
                                                day
                                              ).toLocaleDateString()
                                          ) >= 0
                                        }
                                        onChange={(e) => {
                                          if (
                                            raffle.extras.seriesRaffle.dailyPattern.days.findIndex(
                                              (x) => new Date(x).toLocaleDateString("en-US", {
                                                month: "2-digit",
                                                day: "2-digit",
                                                year: "numeric",
                                              }) === new Date(day).toLocaleDateString("en-US", {
                                                month: "2-digit",
                                                day: "2-digit",
                                                year: "numeric",
                                              })
                                            ) >= 0 &&
                                            !e.target.checked
                                          ) {
                                            setRaffle((rff) => ({
                                              ...rff,
                                              extras: {
                                                ...rff.extras,
                                                seriesRaffle: {
                                                  ...rff.extras
                                                    .seriesRaffle,
                                                  dailyPattern: {
                                                    ...rff.extras
                                                      .seriesRaffle
                                                      .dailyPattern,
                                                    days: rff.extras.seriesRaffle.dailyPattern.days.filter(
                                                      (x) => new Date(x).toLocaleDateString("en-US", {
                                                        month: "2-digit",
                                                        day: "2-digit",
                                                        year: "numeric",
                                                      }) !== new Date(day).toLocaleDateString("en-US", {
                                                        month: "2-digit",
                                                        day: "2-digit",
                                                        year: "numeric",
                                                      })
                                                    ),
                                                  },
                                                },
                                              },
                                            }));
                                          } else {
                                            setRaffle((rff) => ({
                                              ...rff,
                                              extras: {
                                                ...rff.extras,
                                                seriesRaffle: {
                                                  ...rff.extras
                                                    .seriesRaffle,
                                                  dailyPattern: {
                                                    ...rff.extras
                                                      .seriesRaffle
                                                      .dailyPattern,
                                                    days: [
                                                      ...rff.extras
                                                        .seriesRaffle
                                                        .dailyPattern.days,
                                                      day,
                                                    ],
                                                  },
                                                },
                                              },
                                            }));
                                          }
                                        }}
                                      />
                                      {/* &nbsp; */}
                                      <lable>
                                        {raffle.extras.seriesRaffle
                                          .isMultipleRaffles
                                          ? `${getDatePartTZ(new Date(day)).split(",")[0]}
                                              [Open @ ${getTimeStrWithTZ(new Date(raffle.participationOpens))},
                                              Close @ ${getTimeStrWithTZ(new Date(raffle.participationCloses))},
                                              Draw @ ${getTimeStrWithTZ(new Date(raffle.drawAt))}]`
                                          : `${getDatePartTZ(new Date(day)).split(",")[0]} [Draw @ ${getTimeStrWithTZ(new Date(raffle.drawAt))}]`}
                                      </lable>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="selected-instances">
                      <label className="selected-instances-title">
                        {`SELECTED INSTANCES (${raffle.extras.seriesRaffle.dailyPattern.days.length})`}
                      </label>
                      <div className="instances-content">
                        {Object.keys(selectedDays).map((weekObj, index) => {
                          raffle.extras.seriesRaffle.dailyPattern.days.sort(
                            (a, b) => new Date(a) - new Date(b)
                          );
                          return (
                            <div key={index} className={"selected-hours"}>
                              <div className="selected-head">
                                {`Week ${index + 1}:
                                      ${weekObj.split(',')[0]} - ${getDatePartTZ(new Date(new Date(weekObj).getTime() + 6 * 24 * 60 * 60000)).split(',')[0]}
                                        (${selectedDays[weekObj].length})`}
                              </div>
                              {/* <br /> */}
                              {selectedDays[weekObj].length === 0 ? (
                                <label>No instances selected</label>
                              ) : (
                                <div className="selected-content">
                                  {selectedDays[weekObj].map(
                                    (dayObj, index) => {
                                      const dt = new Date(dayObj);
                                      return (
                                        <div key={index}>
                                          {raffle.extras.seriesRaffle
                                            .isMultipleRaffles ? (
                                            <label>
                                              {`${getDatePartTZ(dt).split(",")[0]}
                                                  [Open @ ${getTimeStrWithTZ(new Date(raffle.participationOpens))},
                                                  Close @ ${getTimeStrWithTZ(new Date(raffle.participationCloses))},
                                                  Draw @ ${getTimeStrWithTZ(new Date(raffle.drawAt))}]`}
                                            </label>
                                          ) : (
                                            <label>
                                              {`${getDatePartTZ(dt).split(",")[0]} [Draw @ ${getTimeStrWithTZ(new Date(raffle.drawAt))}]`}
                                            </label>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                {raffle.extras.seriesRaffle.recurrencePattern ===
                  "Weekly" && (
                    <WeeklyRaffle
                      raffle={raffle}
                      daysInWeek={daysList}
                      setRaffleFields={setRaffleFields}
                      freqStartinTz={freqStartinTz}
                      freqEndinTz={freqEndinTz}
                      setMinEndPeriodDate={setMinEndPeriodDate}
                      setSelectEndbyPeriod={setSelectEndbyPeriod}
                      getDateStrWithTZ={getDateStrWithTZ}
                      getMilliSecFromTime={getMilliSecFromTime}
                      getFormattedDateTime={getFormattedDateTime}
                      options={options}
                      seriesEdit={seriesEdit}
                      type={type}
                      choosenRaffle={choosenRaffle}
                      setRaffle={setRaffle}
                      availableInstances={availableInstances}
                      remainingDays={remainingDays}
                      getDatePartTZ={getDatePartTZ}
                      getTimeStrWithTZ={getTimeStrWithTZ}
                      selectedDays={selectedDays}
                    />
                  )}
                {raffle.extras.seriesRaffle.recurrencePattern ===
                  "Monthly" && (
                    <MonthlyRaffle
                      raffle={raffle}
                      choosenRaffle={choosenRaffle}
                      setRaffle={setRaffle}
                      setRaffleFields={setRaffleFields}
                      seriesEdit={seriesEdit}
                      type={type}
                      freqStartinTz={freqStartinTz}
                      freqEndinTz={freqEndinTz}
                      getDatePartTZ={getDatePartTZ}
                      getTimeStrWithTZ={getTimeStrWithTZ}
                      getFormattedDateTime={getFormattedDateTime}
                    />
                  )}
                {raffle.extras.seriesRaffle.recurrencePattern ===
                  "Yearly" && (
                    <YearlyRaffle
                      raffle={raffle}
                      choosenRaffle={choosenRaffle}
                      setRaffle={setRaffle}
                      setRaffleFields={setRaffleFields}
                      seriesEdit={seriesEdit}
                      type={type}
                      freqStartinTz={freqStartinTz}
                      freqEndinTz={freqEndinTz}
                      getDatePartTZ={getDatePartTZ}
                      getTimeStrWithTZ={getTimeStrWithTZ}
                      getFormattedDateTime={getFormattedDateTime}
                    />
                  )}
              </div>
            )}
        </div>
        {raffle.type === "Regular" && (
          <div>
            <div className="regular-date-container">
              <div className="time-zone">
                <label>TIME ZONE</label>
                <select
                  disabled={
                    type === "EDIT" && (
                      raffle.status === "OPEN" ||
                      raffle.status === "CLOSE" ||
                      raffle.status === "CANCEL" ||
                      raffle.status === "COMPLETE" ||
                      raffle.status === "DRAWN"
                    ) || disablingTabs || raffle.type !== "Regular"
                  }
                  value={raffle.extras.timeZone}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    onChangeTz(value);
                  }}
                >
                  {timeZonesList.map((tzone, index) => {
                    return (
                      <option key={index} value={tzone}>
                        {tzone}
                      </option>
                    );
                  })}
                </select>
              </div>
              {raffle.extras.seriesRaffle.recurrencePattern ==="One Time" && 
              <>
                <div className="open">
                  <label>OPEN</label>
                  <DatePicker
                    disabled={
                      // seriesEdit &&
                      type === "EDIT" &&
                      !raffle.extras.draft &&
                      (
                        raffle.status === "OPEN" ||
                        raffle.status === "CLOSE" ||
                        raffle.status === "CANCEL" ||
                        raffle.status === "COMPLETE" ||
                        raffle.status === "DRAWN"
                      ) ||
                      disablingTabs
                    }
                    dateFormat={
                      raffle.extras.seriesRaffle.recurrencePattern === "Daily"
                        ? "hh:mm aa"
                        : "MM/dd/yyyy hh:mm aa"
                    }
                    selected={new Date(getFormattedDateTime(new Date(raffle.participationOpens)))}
                    value={getFormattedDateTime(new Date(raffle.participationOpens))}
                    minDate={
                      raffle.extras.seriesRaffle.recurrencePattern ===
                        "Weekly" ||
                        raffle.extras.seriesRaffle.recurrencePattern === "Monthly"
                        ? raffle.extras.seriesRaffle.frequencyRange.startDate
                        : new Date()
                    }
                    maxDate={
                      raffle.extras.seriesRaffle.recurrencePattern ===
                        "Weekly" ||
                        raffle.extras.seriesRaffle.recurrencePattern ===
                        "Monthly" ||
                        raffle.extras.seriesRaffle.recurrencePattern === "Yearly"
                        ? new Date(new Date(raffle.drawAt).getTime() - day)
                        : null
                    }
                    fixedHeight
                    // popperPlacement={"top"}
                    showTimeInput={
                      raffle.extras.seriesRaffle.recurrencePattern !== "Hourly"
                        ? true
                        : false
                    }
                    showTimeSelectOnly={
                      raffle.extras.seriesRaffle.recurrencePattern === "Daily"
                        ? true
                        : false
                    }
                    timeIntervals={15}
                    timeCaption="Time"
                    timeFormat="hh:mm"
                    filterTime={
                      selectionStart.getTime() === today.getTime()
                        ? filterPassedTime
                        : null
                    }
                    onChange={(event) => {
                      // setSelectedDateTime(new Date(event.target.value))
                      const dateTime = event;
                      dateTime.setSeconds(0, 0);
                      options.timeZone = raffle.extras.timeZone;
                      const now = new Intl.DateTimeFormat('en-US', options).format(new Date());
                      const selectedDttm = new Date(dateTime.toLocaleString('en-US') + ` ${now.split(" ")[3]}`);
                      // alertPastTime(dateTime);
                      if (selectedDttm <= new Date(now)) {
                        alert.removeAll();
                        alert.show("Please select a valid time in the future.");
                      } else {
                        if (
                          raffle.extras.seriesRaffle.recurrencePattern ===
                          "Daily"
                        ) {
                          const hours = new Date(selectedDttm).getHours();
                          const mins = new Date(selectedDttm).getMinutes();
                          let startPeriod = new Date(
                            raffle.extras.seriesRaffle.frequencyRange.startDate
                          );
                          startPeriod.setHours(hours, mins, 0, 0);

                          setRaffleFields(startPeriod, "participationOpens");
                        } else if (
                          raffle.extras.seriesRaffle.recurrencePattern ===
                          "Weekly"
                        ) {
                          setRaffleFields(selectedDttm, "participationOpens");
                          setSelectedDateTime(new Date(selectedDttm));
                        } else if (
                          raffle.extras.seriesRaffle.recurrencePattern ===
                          "Monthly"
                        ) {
                          setRaffleFields(selectedDttm, "participationOpens");
                        } else if (
                          raffle.extras.seriesRaffle.recurrencePattern ===
                          "Yearly"
                        ) {
                          setRaffleFields(selectedDttm, "participationOpens");
                        } else if (
                          raffle.extras.seriesRaffle.recurrencePattern ===
                          "One Time"
                        ) {
                          setRaffleFields(selectedDttm, "participationOpens");
                          setSelectedDateTime(new Date(selectedDttm));
                        } else if (
                          raffle.extras.seriesRaffle.recurrencePattern ===
                          "Hourly"
                        ) {
                          const tempDate = new Date(selectedDttm);
                          const today = new Date();
                          if (
                            tempDate.toLocaleDateString() ===
                            today.toLocaleDateString()
                          ) {
                            const tempDate1 = new Date(selectedDttm);
                            tempDate1.setHours(today.getHours());
                            tempDate1.setMinutes(today.getMinutes());
                            tempDate1.setSeconds(today.getSeconds());
                            setRaffleFields(tempDate1, "participationOpens");
                          } else {
                            const tempDate1 = new Date(selectedDttm);
                            tempDate1.setHours(23);
                            tempDate1.setMinutes(59);
                            tempDate1.setSeconds(59);
                            setRaffleFields(tempDate1, "participationOpens");
                          }
                        }
                      }
                    }}
                  />
                </div>
                <div className="close">
                  <label>CLOSE</label>
                  <DatePicker
                    disabled={
                      // seriesEdit &&
                      type === "EDIT" &&
                      !raffle.extras.draft &&
                      (
                        raffle.status === "CLOSE" ||
                        raffle.status === "CANCEL" ||
                        raffle.status === "COMPLETE" ||
                        raffle.status === "DRAWN"
                      ) ||
                      disablingTabs
                    }
                    selected={new Date(getFormattedDateTime(new Date(raffle.participationCloses)))}
                    value={getFormattedDateTime(new Date(raffle.participationCloses))}
                    dateFormat={
                      raffle.extras.seriesRaffle.recurrencePattern === "Daily"
                        ? "hh:mm aa"
                        : "MM/dd/yyyy hh:mm aa"
                    }
                    minDate={new Date(raffle.participationOpens)}
                    maxDate={
                      raffle.extras.seriesRaffle.recurrencePattern ===
                        "Weekly" ||
                        raffle.extras.seriesRaffle.recurrencePattern ===
                        "Monthly" ||
                        raffle.extras.seriesRaffle.recurrencePattern === "Yearly"
                        ? new Date(
                          new Date(raffle.drawAt).getTime() - 15 * 60000
                        )
                        : null
                    }
                    // popperPlacement={"top-end"}
                    fixedHeight
                    showTimeInput={
                      raffle.extras.seriesRaffle.recurrencePattern !== "Hourly"
                        ? true
                        : false
                    }
                    showTimeSelectOnly={
                      raffle.extras.seriesRaffle.recurrencePattern === "Daily"
                        ? true
                        : false
                    }
                    timeIntervals={15}
                    timeCaption="Time"
                    timeFormat="hh:mm"
                    onChange={
                      (event) => {
                        const dateTime = event;
                        dateTime.setSeconds(0, 0);
                        options.timeZone = raffle.extras.timeZone;
                        const now = new Intl.DateTimeFormat('en-US', options).format(new Date());
                        const selectedDttm = new Date(dateTime.toLocaleString('en-US') + ` ${now.split(" ")[3]}`);
                        if (selectedDttm <= new Date(now)) {
                          alert.removeAll();
                          alert.show(
                            "Please select a valid time in the future."
                          );
                        }
                        else if (
                          selectedDttm <=
                          new Date(raffle.participationOpens).getTime()
                        ) {
                          alert.removeAll();
                          alert.show(
                            "Please select a valid time greater than start time."
                          );
                        } else if (
                          selectedDttm >
                          new Date(raffle.drawAt).getTime()
                        ) {
                          alert.removeAll();
                          alert.show(
                            "Please select a valid time less than draw time."
                          );
                        } else {
                          if (
                            raffle.extras.seriesRaffle.recurrencePattern ===
                            "One Time" ||
                            raffle.extras.seriesRaffle.recurrencePattern ===
                            "Weekly"
                          ) {
                            setRaffleFields(selectedDttm, "participationCloses");
                          }
                          if (
                            raffle.extras.seriesRaffle.recurrencePattern ===
                            "Daily"
                          ) {
                            const hours = new Date(selectedDttm).getHours();
                            const mins = new Date(selectedDttm).getMinutes();
                            let startPeriod = new Date(
                              raffle.extras.seriesRaffle.frequencyRange.startDate
                            );
                            startPeriod.setHours(hours, mins, 0, 0);
                            setRaffleFields(startPeriod, "participationCloses");
                          }
                          if (
                            raffle.extras.seriesRaffle.recurrencePattern ===
                            "Monthly"
                          ) {
                            setRaffleFields(selectedDttm, "participationCloses");
                          }
                        }
                      }
                    }
                  />
                </div>
                <div className="draw">
                  <label>DRAW</label>
                  <DatePicker
                    disabled={
                      // seriesEdit &&
                      type === "EDIT" &&
                      !raffle.extras.draft &&
                      (
                        raffle.status === "CLOSE" ||
                        raffle.status === "CANCEL" ||
                        raffle.status === "COMPLETE" ||
                        raffle.status === "DRAWN"
                      ) ||
                      disablingTabs
                    }
                    dateFormat={
                      raffle.extras.seriesRaffle.recurrencePattern === "Daily"
                        ? "hh:mm aa"
                        : "MM/dd/yyyy hh:mm aa"
                    }
                    selected={new Date(getFormattedDateTime(new Date(raffle.drawAt)))}
                    value={getFormattedDateTime(new Date(raffle.drawAt))}
                    minDate={new Date(raffle.participationCloses)}
                    maxDate={maxDrawDate}
                    // popperPlacement={"top-end"}
                    fixedHeight
                    showTimeInput={
                      raffle.extras.seriesRaffle.recurrencePattern !== "Hourly"
                        ? true
                        : false
                    }
                    showTimeSelectOnly={
                      raffle.extras.seriesRaffle.recurrencePattern ===
                        "Daily" ||
                        raffle.extras.seriesRaffle.recurrencePattern === "Weekly"
                        ? true
                        : false
                    }
                    timeIntervals={15}
                    timeCaption="Time"
                    timeFormat="hh:mm"
                    onChange={(event) => {
                      const dateTime = event;
                      dateTime.setSeconds(0, 0);
                      options.timeZone = raffle.extras.timeZone;
                      const now = new Intl.DateTimeFormat('en-US', options).format(new Date());
                      const selectedDttm = new Date(dateTime.toLocaleString('en-US') + ` ${now.split(" ")[3]}`);
                      if (selectedDttm <= new Date(now)) {
                        alert.removeAll();
                        alert.show("Please select a valid time in the future.");
                      } else if (
                        selectedDttm <=
                        new Date(raffle.participationCloses).getTime()
                      ) {
                        alert.removeAll();
                        alert.show(
                          "Please select a valid time greater than close time."
                        );
                      } else {
                        if (
                          raffle.extras.seriesRaffle.recurrencePattern ===
                          "One Time" ||
                          raffle.extras.seriesRaffle.recurrencePattern ===
                          "Weekly"
                        ) {
                          setRaffleFields(selectedDttm, "drawAt");
                        }
                        if (
                          raffle.extras.seriesRaffle.recurrencePattern ===
                          "Daily"
                        ) {
                          const hours = new Date(selectedDttm).getHours();
                          const mins = new Date(selectedDttm).getMinutes();
                          let startPeriod = new Date(
                            raffle.extras.seriesRaffle.frequencyRange.startDate
                          );
                          startPeriod.setHours(hours, mins, 0, 0);
                          setRaffleFields(startPeriod, "drawAt");
                        }
                      }
                    }}
                  />
                </div>
              </>
              }
              {raffle.extras.seriesRaffle.recurrencePattern ==="On Demand" && 
              <OnDemand 
                disablingTabs={disablingTabs}
                raffle={raffle}
                choosenRaffle={choosenRaffle}
                setRaffle={setRaffle}
                setRaffleFields={setRaffleFields}
                seriesEdit={seriesEdit}
                type={type}
                selectedPrizes={selectedPrizes}
                setSelectedPrizes={setSelectedPrizes}
                applyToAllStatus={applyToAllStatus}
              />}
            </div>
            <label className="title">RAFFLE DATES</label>
          </div>
        )}
      </div>
    );
  };

  const _renderIncludeExcludeWinnersOption = () => {
    const onChange = () =>
      setRaffle((rff) => ({
        ...rff,
        extras: {
          ...rff.extras,
          seriesRaffle: {
            ...rff.extras.seriesRaffle,
            includeWinners: !rff.extras.seriesRaffle.includeWinners,
          },
        },
      }));
    return (
      <div className="include_exclude_winner_option">
        {/* <input type="radio" onChange={onChange} checked={raffle.extras.seriesRaffle.includeWinners} /><p>Include</p> */}
        {/* <input type="radio" onChange={onChange} checked={!raffle.extras.seriesRaffle.includeWinners} /><p>Exclude</p> */}
        <input
          type="checkbox"
          onChange={onChange}
          checked={raffle.extras.seriesRaffle.includeWinners}
          disabled={
            type === "EDIT" &&
            (
              raffle.status === "OPEN" ||
              raffle.status === "CLOSE" ||
              raffle.status === "CANCEL" ||
              raffle.status === "COMPLETE" ||
              raffle.status === "DRAWN"
            )
          }
        />
        <label>EXCLUDE WINNER(S) FROM FUTURE DRAW(S) OF THIS RAFFLE</label>
      </div>
    );
  };

  const _renderRaffleLandscape = () => {
    return (
      <div className="raffle_landscape">
        {/* top */}
        <div>
          {/* age group */}
          <div>
            <label>AGE GROUP</label>
            <select
              disabled={disablingTabs ||
                (type === "EDIT" && (
                  raffle.status === "OPEN" ||
                  raffle.status === "CLOSE" ||
                  raffle.status === "CANCEL" ||
                  raffle.status === "COMPLETE" ||
                  raffle.status === "DRAWN"
                ))}
              value={raffle.extras.landscape.age}
              onChange={(e) => {
                const text = e.currentTarget.value;
                setRaffle((rff) => ({
                  ...rff,
                  extras: {
                    ...rff.extras,
                    landscape: { ...rff.extras.landscape, age: text },
                  },
                }));
              }}
            >
              {ageGroups.map((age, index) => (
                <option key={index} name={age} value={age}>
                  {age}
                </option>
              ))}
            </select>
          </div>
          {/* gender group */}
          <div>
            <label>GENDER</label>
            <select
              disabled={disablingTabs ||
                (type === "EDIT" && (
                  raffle.status === "OPEN" ||
                  raffle.status === "CLOSE" ||
                  raffle.status === "CANCEL" ||
                  raffle.status === "COMPLETE" ||
                  raffle.status === "DRAWN"
                ))}
              value={raffle.extras.landscape.gender}
              onChange={(e) => {
                const text = e.currentTarget.value;
                setRaffle((rff) => ({
                  ...rff,
                  extras: {
                    ...rff.extras,
                    landscape: { ...rff.extras.landscape, gender: text },
                  },
                }));
              }}
            >
              {genders.map((gender, index) => (
                <option key={index} name={gender} value={gender}>
                  {gender}
                </option>
              ))}
            </select>
          </div>
          {/* state */}
          {/* <div>
                    <label>STATE</label>
                    <RegionDropdown country={raffle.extras.landscape.country} value={raffle.extras.landscape.state} onChange={(state) => setRaffle(rff => ({ ...rff, extras: { ...rff.extras, landscape: { ...rff.extras.landscape, state } } }))} disableWhenEmpty />
                </div> */}
          {/* relegions group */}
          {/* <div>
                    <label>RELIGION</label>
                    <select value={raffle.extras.landscape.religion} onChange={(e) => {
                        const text = e.currentTarget.value;
                        setRaffle(rff => ({ ...rff, extras: { ...rff.extras, landscape: { ...rff.extras.landscape, religion: text } } }))
                    }}>
                        {
                            religions.map((religion, index) => <option key={index} name={religion} value={religion}>
                                {religion}
                            </option>)
                        }
                    </select>
                </div> */}
        </div>
        {/* regions */}
        <div>
          <label>REGIONS</label>
          <Multiselect
            isObject={false}
            options={RegionsList} // Options to display in the dropdown
            selectedValues={raffle.extras.landscape.region} // Preselected value to persist in dropdown
            onSelect={(options) => {
              setRaffle((rff) => ({
                ...rff,
                extras: {
                  ...rff.extras,
                  landscape: { ...rff.extras.landscape, region: options },
                },
              }));
            }} // Function will trigger on select event
            onRemove={(options) => {
              setRaffle((rff) => ({
                ...rff,
                extras: {
                  ...rff.extras,
                  landscape: { ...rff.extras.landscape, region: options },
                },
              }));
            }} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
            disabled={disablingTabs ||
              (type === "EDIT" && (
                raffle.status === "OPEN" ||
                raffle.status === "CLOSE" ||
                raffle.status === "CANCEL" ||
                raffle.status === "COMPLETE" ||
                raffle.status === "DRAWN"
              ))}
          />
        </div>
        {/* country */}
        <div>
          <label>COUNTRY</label>
          <Multiselect
            options={countriesList} // Options to display in the dropdown
            selectedValues={raffle.extras.landscape.country} // Preselected value to persist in dropdown
            onSelect={(options) => {
              setRaffle((rff) => ({
                ...rff,
                extras: {
                  ...rff.extras,
                  landscape: { ...rff.extras.landscape, country: options },
                },
              }));
            }} // Function will trigger on select event
            onRemove={(options) => {
              setRaffle((rff) => ({
                ...rff,
                extras: {
                  ...rff.extras,
                  landscape: { ...rff.extras.landscape, country: options },
                },
              }));
            }} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
            disabled={disablingTabs ||
              (type === "EDIT" && (
                raffle.status === "OPEN" ||
                raffle.status === "CLOSE" ||
                raffle.status === "CANCEL" ||
                raffle.status === "COMPLETE" ||
                raffle.status === "DRAWN"
              ))}
          />
          {/* <CountryDropdown value={raffle.extras.landscape.country} onChange={(country) => setRaffle(rff => ({ ...rff, extras: { ...rff.extras, landscape: { ...rff.extras.landscape, country } } }))} /> */}
        </div>
        <div>
          <label>KEYWORDS</label>
          <textarea
            value={raffle.extras.landscape.keywords}
            onChange={(e) => {
              const text = e.currentTarget.value;
              setRaffle((rff) => ({
                ...rff,
                extras: {
                  ...rff.extras,
                  landscape: { ...rff.extras.landscape, keywords: text },
                },
              }));
            }}
            placeholder="Type all keywords separated by space"
            disabled={disablingTabs ||
              (type === "EDIT" && (
                raffle.status === "OPEN" ||
                raffle.status === "CLOSE" ||
                raffle.status === "CANCEL" ||
                raffle.status === "COMPLETE" ||
                raffle.status === "DRAWN"
              ))}
          ></textarea>
        </div>
      </div>
    );
  };

  const saveAsDraftClicked = () => {
    if (raffle.title.trim().length > 0) {
      if (type === "NEW") {
        addNewRaffle({
          ...raffle,
          status: "DRAFT",
          extras: { ...raffle.extras, draft: true },
        });
      } else if (type === "EDIT") {
        updateARaffle({
          ...raffle,
          status: "DRAFT",
          extras: { ...raffle.extras, draft: true },
        });
      }
    } else if (raffle.title.length === 0) {
      alert.removeAll();
      alert.show("Provide raffle title to save as draft");
    } else {
      alert.removeAll();
      alert.show("Raffle saved as Draft");
    }
  };

  const [note, setNote] = useState("");

  const onproceed = () => {
    dispatch(togggleLoad());
    request("POST_AUTH", activateDeactivateRaffle, { ...raffle, note })
      .then((res) => {
        dispatch(togggleLoad());
        if (res.status === 0) {
          console.log("response for activate/deactivate:::::::::::::::::::::::::", res);
          if (res.payload.extras.active) {
            alert.removeAll();
            // alert.show("Activated successfully");
          }
          else {
            alert.removeAll();
            // alert.show("Deactivated successfully");
          }
          setRaffle({
            ...raffle,
            extras: {
              ...raffle.extras,
              active: !raffle.extras.active
            }
          })
        } else {
          const msg = `${raffle.extras.active ? 'Error while deactivating the raffle' : 'Error while deactivating the raffle'}.`
          alert.removeAll();
          alert.show(msg);
        }
      }).catch((e) => {
        dispatch(togggleLoad());
        const msg = `${raffle.extras.active ? 'Error while deactivating the raffle' : 'Error while deactivating the raffle'}.`
        alert.removeAll();
        alert.show(msg);
      })
  }

  const _renderFooter = () => {
    const newSubTabs = subTabs.filter((tab) => tab !== "HISTORY");
    return (
      <div>
        {
          //If type of model is edit then onclicking the button toggle update or if new model then create
          type === "EDIT" && raffle.extras.draft === false ? (
            // edit raffle
            <Fragment>
              {
                !(raffle.status === "CLOSE" ||
                  raffle.status === "DRAWN" ||
                  raffle.status === "CANCEL" ||
                  raffle.status === "COMPLETE") &&
                <div
                  className={
                    raffle.policiesAgree ? "update-btn" : "update-btn-disabled"
                  }
                  onClick={() => {
                    saveRaffle();
                    // // if (isValid) {
                    // //   saveRaffle();
                    // // }
                    // // validateInput();
                    // if (raffle.policiesAgree) {
                    //   const valid = validateInput();
                    //   if (valid) {

                    //   }
                    // }
                  }}
                >
                  UPDATE
                </div>}
              {type === "EDIT" && window.innerWidth > 720 && (
                <Fragment>
                  <AiOutlineCaretRight
                    opacity={
                      activeSubTab !== subTabs[subTabs.length - 1] ? 1 : 0.6
                    }
                    onClick={() => {
                      const newIndex = subTabs.findIndex((x) => x === activeSubTab) + 1;
                      if (newIndex < subTabs.length) {
                        setActiveSubTab(subTabs[newIndex]);
                      }
                    }}
                    className="left_right_arrows"
                  />
                  <AiOutlineCaretLeft
                    opacity={
                      (seriesEdit && activeSubTab !== subTabs[0]) ||
                        (!seriesEdit && activeSubTab !== subTabs[1])
                        ? 1
                        : 0.6
                    }
                    onClick={() => {
                      const newIndex = subTabs.findIndex((x) => x === activeSubTab) - 1;
                      if (newIndex >= 0) {
                        setActiveSubTab(subTabs[newIndex]);
                      }
                    }}
                    className="left_right_arrows"
                  />
                </Fragment>
              )}
              {(!createRaffleTemplate &&
                !(raffle.status === "CLOSE" ||
                  raffle.status === "DRAWN" ||
                  raffle.status === "CANCEL" ||
                  raffle.status === "COMPLETE")) && (
                  <div className="saveastemplate" onClick={saveasTemplate}>
                    SAVE AS TEMPLATE
                  </div>
                )}
              {!(raffle.status === "CLOSE" ||
                raffle.status === "DRAWN" ||
                raffle.status === "CANCEL" ||
                raffle.status === "COMPLETE") &&
                <button
                  className={(raffle.extras.draft ||
                    seriesEdit ||
                    new Date(raffle.participationCloses) < new Date() ||
                    raffle.status === "CLOSE" ||
                    raffle.status === "OPEN") ? "saveastemplate-disable" : "saveastemplate"}
                  onClick={() => {
                    // if (!(raffle.extras.draft ||
                    //   seriesEdit ||
                    //   raffle.status === "CLOSE" ||
                    //   raffle.status === "OPEN")) {
                    //   onActivateDeactivate({
                    //     raffle: { ...raffle },
                    //     winners: winners,
                    //   })
                    // }
                    if (new Date(raffle.participationCloses) < new Date()) {

                    }
                    if (raffle.extras.active) {
                      alert.removeAll()
                      alert.show("DEACTIVATED SUCCESSFULLY")
                      onproceed();
                    }
                    else {
                      alert.removeAll()
                      alert.show("ACTIVATED SUCCESSFULLY")
                      onproceed();
                    }
                  }}
                  disabled={raffle.extras.active ? new Date(raffle.participationOpens) < new Date() : new Date(raffle.participationCloses) < new Date() || raffle.status === "INACTIVE" ? new Date(raffle.participationCloses) < new Date() : new Date(raffle.participationOpens) < new Date()}
                >
                  {raffle.extras.active ? "DEACTIVATE" : "ACTIVATE"}
                </button>}
            </Fragment>
          ) : // new raffle
            raffle.serviceType === "Full Service" ? (
              <div onClick={saveRaffle}>PAY & CREATE</div>
            ) : (
              <button
                // disabled={disablingTabs}
                className={
                  raffle.policiesAgree
                    ? "create-btn-enabled"
                    : "create-btn-disabled"
                }
                onClick={() => {
                  console.log("clicked")
                  if (raffle.policiesAgree) {
                    // const valid = validateInput();
                    saveRaffle();
                  }
                }}
              >
                CREATE
              </button>
            )
          //             activeSubTab === subTabs[subTabs.length - 1] ?
          //                 null :
          //                 <div onClick={() => {
          //     setActiveSubTab(subTabs[subTabs.findIndex(x => x === activeSubTab) + 1])
          // }}>NEXT</div>
        }
        {type === "NEW" && window.innerWidth > 720 && (
          <Fragment>
            <AiOutlineCaretRight
              opacity={
                activeSubTab !== newSubTabs[newSubTabs.length - 1] ? 1 : 0.6
              }
              onClick={(event) => {
                event.preventDefault();
                if (
                  !disablingTabs ||
                  (raffle.type === "Series" &&
                    activeSubTab !== newSubTabs[newSubTabs.length - 1])
                ) {
                  const newIndex =
                    newSubTabs.findIndex((x) => x === activeSubTab) + 1;
                  if (newIndex < newSubTabs.length) {
                    setActiveSubTab(newSubTabs[newIndex]);
                  }
                }
              }}
              className="left_right_arrows"
            />

            <AiOutlineCaretLeft
              opacity={activeSubTab !== newSubTabs[0] ? 1 : 0.6}
              onClick={(event) => {
                event.preventDefault();
                if (
                  !disablingTabs ||
                  (raffle.type === "Series" && activeSubTab !== newSubTabs[0])
                ) {
                  const newIndex =
                    newSubTabs.findIndex((x) => x === activeSubTab) - 1;
                  if (newIndex >= 0) {
                    setActiveSubTab(newSubTabs[newIndex]);
                  }
                }
              }}
              className="left_right_arrows"
            />
          </Fragment>
        )}

        {!createRaffleTemplate && raffle.status === "DRAFT" && (

          <button disabled={disablingTabs || raffle.type !== "Series" || raffle.type !== "Regular"} className="save_as_draft" onClick={saveAsDraftClicked}>
            SAVE AS DRAFT
          </button>
        )}
        <button className="cancel" onClick={onClose}>
          CLOSE
        </button>
        <div className="add_to_rafflera_button">
          <input
            disabled={
              type === "EDIT" &&
              (raffle.status === "CLOSE" ||
                raffle.status === "DRAWN" ||
                raffle.status === "CANCEL" ||
                raffle.status === "COMPLETE")
            }
            onChange={(e) =>
              setRaffle((rff) => ({
                ...rff,
                policiesAgree: !rff.policiesAgree,
              }))
            }
            checked={raffle.policiesAgree}
            type="checkbox"
          />
          <label>
            <FcDocument />
            <span>
              Agree to the <a href="#">policies</a> of Rafflerra.com
            </span>
          </label>
        </div>
        <div className="add_to_rafflera_button">
          <input
            disabled={
              type === "EDIT" &&
              (raffle.status === "CLOSE" ||
                raffle.status === "DRAWN" ||
                raffle.status === "CANCEL" ||
                raffle.status === "COMPLETE")
            }
            onChange={(e) =>
              setRaffle((rff) => ({ ...rff, private: !rff.private }))
            }
            checked={raffle.private}
            type="checkbox"
          />
          <label>ADD TO RAFFLERA CATALOG</label>
          <img src={require("../../../assets/img/catalog_logo.png")} alt="" />
        </div>
      </div>
    );
  };

  return (
    <div className="create_raffle_model">
      <div className={confirmDilog ? "confirmUpdate" : "confirmNone"}>
        <ConfirmOverride
          setConfirmDilog={setConfirmDilog}
          onCancel={onKeepChanges}
          onProceed={onOverride}
          updatedInstances={updatedInstances}
        />
      </div>
      <div className="create_raffle_div">
        <div>
          <img src={require("../../../assets/img/cup.png")} alt="" />
          <div>
            {raffle.status === "CREATED" ? "Update " : "Create "}
            {raffle.rafflerType} Raffle {createRaffleTemplate && "Template"}
            {raffle.title.length > 0 ? " - " : ""}
            {raffle.title}
          </div>
          <label onClick={onClose}>X</label>
        </div>
        <div>
          <form>
            {_renderBaseDettails()}
            {/* sub tabs */}
            {_renderSubTabs()}
            {activeSubTab === "HISTORY" && _renderRaffleHistory()}
            {activeSubTab === "AUTO ACTIONS" && _renderAutoActions()}
            {activeSubTab === "TERMS & CONDITIONS" && _renderDisclosure()}
            {activeSubTab === "TICKET" && _renderRaffleTicket()}
            {activeSubTab === "DRAW CEREMONY" && _renderRaffleCeremony()}
            {activeSubTab === "LANDSCAPE" && _renderRaffleLandscape()}
            {activeSubTab === "PARTICIPANTS/PRIZE(S)" && _renderRafflePrizes()}
            {activeSubTab === "RAFFLE DATES" && _renderRaffleDates()}
            {activeSubTab === "RECURRENCE" && _renderRaffleRecurrenceOptions()}
            {activeSubTab === "RECURRENCE" &&
              // raffle.type === "Series" &&
              !raffle.extras.seriesRaffle.isMultipleRaffles &&
              _renderIncludeExcludeWinnersOption()}

            {/*
                    {
                        raffle.rafflerType === "Fundraising" ?
                            <div>
                                <label>PROPORTION</label>
                                <div>
                                    <input
                                        value={raffle.proportion[0]}
                                        onChange={(e) => {
                                            let text = e.currentTarget.value.trim();
                                            if (!isNaN(text)) {
                                                setRaffleFields(raffle.proportion.map((x, i) => i === 0 ? text.length > 0 ? parseInt(text) : "" : x), "proportion")
                                            } else {
                                                alert.removeAll();
                                alert.show("Please input a number")
                                            }
                                        }
                                        } placeholder="To The Beneficiary" />
                                    <input
                                        value={raffle.proportion[1]}
                                        onChange={(e) => {
                                            let text = e.currentTarget.value.trim();
                                            if (!isNaN(text)) {
                                                setRaffleFields(raffle.proportion.map((x, i) => i === 1 ? text.length > 0 ? parseInt(text) : "" : x), "proportion")
                                            } else {
                                                alert.removeAll();
alert.show("Please input a number")
                                            }
                                        }
                                        } placeholder="To The Winner" />
                                    <input readOnly value="5%(Raffle Manager)" />
                                </div>
                            </div> :
                            <div></div>
                    }
                    
                    {
                        type !== "EDIT" &&
                        <div>
                            <label>SERIES RAFFLE</label>
                            <div>
                                <div>
                                    <label>RECURS</label>
                                    <select onChange={(e) => {
                                        const value = e.currentTarget.value;
                                        if (value === "NONE") {
                                            setRaffle(rff => ({ ...rff, extras: { ...rff.extras, seriesRaffle: null } }))
                                        } else if (value === "CUSTOM") {
                                            setRaffle(rff => ({ ...rff, extras: { ...rff.extras, seriesRaffle: { id: new Date().getTime().toString(), "recurs": value, "noOfRecurs": 2, "recuringDelay": 2, "includeWinners": "INCLUDE WINNER/S" } } }))
                                        } else {
                                            setRaffle(rff => ({ ...rff, extras: { ...rff.extras, seriesRaffle: { id: new Date().getTime().toString(), "recurs": value, "noOfRecurs": 2, "recuringDelay": 2, "includeWinners": "INCLUDE WINNER/S" } } }))
                                        }
                                    }}>
                                        <option value="NONE">NONE</option>
                                        <option value="DAILY">DAILY</option>
                                        <option value="WEEKLY">WEEKLY</option>
                                        <option value="MONTHLY">MONTHLY</option>
                                        <option value="CUSTOM">CUSTOM</option>
                                    </select>
                                </div>
                                {
                                    (raffle.extras.seriesRaffle && raffle.extras.seriesRaffle.recurs === "CUSTOM") &&
                                    <div>
                                        <label>RECURING DELAY</label>
                                        <input onChange={(e) => {
                                            const recuringDelay = e.currentTarget.value;
                                            if (recuringDelay > 0) {
                                                setRaffle(rff => ({ ...rff, 'extras': { ...rff.extras, 'seriesRaffle': { ...rff.extras.seriesRaffle, recuringDelay } } }))
                                            }
                                        }} value={raffle.extras.seriesRaffle.recuringDelay} type="number" />
                                    </div>
                                }
                                {
                                    raffle.extras.seriesRaffle &&
                                    <div>
                                        <label>NO. OF RECURS</label>
                                        <input onChange={(e) => {
                                            const noOfRecurs = e.currentTarget.value;
                                            if (noOfRecurs > 0) {
                                                setRaffle(rff => ({ ...rff, 'extras': { ...rff.extras, 'seriesRaffle': { ...rff.extras.seriesRaffle, noOfRecurs } } }))
                                            }
                                        }} value={raffle.extras.seriesRaffle.noOfRecurs} type="number" />
                                    </div>
                                }
                                {
                                    raffle.extras.seriesRaffle &&
                                    <div>
                                        <label>.</label>
                                        <select onChange={(e) => {
                                            const includeWinners = e.currentTarget.value;
                                            setRaffle(rff => ({ ...rff, 'extras': { ...rff.extras, 'seriesRaffle': { ...rff.extras.seriesRaffle, includeWinners } } }))
                                        }} value={raffle.extras.seriesRaffle.includeWinners}>
                                            <option value="INCLUDE WINNER/S">INCLUDE WINNER/S</option>
                                            <option value="EXCLUDE WINNER/S">EXCLUDE WINNER/S</option>
                                        </select>
                                    </div>
                                }
                            </div>
                        </div>
                    } */}
          </form>
        </div>
        {_renderFooter()}
      </div>
    </div>
  );
};
