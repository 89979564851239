import React, { useEffect, useState } from "react";
import "./emailVerification.scss";
import { confirmAccount, getUserByEmail } from "../../config/endpoints";
import { CRYPTER_SECRET } from "../../helper/appService";
import Cryptr from 'cryptr';
import { request } from "../../helper/request";
import Loader from "react-loader-spinner";
import ReactLoaderSpinner from "react-loader-spinner";
import { useLocation, useHistory } from "react-router-dom";

const cryptr = new Cryptr(CRYPTER_SECRET);

const EmailVerification = (props) => {

    const [user, setUser] = useState(null);
    const [loading, toggleLoading] = useState(false);
    const [queryParamsNum, setQueryParamsNum] = useState(0);
    const [queryParams, setQueryParams] = useState([]);
    const history = useHistory();

    useEffect(() => {
        const url = window.location.href;
        const encryptedParams = url.split('?')[1];
        const decyptedParams = cryptr.decrypt(encryptedParams);
        const searchParams = new URLSearchParams(new URL(`${url.split('?')[0]}?${decyptedParams}`).search);
        setQueryParams(Array.from(searchParams.keys()));
        setQueryParamsNum(Array.from(searchParams.keys()).length);
        const email = searchParams.get('email');
        sessionStorage.setItem("toJoinEmail", email);
        getUserById(email);
        verifyAccountAPI(email);
        if (searchParams.get('invId') && searchParams.get('joinChoice')) {
            sessionStorage.setItem("invitationId", searchParams.get('invId'));
            sessionStorage.setItem("joinChoice", searchParams.get('joinChoice'));
            props.setOpenPage("invitations");
        }
    }, [])

    const getUserById = (email) => {
        toggleLoading(true);

        request("POST_AUTH", getUserByEmail, { email: email })
            .then((response) => {
                if (response.status === 0 && response.payload.length > 0) {
                    setUser(response.payload[0])
                } else {
                    console.log("user not found")
                } 
                toggleLoading(false);
            })
            .catch((e) => {
                console.log("OOPS! somthing went wrong", e.message);
                toggleLoading(false);
            });
    }

    const verifyAccountAPI = (email) => {
        toggleLoading(true);

        request("GET_AUTH", confirmAccount+ email )
            .then((response) => {
                if (response.status === 0) {
                    console.log(response.payload)
                } else {
                    console.log(response)
                } 
                toggleLoading(false);
            })
            .catch((e) => {
                console.log("OOPS! somthing went wrong", e.message);
                toggleLoading(false);
            });
    }

    const onClickContinue = () => {
        history.push("authentication");
    }

    return (
        <div className="cofirm-acc-page">
            <div className="header">
                <img src={require("../../assets/img/logo.png")} className="logo-png"/>
                <label className="app-title">Rafflerra</label>
            </div>
            <div className="body">
                <div className="content">
                    <div className="status-msg">
                        <p className="heading">Hello{" "}
                        <span className="bold-text">{user?.name}</span>
                        , Welcome to 
                        <span className="rafflera-title">Rafflerra </span>
                        🎉</p>
                        <p>Your account is now confirmed and ready to roll.</p>
                        <p>We're thrilled to have you on board and excited to share countless opportunities for you to win amazing prizes.</p>
                        <p className="bold-text">Did you know you can also host your own raffles on Rafflerra?</p>
                        <p>Share your passions and prized possessions with the world while raising funds or simply having fun. It's easy to get started - just visit your profile page and click 'Create Raffle.'</p>
                        <p>If you need assistance, don't hesitate to reach out. We're here to make your Rafflerra experience incredible.</p>
                        <p> Once again, welcome to Rafflerra! May the luckiest of draws be in your favor.</p>
                        <p>Start exploring the raffles and discover your next exciting win! To get started, click Continue.</p>
                    </div>
                    <button className="btn"
                        onClick={() => {
                            onClickContinue();
                        }}>
                        Continue
                    </button>
                </div>
            </div>
            {loading ? (
                <div className="loadingspinner">
                  <ReactLoaderSpinner type="TailSpin" color="rgb(11, 70, 85)" width={60} />
                </div>
            ) : null}
        </div>
    )

};

export default EmailVerification;