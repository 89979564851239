export const environment = "production"; //development, staging, production;
// export const environment = "development"; //development, staging, production;

const development_host = "https://us-central1-raffleradev.cloudfunctions.net/";
const production_host = "https://us-central1-rafflera.cloudfunctions.net/";

// hosted dev https://raffleradev.web.app/
// local dev http://localhost:3000/

// web app url
// export const web_url = environment === "development" ? "http://localhost:3000/" : "https://rafflera.web.app/";

export const host = environment === "development" ? development_host : production_host;

// delete from here
// export const web_url = "http://localhost:3000/";
// export const localHost = "http://localhost:8080";
// export const postRaffle = `${localHost}/add`;
// export const getRaffles = `${localHost}/all`;
// export const getRaffleBySeriesId = `${localHost}/getRaffleBySeriesId`;
// export const searchAllRaffles = `${localHost}/allSearch`;
// export const searchCount = `${localHost}/searchCount`;
// export const getAllCount = `${localHost}/allCount`;
// export const myRaffles = `${localHost}/myRaffles`;
// export const partRaffles = `${localHost}/participatedRaffles`;
// export const partCount = `${localHost}/participatedCount`;
// export const hostRaffles = `${localHost}/hostedRaffles`;
// export const calRaffles = `${localHost}/calenderRaffles`;
// export const hostCount = `${localHost}/hostedCount`;
// export const mySearch = `${localHost}/mySearch`;
// export const sidePanelList = `${localHost}/sidePanelList`;
// export const mySearchCount = `${localHost}/mySearchCount`;
// export const historyList = `${localHost}/raffleHistory`;
// export const getRaffleById = `${localHost}/getById`;
// export const getRafflePrizes = `${localHost}/getRafflePrizes`;
// export const deleteRaffle = `${localHost}/delete/`;
// export const updateRaffle = `${localHost}/update`;
// export const joinRaffle = `${localHost}/join`;
// export const joinAsGuest = `${localHost}/joinAsGuest`;
// export const claimPrize = `${localHost}/claimPrize`;
// export const isValidToClaim = `${localHost}/isValidToClaim`;
// export const activateDeactivateRaffle = `${localHost}/activateDeactivateRaffle`;
// export const pickWinners = `${localHost}/pickawinner`;
// export const cancelRaffleUrl = `${localHost}/cancel`;
// export const getAllNotification = `${localHost}/notification?email=`;
// export const getNotificationCount = `${localHost}/notificationsCount?email=`;
// export const notifSearch = `${localHost}/notifSearch`;
// export const notifSearchCount = `${localHost}/notifSearchCount`;
// export const moveToTrash = `${localHost}/movetotrash`;
// export const restoreTrash = `${localHost}/restoretrash`;
// export const emptyTrash = `${localHost}/emptytrash?email=`;
// export const deletePermanently = `${localHost}/deleteselectedtrash`;
// export const sendInvitation = `${localHost}/sendInvitation`;
// export const invitaionsList    = `${localHost}/invitaionsList`;
// export const invCatCount   = `${localHost}/invCatCount`;
// export const invSubCatCount = `${localHost}/invSubCatCount`;
// export const emptytrashinv = `${localHost}/emptytrashinv`
// export const deleteTrashInv = `${localHost}/deleteTrashInv`
// export const movetotrashinv = `${localHost}/movetotrashinv`;
// export const restoretrashinv = `${localHost}/restoretrashinv`;
// export const invitaionSearch = `${localHost}/invitaionsSearch`;
// export const InvCatSearchCount = `${localHost}/InvCatSearchCount`;
// export const InvSubCatSearchCount = `${localHost}/InvSubCatSearchCount`;
// export const acceptInv = `${localHost}/acceptInv`;
// export const getInvById = `${localHost}/getInvById?_id=`;
// // delete till here

// notificatons local
// export const localHost = "http://localhost:8080";
// export const getAllNotification = `${localHost}/notification?email=`;
// export const getNotificationCount = `${localHost}/notificationsCount?email=`;
// export const notifSearch = `${localHost}/notifSearch`;
// export const notifSearchCount = `${localHost}/notifSearchCount`;
// export const moveToTrash = `${localHost}/movetotrash`;
// export const restoreTrash = `${localHost}/restoretrash`;
// export const emptyTrash = `${localHost}/emptytrash?email=`;
// export const deletePermanently = `${localHost}/deleteselectedtrash`;


//authentication; authService
export const registerUser = `${host}/authService/register`;
export const confirmAccount = `${host}/authService/confirmAccount?email=`;
export const loginUser = `${host}/authService/login`;
export const sendForgetPassword = `${host}/authService/sendForgetPasswordLink`;
export const updateUserName = `${host}/authService/updateName`;
export const resendVerificationlinkHost = `${host}/authService/resendVerificationlink`;
export const updatePassword = `${host}/authService/saveNewPassword`;
//beneficiaries; beneficiaryService
export const beneficiaryHost = `${host}/beneficiaryService/`
export const getAllBeneficiariesHost = `${host}/beneficiaryService/all`;
//user
export const updateBenesInUserHost = `${host}/userService/updateBenes`;
export const toggleAccountStatusHost = `${host}/userService/toggleAccountStatus`;
export const updateUserHost = `${host}/userService/updateUser`;
export const getUserByEmail = `${host}/userService/getUserByEmail`;
export const updateAddressUrl = `${host}/userService/updateAddress`;
// //raffle
export const web_url = "https://rafflera.web.app/";
export const postRaffle = `${host}/raffleService/add`;
export const getRaffles = `${host}/raffleService/all`;
export const getRaffleBySeriesId = `${host}/raffleService/getRaffleBySeriesId`;
export const searchAllRaffles = `${host}/raffleService/allSearch`;
export const searchCount = `${host}/raffleService/searchCount`;
export const getAllCount = `${host}/raffleService/allCount`;
export const myRaffles = `${host}/raffleService/myRaffles`;
export const partRaffles = `${host}/raffleService/participatedRaffles`;
export const partCount = `${host}/raffleService/participatedCount`;
export const hostRaffles = `${host}/raffleService/hostedRaffles`;
export const calRaffles = `${host}/raffleService/calenderRaffles`;
export const hostCount = `${host}/raffleService/hostedCount`;
export const sidePanelList = `${host}/raffleService/sidePanelList`;
export const mySearch = `${host}/raffleService/mySearch`;
export const mySearchCount = `${host}/raffleService/mySearchCount`;
export const historyList = `${host}/raffleService/raffleHistory`;
export const getRaffleById = `${host}/raffleService/getById`;
export const getRafflePrizes = `${host}/raffleService/getRafflePrizes`;
export const deleteRaffle = `${host}/raffleService/delete/`;
export const updateRaffle = `${host}/raffleService/update`;
export const joinRaffle = `${host}/raffleService/join`;
export const joinAsGuest = `${host}/raffleService/joinAsGuest`;
export const claimPrize = `${host}raffleService/claimPrize`;
export const isValidToClaim = `${host}raffleService/isValidToClaim`;
export const activateDeactivateRaffle = `${host}raffleService/activateDeactivateRaffle`;
export const pickWinners = `${host}raffleService/pickawinner`;
export const cancelRaffleUrl = `${host}raffleService/cancel`;
export const getAllNotification = `${host}raffleService/notification?email=`;
export const getNotificationCount = `${host}raffleService/notificationsCount?email=`;
export const notifSearch = `${host}raffleService/notifSearch`;
export const notifSearchCount = `${host}raffleService/notifSearchCount`;
export const moveToTrash = `${host}raffleService/movetotrash`;
export const restoreTrash = `${host}raffleService/restoretrash`;
export const emptyTrash = `${host}raffleService/emptytrash?email=`;
export const deletePermanently = `${host}raffleService/deleteselectedtrash`;
export const sendInvitation = `${host}raffleService/sendInvitation`;
export const invitaionsList    = `${host}raffleService/invitaionsList`;
export const invCatCount   = `${host}raffleService/invCatCount`;
export const invSubCatCount = `${host}raffleService/invSubCatCount`;
export const emptytrashinv = `${host}raffleService/emptytrashinv`
export const deleteTrashInv = `${host}raffleService/deleteTrashInv`
export const movetotrashinv = `${host}raffleService/movetotrashinv`;
export const restoretrashinv = `${host}raffleService/restoretrashinv`;
export const invitaionSearch = `${host}raffleService/invitaionsSearch`;
export const InvCatSearchCount = `${host}raffleService/InvCatSearchCount`;
export const InvSubCatSearchCount = `${host}raffleService/InvSubCatSearchCount`;
export const acceptInv = `${host}raffleService/acceptInv`;
export const getInvById = `${host}raffleService/getInvById?_id=`;


//notifications
// export const getAllNotification = `${host}notificationsService/notification?email=`;
// export const getNotificationCount = `${host}notificationsService/notificationsCount?email=`;
// export const notifSearch = `${host}notificationsService/notifSearch`;
// export const notifSearchCount = `${host}notificationsService/notifSearchCount`;
// export const moveToTrash = `${host}notificationsService/movetotrash`;
// export const restoreTrash = `${host}notificationsService/restoretrash`;
// export const emptyTrash = `${host}notificationsService/emptytrash?email=`;
// export const deletePermanently = `${host}notificationsService/deleteselectedtrash`;
// export const invitationEmptyTrash = `${localHost}/emptytrashinv`;
// export const InvSubCatSearchCount = `${localHost}/InvSubCatSearchCount`;

//payments
export const getCheckSumHost = `${host}/payment/genChecksum`;
export const csfReturnUrl = `${host}/payment/paymentResponse`;
// mail service
export const sendEmails = `${host}mailService`;
// config service
export const configHost = `${host}configService`;
