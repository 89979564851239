import React, { useState, useEffect } from 'react';
import './ConfirmCancel.scss'

export default ({
  setConfirmDilog,
  onProceed
}) => {
  return (
    <div className='Confirm-Cancel'>
      <div className='container1'>
        <div className='container1-child1'>
          <div className='container1-child1-child1'>
            <div className='header'>
            <img
                className="confirm-cancel-cup"
                src={require("../../../../assets/img/cup.png")}
                alt="Cup"
              />
              <div>
                CONFIRM CANCELLATION
              </div>
              <button
                className='x-close-btn'
                onClick={() => {
                  setConfirmDilog(false);
                }}>
                X
              </button>
            </div>
            <div className='body'>
              Are you sure you want to cancel this raffle?
            </div>
            <div className='footer'>
              <button
                className={"close-btn"}
                onClick={() => {
                  setConfirmDilog(false);
                }}>
                NO
              </button>
              <button
                className={"confirm-btn"}
                onClick={onProceed}>
                YES
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


