import React, { useState, useRef, useEffect, useCallback, Fragment } from "react";
import "./RaffleTicketViewModel.scss";
import { useDispatch } from "react-redux";
import { togggleLoad } from "../../../../store/actions/loadAction";
import { JoinRaffle } from "../../../../store/actions/raffleAction";
import ReactTooltip from "react-tooltip";
import {
  joinRaffle,
  getCheckSumHost,
  csfReturnUrl,
} from "../../../../config/endpoints";
import { request } from "../../../../helper/request";
import { useAlert } from "react-alert";
import { getUserByEmail } from "../../../../config/endpoints";
import AddUserToRaffle from "../../../../helper/addUserToRaffle";
import { FaAngleDoubleDown, FaAngleDoubleUp, FaShareAlt, FaTimes, FaSignInAlt } from "react-icons/fa";
import { GrDocumentText } from "react-icons/gr";
import { web_url } from "../../../../config/endpoints";
import RaffleTicketPlain from "../../../../assets/svg_components/raffle_ticket_plain";
// html parser
import parse from "html-react-parser";
// text editor
import RichTextEditor from "react-rte";
import firebase from "../../../../helper/firebase";
import ImageViewer from "react-simple-image-viewer";
import DrawsTabContent from "../raffleTicketViewComponents/drawTabContent";

const options = {
  hour: "2-digit",
  minute: "numeric",
  month: "2-digit",
  day: "2-digit",
  year: "numeric",
};

export default ({
  userType,
  asParticipant,
  asCatalogue,
  choosenRaffle,
  choosenRafflePrizes,
  drawMode,
  toggleAnimation,
  timeDelay,
  isPlaying,
  activeDrumroll,
  winnersTab,
  setWinnersTab,
  onProceed,
  onClose,
  type,
  toggleShareModel,
  guestInfo,
  invAccepted, // prop from invtationsList.js to see if invitation accepted from ticket
}) => {
  const [csfForm, setCsfForm] = useState(null);
  const csfRef = useRef();
  const dispatch = useDispatch();
  const alert = useAlert();
  const [joined, setJoined] = useState(false);
  const [raffleUser, setRaffleUser] = useState(null);
  const [img, setImg] = React.useState(null);
  const [isImage, setIsImage] = useState(true);
  const [participant, setParticipant] = useState({});
  const [drawOccurances, setDrawOccurances] = useState([]);
  const [showingDesc, setShowingDesc] = useState(true);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("details");
  const [activeTab2, setActiveTab2] = useState("Description");

  //get raffle creater name
  useEffect(() => {
    if (Object.keys(choosenRaffle).length > 0) {
      if (choosenRaffle.uuid && choosenRaffle.extras.imageUploaded) {
        firebase
          .storage()
          .ref()
          .child("raffle_pics/" + choosenRaffle.uuid)
          .getDownloadURL()
          .then((url) => {
            setImg(url);
          })
          .catch((e) => { });
        console.log("urul");
      }
      console.log("aurul");
      request("POST_AUTH", getUserByEmail, { email: choosenRaffle.createdBy })
        .then((res) => {
          setRaffleUser(res.payload[0]);
        })
        .catch((e) => {
          alert.removeAll();
          alert.show(e.message);
        });
      let usr = JSON.parse(sessionStorage.getItem("User"));
      if (choosenRaffle.participants.length > 0) {
        const participant = choosenRaffle.participants.find(
          (p) => p.email === usr?.email
        );
        setParticipant(participant);
      }

      if (choosenRaffle.extras.seriesRaffle.recurrencePattern === "Hourly") {
        setDrawOccurances([...choosenRaffle.extras.seriesRaffle.hourlyPattern]);
      } else if (
        choosenRaffle.extras.seriesRaffle.recurrencePattern === "Daily"
      ) {
        setDrawOccurances([
          ...choosenRaffle.extras.seriesRaffle.dailyPattern.days,
        ]);
      } else if (
        choosenRaffle.extras.seriesRaffle.recurrencePattern === "Weekly"
      ) {
        let weekStarts = choosenRaffle.extras.seriesRaffle.weeklyPattern.days;
        let drawDt = choosenRaffle.drawAt
        const draws = weekStarts.map((_, index) => {
          const newDrawDt = new Date(drawDt);
          newDrawDt.setDate(newDrawDt.getDate() + (7 * index));
          return newDrawDt;
        });
        setDrawOccurances([...draws]);
      } else if (choosenRaffle.extras.seriesRaffle.recurrencePattern === "On Demand") {
        setDrawOccurances([...new Array(choosenRafflePrizes.length || 0).keys()])
      } else {
        setDrawOccurances([]);
      }
    }
  }, [choosenRaffle]);

  //to check if user included in raffle
  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("User"));
    if (user !== null) {
      choosenRaffle.participants.findIndex((p) => p.email === user?.email) ===
        -1
        ? setJoined(false)
        : setJoined(true);
    }
    if (drawMode) {
      setActiveTab("draws")
      setActiveTab2("draws")
    }
  }, []);

  //to open winners tab right after draw
  useEffect(() => {
    if(winnersTab){
      setActiveTab("prizes")
      setActiveTab2("prizes")
    }
    if (userType === "host") {
      setWinnersTab(false);
    }
  }, [winnersTab]);

  const joinButtonClicked = () => {
    const user = JSON.parse(sessionStorage.getItem("User"));
    if (user !== null) {
      dispatch(togggleLoad());
      AddUserToRaffle(choosenRaffle._id, user.email)
        .then((res) => {
          dispatch(JoinRaffle(choosenRaffle._id, user.email));
          alert.removeAll();
          alert.show(res.msg);
          if (sessionStorage.getItem("invitationId")) { // condition is needed for login/register to join option in join page or from a inv link
            invAccepted(sessionStorage.getItem("invitationId"))
          }
          dispatch(togggleLoad());
          onClose();
        })
        .catch((e) => {
          alert.removeAll();
          alert.show(e);
          dispatch(togggleLoad());
        });
    } else {
      onClose();
      window.open(web_url + "joinRaffle?id=" + choosenRaffle._id, "_blank");
    }
    // }
  };

  const openImageViewer = () => {
    if (img) {
      setIsViewerOpen(true);
    }
  };

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const [openTAndC, setOpenTAndC] = useState(false);

  const [drawTab, setDrawTab] = useState(false);
  const [tandc, setTAndC] = useState(false);

  const multipleRaffleTickets = choosenRaffle.participants.filter((obj) => {
    let sessEmail = JSON.parse(sessionStorage.getItem("User"))?.email;
    if (type === "GUEST-VIEW") {
      if (guestInfo.email.length > 0) {
        sessEmail = guestInfo?.email;
      } else {
        sessEmail = guestInfo?.mobile;
      }
    }
    return sessEmail === obj.email;
  });

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <div style={{ position: "fixed", zIndex: "11000" }}>
        {isViewerOpen && (
          <ImageViewer
            src={[img]}
            currentIndex={0}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </div>
      <div
        className={
          type === "DRAW-VIEW"
            ? "normal_raffle_view_model"
            : "participanty_raffle_view_model"
        }
      >
        {/* main view */}
        {Object.keys(choosenRaffle).length > 0 ? (
          <>
            <div className="raffle_view">
              {/* <img src={require('../../../assets/img/ticket_empty.png')} /> */}
              <RaffleTicketPlain
                backgroundColor={choosenRaffle.styles.background}
              />
              {/* Content */}
              <div className="raffle-content">
                {/* title */}
                <div className="hosted">
                  <p data-tip data-for={"title"} className="hosted-title">{choosenRaffle.title.toUpperCase()}</p>
                  <ReactTooltip place="top" id={"title"}>
                        {choosenRaffle.title.toUpperCase()}
                      </ReactTooltip>
                  <div className="short-description">
                    {window.innerWidth < 720 && <div className="emp-cont"></div>}
                    <p className="s-des" data-tip data-for={"short_desc"}>
                      {choosenRaffle.shortDescription}
                    </p>
                    <ReactTooltip place="right" id={"short_desc"}>
                      {choosenRaffle.shortDescription}
                    </ReactTooltip>
                    {window.innerWidth < 720 && type !== "GUEST-VIEW" && <div className="action-icons">
                      {
                        // disable join raffle if contest is completed, host opens, and already joined
                        (sessionStorage.getItem("User") === null
                          ? true
                          : choosenRaffle.createdBy !==
                          JSON.parse(sessionStorage.getItem("User")).email) &&
                          new Date(choosenRaffle.participationCloses) > new Date()
                          ? type === "VIEW"
                            ? null
                            : !(userType === "host") &&
                            asCatalogue && (
                              <FaSignInAlt
                                className="join-icon"
                                onClick={joinButtonClicked}
                              />
                            )
                          : !(userType === "host") &&
                          asCatalogue && (
                            <FaSignInAlt
                              className="join-icon disable"
                              onClick={() => {
                                if (
                                  new Date() >
                                  new Date(choosenRaffle.participationCloses)
                                ) {
                                  alert.removeAll();
                                  alert.show("Raffle closed");
                                } else if (
                                  choosenRaffle.createdBy ===
                                  JSON.parse(sessionStorage.getItem("User"))
                                    .email
                                ) {
                                  alert.removeAll();
                                  alert.show(
                                    "Host can't register for a raffle"
                                  );
                                } else if (
                                  choosenRaffle.maxNumParticipants -
                                  choosenRaffle.participants.length ===
                                  0
                                ) {
                                  alert.removeAll();
                                  alert.show("Maximum no. participants exceed");
                                } else if (participant.email) {
                                  alert.removeAll();
                                  alert.show("Already joined");
                                }
                              }}
                            />
                          )
                      }
                      {
                        choosenRaffle.status !== "CLOSE" &&
                          choosenRaffle.status !== "DRAWN" &&
                          choosenRaffle.status !== "COMPLETE" ? (
                          <FaShareAlt
                            onClick={() =>
                              toggleShareModel(
                                web_url + "joinRaffle?id=" + choosenRaffle._id
                              )
                            }
                          />
                        ) : (
                          <FaShareAlt
                            onClick={() => {
                              alert.removeAll();
                              alert.show("Raffle closed");
                            }}
                          />
                        )
                      }
                      <FaTimes
                        onClick={!toggleAnimation && onClose}
                      />
                    </div>}
                  </div>
                  {window.innerWidth > 720 && <div className="hosted-sub-title">
                    {choosenRaffle.rafflerType} Raffle by{" "}
                    {raffleUser !== null || raffleUser !== undefined ? raffleUser?.name : ""}
                  </div>}
                </div>

                {/* body */}
                <div className="hosted-body">
                  {/*Column 1 - for tabs box*/}
                  <div className="raffle-view-tabs">
                    <div className="tabs-header">
                      <button
                        onClick={() => setActiveTab("details")}
                        className={activeTab === "details" ? "activeTab" : ""}
                      >
                        Details
                      </button>
                      <button
                        onClick={() => setActiveTab("prizes")}
                        className={activeTab === "prizes" ? "activeTab" : ""}
                      >
                        Prize(s) / Winner(s)
                      </button>
                      {window.innerWidth > 720 &&
                        // !choosenRaffle.extras.seriesRaffle
                        //   .isMultipleRaffles &&
                          <button
                            onClick={() => setActiveTab("draws")}
                            className={activeTab === "draws" ? "activeTab" : ""}
                          >
                            Draw(s)
                          </button>
                        }
                      {userType === "host" && window.innerWidth > 720 && (
                        <button
                          onClick={() => setActiveTab("participants")}
                          className={
                            activeTab === "participants" ? "activeTab" : ""
                          }
                        >
                          Participant(s)
                        </button>
                      )}
                      {/* {window.innerWidth > 720 && (
                        <button
                          onClick={() => setActiveTab("winners")}
                          className={activeTab === "winners" ? "activeTab" : ""}
                        >
                          Winner(s)
                        </button>
                      )} */}
                    </div>
                    <div className="tabs-content">
                      {activeTab === "details" && (
                        <div className="details-content">
                          {asParticipant && (
                            <div className="detail">
                              <label className="lable">
                                Ticket #(s):{" "}
                              </label>
                              <label className="value">
                                {multipleRaffleTickets.map(
                                  (participant, index) => (
                                    <span key={index}>
                                      {participant.ticketNumber}
                                      {multipleRaffleTickets.length > 1 &&
                                        index === 0 &&
                                        ", "}
                                      {index > 0 &&
                                        multipleRaffleTickets.length - 1 !==
                                        index &&
                                        ", "}
                                    </span>
                                  )
                                )}
                              </label>
                            </div>
                          )}
                          <div className="detail">
                            <label className="lable">Entry Fee: </label>
                            <label className="value">
                              {choosenRaffle.fee === 0
                                ? "Free"
                                : choosenRaffle.fee + choosenRaffle.currency[0]}
                            </label>
                          </div>
                          <div className="detail">
                            <label className="lable">
                              Ticket limit per user:{" "}
                            </label>
                            <label className="value">
                              {choosenRaffle.extras.ticketLimitPerUser}
                            </label>
                          </div>
                          <div className="detail">
                            <label className="lable">Participants: </label>
                            <label className="value">
                              {choosenRaffle.participants.length} out of{" "}
                              {choosenRaffle.maxNumParticipants}
                            </label>
                          </div>
                          <div className="detail">
                            <label className="lable">Type: </label>
                            <label className="value">
                              {choosenRaffle.type}
                            </label>
                          </div>
                          <div className="detail">
                            <label className="lable">
                              Recurrence:{" "}
                            </label>
                            <label className="value">
                              {
                                choosenRaffle.extras.seriesRaffle
                                  .recurrencePattern
                              }
                              {choosenRaffle.type === "Series" &&
                                (choosenRaffle.extras.seriesRaffle
                                  .isMultipleRaffles
                                  ? " (Multiple Raffles & Respective Draws)"
                                  : " (Single Raffle & Multiple Draws)")}
                            </label>
                          </div>
                          <div className="detail">
                            <label className="lable">Open at: </label>
                            <label className="value">
                              {new Date(
                                choosenRaffle.participationOpens
                              ).toLocaleString("en-US", options)}
                            </label>
                          </div>
                          <div className="detail">
                            <label className="lable">Close at: </label>
                            <label className="value">
                              {new Date(
                                choosenRaffle.participationCloses
                              ).toLocaleString("en-US", options)}
                            </label>
                          </div>
                          {choosenRaffle.status === "DRAWN" &&
                            choosenRaffle.type === "Instant" ? (
                            <div className="detail">
                              <label className="lable">Drawn At: </label>
                              <label className="value">
                                {new Date(choosenRaffle.drawnAt).toLocaleString(
                                  "en-US",
                                  options
                                )}
                              </label>
                            </div>
                          ) : (
                            ""
                          )}

                          {/* {choosenRaffle.type !== "Instant" &&
                            choosenRaffle.status !== "DRAWN" &&
                            choosenRaffle.extras.seriesRaffle
                              .isMultipleRaffles ? (
                            <div className="detail">
                              <label className="lable">
                                {choosenRaffle.status === "DRAWN" ||
                                  choosenRaffle.winners.length > 0
                                  ? "Drawn:"
                                  : "Draw at:"}
                              </label>
                              <label className="value">
                                {choosenRaffle.status === "DRAWN" ||
                                  choosenRaffle.winners.length > 0
                                  ? new Date(
                                    choosenRaffle.drawnAt
                                  ).toLocaleString("en-US", options)
                                  : new Date(
                                    choosenRaffle.drawAt
                                  ).toLocaleString("en-US", options)}
                              </label>
                            </div>
                          ) : choosenRaffle.type !== "Instant" &&
                            choosenRaffle.status === "DRAWN" ? (
                            <div className="detail">
                              <label className="lable">Drawn:</label>
                              <label className="value">
                                {new Date(choosenRaffle.drawnAt).toLocaleString(
                                  "en-US",
                                  options
                                )}
                              </label>
                            </div>
                          ) : null} */}

                          {userType === "host" && (
                            <div className="detail">
                              <label className="lable">
                                Maximum Participants:{" "}
                              </label>
                              <label className="value">
                                {choosenRaffle.maxNumParticipants}
                              </label>
                            </div>
                          )}
                          {userType === "host" &&
                            choosenRaffle.autoActions.allowAutoActions && (
                              <div className="detail">
                                <label className="lable">
                                  Minimum Participants:{" "}
                                </label>
                                <label className="value">
                                  {choosenRaffle.minNumParticipants}
                                </label>
                              </div>
                            )}
                          {userType === "host" &&
                            choosenRaffle.autoActions.allowAutoActions && (
                              <div className="detail">
                                <label className="lable" style={{ width: "170px" }}>
                                  Auto Action(s):{" "}
                                </label>
                                <label className="value">
                                  If minimum participant(s){" "}
                                  {choosenRaffle.minNumParticipants} is not met
                                  by{" "}
                                  {choosenRaffle.autoActions.checkTimePercent}%
                                  of raffle period,
                                  {choosenRaffle.autoActions.autoCancel
                                    ? " Cancel"
                                    : ` Allow ${choosenRaffle.autoActions.maxExtensions} extension(s) by ${choosenRaffle.autoActions.extDurationPercent}% of raffle period. After extension(s) ${choosenRaffle.autoActions.autoActionAfterMaxExtns} the raffle.`}
                                </label>
                              </div>
                            )}
                        </div>
                      )}
                      {activeTab === "prizes" && (
                        <div className="prizes-table">
                          <table>
                            <thead>
                              <tr>
                                <th style={{ textAlign: "left" }}>#</th>
                                <th style={{ textAlign: "left" }}>
                                  Prize Type
                                </th>
                                <th style={{ textAlign: "right" }}>Prize</th>
                                <th style={{ textAlign: "left" }}>Winners' Name(s)</th>
                                <th style={{ textAlign: "left" }}>Ticket Number</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                choosenRafflePrizes.map((prizeObj, index) => {
                                  return (
                                    <Fragment key={index}>
                                      <tr>
                                        <td colSpan={3} className="drawnum">
                                          Draw #{index + 1}
                                        </td>
                                      </tr>
                                      {prizeObj.numPrizes.map((p, j) => {
                                        return (
                                          <tr key={j}>
                                            <td style={{ textAlign: "left" }}>
                                              {j + 1}
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {prizeObj.prizeType[j]
                                              }
                                            </td>                                            
                                            <td style={{ textAlign: "right" }}>
                                              {`${p} ${prizeObj.prizeType[j] === "Money" ? prizeObj.currency[j] : ""}`}
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {prizeObj.winners[j]?.name
                                              }
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {prizeObj.winners[j]?.ticketNumber
                                              }
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </Fragment>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                      )}
                      {activeTab === "draws" &&
                        window.innerWidth > 720 &&
                        (
                          <DrawsTabContent
                            choosenRaffle={choosenRaffle}
                            onProceed={onProceed}
                            drawMode={drawMode}
                            winnersTab={winnersTab}
                            toggleAnimation={toggleAnimation}
                            timeDelay={timeDelay}
                            isPlaying={isPlaying}
                            activeDrumroll={activeDrumroll}
                          />
                        )}
                      {activeTab === "participants" &&
                        window.innerWidth > 720 &&
                        (
                          <div className="participants">
                            <table>
                              <thead>
                                <tr>
                                  <th style={{ textAlign: "right" }}>#</th>
                                  <th style={{ textAlign: "left" }}>
                                    Ticket Number
                                  </th>
                                  <th style={{ textAlign: "left" }}>
                                    Participants' Name(s)
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {choosenRaffle.participants.map((item, key) => {
                                  return (
                                    <tr key={key}>
                                      <td style={{ textAlign: "right" }}>
                                        {key + 1}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {item?.ticketNumber}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {item?.name}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        )}
                      {/* {activeTab === "winners" &&
                        window.innerWidth > 720 &&
                        !choosenRaffle.extras.seriesRaffle.isMultipleRaffles &&
                        (choosenRaffle.extras.seriesRaffle.recurrencePattern === "Hourly" ?
                          choosenRaffle.extras.seriesRaffle.hourlyPattern :
                          choosenRaffle.extras.seriesRaffle.recurrencePattern === "Daily" ?
                            choosenRaffle.extras.seriesRaffle.dailyPattern.days :
                            choosenRaffle.extras.seriesRaffle.weeklyPattern.days
                        ).map(
                          (drawInstance, index) => {
                            return (
                              <div className="participants">
                                <table>
                                  <thead>
                                    <tr>
                                      <th colSpan={4}>
                                        {"Draw Instance " +
                                          (index + 1) +
                                          " - " +
                                          new Date(drawInstance).toLocaleString(
                                            "en-US",
                                            {
                                              month: "2-digit",
                                              day: "2-digit",
                                              year: "numeric",
                                              hour: "2-digit",
                                              minute: "2-digit",
                                            }
                                          )}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ textAlign: "right" }}>#</th>
                                      <th style={{ textAlign: "left" }}>
                                        Ticket Number
                                      </th>
                                      <th style={{ textAlign: "left" }}>
                                        Winners' Name(s)
                                      </th>
                                      <th
                                        style={{
                                          textAlign: "right",
                                          width: "20%",
                                        }}
                                      >
                                        Prize
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {choosenRaffle.winners
                                      .filter(
                                        (winObj) =>
                                          winObj.drawInstance === index
                                      )
                                      .map((item, key) => {
                                        console.log(
                                          "choosenRaffle.currency[item?.drawInstance]",
                                          choosenRaffle.currency
                                        );
                                        return (
                                          <tr>
                                            <td style={{ textAlign: "right" }}>
                                              {key + 1}
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {item?.ticketNumber}
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {item?.name}
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {`${(choosenRaffle.currency[item?.winPosition])} ${item?.prize}`}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            );
                          }
                        )}
                      {activeTab === "winners" &&
                        window.innerWidth > 720 &&
                        choosenRaffle.extras.seriesRaffle.isMultipleRaffles && (
                          <div className="participants">
                            <table>
                              <thead>
                                <tr>
                                  <th style={{ textAlign: "right" }}>#</th>
                                  <th style={{ textAlign: "left" }}>
                                    Ticket Number
                                  </th>
                                  <th style={{ textAlign: "left" }}>
                                    Winners' Name(s)
                                  </th>
                                  <th style={{ textAlign: "right" }}>Prize</th>
                                </tr>
                              </thead>
                              <tbody>
                                {choosenRaffle.winners.map((item, key) => {
                                  return (
                                    <tr key={key}>
                                      <td style={{ textAlign: "right" }}>
                                        {key + 1}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {item?.ticketNumber}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {item?.name}
                                      </td>
                                      <td style={{ textAlign: "right" }}>
                                        {`${(choosenRaffle.currency[item?.winPosition])} ${item?.prize}`}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        )} */}
                    </div>
                  </div>

                  {/* Column 2 - for logo and description */}
                  <div className="logo-and-description">
                    <div
                      className="logo-box"
                      onContextMenu={(e) => e.preventDefault()}
                    >
                      {isImage ? (
                        <img
                          src={
                            choosenRaffle.image
                              ? choosenRaffle.image
                              : img !== null
                                ? img
                                : require("../../../../assets/img/logo.png")
                          }
                          onError={(e) => {
                            setIsImage(false);
                          }}
                          alt="raffle pic"
                          onClick={() => openImageViewer()}
                        />
                      ) : (
                        <video
                          src={img !== null ? img : choosenRaffle.image}
                          controls
                          loop
                          controlslist="nodownload noplaybackrate"
                        ></video>
                      )}
                    </div>
                    {window.innerWidth > 720 && (
                      <div className="description">
                        <div
                          className={
                            showingDesc
                              ? "flip-box-desc pinkscroll"
                              : "flip-box-tnc pinkscroll"
                          }
                        >
                          <label className="title">
                            {showingDesc ? "Description" : "Terms & Conditions"}
                          </label>
                          <div className="content">
                            {showingDesc ? (
                              choosenRaffle.longDescription
                            ) : (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    choosenRaffle.extras.disclosure.toString(
                                      "html"
                                    ),
                                }}
                              ></div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* footer */}
                {!(type === "DRAW-VIEW" || window.innerWidth < 720) && (
                  <div className="footer-buttons">
                    {
                      // disable join raffle if contest is completed, host opens, and already joined
                      (sessionStorage.getItem("User") === null
                        ? true
                        : choosenRaffle.createdBy !==
                        JSON.parse(sessionStorage.getItem("User")).email) &&
                        new Date(choosenRaffle.participationCloses) > new Date()
                        ? type === "VIEW"
                          ? null
                          : !(userType === "host") &&
                          asCatalogue && (
                            <div
                              className="joinCheckout_button"
                              onClick={joinButtonClicked}
                            >
                              {choosenRaffle.fee !== 0 ? "CHECKOUT" : "JOIN"}
                            </div>
                          )
                        : !(userType === "host") &&
                        asCatalogue && (
                          <div
                            className="joinCheckout_button disable"
                            onClick={() => {
                              if (
                                new Date() >
                                new Date(choosenRaffle.participationCloses)
                              ) {
                                alert.removeAll();
                                alert.show("Raffle closed");
                              } else if (
                                choosenRaffle.createdBy ===
                                JSON.parse(sessionStorage.getItem("User"))
                                  .email
                              ) {
                                alert.removeAll();
                                alert.show(
                                  "Host can't register for a raffle"
                                );
                              } else if (
                                choosenRaffle.maxNumParticipants -
                                choosenRaffle.participants.length ===
                                0
                              ) {
                                alert.removeAll();
                                alert.show("Maximum no. participants exceed");
                              } else if (participant.email) {
                                alert.removeAll();
                                alert.show("Already joined");
                              }
                            }}
                          >
                            {choosenRaffle.fee !== 0 ? "CHECKOUT" : "JOIN"}
                          </div>
                        )
                    }

                    {type !== "GUEST-VIEW" &&
                      <button
                        className="close_button"
                        disabled={toggleAnimation}
                        onClick={onClose}
                      >
                        CLOSE
                      </button>
                    }

                    {window.innerWidth > 720 && (
                      <button
                        className="close_button"
                        onClick={() => {
                          console.log("T and c is clicked....");
                          setShowingDesc(!showingDesc);
                        }}
                      >
                        {showingDesc ? "TERMS & CONDITIONS" : "DESCRIPTION"}
                      </button>
                    )}
                    {
                      // choosenRaffle.winners.length === 0 &&
                      //   new Date(choosenRaffle.participationCloses) > new Date() &&
                      //   choosenRaffle.maxNumParticipants -
                      //   choosenRaffle.participants.length >
                      //   0
                      choosenRaffle.status !== "CLOSE" &&
                        choosenRaffle.status !== "DRAWN" &&
                        choosenRaffle.status !== "COMPLETE" ? (
                        <button
                          onClick={() =>
                            toggleShareModel(
                              web_url + "joinRaffle?id=" + choosenRaffle._id
                            )
                          }
                          className="share_button"
                        >
                          SHARE
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            alert.removeAll();
                            alert.show("Raffle closed");
                          }}
                          className="share_button disable_share"
                        >
                          SHARE
                        </button>
                      )
                    }
                  </div>
                )}
                {window.innerWidth < 720 && <div className="hosted-sub-title">
                  {choosenRaffle.rafflerType} Raffle by{" "}
                  {raffleUser !== null || raffleUser !== undefined ? raffleUser?.name : ""}
                </div>}
              </div>
            </div>
            {window.innerWidth < 720 && (
              <div className="addon-tabs-container">
                <button
                  className="drag-btn"
                  onClick={() => setOpenTAndC(!openTAndC)}
                >
                  {openTAndC ? <FaAngleDoubleUp /> : <FaAngleDoubleDown />}
                </button>
                {openTAndC && (
                  <div className="rest-all-tabs" style={{ backgroundColor: choosenRaffle.styles.background }}>
                    <div className="draw-participant-winners">
                      {openTAndC && (
                        <div className="addon-tabs">
                          <button className={
                            activeTab2 === "Description" ? "activeTab2" : ""
                          } onClick={() => setActiveTab2("Description")}>
                            Description
                          </button>
                          <button className={
                            activeTab2 === "Terms and Condition" ? "activeTab2" : ""
                          } onClick={() => setActiveTab2("Terms and Condition")}>
                            T & C
                          </button>
                          {window.innerWidth < 720 &&
                            !choosenRaffle.extras.seriesRaffle
                              .isMultipleRaffles && (
                              <button
                                onClick={() => setActiveTab2("draws")}
                                className={
                                  activeTab2 === "draws" ? "activeTab2" : ""
                                }
                              >
                                Draw(s)
                              </button>
                            )}
                          {userType === "host" && window.innerWidth < 720 && (
                            <button
                              onClick={() => setActiveTab2("participants")}
                              className={
                                activeTab2 === "participants" ? "activeTab2" : ""
                              }
                            >
                              Participant(s)
                            </button>
                          )}
                          {/* {window.innerWidth < 720 && (
                            <button
                              onClick={() => setActiveTab2("winners")}
                              className={
                                activeTab2 === "winners" ? "activeTab2" : ""
                              }
                            >
                              Winner(s)
                            </button>
                          )} */}
                        </div>
                      )}
                      <div className="addon-tabs-content">
                        {activeTab2 === "Description" && <p className="tandc-content">
                          {activeTab ? choosenRaffle.longDescription : ""}
                        </p>}
                        {activeTab2 === "Terms and Condition" && <p className="tandc-content">
                          {drawTab ? choosenRaffle.disclosure : ""}
                        </p>}
                        {activeTab2 === "draws" && window.innerWidth < 720 && (
                          // <div className="draws-list">
                          //   <table>
                          //     <thead>
                          //       <tr>
                          //         <th style={{ textAlign: "left" }}>#</th>
                          //         <th style={{ textAlign: "left" }}>
                          //           Draw Time
                          //         </th>
                          //         <th style={{ textAlign: "left" }}>Status</th>
                          //       </tr>
                          //     </thead>
                          //     <tbody>
                          //       {drawOccurances.map((date, j) => {
                          //         return (
                          //           <tr key={j}>
                          //             <td style={{ textAlign: "left" }}>
                          //               {j + 1}
                          //             </td>
                          //             <td style={{ textAlign: "left" }}>
                          //               {new Date(date).toLocaleString(
                          //                 "en-US",
                          //                 {
                          //                   month: "2-digit",
                          //                   day: "2-digit",
                          //                   year: "numeric",
                          //                   hour: "2-digit",
                          //                   minute: "2-digit",
                          //                 }
                          //               )}
                          //             </td>
                          //             <td style={{ textAlign: "left" }}>
                          //               {new Date(date) > new Date()
                          //                 ? "Yet to be drawn"
                          //                 : choosenRaffle.winners.findIndex(
                          //                   (obj) => obj.drawInstance == j
                          //                 ) === -1
                          //                   ? "Cancelled"
                          //                   : "Drawn"}
                          //             </td>
                          //           </tr>
                          //         );
                          //       })}
                          //     </tbody>
                          //   </table>
                          // </div>
                          <DrawsTabContent
                            choosenRaffle={choosenRaffle}
                            onProceed={onProceed}
                            drawMode={drawMode}
                            winnersTab={winnersTab}
                            toggleAnimation={toggleAnimation}
                            timeDelay={timeDelay}
                            isPlaying={isPlaying}
                            activeDrumroll={activeDrumroll}
                          />
                        )}
                        {activeTab2 === "participants" &&
                          window.innerWidth < 720 &&
                          (
                            <div className="participants">
                              <table>
                                <thead>
                                  <tr>
                                    <th style={{ textAlign: "right" }}>#</th>
                                    <th style={{ textAlign: "left" }}>
                                      Ticket Number
                                    </th>
                                    <th style={{ textAlign: "left" }}>
                                      Participants' Name(s)
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {choosenRaffle.participants.map((item, key) => {
                                    return (
                                      <tr key={key}>
                                        <td style={{ textAlign: "right" }}>
                                          {key + 1}
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                          {item?.ticketNumber}
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                          {item?.name}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          )}
                        {/* {activeTab2 === "winners" &&
                          window.innerWidth < 720 &&
                          !choosenRaffle.extras.seriesRaffle
                            .isMultipleRaffles &&
                          (choosenRaffle.extras.seriesRaffle.recurrencePattern === "Hourly" ?
                            choosenRaffle.extras.seriesRaffle.hourlyPattern :
                            choosenRaffle.extras.seriesRaffle.recurrencePattern === "Daily" ?
                              choosenRaffle.extras.seriesRaffle.dailyPattern.days :
                              choosenRaffle.extras.seriesRaffle.weeklyPattern.days
                          ).map(
                            (drawInstance, index) => {
                              return (
                                <div className="participants">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th colSpan={4}>
                                          {"Draw Instance " +
                                            (index + 1) +
                                            " - " +
                                            new Date(
                                              drawInstance
                                            ).toLocaleString("en-US", {
                                              month: "2-digit",
                                              day: "2-digit",
                                              year: "numeric",
                                              hour: "2-digit",
                                              minute: "2-digit",
                                            })}
                                        </th>
                                      </tr>
                                      <tr>
                                        <th style={{ textAlign: "right" }}>
                                          #
                                        </th>
                                        <th style={{ textAlign: "left" }}>
                                          Ticket Number
                                        </th>
                                        <th style={{ textAlign: "left" }}>
                                          Winners' Name(s)
                                        </th>
                                        <th
                                          style={{
                                            textAlign: "right",
                                            width: "20%",
                                          }}
                                        >
                                          Prize
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {choosenRaffle.winners
                                        .filter(
                                          (winObj) =>
                                            winObj.drawInstance === index
                                        )
                                        .map((item, key) => {
                                          console.log(
                                            "choosenRaffle.currency[item?.drawInstance]",
                                            choosenRaffle.currency
                                          );
                                          return (
                                            <tr>
                                              <td
                                                style={{ textAlign: "right" }}
                                              >
                                                {key + 1}
                                              </td>
                                              <td style={{ textAlign: "left" }}>
                                                {item?.ticketNumber}
                                              </td>
                                              <td style={{ textAlign: "left" }}>
                                                {item?.name}
                                              </td>
                                              <td
                                                style={{ textAlign: "right" }}
                                              >
                                                {choosenRaffle.currency[
                                                  item?.winPosition
                                                ] +
                                                  " " +
                                                  item?.prize}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                              );
                            }
                          )} */}
                        {/* {activeTab2 === "winners" &&
                          window.innerWidth < 720 &&
                          choosenRaffle.extras.seriesRaffle
                            .isMultipleRaffles && (
                            <div className="participants">
                              <table>
                                <thead>
                                  <tr>
                                    <th style={{ textAlign: "right" }}>#</th>
                                    <th style={{ textAlign: "left" }}>
                                      Ticket Number
                                    </th>
                                    <th style={{ textAlign: "left" }}>
                                      Winners' Name(s)
                                    </th>
                                    <th style={{ textAlign: "right" }}>
                                      Prize
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {choosenRaffle.winners.map((item, key) => {
                                    return (
                                      <tr key={key}>
                                        <td style={{ textAlign: "right" }}>
                                          {key + 1}
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                          {item?.ticketNumber}
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                          {item?.name}
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                          {choosenRaffle.currency[
                                            item?.drawInstance
                                          ] +
                                            " " +
                                            item?.prize}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          )} */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        ) : null}
        {/* Form for submiting to cashfree */}
        {csfForm !== null ? (
          <form
            ref={csfRef}
            method="post"
            action="https://test.cashfree.com/billpay/checkout/post/submit"
          >
            <input type="hidden" name="appId" value={csfForm.appId} />
            <input type="hidden" name="orderId" value={csfForm.orderId} />
            <input
              type="hidden"
              name="orderAmount"
              value={csfForm.orderAmount}
            />
            <input
              type="hidden"
              name="orderCurrency"
              value={csfForm.orderCurrency}
            />
            <input type="hidden" name="orderNote" value={csfForm.orderNote} />
            <input
              type="hidden"
              name="customerName"
              value={csfForm.customerName}
            />
            <input
              type="hidden"
              name="customerPhone"
              value={csfForm.customerPhone}
            />
            <input
              type="hidden"
              name="customerEmail"
              value={csfForm.customerEmail}
            />
            <input type="hidden" name="returnUrl" value={csfForm.returnUrl} />
            <input type="hidden" name="signature" value={csfForm.signature} />
          </form>
        ) : null}
      </div>
    </div>
  );
};
