import React, { useState, useRef, useEffect, Fragment } from "react";
import "./TicketViewModel.scss";
import { useDispatch } from "react-redux";
import { togggleLoad } from "../../../store/actions/loadAction";
import { JoinRaffle } from "../../../store/actions/raffleAction";
import {
  joinRaffle,
  getCheckSumHost,
  csfReturnUrl,
} from "../../../config/endpoints";
import { request } from "../../../helper/request";
import { useAlert } from "react-alert";
import { getUserByEmail } from "../../../config/endpoints";
import AddUserToRaffle from "../../../helper/addUserToRaffle";
import { FaAngleDoubleDown, FaAngleDoubleUp, FaShareAlt, FaTimes, FaSignInAlt } from "react-icons/fa";
import { GrDocumentText } from "react-icons/gr";
import { web_url } from "../../../config/endpoints";
import RaffleTicketPlain from "../../../assets/svg_components/raffle_ticket_plain";
import firebase from "../../../helper/firebase";
import ImageViewer from "react-simple-image-viewer";
// html parser
import parse from "html-react-parser";
import DrawsTabContent from "../drawerPages/raffleTicketViewComponents/drawTabContent";

export default ({
  userType,
  asParticipant,
  choosenRaffle,
  choosenRafflePrizes,
  onClose,
  type,
  toggleShareModel,
  guestInfo,
}) => {
  const [csfForm, setCsfForm] = useState(null);
  const csfRef = useRef();
  const dispatch = useDispatch();
  const alert = useAlert();
  const [joined, setJoined] = useState(false);
  const [raffleUser, setRaffleUser] = useState(null);

  const [isImage, setIsImage] = useState(true);
  const [participant, setParticipant] = useState({});
  const [drawOccurances, setDrawOccurances] = useState([]);
  const [img, setImg] = React.useState(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("details");
  const [activeTab2, setActiveTab2] = useState("Description");
  const [drawTab, setDrawTab] = useState(false);
  //get raffle creater name
  useEffect(() => {
    if (Object.keys(choosenRaffle).length > 0) {
      if (choosenRaffle.uuid) {
        firebase
          .storage()
          .ref()
          .child("raffle_pics/" + choosenRaffle.uuid)
          .getDownloadURL()
          .then((url) => {
            setImg(url);
          })
          .catch((e) => { });
        console.log("urul");
      }
      request("POST_AUTH", getUserByEmail, { email: choosenRaffle.createdBy })
        .then((res) => {
          setRaffleUser(res.payload[0]);
        })
        .catch((e) => {
          alert.removeAll();
          alert.show(e.message);
        });
      let usr = JSON.parse(sessionStorage.getItem("User"));
      if (choosenRaffle.participants.length > 0) {
        const participant = choosenRaffle.participants.find(
          (p) => p.email === usr.email
        );
        setParticipant(participant);
      }

      if (choosenRaffle.extras.seriesRaffle.recurrencePattern === "Hourly") {
        setDrawOccurances([...choosenRaffle.extras.seriesRaffle.hourlyPattern]);
      } else if (
        choosenRaffle.extras.seriesRaffle.recurrencePattern === "Daily"
      ) {
        setDrawOccurances([
          ...choosenRaffle.extras.seriesRaffle.dailyPattern.days,
        ]);
      } else {
        setDrawOccurances([]);
      }
    }
  }, [choosenRaffle]);

  //to check if user included in raffle
  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("User"));
    if (user !== null) {
      choosenRaffle.participants.includes(user.email)
        ? setJoined(true)
        : setJoined(false);
    }
  }, []);

  const joinButtonClicked = () => {
    if (choosenRaffle.fee != 0) {
      const user = JSON.parse(sessionStorage.getItem("User"));
      alert(JSON.stringify(user));
      //Promotional raffle its free

      // Cash Free
      const postData = {
        appId: "16064c2a54ac3bfd1710857ad46061",
        // "orderId": orderId,
        orderAmount: choosenRaffle.fee,
        orderCurrency: choosenRaffle.currency,
        orderNote: "Raffle ticket payment",
        customerName: user.name,
        customerPhone: user.mobile,
        customerEmail: user.email,
        returnUrl: csfReturnUrl,
        //rafflerra attributes
        raffleId: choosenRaffle["_id"],
      };
      request("POST_AUTH", getCheckSumHost, postData)
        .then((res) => {
          setCsfForm({ ...postData, signature: res.token });
          csfRef.current.submit();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      const user = JSON.parse(sessionStorage.getItem("User"));
      if (user !== null) {
        dispatch(togggleLoad());
        AddUserToRaffle(choosenRaffle._id, user.email)
          .then((res) => {
            dispatch(JoinRaffle(choosenRaffle._id, user.email));
            alert.removeAll();
            alert.show(res.msg);
            dispatch(togggleLoad());
            onClose();
          })
          .catch((e) => {
            alert.removeAll();
            alert.show(e);
            dispatch(togggleLoad());
          });
      } else {
        onClose();
        window.open(web_url + "joinRaffle?id=" + choosenRaffle._id, "_blank");
      }
    }
  };

  const openImageViewer = () => {
    if (img) {
      setIsViewerOpen(true);
    }
  };

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  // const [termsAndConditionsWithDescription, setTermsAndConditionsDescription] = useState(false);
  const [openTAndC, setOpenTAndC] = useState(false);

  return (
    <div className="ticket-view">
      <div style={{ position: "fixed", zIndex: "11000" }}>
        {isViewerOpen && (
          <ImageViewer
            src={[img]}
            currentIndex={0}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </div>
      {/* main view */}
      {Object.keys(choosenRaffle).length > 0 ? (
        <>
          <div className="raffle_view">
            <RaffleTicketPlain
              backgroundColor={choosenRaffle.styles.background}
            />
            {/* Content */}
            <div>
              {/* title */}
              <div className="hosted-title">
                {choosenRaffle.title.toUpperCase()}
                <div className="short-description">
                  {window.innerWidth < 720 && <div className="emp-cont"></div>}
                  <p className="s-des">
                    {choosenRaffle.shortDescription}
                  </p>
                  {window.innerWidth < 720 && <div className="action-icons">
                  </div>}
                </div>
                {window.innerWidth > 720 && <div className="hosted-sub-title">
                  {choosenRaffle.rafflerType} Raffle by{" "}
                  {raffleUser !== null ? raffleUser.name : ""}
                </div>}
              </div>
              {/* body */}
              <div className="hosted-body">
                {/*Column 1 - for tabs box*/}
                <div className="raffle-view-tabs">
                  <div className="tabs-header">
                    <button
                      onClick={() => setActiveTab("details")}
                      className={activeTab === "details" ? "activeTab" : ""}
                    >
                      Details
                    </button>
                    <button
                      onClick={() => setActiveTab("prizes")}
                      className={activeTab === "prizes" ? "activeTab" : ""}
                    >
                      Prizes
                    </button>
                    {window.innerWidth > 720 && (
                      <>
                        {!choosenRaffle.extras.seriesRaffle.isMultipleRaffles && (
                          <button
                            onClick={() => setActiveTab("draws")}
                            className={activeTab === "draws" ? "activeTab" : ""}
                          >
                            Draws
                          </button>
                        )}
                        <button
                          onClick={() => setActiveTab("participants")}
                          className={
                            activeTab === "participants" ? "activeTab" : ""
                          }
                        >
                          Participants
                        </button>
                        <button
                          onClick={() => setActiveTab("winners")}
                          className={activeTab === "winners" ? "activeTab" : ""}
                        >
                          Winners
                        </button>
                      </>
                    )}
                  </div>
                  <div className="tabs-content">
                    {activeTab === "details" && (
                      <div className="details-content">
                        <div className="detail">
                          <label className="lable">Entry Fee: </label>
                          <label className="value">
                            {choosenRaffle.fee === 0
                              ? "Free"
                              : choosenRaffle.fee + choosenRaffle.currency[0]}
                          </label>
                        </div>
                        <div className="detail">
                          <label className="lable">Participants: </label>
                          <label className="value">
                            {choosenRaffle.participants.length} out of{" "}
                            {choosenRaffle.maxNumParticipants}
                          </label>
                        </div>
                        <div className="detail">
                          <label className="lable">Type: </label>
                          <label className="value">{choosenRaffle.type}</label>
                        </div>
                        <div className="detail">
                          <label className="lable">Recurrence: </label>
                          <label className="value">
                            {choosenRaffle.extras.seriesRaffle.recurrencePattern}
                          </label>
                        </div>
                        <div className="detail">
                          <label className="lable">Open at: </label>
                          <label className="value">
                            {new Date(
                              choosenRaffle.participationOpens
                            ).toLocaleString("en-US")}
                          </label>
                        </div>
                        <div className="detail">
                          <label className="lable">Close at: </label>
                          <label className="value">
                            {new Date(
                              choosenRaffle.participationCloses
                            ).toLocaleString("en-US")}
                          </label>
                        </div>
                        {choosenRaffle.status === "DRAWN" ? (
                          <div className="detail">
                            <label className="lable">DrawnAt: </label>
                            <label className="value">
                              {new Date(choosenRaffle.drawnAt).toLocaleString(
                                "en-US"
                              )}
                            </label>
                          </div>
                        ) : (
                          ""
                        )}
                        {choosenRaffle.type !== "Instant" &&
                          choosenRaffle.extras.seriesRaffle.isMultipleRaffles ? (
                          <div className="detail">
                            <label className="lable">
                              {choosenRaffle.status === "DRAWN" ||
                                choosenRaffle.winners.length > 0
                                ? "Drawn:"
                                : "Draw at:"}
                            </label>
                            <label className="last-value">
                              {choosenRaffle.status === "DRAWN" ||
                                choosenRaffle.winners.length > 0
                                ? new Date(choosenRaffle.drawnAt).toLocaleString(
                                  "en-US"
                                )
                                : new Date(choosenRaffle.drawAt).toLocaleString(
                                  "en-US"
                                )}
                            </label>
                          </div>
                        ) : null}
                        {userType === "host" && (
                          <div className="detail">
                            <label className="lable">
                              Maximum Participants:{" "}
                            </label>
                            <label className="value">
                              {choosenRaffle.maxNumParticipants}
                            </label>
                          </div>
                        )}
                        {userType === "host" &&
                          choosenRaffle.autoActions.allowAutoActions && (
                            <div className="detail">
                              <label className="lable">
                                Minimum Participants:{" "}
                              </label>
                              <label className="value">
                                {choosenRaffle.minNumParticipants}
                              </label>
                            </div>
                          )}
                        {userType === "host" &&
                          choosenRaffle.autoActions.allowAutoActions && (
                            <div className="detail">
                              <label className="lable" style={{ width: "170px" }}>
                                Auto Action(s):{" "}
                              </label>
                              <label className="value">
                                If minimum participant(s){" "}
                                {choosenRaffle.minNumParticipants} is not met
                                by{" "}
                                {choosenRaffle.autoActions.checkTimePercent}%
                                of raffle period,
                                {choosenRaffle.autoActions.autoCancel
                                  ? " Cancel"
                                  : ` Allow ${choosenRaffle.autoActions.maxExtensions} extension(s) by ${choosenRaffle.autoActions.extDurationPercent}% of raffle period. After extension(s) ${choosenRaffle.autoActions.autoActionAfterMaxExtns} the raffle.`}
                              </label>
                            </div>
                          )}
                      </div>
                    )}
                    {activeTab === "prizes" && (
                      <div className="prizes-table">
                        <table>
                          <thead>
                            <tr>
                              <th style={{ textAlign: "right" }}>#</th>
                              <th style={{ textAlign: "left" }}>Prize Type</th>
                              <th style={{ textAlign: "right" }}>Prize</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              choosenRafflePrizes.map((prizeObj, index) => {
                                return (
                                  <Fragment key={index}>
                                    <tr>
                                      <td colSpan={3} className="drawnum">
                                        Draw #{index + 1}
                                      </td>
                                    </tr>
                                    {prizeObj.numPrizes.map((p, j) => {
                                    return (
                                      <tr key={j}>
                                        <td style={{ textAlign: "left" }}>
                                          {j + 1}
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                          {prizeObj.prizeType[j]
                                          }
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                          {prizeObj.currency[j] + " " + p}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                  </Fragment>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    )}
                    {activeTab === "draws" && (
                      // <div className="draws-list">
                      //   <table>
                      //     <thead>
                      //       <tr>
                      //         <th style={{ textAlign: "right" }}>#</th>
                      //         <th style={{ textAlign: "left" }}>Draw Time</th>
                      //         <th style={{ textAlign: "left" }}>Status</th>
                      //       </tr>
                      //     </thead>
                      //     <tbody>
                      //       {drawOccurances.map((date, j) => {
                      //         return (
                      //           <tr key={j}>
                      //             <td style={{ textAlign: "right" }}>{j + 1}</td>
                      //             <td style={{ textAlign: "left" }}>
                      //               {new Date(date).toLocaleString("en-US", {
                      //                 month: "2-digit",
                      //                 day: "2-digit",
                      //                 year: "numeric",
                      //                 hour: "2-digit",
                      //                 minute: "2-digit",
                      //               })}
                      //             </td>
                      //             <td style={{ textAlign: "left" }}>
                      //               {new Date(date) > new Date()
                      //                 ? "Yet to be drawn"
                      //                 : choosenRaffle.winners.findIndex(
                      //                   (obj) => obj.drawInstance == j
                      //                 ) === -1
                      //                   ? "Cancelled"
                      //                   : "Drawn"}
                      //             </td>
                      //           </tr>
                      //         );
                      //       })}
                      //     </tbody>
                      //   </table>
                      // </div>
                      <DrawsTabContent
                        choosenRaffle={choosenRaffle}
                      />
                    )}
                    {activeTab === "participants" && (
                      <div className="participants">
                        <table>
                          <thead>
                            <tr>
                              <th style={{ textAlign: "right" }}>#</th>
                              <th style={{ textAlign: "left" }}>Ticket Number</th>
                              <th style={{ textAlign: "left" }}>
                                Participants' Name(s)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {choosenRaffle.participants.map((item, key) => {
                              return (
                                <tr key={key}>
                                  <td style={{ textAlign: "right" }}>
                                    {key + 1}
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {item?.ticketNumber}
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {item?.name}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {activeTab === "winners" &&
                      !choosenRaffle.extras.seriesRaffle.isMultipleRaffles &&
                      // choosenRaffle.winners.filter(winObj => {
                      //   choosenRaffle.extras.seriesRaffle.hourlyPattern.some((h, i) => winObj.drawInstance === i)
                      // }).length <= 0 &&
                      (choosenRaffle.extras.seriesRaffle.recurrencePattern === "Hourly" ?
                          choosenRaffle.extras.seriesRaffle.hourlyPattern :
                          choosenRaffle.extras.seriesRaffle.recurrencePattern === "Daily" ?
                            choosenRaffle.extras.seriesRaffle.dailyPattern.days :
                            choosenRaffle.extras.seriesRaffle.weeklyPattern.days
                        ).map(
                        (drawInstance, index) => {
                          return (
                            <div className="participants">
                              <table>
                                <thead>
                                  <tr>
                                    <th colSpan={4}>
                                      {"Draw Instance " +
                                        (index + 1) +
                                        " - " +
                                        new Date(drawInstance).toLocaleString(
                                          "en-US",
                                          {
                                            month: "2-digit",
                                            day: "2-digit",
                                            year: "numeric",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                          }
                                        )}
                                    </th>
                                  </tr>
                                  <tr>
                                    <th style={{ textAlign: "right" }}>#</th>
                                    <th style={{ textAlign: "left" }}>
                                      Ticket Number
                                    </th>
                                    <th style={{ textAlign: "left" }}>
                                      Winners' Name(s)
                                    </th>
                                    <th style={{ textAlign: "right" }}>Prize</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {choosenRaffle.winners
                                    .filter(
                                      (winObj) => winObj.drawInstance === index
                                    )
                                    .map((item, key) => {
                                      return (
                                        <tr>
                                          <td style={{ textAlign: "right" }}>
                                            {key + 1}
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {item?.ticketNumber}
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {item?.name}
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "right",
                                              width: "100%",
                                            }}
                                          >
                                            {choosenRaffle.currency[
                                              item?.drawInstance
                                            ] +
                                              " " +
                                              item?.prize}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          );
                        }
                      )}
                    {activeTab === "winners" &&
                      choosenRaffle.extras.seriesRaffle.isMultipleRaffles && (
                        <div className="participants">
                          <table>
                            <thead>
                              <tr>
                                <th style={{ textAlign: "right" }}>#</th>
                                <th style={{ textAlign: "left" }}>
                                  Ticket Number
                                </th>
                                <th style={{ textAlign: "left" }}>
                                  Winners' Name(s)
                                </th>
                                <th style={{ textAlign: "right" }}>Prize</th>
                              </tr>
                            </thead>
                            <tbody>
                              {choosenRaffle.winners.map((item, key) => {
                                return (
                                  <tr key={key}>
                                    <td style={{ textAlign: "right" }}>
                                      {key + 1}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {item?.ticketNumber}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {item?.name}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "right",
                                        width: "100%",
                                      }}
                                    >
                                      {choosenRaffle.currency[
                                        item?.drawInstance
                                      ] +
                                        " " +
                                        item?.prize}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      )}
                  </div>
                </div>

                {/* Column 2 - for logo and description */}
                <div className="logo-and-description">
                  <div className="logo-box">
                    {isImage ? (
                      <img
                        src={
                          choosenRaffle.image
                            ? choosenRaffle.image
                            : img !== null
                              ? img
                              : require("../../../assets/img/logo.png")
                        }
                        onError={(e) => {
                          setIsImage(false);
                        }}
                        alt="raffle pic"
                        onClick={() => openImageViewer()}
                      />
                    ) : (
                      <video
                        src={img !== null ? img : choosenRaffle.image}
                        controls
                      ></video>
                    )}
                  </div>
                  {window.innerWidth > 720 && (
                    <div className="description">
                      <div className={"flip-box-desc pinkscroll"}>
                        <label className="title">Description</label>
                        <div className="content">
                          {choosenRaffle.longDescription}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* footer */}
              {window.innerWidth < 720 && <div className="hosted-sub-title">
                {choosenRaffle.rafflerType} Raffle by{" "}
                {raffleUser !== null ? raffleUser.name : ""}
              </div>}
            </div>
          </div>
          {window.innerWidth < 720 &&
            <div className="addon-tabs-container">
              <button
                className="drag-btn"
                onClick={() => setOpenTAndC(!openTAndC)}
              >
                {openTAndC ? <FaAngleDoubleUp /> : <FaAngleDoubleDown />}
              </button>
              {openTAndC && (
                <div
                  className="rest-all-tabs"
                  style={{ backgroundColor: choosenRaffle.styles.background }}
                >
                  <div className="draw-participant-winners">
                    {openTAndC && (
                      <div className="addon-tabs">
                        <button
                          className={
                            activeTab2 === "Description" ? "activeTab2" : ""
                          }
                          onClick={() => setActiveTab2("Description")}
                        >
                          Description
                        </button>
                        <button
                          className={
                            activeTab2 === "Terms and Condition"
                              ? "activeTab2"
                              : ""
                          }
                          onClick={() => setActiveTab2("Terms and Condition")}
                        >
                          T & C
                        </button>
                        {window.innerWidth < 720 &&
                          !choosenRaffle.extras.seriesRaffle.isMultipleRaffles && (
                            <button
                              onClick={() => setActiveTab2("draws")}
                              className={
                                activeTab2 === "draws" ? "activeTab2" : ""
                              }
                            >
                              Draw(s)
                            </button>
                          )}
                        {userType === "host" && window.innerWidth < 720 && (
                          <button
                            onClick={() => setActiveTab2("participants")}
                            className={
                              activeTab2 === "participants"
                                ? "activeTab2"
                                : ""
                            }
                          >
                            Participant(s)
                          </button>
                        )}
                        {window.innerWidth < 720 && (
                          <button
                            onClick={() => setActiveTab2("winners")}
                            className={
                              activeTab2 === "winners" ? "activeTab2" : ""
                            }
                          >
                            Winner(s)
                          </button>
                        )}
                      </div>
                    )}
                    <div
                      className="addon-tabs-content"
                    >
                      {activeTab2 === "Description" && (
                        <p className="tandc-content">
                          {activeTab ? choosenRaffle.longDescription : ""}
                        </p>
                      )}
                      {activeTab2 === "Terms and Condition" && (
                        <p className="tandc-content">
                          {drawTab ? choosenRaffle.disclosure : ""}
                        </p>
                      )}
                      {activeTab2 === "draws" && window.innerWidth < 720 && (
                        // <div className="draws-list">
                        //   <table>
                        //     <thead>
                        //       <tr>
                        //         <th style={{ textAlign: "left" }}>#</th>
                        //         <th style={{ textAlign: "left" }}>Draw Time</th>
                        //         <th style={{ textAlign: "left" }}>Status</th>
                        //       </tr>
                        //     </thead>
                        //     <tbody>
                        //       {drawOccurances.map((date, j) => {
                        //         return (
                        //           <tr key={j}>
                        //             <td style={{ textAlign: "left" }}>{j + 1}</td>
                        //             <td style={{ textAlign: "left" }}>
                        //               {new Date(date).toLocaleString("en-US", {
                        //                 month: "2-digit",
                        //                 day: "2-digit",
                        //                 year: "numeric",
                        //                 hour: "2-digit",
                        //                 minute: "2-digit",
                        //               })}
                        //             </td>
                        //             <td style={{ textAlign: "left" }}>
                        //               {new Date(date) > new Date()
                        //                 ? "Yet to be drawn"
                        //                 : choosenRaffle.winners.findIndex(
                        //                   (obj) => obj.drawInstance == j
                        //                 ) === -1
                        //                   ? "Cancelled"
                        //                   : "Drawn"}
                        //             </td>
                        //           </tr>
                        //         );
                        //       })}
                        //     </tbody>
                        //   </table>
                        // </div>
                        <DrawsTabContent
                          choosenRaffle={choosenRaffle}
                        />
                      )}
                      {activeTab2 === "participants" && window.innerWidth < 720 && (
                        <div className="participants">
                          <table>
                            <thead>
                              <tr>
                                <th style={{ textAlign: "right" }}>#</th>
                                <th style={{ textAlign: "left" }}>Ticket Number</th>
                                <th style={{ textAlign: "left" }}>
                                  Participants' Name(s)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {choosenRaffle.participants.map((item, key) => {
                                return (
                                  <tr key={key}>
                                    <td style={{ textAlign: "right" }}>
                                      {key + 1}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {item?.ticketNumber}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {item?.name}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {activeTab2 === "winners" &&
                        window.innerWidth < 720 &&
                        !choosenRaffle.extras.seriesRaffle.isMultipleRaffles &&
                        // choosenRaffle.winners.filter(winObj => {
                        //   choosenRaffle.extras.seriesRaffle.hourlyPattern.some((h, i) => winObj.drawInstance === i)
                        // }).length <= 0 &&
                        (choosenRaffle.extras.seriesRaffle.recurrencePattern === "Hourly" ?
                          choosenRaffle.extras.seriesRaffle.hourlyPattern :
                          choosenRaffle.extras.seriesRaffle.recurrencePattern === "Daily" ?
                            choosenRaffle.extras.seriesRaffle.dailyPattern.days :
                            choosenRaffle.extras.seriesRaffle.weeklyPattern.days
                        ).map(
                          (drawInstance, index) => {
                            return (
                              <div className="participants">
                                <table>
                                  <thead>
                                    <tr>
                                      <th colSpan={4}>
                                        {"Draw Instance " +
                                          (index + 1) +
                                          " - " +
                                          new Date(drawInstance).toLocaleString(
                                            "en-US",
                                            {
                                              month: "2-digit",
                                              day: "2-digit",
                                              year: "numeric",
                                              hour: "2-digit",
                                              minute: "2-digit",
                                            }
                                          )}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ textAlign: "right" }}>#</th>
                                      <th style={{ textAlign: "left" }}>
                                        Ticket Number
                                      </th>
                                      <th style={{ textAlign: "left" }}>
                                        Winners' Name(s)
                                      </th>
                                      <th
                                        style={{
                                          textAlign: "right",
                                          width: "20%",
                                        }}
                                      >
                                        Prize
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {choosenRaffle.winners
                                      .filter(
                                        (winObj) => winObj.drawInstance === index
                                      )
                                      .map((item, key) => {
                                        console.log(
                                          "choosenRaffle.currency[item?.drawInstance]",
                                          choosenRaffle.currency
                                        );
                                        return (
                                          <tr>
                                            <td style={{ textAlign: "right" }}>
                                              {key + 1}
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {item?.ticketNumber}
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {item?.name}
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {choosenRaffle.currency[
                                                item?.winPosition
                                              ] +
                                                " " +
                                                item?.prize}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            );
                          }
                        )}
                      {activeTab2 === "winners" &&
                        window.innerWidth < 720 &&
                        choosenRaffle.extras.seriesRaffle.isMultipleRaffles && (
                          <div className="participants">
                            <table>
                              <thead>
                                <tr>
                                  <th style={{ textAlign: "right" }}>#</th>
                                  <th style={{ textAlign: "left" }}>Ticket Number</th>
                                  <th style={{ textAlign: "left" }}>
                                    Winners' Name(s)
                                  </th>
                                  <th style={{ textAlign: "right" }}>Prize</th>
                                </tr>
                              </thead>
                              <tbody>
                                {choosenRaffle.winners.map((item, key) => {
                                  return (
                                    <tr key={key}>
                                      <td style={{ textAlign: "right" }}>
                                        {key + 1}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {item?.ticketNumber}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {item?.name}
                                      </td>
                                      <td style={{ textAlign: "right" }}>
                                        {choosenRaffle.currency[item?.drawInstance] +
                                          " " +
                                          item?.prize}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          }
        </>
      ) : null}

      {/* {openTAndC && (
        <div>
        {!choosenRaffle.extras.seriesRaffle.isMultipleRaffles && (
          <button
            onClick={() => setActiveTab("draws")}
            className={activeTab === "draws" ? "activeTab" : ""}
          >
            Draws
          </button>
        )}
        <button
          onClick={() => setActiveTab("participants")}
          className={activeTab === "participants" ? "activeTab" : ""}
        >
          Participants
        </button>
        <button
          onClick={() => setActiveTab("winners")}
          className={activeTab === "winners" ? "activeTab" : ""}
        >
          Winners
        </button>
      </div>
      )} */}
    </div>
  );
};
