import React, { Fragment, useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import { getFormattedDateTime } from '../../../../helper/appService';
import { useAlert } from 'react-alert';
import './onDemand.scss'

const day = 24 * 60 * 60 * 1000;
const hour = 3600000;
const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric', // Use 24-hour format
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  }

const OnDemand = ({ 
    disablingTabs, 
    raffle, 
    choosenRaffle, 
    setRaffle, 
    setRaffleFields, 
    seriesEdit, 
    type, 
    selectedPrizes, 
    setSelectedPrizes, 
    applyToAllStatus }) => {
    const alert = useAlert();

    useEffect(() => {
        if (selectedPrizes) {
            let tempPrizes = [...selectedPrizes];
            if (raffle.extras.seriesRaffle.onDemandDraws > selectedPrizes.length) {
                [...new Array(raffle.extras.seriesRaffle.onDemandDraws - selectedPrizes.length || 0).keys()].forEach((num) => {
                    const prizeObj = {
                        drawInsId: selectedPrizes.length + num + 1,
                        seriesId: raffle.extras.seriesRaffle.seriesId,
                        prizeType:
                          applyToAllStatus && selectedPrizes.length > 0
                            ? selectedPrizes[0].prizeType
                            : ["Money", "Money", "Money"],
                        currency:
                          applyToAllStatus && selectedPrizes.length > 0
                            ? selectedPrizes[0].currency
                            : ["$", "$", "$"],
                        numPrizes:
                          applyToAllStatus && selectedPrizes.length > 0
                            ? selectedPrizes[0].numPrizes
                            : ["", "", ""],
                        prizesDescription:
                          applyToAllStatus && selectedPrizes.length > 0
                            ? selectedPrizes[0].prizesDescription
                            : ["", "", ""],
                        winners: [],
                    };
                    tempPrizes.push(prizeObj);
                })
            } else if (raffle.extras.seriesRaffle.onDemandDraws < selectedPrizes.length) {
                tempPrizes = selectedPrizes.filter(obj => obj.drawInsId <= raffle.extras.seriesRaffle.onDemandDraws);
            } else {
                // do nothing
            }
            setSelectedPrizes([...tempPrizes]);
        } 
    }, [raffle.extras.seriesRaffle.onDemandDraws])

    const onChangeOpensAt = (event) => {
        const dateTime = event;
        dateTime.setSeconds(0, 0);
        options.timeZone = raffle.extras.timeZone;
        const now = new Intl.DateTimeFormat('en-US', options).format(new Date());
        const selectedDttm = new Date(dateTime.toLocaleString('en-US') + ` ${now.split(" ")[3]}`);
        if (selectedDttm <= new Date(now)) {
          alert.removeAll();
          alert.show("Please select a valid time in the future.");
        } else {
          setRaffleFields(selectedDttm, "participationOpens");
          setRaffleFields(new Date(new Date(selectedDttm).getTime() + day), "participationCloses");
        }
    }

    const onChangeClosesAt = (event) => {
        const dateTime = event;
        dateTime.setSeconds(0, 0);
        options.timeZone = raffle.extras.timeZone;
        const now = new Intl.DateTimeFormat('en-US', options).format(new Date());
        const selectedDttm = new Date(dateTime.toLocaleString('en-US') + ` ${now.split(" ")[3]}`);
        if (selectedDttm <= new Date(now)) {
            alert.removeAll();
            alert.show("Please select a valid time in the future.");
        }
        else if (selectedDttm <= new Date(raffle.participationOpens).getTime()) {
            alert.removeAll();
            alert.show("Please select a valid time greater than start time.");
        } else {
            setRaffleFields(selectedDttm, "participationCloses");
        }
    }

    const onChangeNumOfDraws = (e) => {
        let num = parseInt(e.target.value);
        setRaffle((rff) => ({
            ...rff,
            extras: { ...rff.extras, seriesRaffle: {
                ...rff.extras.seriesRaffle, onDemandDraws: num
            }},
        }))
    }

    const onBlurNumOfDraws = (_e) => {
        let num = _e.target.value;
        if (isNaN(num) || num < 1) {
            alert.removeAll();
            alert.show(`Draws should be at least 1`);
            setRaffle((rff) => ({
                ...rff,
                extras: { ...rff.extras, seriesRaffle: {
                    ...rff.extras.seriesRaffle, onDemandDraws: 1
                }},
            }))
        }
    }

    return (
        <Fragment>
            <div className="open">
                <label>OPEN</label>
                <DatePicker
                    disabled={
                        type === "EDIT" &&
                        !raffle.extras.draft &&
                        (
                        raffle.status === "OPEN" ||
                        raffle.status === "CLOSE" ||
                        raffle.status === "CANCEL" ||
                        raffle.status === "COMPLETE" ||
                        raffle.status === "DRAWN"
                        ) ||
                        disablingTabs
                    }
                    dateFormat={"MM/dd/yyyy hh:mm aa"}
                    selected={new Date(getFormattedDateTime(new Date(raffle.participationOpens), raffle.extras.timeZone))}
                    value={getFormattedDateTime(new Date(raffle.participationOpens), raffle.extras.timeZone)}
                    minDate={new Date()}
                    fixedHeight
                    showTimeInput
                    timeIntervals={15}
                    timeCaption="Time"
                    timeFormat="hh:mm"
                    onChange={onChangeOpensAt}
                />
            </div>
            <div className="close">
                <label>CLOSE</label>
                <DatePicker
                disabled={
                    // seriesEdit &&
                    type === "EDIT" &&
                    !raffle.extras.draft &&
                    (
                    raffle.status === "CLOSE" ||
                    raffle.status === "CANCEL" ||
                    raffle.status === "COMPLETE" ||
                    raffle.status === "DRAWN"
                    ) ||
                    disablingTabs
                }
                selected={new Date(getFormattedDateTime(new Date(raffle.participationCloses)))}
                value={getFormattedDateTime(new Date(raffle.participationCloses))}
                dateFormat={"MM/dd/yyyy hh:mm aa"}
                minDate={new Date(new Date(raffle.participationOpens).getTime() + hour)}
                maxDate={new Date(new Date(raffle.participationOpens).getTime() + day)}
                fixedHeight
                showTimeInput
                timeIntervals={15}
                timeCaption="Time"
                timeFormat="hh:mm"
                onChange={onChangeClosesAt}
                />
            </div>
            <div className="lbl-input-field">
                <label>NO. OF DRAWS</label>
                <input
                    type="number"
                    disabled={
                        type === "EDIT" &&
                        !raffle.extras.draft &&
                        (
                        raffle.status === "OPEN" ||
                        raffle.status === "CLOSE" ||
                        raffle.status === "CANCEL" ||
                        raffle.status === "COMPLETE" ||
                        raffle.status === "DRAWN"
                        ) ||
                        disablingTabs
                    }
                    min={1}
                    max={100}
                    maxLength={3}
                    value={raffle.extras.seriesRaffle.onDemandDraws}
                    onChange={onChangeNumOfDraws}
                    onBlur={onBlurNumOfDraws}
                />
            </div>
        </Fragment>
    )
}

export default OnDemand;