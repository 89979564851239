import React, { Fragment, useEffect, useState } from 'react'
import './prizes.scss'
import currencies from '../../../assets/currencies';
import { app } from 'firebase';
import { useAlert } from 'react-alert';

const Prizes = ({
    raffle, // for raffle information
    selectedInstances, // array of dates
    selectedPrizes, 
    setSelectedPrizes,
    applyToAllStatus, 
    setApplyToAllStatus
}) => {
    const [prizesArray, setPrizesArray] = useState(selectedPrizes);
    const [activeTab, setActiveTab] = useState(0)
    const [winners, setWinners] = useState(3);
    const [applyToAll, setApplyToAll] = useState(applyToAllStatus);
    const alert = useAlert();


    useEffect(() => {
        let tempPrizes = [];
        if (selectedPrizes.length === 0) {
            if (raffle.type === "Series" && selectedInstances.length > 0) {
                [...new Array(selectedInstances.length || 0).keys()].forEach((num) => {
                    const prizeObj = {
                        drawInsId: num,
                        seriesId: raffle.extras.seriesRaffle.seriesId,
                        prizeType: ["Money", "Money", "Money"],
                        currency: ["$", "$", "$"],
                        numPrizes: ["", "", ""],
                        prizesDescription: ["", "", ""],
                        winners: [],
                    }
                    tempPrizes.push(prizeObj);
                })
            } else if (raffle.extras.seriesRaffle.recurrencePattern === "On Demand") {
                [...new Array(raffle.extras.seriesRaffle.onDemandDraws || 0).keys()].forEach((num) => {
                    const prizeObj = {
                        drawInsId: num,
                        seriesId: raffle.extras.seriesRaffle.seriesId,
                        prizeType: ["Money", "Money", "Money"],
                        currency: ["$", "$", "$"],
                        numPrizes: ["", "", ""],
                        prizesDescription: ["", "", ""],
                        winners: [],
                    }
                    tempPrizes.push(prizeObj);
                })
            } else {
                // single instance and single draw
                console.log("in else single draw");
                tempPrizes.push({
                    drawInsId: 1,
                    seriesId: raffle.extras.seriesRaffle.seriesId,
                    prizeType: ["Money", "Money", "Money"],
                    currency: ["$", "$", "$"],
                    numPrizes: ["", "", ""],
                    prizesDescription: ["", "", ""],
                    winners: [],
                });
            }
            setPrizesArray([...tempPrizes]);
            setSelectedPrizes([...tempPrizes]);
        } else {
            setPrizesArray([...selectedPrizes]);
        }
        
    }, [selectedPrizes])

    useEffect(() => {
        setApplyToAll(applyToAllStatus);
    }, [applyToAllStatus])

    const onChangeNumWinners = (e, prizeObjId) => {
        let num = parseInt(e.currentTarget.value);
        if (isNaN(num)) {
            num = 0;
            setWinners("");
        } else {
            setWinners(num);
        }
        const prizesArr = [...Array(num).keys()].map((x, i) => {
            return prizesArray[prizeObjId].numPrizes[i] === undefined ? "" : prizesArray[prizeObjId].numPrizes[i];
        });
        const prizeData = [...Array(num).keys()].map((x, i) => {
            return prizesArray[prizeObjId].prizeType[i] === undefined ? "Money" : prizesArray[prizeObjId].prizeType[i];
        });
        const currencyData = [...Array(num).keys()].map((x, i) => {
            return raffle.feeCurrency;
        });
        const prizeDesc = [...Array(num).keys()].map((x, i) => {
            return prizesArray[prizeObjId].prizesDescription[i] === undefined ? "" : prizesArray[prizeObjId].prizesDescription[i];
        });
        
        if (applyToAll) {
            prizesArray.map((obj) => {
                obj.numPrizes = prizesArr;
                obj.prizeType = prizeData;
                obj.currency = currencyData;
                obj.prizesDescription = prizeDesc;
            })
        } else {
            prizesArray[prizeObjId].numPrizes = prizesArr;
            prizesArray[prizeObjId].prizeType = prizeData;
            prizesArray[prizeObjId].currency = currencyData;
            prizesArray[prizeObjId].prizesDescription = prizeDesc;
        }

        setPrizesArray([...prizesArray]);
        setSelectedPrizes([...prizesArray]);
    };
    
    const onBlurNumWinners = (prizeObjId) => {
        let num = winners;
        if (isNaN(num) || num < 1 || num > raffle.maxNumParticipants) {
            num = 1;
            alert.removeAll();
            alert.show(`Enter value between 1 to ${raffle.maxNumParticipants}`);
        }
        setWinners(num);
        const prizesArr = [...Array(num).keys()].map((x, i) => {
            return prizesArray[prizeObjId].numPrizes[i] === undefined ? "" : prizesArray[prizeObjId].numPrizes[i];
        });
        const prizeData = [...Array(num).keys()].map((x, i) => {
            return prizesArray[prizeObjId].prizeType[i] === undefined ? "Money" : prizesArray[prizeObjId].prizeType[i];
        });
        const currencyData = [...Array(num).keys()].map((x, i) => {
            return raffle.feeCurrency;
        });

        if (applyToAll) {
            prizesArray.map((obj) => {
                obj.numPrizes = prizesArr;
                obj.prizeType = prizeData;
                obj.currency = currencyData;
            })
        } else {
            prizesArray[prizeObjId].numPrizes = prizesArr;
            prizesArray[prizeObjId].prizeType = prizeData;
            prizesArray[prizeObjId].currency = currencyData;
        }

        setPrizesArray([...prizesArray]);
        setSelectedPrizes([...prizesArray]);
    };

    const onChangePrizeType = (_e, prizeObjId, winnerIndex) => {
        const type = _e.currentTarget.value;
        const prizeTypes = prizesArray[prizeObjId].prizeType.map((x, j) =>
            winnerIndex === j ? type : x
        );
        const currencyTypes = [];
        prizeTypes.map((ptype, i) => {
            if (ptype === "Money") {
                currencyTypes.push(raffle.feeCurrency);
            } else {
                currencyTypes.push("");
            }
        });

        if (applyToAll) {
            prizesArray.map((obj) => {
                obj.prizeType= prizeTypes;
                obj.currency = currencyTypes;
            })
        } else {
            prizesArray[prizeObjId].prizeType= prizeTypes;
            prizesArray[prizeObjId].currency = currencyTypes;
        }

        setPrizesArray([...prizesArray]);
        setSelectedPrizes([...prizesArray]);
    }

    const onChangeCurrency = (_e, prizeObjId, winnerIndex) => {
        const type = _e.currentTarget.value;
        const currencies = prizesArray[prizeObjId].currency.map((x, j) =>
            winnerIndex === j ? type : x
        );
        if (applyToAll) {
            prizesArray.map((obj) => {
                obj.currency = currencies;
            })
        } else {
            prizesArray[prizeObjId].currency = currencies;
        }
        
        setPrizesArray([...prizesArray]);
        setSelectedPrizes([...prizesArray]);
    }

    const onChangePrize = (_e, prizeObjId, winnerIndex) => {
        let text = _e.currentTarget.value;
        const prizesArr = prizesArray[prizeObjId].numPrizes.map((x, i) => {
            return i === winnerIndex ? text : x;
        });

        if (applyToAll) {
            prizesArray.map((obj) => {
                obj.numPrizes = prizesArr;
            })
        } else {
            prizesArray[prizeObjId].numPrizes = prizesArr;
        }

        setPrizesArray([...prizesArray]);
        setSelectedPrizes([...prizesArray]);
    }

    const onChangeDesc = (_e, prizeObjId, winnerIndex) => {
        let text = _e.currentTarget.value;
        const descArray = prizesArray[prizeObjId].prizesDescription.map((x, i) => {
            return i === winnerIndex ? text : x;
        });

        if (applyToAll) {
            prizesArray.map((obj) => {
                obj.prizesDescription = descArray;
            })
        } else {
            prizesArray[prizeObjId].prizesDescription = descArray;
        }
        
        setPrizesArray([...prizesArray]);
        setSelectedPrizes([...prizesArray]);
    }

    const onChangeApplyToAll = (_e) => {
        console.log(_e.currentTarget.checked);
        setApplyToAll(_e.currentTarget.checked);
        setApplyToAllStatus(_e.currentTarget.checked);
    }


    useEffect(() => {
        console.log(prizesArray);
    }, [prizesArray])

    useEffect(() => {
        if (prizesArray.length > 0) {
            setWinners(prizesArray[activeTab].numPrizes.length);
        }
    }, [activeTab])
    
    return (
        <div className="prizes-section">
            <label className="title">PRIZES</label>
            <div className='pr-tabs-sheet'>
                <div className='checkbox-container'>
                    <input 
                        className='checkbox-input'
                        checked={applyToAll}
                        onChange={onChangeApplyToAll}
                        type='checkbox'/>
                    <label className='checkbox-label'>Apply to all draws</label>
                </div>
                <div className='pr-tabs-btns-continer'>
                    {prizesArray.map((prizeObj, index) => {
                        return (
                            <button 
                                className={`pr-tab-btn ${activeTab === index ? "active-tab" : ""}`}
                                key={index}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActiveTab(index);
                                }}>
                                {`Draw #${index + 1}`}
                                {raffle.type === "Series" && selectedInstances.length > 0 &&
                                    <p>{new Date(selectedInstances[index]).toLocaleString('en-US')}</p>
                                }
                            </button>
                        )
                    })}
                </div>
                <div className='pr-tabs-content-container'>
                    {prizesArray.map((prizeObj, index) => {
                        return (
                            activeTab === index &&
                            <Fragment key={index}>
                                <div className="num-of-winners">
                                    <label>NO. OF WINNERS</label>
                                    <input
                                        // disabled={index > 0 && applyToAll}
                                        type="number"
                                        min={1}
                                        max={100}
                                        maxLength={3}
                                        value={winners}
                                        onChange={(_e) => {onChangeNumWinners(_e, activeTab)}}
                                        onBlur={() => onBlurNumWinners(activeTab)}
                                    />
                                </div>
                                <div className="winners-list">
                                    {[...new Array(winners || 0).keys()].map((winner, i) => {
                                    return (
                                        <div key={i}>#{i + 1}
                                            <select 
                                                // disabled={index > 0 && applyToAll}
                                                value={prizeObj.prizeType[i]}
                                                onChange={(e) => {onChangePrizeType(e, activeTab, i)}}>
                                                <option>Money</option>
                                                <option>Product</option>
                                                <option>Service</option>
                                            </select>
                                            {prizeObj.prizeType[i] === "Money" ? (
                                                <select
                                                    // disabled={index > 0 && applyToAll}
                                                    value={prizeObj.currency[i]}
                                                    onChange={(e) => {
                                                        onChangeCurrency(e, activeTab, i)
                                                    }}
                                                >
                                                {currencies.map((cprizeObj, index) => {
                                                    return (
                                                    <option key={index} value={cprizeObj.symbol}>
                                                        {cprizeObj.name + " (" + cprizeObj.symbol + ")"}
                                                    </option>
                                                    );
                                                })}
                                                </select>
                                            ) : null}
                                            <input
                                                // disabled={index > 0 && applyToAll}
                                                required
                                                type={prizeObj.prizeType[i] === "Money" ? "number" : "text"}
                                                value={prizeObj.numPrizes[i]}
                                                placeholder={"Prize " + (i + 1)}
                                                min={1}
                                                onChange={(e) => {
                                                    onChangePrize(e, activeTab, i)
                                                }}
                                            />
                                            <input
                                                // disabled={index > 0 && applyToAll}
                                                placeholder="Short Description"
                                                value={prizeObj.prizesDescription[i]}
                                                onChange={(e) => {
                                                    onChangeDesc(e, activeTab, i)
                                                }}
                                            />
                                        </div>
                                    );
                                    })}
                                </div>
                            </Fragment>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Prizes;