import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';
import store from './store';
import { Provider } from 'react-redux';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

//alert options
const options = {
    position: 'bottom center',
    timeout: 5000,
    offset: '3px',
    transition: 'scale',
    containerStyle:{
        zIndex: 999999
    }
}

ReactDOM.render(<Provider store={store}>
    <AlertProvider template={AlertTemplate} {...options}>
        <App />
    </AlertProvider>
</Provider>, document.getElementById('root'));