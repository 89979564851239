export default [{
    name: "Angola",
    region: "Africa"
},
{
    name: "Burkina Faso",
    region: "Africa"
},
{
    name: "Burundi",
    region: "Africa"


},
{
    name: "Benin",
    region: "Africa"


},
{
    name: "Botswana",
    region: "Africa"


},
{
    name: "Congo, The Democratic Republic of the",
    region: "Africa"


},
{
    name: "Central African Republic",
    region: "Africa"


},
{
    name: "Congo",
    region: "Africa"


},
{
    name: "Côte D'Ivoire",
    region: "Africa"

},
{
    name: "Cameroon",
    region: "Africa"

},
{
    name: "Cape Verde",
    region: "Africa"

},
{
    name: "Western Sahara",
    region: "Africa"

},
{
    name: "Eritrea",
    region: "Africa"

},
{
    name: "Ethiopia",
    region: "Africa"

},
{
    name: "Gabon",
    region: "Africa"

},
{
    name: "Ghana",
    region: "Africa"

},
{
    name: "Gambia",
    region: "Africa"

},
{
    name: "Guinea",
    region: "Africa"

},
{
    name: "Equatorial Guinea",
    region: "Africa"

},
{
    name: "Guinea-Bissau",
    region: "Africa"

},
{
    name: "Kenya",
    region: "Africa"

},
{
    name: "Liberia",
    region: "Africa"

},
{
    name: "Lesotho",
    region: "Africa"

},
{
    name: "Madagascar",
    region: "Africa"

},
{
    name: "Mali",
    region: "Africa"

},
{
    name: "Mauritius",
    region: "Africa"

},
{
    name: "Malawi",
    region: "Africa"

},
{
    name: "Mozambique",
    region: "Africa"

},
{
    name: "Namibia",
    region: "Africa"

},
{
    name: "Niger",
    region: "Africa"

},
{
    name: "Nigeria",
    region: "Africa"

},

{
    name: "Rwanda",
    region: "Africa"

},
{
    name: "Seychelles",
    region: "Africa"

},
{
    name: "South Sudan",
    region: "Africa"

},
{
    name: "Saint Helena",
    region: "Africa"

},
{
    name: "Sierra Leone",
    region: "Africa"

},
{
    name: "Senegal",
    region: "Africa"

},
{
    name: "Sao Tome and Principe",
    region: "Africa"

},
{
    name: "Swaziland",
    region: "Africa"

},
{
    name: "Chad",
    region: "Africa"

},
{
    name: "Togo",
    region: "Africa"

},
{
    name: "Tanzania, United Republic of",
    region: "Africa"

},
{
    name: "Mayotte",
    region: "Africa"

},
{
    name: "South Africa",
    region: "Africa"

},
{
    name: "Zambia",
    region: "Africa"

},
{
    name: "Zimbabwe",
    region: "Africa"

},
{
    name: "Bahrain",
    region: "Arab States"

},
{
    name: "Djibouti",
    region: "Arab States"

},
{
    name: "Algeria",
    region: "Arab States"

},
{
    name: "Comoros",
    region: "Arab States"

},
{
    name: "Morocco",
    region: "Arab States"

},
{
    name: "Mauritania",
    region: "Arab States"

},
{
    name: "Palestinian Territory",
    region: "Arab States"

},
{
    name: "Sudan",
    region: "Arab States"

},
{
    name: "Somalia",
    region: "Arab States"

},
{
    name: "Tunisia",
    region: "Arab States"

},
{
    name: "Afghanistan",
    region: "Asia & Pacific"

},
{
    name: "Antarctica",
    region: "Asia & Pacific"

},
{
    name: "American Samoa",
    region: "Asia & Pacific"

},
{
    name: "Australia",
    region: "Asia & Pacific"

},
{
    name: "Azerbaijan",
    region: "Asia & Pacific"

},
{
    name: "Bangladesh",
    region: "Asia & Pacific"

},
{
    name: "Brunei Darussalam",
    region: "Asia & Pacific"

},
{
    name: "Bhutan",
    region: "Asia & Pacific"

},
{
    name: "Cocos (Keeling) Islands",
    region: "Asia & Pacific"

},
{
    name: "Cook Islands",
    region: "Asia & Pacific"

},
{
    name: "China",
    region: "Asia & Pacific"

},
{
    name: "Christmas Island",
    region: "Asia & Pacific"

},
{
    name: "Fiji",
    region: "Asia & Pacific"

},
{
    name: "Micronesia, Federated States of",
    region: "Asia & Pacific"

},
{
    name: "Guam",
    region: "Asia & Pacific"

},
{
    name: "Hong Kong",
    region: "Asia & Pacific"

},
{
    name: "Heard Island and McDonald Islands",
    region: "Asia & Pacific"

},
{
    name: "Indonesia",
    region: "Asia & Pacific"

},
{
    name: "India",
    region: "Asia & Pacific"

},
{
    name: "British Indian Ocean Territory",
    region: "Asia & Pacific"

},
{
    name: "Japan",
    region: "Asia & Pacific"

},
{
    name: "Kyrgyzstan",
    region: "Asia & Pacific"

},
{
    name: "Cambodia",
    region: "Asia & Pacific"

},
{
    name: "Kiribati",
    region: "Asia & Pacific"

},
{
    name: "Korea, Democratic People's Republic of",
    region: "Asia & Pacific"

},
{
    name: "Korea, Republic of",
    region: "Asia & Pacific"

},
{
    name: "Kazakhstan",
    region: "Asia & Pacific"

},
{
    name: "Lao People's Democratic Republic",
    region: "Asia & Pacific"

},
{
    name: "Sri Lanka",
    region: "Asia & Pacific"

},
{
    name: "Marshall Islands",
    region: "Asia & Pacific"

},
{
    name: "Myanmar",
    region: "Asia & Pacific"

},
{
    name: "Mongolia",
    region: "Asia & Pacific"

},
{
    name: "Macau",
    region: "Asia & Pacific"

},
{
    name: "Northern Mariana Islands",
    region: "Asia & Pacific"

},
{
    name: "Maldives",
    region: "Asia & Pacific"

},
{
    name: "Malaysia",
    region: "Asia & Pacific"

},
{
    name: "New Caledonia",
    region: "Asia & Pacific"

},
{
    name: "Norfolk Island",
    region: "Asia & Pacific"

},
{
    name: "Nepal",
    region: "Asia & Pacific"

},
{
    name: "Nauru",
    region: "Asia & Pacific"

},
{
    name: "Niue",
    region: "Asia & Pacific"

},
{
    name: "New Zealand",
    region: "Asia & Pacific"

},
{
    name: "French Polynesia",
    region: "Asia & Pacific"

},
{
    name: "Papua New Guinea",
    region: "Asia & Pacific"

},
{
    name: "Philippines",
    region: "Asia & Pacific"

},
{
    name: "Pakistan",
    region: "Asia & Pacific"

},
{
    name: "Pitcairn Islands",
    region: "Asia & Pacific"

},
{
    name: "Palau",
    region: "Asia & Pacific"

},
{
    name: "Reunion",
    region: "Asia & Pacific"

},
{
    name: "Solomon Islands",
    region: "Asia & Pacific"

},
{
    name: "Singapore",
    region: "Asia & Pacific"

},
{
    name: "Syrian Arab Republic",
    region: "Asia & Pacific"

},
{
    name: "French Southern Territories",
    region: "Asia & Pacific"

},
{
    name: "Thailand",
    region: "Asia & Pacific"

},
{
    name: "Tajikistan",
    region: "Asia & Pacific"

},
{
    name: "Tokelau",
    region: "Asia & Pacific"

},
{
    name: "Turkmenistan",
    region: "Asia & Pacific"

},
{
    name: "Tonga",
    region: "Asia & Pacific"

},
{
    name: "Timor-Leste",
    region: "Asia & Pacific"

},
{
    name: "Tuvalu",
    region: "Asia & Pacific"

},
{
    name: "Taiwan",
    region: "Asia & Pacific"

},
{
    name: "United States Minor Outlying Islands",
    region: "Asia & Pacific"

},
{
    name: "Uzbekistan",
    region: "Asia & Pacific"

},
{
    name: "Vietnam",
    region: "Asia & Pacific"

},
{
    name: "Vanuatu",
    region: "Asia & Pacific"

},
{
    name: "Wallis and Futuna	",
    region: "Asia & Pacific"

},
{
    name: "Samoa",
    region: "Asia & Pacific"

},
{
    name: "Andorra",
    region: "Europe"

},
{
    name: "Albania",
    region: "Europe"

},
{
    name: "Armenia",
    region: "Europe"

},
{
    name: "Austria",
    region: "Europe"

},
{
    name: "Bosnia and Herzegovina	",
    region: "Europe"

},
{
    name: "Belgium",
    region: "Europe"

},
{
    name: "Bulgaria",
    region: "Europe"

},
{
    name: "Belarus",
    region: "Europe"

},
{
    name: "Switzerland",
    region: "Europe"

},
{
    name: "Cyprus",
    region: "Europe"

},
{
    name: "Czech Republic",
    region: "Europe"

},
{
    name: "Germany",
    region: "Europe"

},
{
    name: "Denmark",
    region: "Europe"

},
{
    name: "Estonia",
    region: "Europe"

},
{
    name: "Spain",
    region: "Europe"

},
{
    name: "Finland",
    region: "Europe"

},
{
    name: "Faroe Islands	",
    region: "Europe"

},
{
    name: "France",
    region: "Europe"

},
{
    name: "France, Metropolitan",
    region: "Europe"

},
{
    name: "United Kingdom",
    region: "Europe"

},
{
    name: "Georgia",
    region: "Europe"

},
{
    name: "Gibraltar",
    region: "Europe"

},
{
    name: "Greenland",
    region: "Europe"

},
{
    name: "Greece",
    region: "Europe"

},
{
    name: "Croatia",
    region: "Europe"

},
{
    name: "Hungary",
    region: "Europe"

},
{
    name: "Ireland",
    region: "Europe"

},
{
    name: "Israel",
    region: "Europe"

},
{
    name: "Iceland",
    region: "Europe"

},
{
    name: "Italy",
    region: "Europe"

},
{
    name: "Liechtenstein",
    region: "Europe"

},
{
    name: "Lithuania",
    region: "Europe"

},
{
    name: "Luxembourg",
    region: "Europe"

},
{
    name: "Latvia",
    region: "Europe"

},
{
    name: "Monaco",
    region: "Europe"

},
{
    name: "Moldova, Republic of	",
    region: "Europe"

},
{
    name: "Macedonia",
    region: "Europe"

},
{
    name: "Malta",
    region: "Europe"

},
{
    name: "Netherlands",
    region: "Europe"

},
{
    name: "Norway",
    region: "Europe"

},
{
    name: "Poland",
    region: "Europe"

},
{
    name: "Portugal",
    region: "Europe"

},
{
    name: "Romania",
    region: "Europe"

},
{
    name: "Russian Federation",
    region: "Europe"

},
{
    name: "Sweden",
    region: "Europe"

},
{
    name: "Slovenia",
    region: "Europe"

},
{
    name: "Svalbard and Jan Mayen",
    region: "Europe"

},
{
    name: "Slovakia",
    region: "Europe"

},
{
    name: "San Marino",
    region: "Europe"

},
{
    name: "Turkey",
    region: "Europe"

},
{
    name: "Ukraine",
    region: "Europe"

},
{
    name: "Holy See (Vatican City State)",
    region: "Europe"

},
{
    name: "Serbia",
    region: "Europe"

},
{
    name: "Montenegro",
    region: "Europe"

},
{
    name: "Aland Islands",
    region: "Europe"

},
{
    name: "Guernsey",
    region: "Europe"

},
{
    name: "Isle of Man",
    region: "Europe"

},
{
    name: "Jersey",
    region: "Europe"

},
{
    name: "United Arab Emirates",
    region: "Middle east"

},
{
    name: "Egypt",
    region: "Middle east"

},
{
    name: "Iraq",
    region: "Middle east"

},
{
    name: "Iran, Islamic Republic of",
    region: "Middle east"

},
{
    name: "Jordan",
    region: "Middle east"

},
{
    name: "Kuwait",
    region: "Middle east"

},
{
    name: "Lebanon",
    region: "Middle east"

},
{
    name: "Libya",
    region: "Middle east"

},
{
    name: "Oman",
    region: "Middle east"

},
{
    name: "Qatar",
    region: "Middle east"

},
{
    name: "Saudi Arabia",
    region: "Middle east"

},
{
    name: "Yemen",
    region: "Middle east"

},
{
    name: "Bermuda",
    region: "North America"

},
{
    name: "Canada",
    region: "North America"

},
{
    name: "Saint Pierre and Miquelon",
    region: "North America"

},
{
    name: "United States",
    region: "North America"

},
{
    name: "Antigua and Barbuda",
    region: "South/Latin America"

},
{
    name: "Anguilla",
    region: "South/Latin America"

},
{
    name: "Netherlands Antilles",
    region: "South/Latin America"

},
{
    name: "Argentina",
    region: "South/Latin America"

},
{
    name: "Aruba",
    region: "South/Latin America"

},
{
    name: "Barbados",
    region: "South/Latin America"

},
{
    name: "Bolivia",
    region: "South/Latin America"

},
{
    name: "Brazil",
    region: "South/Latin America"

},
{
    name: "Bahamas",
    region: "South/Latin America"

},
{
    name: "Bouvet Island",
    region: "South/Latin America"

},
{
    name: "Belize",
    region: "South/Latin America"

},
{
    name: "Chile",
    region: "South/Latin America"

},
{
    name: "Colombia",
    region: "South/Latin America"

},
{
    name: "Costa Rica",
    region: "South/Latin America"

},
{
    name: "Cuba",
    region: "South/Latin America"

},
{
    name: "Dominica",
    region: "South/Latin America"

},
{
    name: "Dominican Republic",
    region: "South/Latin America"

},
{
    name: "Ecuador",
    region: "South/Latin America"

},
{
    name: "Falkland Islands (Malvinas)",
    region: "South/Latin America"

},
{
    name: "Grenada",
    region: "South/Latin America"

},
{
    name: "French Guiana",
    region: "South/Latin America"

},
{
    name: "Guadeloupe",
    region: "South/Latin America"

},
{
    name: "South Georgia and the South Sandwich Islands",
    region: "South/Latin America"

},
{
    name: "Guatemala",
    region: "South/Latin America"

},
{
    name: "Guyana",
    region: "South/Latin America"

},
{
    name: "Honduras",
    region: "South/Latin America"

},
{
    name: "Haiti",
    region: "South/Latin America"

},
{
    name: "Jamaica",
    region: "South/Latin America"

},
{
    name: "Saint Kitts and Nevis",
    region: "South/Latin America"

},
{
    name: "Cayman Islands",
    region: "South/Latin America"

},
{
    name: "Saint Lucia",
    region: "South/Latin America"

},
{
    name: "Martinique",
    region: "South/Latin America"

},
{
    name: "Montserrat",
    region: "South/Latin America"

},
{
    name: "Mexico",
    region: "South/Latin America"

},
{
    name: "Nicaragua",
    region: "South/Latin America"

},
{
    name: "Panama",
    region: "South/Latin America"

},
{
    name: "Peru",
    region: "South/Latin America"

},
{
    name: "Puerto Rico",
    region: "South/Latin America"

},
{
    name: "Paraguay",
    region: "South/Latin America"

},
{
    name: "Suriname",
    region: "South/Latin America"

},
{
    name: "",
    region: "South/Latin America"

},
{
    name: "El Salvador",
    region: "South/Latin America"

},
{
    name: "Turks and Caicos Islands",
    region: "South/Latin America"

},
{
    name: "Trinidad and Tobago",
    region: "South/Latin America"

},
{
    name: "Uruguay",
    region: "South/Latin America"

},
{
    name: "Saint Vincent and the Grenadines",
    region: "South/Latin America"

},
{
    name: "Venezuela",
    region: "South/Latin America"

},
{
    name: "Virgin Islands, British",
    region: "South/Latin America"

},
{
    name: "Virgin Islands, U.S.",
    region: "South/Latin America"

},
{
    name: "Saint Barthelemy",
    region: "South/Latin America"

},
{
    name: "Saint Martin",
    region: "South/Latin America"

},

]