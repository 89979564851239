import React, { useState, useEffect } from "react";
import './emptyTrash.scss'

export default ({ setConfirmDeleteEmptyTrash, onProceed }) => {
  return (
    <div className="empty-trash-container">
      <div className="empty-trash-container-child">
        <div className="empty-trash-container-child1">
          <div className="empty-trash-container-child2">
            <div className="head-line">
              <img
                className="emptytrash-cup"
                src={require("../../../../assets/img/cup.png")}
                alt="Cup"
              />
              <div className="header-container">CONFIRM EMPTY TRASH</div>
              <button
                className="x-btn"
                onClick={() => {
                  setConfirmDeleteEmptyTrash(false);
                }}
              >
                X
              </button>
            </div>
            <div className="message-container">
              This action cannot be undone. Are you sure you want to empty
              trash?
            </div>
            <div className="empty-trash-footer">
              <button
                className="close-btn"
                onClick={() => {
                  setConfirmDeleteEmptyTrash(false);
                }}
              >
                NO
              </button>
              <button className="confirm-btn" onClick={onProceed}>
                YES
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
