import React, { useState, useEffect } from 'react';
import './restoreAllNotifications.scss'

export default ({
  setConfirmDilog,
  onProceed
}) => {
  return (
    <div className='Confirm-restore'>
      <div className='container1'>
        <div className='container1-child1'>
          <div className='container1-child1-child1'>
            <div className='header'>
              <img className='cup' src={require("../../../../assets/img/cup.png")} alt="Cup" />
              <div>
                CONFIRM RESTORE
              </div>
              <button
                className='x-close-btn'
                onClick={() => {
                  setConfirmDilog(false);
                }}>
                X
              </button>
            </div>
            <div className='body'>
              Are you sure you want to Restore selected notifications            </div>
            <div className='footer'>
              <button
                className={"close-btn"}
                onClick={() => {
                  setConfirmDilog(false);
                }}>
                NO
              </button>
              <button
                className={"confirm-btn"}
                onClick={onProceed}>
                YES
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


