import React, { Fragment, useEffect, useState } from 'react';
import './index.scss';
import firebase from '../../helper/firebase';
import htmlParse from 'html-react-parser';
import innerText from 'react-innertext';
import { ReactSVG } from 'react-svg';
import HostFlowModelSVG from '../../assets/img/host_flow_model.svg';
import ParticipatedFlowModelSVG from '../../assets/img/participant_flow_model.svg';

export default () => {
    const [root_state, set_root_state] = useState(null);
    const [activeScreen, setActiveScreen] = useState(1);

    // load guide
    useEffect(() => {
        firebase.firestore().collection('_cms').doc('123456789').get().then((snap) => {
            set_root_state(snap.data().root_state)
        }).catch((e) => { })

        // setting listeners for flow model svg
    }, [])

    return root_state !== null ? <div id="guide">
        {/* header */}
        <div>
            <img src={require('../../assets/img/logo.png')} />
            <label>Rafflerra Interactive Guide</label>
            <nav>
                {
                    root_state.header.map(({ id, value }, i) => <div className={activeScreen === i && "active"} onClick={() => setActiveScreen(i)} key={i}>{value.toUpperCase()}</div>)
                }
            </nav>
        </div>
        {/* conatiner */}
        <div>
            {/* content */}
            <div>
                <div className="main_header">{root_state.header[activeScreen].value}</div>
                {
                    root_state.main.map((_tag, index) => {
                        const { tag, value, screen, focused, styles } = _tag;
                        if (screen === activeScreen) {
                            const text = innerText(htmlParse(value)).trim();
                            if (text.length > 0) {
                                if (tag === "p") {
                                    // find headings(links)
                                    if (_tag.sno === "1603864546453") {
                                        const res = value.split('virtual raffle ceremonies')
                                        return <p>
                                            {res[0]}
                                            <a style={{ display: 'inline' }} href="#1604044481920">virtual raffle ceremonies</a>
                                            {res[1]}
                                        </p>

                                    }
                                    else if ("link" in _tag) {
                                        return <p id={_tag.sno} className="heading" key={index}>{text.replace(/\s\s+/g, ' ')}</p>
                                    }
                                    // to insert flow model
                                    else if (text.includes("flow model")) {
                                        return <Fragment>
                                            <p key={index}>{text.replace(/\s\s+/g, ' ')}</p>
                                            <ReactSVG
                                                evalScripts="always"
                                                fallback={() => <span>Error!</span>}
                                                loading={() => <span>Loading</span>}
                                                renumerateIRIElements={false}
                                                wrapper="span"
                                                className="flow_model"
                                                src={root_state.header[activeScreen].value === "As a Host" ? HostFlowModelSVG : ParticipatedFlowModelSVG} />
                                        </Fragment>
                                    }
                                    // other text
                                    else {
                                        // links in text
                                        if (text.includes('Before creating a raffle you need to register with Rafflerra.')) {
                                            const txt = text.replace(/\s\s+/g, ' ')
                                            const lst = txt.split('register');
                                            return <p key={index}>{lst[0]} <a href="#1603865788395">register</a> {lst[1]}</p>
                                        }
                                        if(text.includes('Note: You need to register before joining a raffle. Otherwise, the email address field will be disabled.')){
                                            const txt = text.replace(/\s\s+/g, ' ')
                                            const lst = txt.split('register');
                                            return <p key={index}>{lst[0]} <a href="#1604046369345">register</a> {lst[1]}</p>
                                        }
                                        if (text.includes('Rafflerra Catalogue - This is where you will be finding different types of raffles that you can Join by just clicking on the raffle ticket and the Join button.')) {
                                            const txt = text.replace(/\s\s+/g, ' ')
                                            const lst = txt.split('Rafflerra Catalogue');
                                            return <p key={index}>{lst[0]} <a href="#1604046504140">Rafflerra Catalogue</a> {lst[1]}</p>
                                        }
                                        return <p key={index}>{text.replace(/\s\s+/g, ' ')}</p>
                                    }
                                } else if (tag === "img") {
                                    return <img key={index} src={value} />
                                }
                                else if (tag === "li") {
                                    return <li key={index}>{text.replace(/\s\s+/g, ' ')}</li>
                                }
                            }
                        }
                    })
                }
            </div>
            {/* links */}
            <div>
                {
                    root_state.main.map((tag, index) => {
                        if ("link" in tag) {
                            if (tag.screen === activeScreen) {
                                if (innerText(htmlParse(tag.value)) === "Other Interactions") {
                                    return <strong style={{ marginTop: 20, fontSize: 15, textDecoration: 'none' }}><a href={`#${tag.sno}`}>{innerText(htmlParse(tag.value))}</a></strong>

                                } else if (innerText(htmlParse(tag.value)) === "Registration Process" || innerText(htmlParse(tag.value)) === "Login Process" || innerText(htmlParse(tag.value)) === "Profile Details"
                                    || innerText(htmlParse(tag.value)) === "Rafflerra Catalogue" || innerText(htmlParse(tag.value)) === "Raffle Dashboard" || innerText(htmlParse(tag.value)) === "Manage Your Account") {
                                    return <strong><a style={{ fontSize: 15, marginLeft: 20 }} href={`#${tag.sno}`}>{innerText(htmlParse(tag.value))}</a></strong>
                                } else {
                                    return <strong><a style={{ fontSize: 15 }} href={`#${tag.sno}`}>{innerText(htmlParse(tag.value))}</a></strong>
                                }
                            }
                        }
                    })
                }
            </div>
        </div>
    </div > : null
}