import React from 'react';
import { ResponsiveLine } from '@nivo/line';

const data = [
    {
      "id": "japan",
      "color": "hsl(105, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 25
        },
        {
          "x": "helicopter",
          "y": 210
        },
        {
          "x": "boat",
          "y": 121
        },
        {
          "x": "train",
          "y": 124
        },
        {
          "x": "subway",
          "y": 29
        },
        {
          "x": "bus",
          "y": 155
        },
        {
          "x": "car",
          "y": 164
        },
        {
          "x": "moto",
          "y": 78
        },
        {
          "x": "bicycle",
          "y": 146
        },
        {
          "x": "horse",
          "y": 50
        },
        {
          "x": "skateboard",
          "y": 11
        },
        {
          "x": "others",
          "y": 78
        }
      ]
    },
    {
      "id": "france",
      "color": "hsl(54, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 290
        },
        {
          "x": "helicopter",
          "y": 151
        },
        {
          "x": "boat",
          "y": 269
        },
        {
          "x": "train",
          "y": 242
        },
        {
          "x": "subway",
          "y": 146
        },
        {
          "x": "bus",
          "y": 274
        },
        {
          "x": "car",
          "y": 205
        },
        {
          "x": "moto",
          "y": 222
        },
        {
          "x": "bicycle",
          "y": 71
        },
        {
          "x": "horse",
          "y": 203
        },
        {
          "x": "skateboard",
          "y": 60
        },
        {
          "x": "others",
          "y": 34
        }
      ]
    },
    {
      "id": "us",
      "color": "hsl(106, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 185
        },
        {
          "x": "helicopter",
          "y": 6
        },
        {
          "x": "boat",
          "y": 197
        },
        {
          "x": "train",
          "y": 179
        },
        {
          "x": "subway",
          "y": 114
        },
        {
          "x": "bus",
          "y": 218
        },
        {
          "x": "car",
          "y": 263
        },
        {
          "x": "moto",
          "y": 245
        },
        {
          "x": "bicycle",
          "y": 241
        },
        {
          "x": "horse",
          "y": 193
        },
        {
          "x": "skateboard",
          "y": 147
        },
        {
          "x": "others",
          "y": 204
        }
      ]
    },
    {
      "id": "germany",
      "color": "hsl(110, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 276
        },
        {
          "x": "helicopter",
          "y": 159
        },
        {
          "x": "boat",
          "y": 152
        },
        {
          "x": "train",
          "y": 230
        },
        {
          "x": "subway",
          "y": 243
        },
        {
          "x": "bus",
          "y": 122
        },
        {
          "x": "car",
          "y": 72
        },
        {
          "x": "moto",
          "y": 174
        },
        {
          "x": "bicycle",
          "y": 259
        },
        {
          "x": "horse",
          "y": 186
        },
        {
          "x": "skateboard",
          "y": 49
        },
        {
          "x": "others",
          "y": 277
        }
      ]
    },
    {
      "id": "norway",
      "color": "hsl(168, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 193
        },
        {
          "x": "helicopter",
          "y": 16
        },
        {
          "x": "boat",
          "y": 14
        },
        {
          "x": "train",
          "y": 104
        },
        {
          "x": "subway",
          "y": 67
        },
        {
          "x": "bus",
          "y": 44
        },
        {
          "x": "car",
          "y": 125
        },
        {
          "x": "moto",
          "y": 128
        },
        {
          "x": "bicycle",
          "y": 256
        },
        {
          "x": "horse",
          "y": 77
        },
        {
          "x": "skateboard",
          "y": 209
        },
        {
          "x": "others",
          "y": 135
        }
      ]
    }
  ]

export default ({line_data}) => (
    <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'transportation',
            legendOffset: 36,
            legendPosition: 'middle'
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'count',
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        colors={{ scheme: 'nivo' }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />
)