import { updateRaffle } from "../../config/endpoints"

const initialState = {
    raffles: [],
    myRaffles: [],
    status: ""
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        //initial load of raffles for rafflera catalouge
        case "FETCHING_RAFFLES":
            return { ...state, status: "FETCHING" }
        case "FETCHED_RAFFLES":
            return { ...state, raffles: payload, status: "FETCHED" }
        case "FETCHED_EMPTY":
            return { ...state, raffles: payload, status: "FETCHED_EMPTY" }
        case "FETCH_ERROR":
            return { ...state, status: "ERROR" }

        //initial load of my raffles
        case "FETCHING_MY_RAFFLES":
            return { ...state, status: "FETCHING" }
        case "FETCHED_MY_RAFFLES":
            return { ...state, myRaffles: payload, status: "FETCHED" }
        case "FETCHED_MY_EMPTY":
            return { ...state, raffles: payload, status: "FETCHED_MY_EMPTY" }
        case "FETCH_MY_ERROR":
            return { ...state, status: "ERROR" }

        //add raffle to local state
        case "ADD_RAFFLE":
            return { ...state, raffles: [payload, ...state.raffles], myRaffles: [payload, ...state.raffles] }

        //delete raffle from local state
        case "DEL_RAFFLE":
            let tempList = state.raffles.filter(x => x._id !== payload);
            return { ...state, raffles: tempList.length > 0 ? tempList : [], myRaffles: tempList.length > 0 ? tempList : [] }

        //update raffle
        case "UPDATE_RAFFLE":
            let raffleToUpdate = payload;
            let updatedList = state.raffles.map((raffle => {
                if (raffle._id === raffleToUpdate._id) {
                    return raffleToUpdate
                } else {
                    return raffle
                }
            }));
            return { ...state, raffles: updatedList, myRaffles: updatedList }

        //update raffle
        case "ACTIVATE_DEACTIVATE_RAFFLE":
            let updatedList2 = state.raffles.map((raffle => {
                if (raffle._id === payload._id) {
                    return { ...raffle, extras: { ...raffle.extras, active: payload.status } }
                } else {
                    return raffle
                }
            }));
            return { ...state, raffles: updatedList2, myRaffles: updatedList2 }

        //join in a raffle
        case "JOIN_RAFFLE":
            let updatedList1 = state.raffles.map((raffle) => {
                if (raffle._id === payload.raffleId) {
                    return { ...raffle, participants: [payload.uid, ...raffle.participants] }
                } else {
                    return raffle;
                }
            })
            return { ...state, raffles: updatedList1, myRaffles: updatedList1 }

        //to swap series raffle with updated raffle(s)
        case "SWAP_SERIES_RAFFLES":
            const { rafflesToRemoveList, newRafflesList } = payload;
            let newRafflesList1 = state.raffles.filter(x => rafflesToRemoveList.find(y => y._id === x._id) === undefined);
            newRafflesList1 = [...newRafflesList1, ...newRafflesList];
            newRafflesList1.sort((a, b) => b.id - a.id)
            return { ...state, raffles: newRafflesList1, myRaffles: newRafflesList1 }

        // raffle winner(s) announced
        case "RAFFLE_WINNER_PICKED":
            const { raffleid, winners } = payload;
            let newRafflesList2 = state.raffles.map(x => x._id === raffleid ? { ...x, winners } : x)
            newRafflesList2.sort((a, b) => b.id - a.id)
            return { ...state, raffles: newRafflesList2, myRaffles: newRafflesList2 }
        default:
            return state;
    }
}