import React, { useEffect, useState, usetate } from "react";
import "./comfirmationDialouge.scss";
import DrawRaffleViewModel from "./DrawRaffleViewModel";
import DrumRoll from "./DrumRoll";
import RaffleTicketViewModel from "../drawerPages/hostedRaffles/RaffleTicketViewModel";
import TicketViewModel from "./TicketViewModel";
import { useAlert } from "react-alert";


export default ({
  onClose,
  onProceed,
  raffle,
  choosenRafflePrizes,
  toggleAnimation,
  winners,
  participants,
  activeDrumroll,
  timeDelay,
  isPlaying,
  winnersCurrencyType,
  winnersPrizeType
}) => {

  const [playConfirm, setPlayConfirm] = useState(false);
  const [confettiOn, setConfettiOn] = useState(true);
  const [showScheduledDraws, setShowScheduledDraws] = useState(true)

  const alert = useAlert();

  useEffect(() => {
    setTimeout(() => {
      setConfettiOn(false);
    }, 15000)
  }, []);

  useEffect(() => {
    setPlayConfirm(isPlaying);
  }, [isPlaying]);

  return (
    <div className="confirmation_dialouge">
      <div className="draw-window">
        {/* header */}
        <div className="draw-window-header">
          <img className="draw-window-logo" src={require("../../../assets/img/cup.png")} alt="Cup" />
          <div className="draw-window-title">
            {winners.length > 0 ? `Raffle Winner(s) - ${raffle.title}` : `Draw Raffle - ${raffle.title}`}
          </div>
          <button
            className={toggleAnimation ? "x-btn-disabled" : "x-btn-enabled"}
            disabled={toggleAnimation}
            onClick={onClose}>X</button>
        </div>
        {/* content */}
        <div className="draw-window-content">
          {/* toggleAnimation ?  */}
          {
            winners.length > 0 ? (
              <div style={{ width: "90%" }}>
                {/* <div className='btn'>Show Ticket</div> */}
                <div style={{ position: "absolute", zIndex: 99999999, width: "100%" }}>
                  {confettiOn && <img width={"100%"} src="Confettie2.gif" alt="Confetti" />}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <img
                    style={{
                      // width: "100%",
                      height: "100px"
                    }}
                    src="ezgif.com-crop (1).gif" alt="Congo_img" />
                </div>
                <table className="winners-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Ticket #</th>
                      <th>Winner's name</th>
                      <th>Prize Type</th>
                      <th>Prize</th>
                    </tr>
                  </thead>
                  <tbody>
                    {winners.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.ticketNumber}</td>
                          <td>{item.name}</td>
                          <td>{winnersPrizeType[index]}</td>
                          <td>{winnersCurrencyType[index]}{item.prize}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )
              :
              (
                <div className="draw-part">
                  {playConfirm && 
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <div
                    style={{
                      display: activeDrumroll ? "block" : "none",
                      justifyContent: "center",
                      alignItems: 'center',
                      position: "absolute",
                      zIndex: 9,
                    }}
                  >
                    <DrumRoll
                     timeDelay={timeDelay} 
                     />
                    <audio src="/drum-roll-sound-effect.mp3" autoPlay={isPlaying} />
                  </div>
                  </div>}
                  <div className="ticket-content">
                    <TicketViewModel
                      userType={"host"}
                      asParticipant={false}
                      choosenRaffle={raffle}
                      choosenRafflePrizes={choosenRafflePrizes}
                      toggleShareModel={(link) => {
                        console.log("toggle share is clicked")
                      }}
                      onClose={() => console.log("close button is clicked")}
                      type={"DRAW-VIEW"}
                    />
                  </div>
                </div>
              )}
        </div>
        {/* footer */}
        <div className="draw-window-footer">
          <button
            className={
              toggleAnimation || winners.length > 0 ? 
              "draw-btn-disabled" : 
              "draw-btn"
            }
            disabled={toggleAnimation}
            onClick={onProceed}
          >
            DRAW
          </button>
          <button
            className={toggleAnimation ? "close-btn-disabled " : "close-btn"}
            disabled={toggleAnimation}
            onClick={onClose}
          >
            CLOSE
          </button>
        </div>
      </div>
    </div>
  );
};
