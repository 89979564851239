import React,  { useState } from 'react';
import './showParticipantsList.scss'
import { useEffect } from 'react';

export default ({ onClose, raffle, title }) => {

    const [winnersAndParticipants, setWinnersAndParticipants] = useState(true);
    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        getParticipantsList(raffle.participants);
    }, [raffle])

    const getParticipantsList = (participants) => {
        let participantsList = []; 
        for (let participant of participants) {
          const ticketNumber = participant.ticketNumber;

          // Check if participant already exists in the list
          const existingParticipant = participantsList.find(p => p.email === participant.email);
          if (existingParticipant) {
            existingParticipant.ticketNumber.push(ticketNumber);
          } else {
            participant.ticketNumber = [participant.ticketNumber];
            participantsList.push(participant);
          }
        }
        console.log("participantsList:::::::::::::::::::::::::", participantsList);
        setParticipants(participantsList);
        return participantsList;
    }
      

    return <div className="showParticipantsList">
        <div>
            {/* header */}
            <div>
                <img src={require('../../../assets/img/cup.png')} />
                <div>
                    {/* {title} */}
                    {title === "Winners" ? "Winner(s)" : title}
                </div>
                <div onClick={onClose}>X</div>
            </div>
            {/* content */}
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Ticket Number(s)</th>
                            <th>{title === "Winners" ? "Winners' Name(s)" : "Participants' Name(s)"}</th>
                            {raffle.winners.length > 0 ?
                                <th>Prize</th> :
                                null
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            [...title === "Winners" ? raffle.winners : participants].map((item, key) => {
                                return <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>{title === "Winners" ? item?.ticketNumber : item?.ticketNumber.join(', ')}</td>
                                    <td>{item?.name}</td>
                                    {raffle.winners.length > 0 ?
                                        <td>{item?.prize}</td> :
                                        null
                                    }
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
            {/* footer */}
            <div>
                <div onClick={onClose}>CLOSE</div>
            </div>
        </div>
    </div>
}