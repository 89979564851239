import React, { useState, useEffect, Imag } from "react";
import "./index.scss";
import firework_img from "../../assets/img/firework.gif";
import { useLocation, useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import { request } from "../../helper/request";
import {
  claimPrize,
  isValidToClaim,
  getUserByEmail,
  updateAddressUrl,
  sendEmails,
} from "../../config/endpoints";
import firebase from "../../helper/firebase";
import UpdateAddress from "../../components/workSpace/models/updateAddress";
import { IoLogoFacebook } from "react-icons/io";
import { FacebookProvider, Share } from "react-facebook";

export default () => {
  const [screen, toggleScreen] = useState("firework");
  const [isAuth, toggleAuth] = useState(true);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [claimStatus, setCliamStatus] = useState("cccccc");
  const [showAddressModel, toggleAddressModel] = useState(false);
  const [lAddress, setAddress] = useState("");
  const [user, setUser] = useState(null);
  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    if (location.search.length > 0) {
      if (location.search.split("&").length == 2) {
        const uid = location.search.split("&")[1].split("=")[1];
        const _id = location.search.split("&")[0].split("=")[1];
        console.log("_id:::::::::::::", _id);
        if (uid.length > 0 && _id.length > 0) {
          setLoading(true);
          request("POST_AUTH", isValidToClaim, {
            uid,
            _id,
          })
            .then((response) => {
              setLoading(false);
              if (response.status === 0) {
                toggleScreen("congrats");
              } else {
                toggleScreen("claim_status");
                setCliamStatus(response.payload.message);
              }
            })
            .catch((e) => {
              setLoading(false);
              toggleScreen("claim_status");
              setCliamStatus("OOPS! somthing went wrong");
            });
        } else {
          toggleAuth(false);
        }
      } else {
        toggleAuth(false);
      }
    }
  }, []);

  useEffect(() => {
    const uid = location.search.split("&")[1].split("=")[1];
    if (uid !== null) {
      firebase
        .storage()
        .ref()
        .child("profile_pics/" + uid)
        .getDownloadURL()
        .then((url) => {
          if (url) {
            setProfileImage(url);
          }
        })
        .catch((e) => {});
    }
  }, [user]);

  const claimPrizeClicked = () => {
    const uid = location.search.split("&")[1].split("=")[1];
    const _id = location.search.split("&")[0].split("=")[1];

    setLoading(true);
    // checking if user has address field
    request("POST_AUTH", getUserByEmail, { email: uid })
      .then((res) => {
        setLoading(false);
        if (res.status === 0) {
          setUser(res.payload[0]);
          if (
            "address" in res.payload[0] &&
            res.payload[0].address.trim().length > 0
          ) {
            setAddress(res.payload[0].address);
            proceedToClaimPrize(uid, _id, res.payload[0].address);
          } else {
            toggleAddressModel(true);
          }
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const proceedToClaimPrize = (uid, _id, addr) => {
    setLoading(true);
    request("POST_AUTH", claimPrize, {
      uid,
      _id,
      name: user.name,
      address: addr,
    })
      .then((response) => {
        setLoading(false);
        if (response.status === 0) {
          toggleScreen("claim_status");
          setCliamStatus("Claimed successfully!");
        } else {
          toggleScreen("claim_status");
          setCliamStatus("OOPS! somthing went wrong ");
        }
      })
      .catch((e) => {
        setLoading(false);
        toggleScreen("claim_status");
        setCliamStatus("OOPS! somthing went wrong");
      });
  };


  const updateAddress = (address) => {
    const uid = location.search.split("&")[1].split("=")[1];
    const _id = location.search.split("&")[0].split("=")[1];

    setLoading(true);
    request("POST_AUTH", updateAddressUrl, { email: uid, address })
      .then((response) => {
        setLoading(false);
        if (response.status === 0) {
          proceedToClaimPrize(uid, _id, address);
          setAddress(address);
        } else {
          setCliamStatus("Error: " + response.payload.message);
        }
      })
      .catch((e) => {
        setCliamStatus(e.message);
        setLoading(false);
      });
  };

  return isAuth ? (
    <div className="congrats_page">
      {screen === "firework" ? (
        <div className="firework">
          <img src={firework_img} alt="firework" />
        </div>
      ) : screen === "congrats" ? (
        <div className="congrats">
          <div className="logo-container">
            <img
              src={require("../../assets/img/logo.png")}
              alt="logo"
              className="logo-img"
            />
            {window.innerWidth > 720 && (
              <label className="logo-name">Rafflerra</label>
            )}
          </div>
          <h1 className="congo-logo">Congratulations</h1>
            <div className="profile-container">
                <img className="profile-img"
                  src={
                    profileImage === null
                      ? require("../../assets/img/cup.png")
                      : profileImage
                  }
                  alt="img"
                />
            </div>
          <div className="congo-quote">
            <p>Congratulations... Small victories are humbling</p>
            <p>and can be large wins</p>
          </div>
          <div>
            <div className="claim-btn" onClick={claimPrizeClicked}>Claim Prize</div>
          </div>
          <div className="facebook">
            <FacebookProvider appId="935291793651974">
              <Share href="https://www.facebook.com">
                {({ handleClick, loading }) => (
                  <div className="fb-btn" type="div" disabled={loading} onClick={handleClick}>
                    <IoLogoFacebook />
                    <p>Post your victory in rafflerra community</p>
                  </div>
                )}
              </Share>
            </FacebookProvider>
          </div>
          {showAddressModel ? (
            <UpdateAddress
              onProceed={updateAddress}
              onClose={() => toggleAddressModel(false)}
            />
          ) : null}
        </div>
      ) : (
        <div className="claim_response">
          <div>
            <p>{claimStatus}</p>
          </div>
        </div>
      )}
      {loading ? (
        <div className="loadingspinner">
          <Loader type="TailSpin" color="rgb(11, 70, 85)" width={60} />
        </div>
      ) : null}
    </div>
  ) : (
    <label className="not_auth_message">
      YOU ARE NOT AUTHORIZED TO VIEW THIS PAGE
    </label>
  );
};
