import React, { useEffect, useState, usetate } from "react";
import "./CancelRaffleModel.scss";
import DrawRaffleViewModel from "../../models/DrawRaffleViewModel";
import DrumRoll from "../../models/DrumRoll";
import ConfirmCancel from "./ConfirmCancel";
import ParticipantRaffleViewModel from "../../models/participantRaffleViewModel";
import RaffleTicketViewModel from "./RaffleTicketViewModel";
import TicketViewModel from "../../models/TicketViewModel";

export default ({
  onClose,
  onProceed,
  raffle
}) => {

  const [confirmDilog, setConfirmDilog] = useState(false);

  const onClickContinue = () => {
    setConfirmDilog(true);
  }

  return (
    <div className="confirmation_dialouge">
      <div className="cancel-window">
        {/* header */}
        <div>
          <img src={require("../../../../assets/img/cup.png")} alt="Cup" />
          <div>{`Cancel Raffle - ${raffle.title}`}</div>
          <button
            className={confirmDilog ? "x-btn-disabled" : "x-btn-enabled"}
            disabled={confirmDilog}
            onClick={onClose}>X</button>
        </div>
        {/* content */}
        <div className="cancel-window-content">
          <div
            style={{
              display: confirmDilog ? "block" : "none",
              position: "absolute",
              zIndex: 9,
            }}
          >
            <ConfirmCancel setConfirmDilog={setConfirmDilog} onProceed={onProceed} />
          </div>
          <div className="cancel-window-ticket">
            <TicketViewModel
              userType={"host"}
              asParticipant={false}
              choosenRaffle={raffle}
              toggleShareModel={(link) => {
                console.log("toggle share is clicked")
              }}
              onClose={() => console.log("close button is clicked")}
              type={"DRAW-VIEW"}
            />
          </div>
          <div className="cancel-note">
            <i>Note: Please make sure this action complies with the raffle rules.</i>
          </div>
        </div>
        {/* footer */}
        <div>
          <div className="notify_checkbox">
            <input
              checked={true}
              type="checkbox"
            />
            <label> Notify participants about cancellation</label>
          </div>
          <div className="btn-container">
            <button
              className={confirmDilog ? "close-btn-disabled " : "close-btn"}
              disabled={confirmDilog}
              onClick={onClose}
            >
              CLOSE
            </button>
            <button
              className={confirmDilog ? "draw-btn-disabled " : "draw-btn"}
              disabled={confirmDilog}
              onClick={onClickContinue}>
              CONTINUE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};