import React, { useState } from 'react';
import './raffleInvitation.scss';
import { GrSend, GrCopy } from 'react-icons/gr';
import { getUserByEmail, sendInvitation, web_url } from '../../../config/endpoints';
import { useAlert } from 'react-alert';
import { request } from '../../../helper/request';
import { sendEmails as sendEmailsEndpoint } from '../../../config/endpoints';
import { useDispatch } from 'react-redux';
import { togggleLoad } from '../../../store/actions/loadAction';
import cryptoJS from 'crypto-js';
import { getDateStrWithTZ } from '../../../helper/appService';


const str_pass = "8isahnf9saipfsafnlkjaslfkj";

export default ({ onClose, raffleId, raffleTitle, raffleData }) => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const shareRaffleId = web_url + "joinRaffle?id=" + raffleId;
    const [emails, setEmails] = useState("");

    const sendEmails = async () => {
        const user = JSON.parse(sessionStorage.getItem("User"));
        const emailsList = emails.length > 0 ? emails.split(',').map(x => x.trim()) : [];
        if (emailsList.length > 0) {
            let error = "";
            emailsList.forEach(email => {
                if (!email.includes('@') && !email.includes('.')) {
                    error = "Error enter valid email. " + email;
                }
            })
            if (error.length === 0) {
                dispatch(togggleLoad());
                for (const email of emailsList) {

                    const participantObj = await request("POST_AUTH", getUserByEmail, { email: email });
                    const invitationPayload = { from: user?.email,
                        to: email,
                        raffleInfo: {
                          raffleId: raffleData._id,
                          raffleTitle: raffleData.title,
                          fee: raffleData.fee,
                          feeCurrency: raffleData.feeCurrency,
                          type: raffleData.type,
                          isMultipleDraws: raffleData.extras.seriesRaffle.isMultipleRaffles,
                        },
                        participationOpens: new Date(raffleData.participationOpens),
                        participationCloses: new Date(raffleData.participationCloses),
                        drawAt: raffleData.type === "Instant" ? null : new Date(raffleData.drawAt),
                        receivedAt: new Date(),
                        isTrash: false,
                        numTickets: 0,
                        hostName: user?.name,
                        isJoined: false,
                        invitedTimes: 1,
                    };
                    const invitationSaveResp = await request("POST_AUTH", sendInvitation, invitationPayload);
                    let link = shareRaffleId + "&email=" + email + "&invitationId=" + invitationSaveResp.payload._id;
                    const mainLink = link.split("?")[0];
                    const queryParams = link.split("?")[1];
                    const cipherText = cryptoJS.AES.encrypt(queryParams, str_pass).toString();
                    link = mainLink + "?" + cipherText;

                    const subject = `Join Our Raffle "${raffleData.title}" Event - Win Big!`
                    const text = `
                    <div> 
                    <p>Hello ${participantObj.payload[0]?.name || 'Participant'},</p>
                    <p style="margin-bottom:10px">Exciting news! You're invited to our raffle:</p>
                    <p style="margin-bottom:10px">🎉 Raffle Name: ${raffleData.title}</p>
                    <p>⏰ Open: ${getDateStrWithTZ(raffleData.extras.timeZone, raffleData.participationOpens)} (${new Date(raffleData.participationOpens)})</p>
                    <p>⏰ Close: ${getDateStrWithTZ(raffleData.extras.timeZone, raffleData.participationCloses)} (${new Date(raffleData.participationCloses)})</p>
                    ${raffleData.type !== "Instant" && raffleData.extras.seriesRaffle.recurrencePattern !== "On Demand" ? 
                        `<p style="margin-bottom:20px">🏆 Draw: ${getDateStrWithTZ(raffleData.extras.timeZone, raffleData.drawAt)} (${new Date(raffleData.drawAt)})</p>` : ``
                    }
                    <p>
                        Join the raffle <a style="margin-bottom:12px" href=${link}>here</a>, and Get your ticket(s) 
                        for a shot at awesome prizes. Spread the word, and let's make this unforgettable!
                    </p>
                    <p> Ignore this email if you have already joined this raffle. </p>
                    <p style="margin-bottom:10px">Best, Team Rafflera</p>
                    <p style="margin-bottom:20px">
                        For assistance, contact our support team at teamrafflerra@gmail.com.
                    </p>
                    </div>
                    `;

                    request("POST_AUTH", sendEmailsEndpoint, {
                        mail: email,
                        subject, text
                    })
                    .then((res) => { 
                        console.log(email, res); 
                    }).catch((e) => {
                        console.log(email, e.message); 
                    })
                    
                };
                if (error.length === 0) {
                    alert.removeAll();
                    alert.show("Invitation/s sent successfully");
                } else {
                    console.log(error);
                    alert.removeAll();
                    alert.show(error);
                }
                dispatch(togggleLoad());
            } else {
                alert.removeAll();
                alert.show("82", error);
            }
        }
    }

    return <div className="raffle_invitation">
        <div>
            {/* header */}
            <div>
                <img src={require('../../../assets/img/cup.png')} />
                <div>Send Invitations</div>
                <label onClick={onClose}>X</label>
            </div>
            {/* body */}
            <div>
                <p>Currently we support two ways of sending Invitations. You can either enter all the potential Participant emails separated by comma or copy the invitation link and broadcast it separately to all the potential participants.</p>
                <div>
                    <textarea onChange={(e) => setEmails(e.currentTarget.value)} placeholder="Enter emails here"></textarea>
                    <GrSend onClick={sendEmails} />
                </div>
                <div>OR</div>
                <div>
                    <input disabled value={shareRaffleId} />
                    <GrCopy onClick={() => {
                        navigator.clipboard.writeText(shareRaffleId);
                        alert.removeAll();
                        alert.show("Copied to clipboard")
                    }} />
                </div>
            </div>
        </div>
    </div>
} 