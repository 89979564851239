import React, { useState, useEffect } from 'react';
import SplashPage from './splashPage';
import LandingPage from './landingPage';
import AuthenticationPage from './authenticationPage';
import WorkSpace from './workSpace';
import JoinRaffle from './joinRafflePage';
import Congrats from './congratsPage';
import Guide from './guide';
import Catalouge from './catalouge';
import Collaborate from './collaborate';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LoadConfig } from '../store/actions/configAction';
import ForgotPassword from './forgotPasswordChange/forgotPassword';
import RaffleViewAsGuest from './viewAsGuest/raffleViewAsGuest';
import EmailVerification from './verificationPage/emailVerification';

export default () => {
    const dispatch = useDispatch();

    // load config
    useEffect(() => {
        dispatch(LoadConfig())
    }, []);

    const [openPage, setOpenPage] = useState("Participated Raffles");

    return <Router>
        <Switch>
            {/* <Route exact path="/" render={() => <SplashPage />} /> */}
            <Route exact path="/" render={() => <LandingPage />} />
            <Route exact path="/authentication" render={() => <AuthenticationPage />} />
            <Route exact path="/authentication/:app" render={() => <AuthenticationPage />} />
            <Route exact path="/workspace" render={() => <WorkSpace openPage={openPage} setOpenPage={setOpenPage}/>} />
            <Route exact path="/catalouge" render={() => <Catalouge />} />
            <Route exact path="/confirmAccount" render={() => <EmailVerification setOpenPage={setOpenPage}/>} />
            <Route exact path="/joinRaffle" render={() => <JoinRaffle setOpenPage={setOpenPage}/>} />
            <Route exact path="/infoRaffle" render={() => <RaffleViewAsGuest />} />
            <Route exact path="/forgetPassword" render={() => <ForgotPassword />} />
            <Route exact path="/congrats" render={() => <Congrats />} />
            <Route exact path="/guide" render={() => <Guide />} />
            {/* <Route exact path="/collaborate/:project/:user/:recipient" render={() => <Collaborate />}/>
            <Route exact path="/collaborate/:project/:user" render={() => <Collaborate />}/> */}
        </Switch>
    </Router>
}
