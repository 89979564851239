import { request } from '../../helper/request';
import { configHost } from '../../config/endpoints';


export const LoadConfig = () => {
    return (dispatch) => {
        dispatch({ type: "FETCHING_CONFIG" });
        request("GET_AUTH", configHost, {}).then((res) => {
            if (res.status === 0) {
                const payload = res.data;
                dispatch({ type: "FETCHED_CONFIG", payload });
            } else {
                dispatch({ type: "FETCH_ERROR" });
            }
        }).catch((e) => {
            dispatch({ type: "FETCH_ERROR" });
        })
    }
}