import React, { useState, useEffect } from 'react';
import './index.scss';
import { GoInfo } from 'react-icons/go';
import { request } from '../../../../helper/request';
import { toggleAccountStatusHost } from '../../../../config/endpoints';
import { useAlert } from 'react-alert';

export default () => {
    const [accountActive, setAccountActive] = useState(true);
    const [user, setUser] = useState(null);
    const alert = useAlert();

    useEffect(() => {
        let usr = JSON.parse(sessionStorage.getItem("User"));
        setUser(usr);
        setAccountActive(usr.status === "Active");
    }, []);

    const toggleActDeActButton = (toggle) => {
        setAccountActive(toggle);
        sessionStorage.setItem("User", JSON.stringify({ ...user, "status": toggle ? "Active" : "InActive" }));
        request("POST_AUTH", toggleAccountStatusHost, {
            _id: user._id,
            status: toggle ? "Active" : "InActive"
        }).then((res) => {
            console.log(res);
            if (res.status === 0) {
                alert.show(`Account ${toggle === true ? "Activate" : "Deactivate"}d successfully`);
            } else {
                alert.show(`Can't ${toggle === true ? "Activate" : "Deactivate"} the account at this moment`);
            }
        }).catch((e) => {
            alert.show(`Can't ${toggle === true ? "Activate" : "Deactivate"} the account at this moment`);
        })
    }

    return <div className="bene_dashboard">
        {/* header */}
        <div>
            {/* active/deactive button toggle */}
            <div>
                <div onClick={() => toggleActDeActButton(!accountActive)}>
                    <div className={accountActive ? "active" : "inactive"}>ACTIVATE</div>
                    <div className={!accountActive ? "active" : "inactive"}>DEACTIVE</div>
                </div>
                <div>
                    <GoInfo />
                </div>
            </div>
        </div>
    </div>
}