import React, { useState, useEffect } from "react";
import "./passwordChange.scss";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { loginUser, updatePassword } from "../../../../config/endpoints";
import { request } from "../../../../helper/request";
import { useDispatch } from "react-redux";
import { togggleLoad } from "../../../../store/actions/loadAction";
import { useAlert } from "react-alert";

export default ({ onClose, userEmail, passwordChanged }) => {
  const alert = useAlert();

  const dispatch = useDispatch();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [submitAttempted, setSubmitAttempted] = useState(false);

  // useEffect(() => {
  //   setOldPassword("");
  //   setNewPassword("");
  //   setConfirmPassword("");
  // }, [])

  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const toggleShowOldPassword = (ev) => {
    ev.preventDefault();
    setShowOldPassword(!showOldPassword);
  };

  const toggleShowNewPassword = (ev) => {
    ev.preventDefault();
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowConfirmPassword = (ev) => {
    ev.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onCloseWindow = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    onClose();
  };

  const onClickSubmit = async () => {
    setSubmitAttempted(true);

    if (
      oldPassword.trim() === "" ||
      newPassword.trim() === "" ||
      confirmPassword.trim() === ""
    ) {
      alert.removeAll();
      alert.show("Please fill all required fields");
      return;
    }
    if (/\s/.test(newPassword)) {
      alert.removeAll();
      alert.show("Spaces are not allowed in the new password");
      return;
    }
    if (newPassword.trim().length > 0 && newPassword.trim().length < 8) {
      alert.removeAll();
      alert.show("New password should have minimum of 8 characters");
      return;
    }
    if (newPassword !== confirmPassword) {
      alert.removeAll();
      alert.show("New password and confirm password do not match");
      return;
    }
    dispatch(togggleLoad());
    if (newPassword === confirmPassword) {
      request("POST", loginUser, {
        email: userEmail,
        password: oldPassword,
      })
        .then(async (response) => {
          if (response.status === 0) {
            // change password
            const payload = {
              email: userEmail,
              newPassword: newPassword,
            };
            let changePassResp = await request("POST", updatePassword, payload);
            if (changePassResp.status === "SUCCESS") {
              // success
              alert.removeAll();
              alert.show("Password updated successfully");
              passwordChanged(changePassResp.payload);
              onCloseWindow();
            } else {
              alert.removeAll();
              alert.show("Error in updating password");
            }
          } else {
            // wrong password
            alert.removeAll();
            alert.show("Invalid current password");
          }
          dispatch(togggleLoad());
        })
        .catch((e) => {
          dispatch(togggleLoad());
        });
    } else {
      alert.removeAll();
      alert.show("New password and confirm password do not match");
      dispatch(togggleLoad());
    }
  };

  return (
    <div className="cp-transparent-bg">
      <div className="cp-container">
        <div className="cp-header">
          <div>CHANGE PASSWORD</div>
          <button
            className="cp-x-btn"
            onClick={(ev) => {
              ev.preventDefault();
              onCloseWindow();
            }}
          >
            X
          </button>
        </div>
        <div className="cp-body">
          <div className="label-input">
            <label>
              Current password<span className="required-marker">*</span>
            </label>
            <div className="input-eye">
              <input
                type={showOldPassword ? "text" : "password"}
                onChange={handleOldPasswordChange}
                required
                value={oldPassword}
              />
              <button
                className="password-toggle"
                onClick={toggleShowOldPassword}
              >
                {showOldPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            {submitAttempted && oldPassword.trim() === "" && (
              <p className="error-message show">Required</p>
            )}
          </div>
          <div className="label-input">
            <label>
              New password<span className="required-marker">*</span>
            </label>
            <div className="input-eye">
              <input
                type={showNewPassword ? "text" : "password"}
                onChange={handleNewPasswordChange}
                required
                minLength={6}
                value={newPassword}
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|\\:;'<>,.?/~])(?!.*\s).{8,}$"
                title="Password must be atleast 8 characters with uppercase, lowercase, digit, and special character."
              />
              <button
                className="password-toggle"
                onClick={toggleShowNewPassword}
              >
                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            {submitAttempted && newPassword === "" && (
              <p className="error-message show">Required</p>
            )}
            {submitAttempted &&
              newPassword.length > 0 &&
              /\s/.test(newPassword) && (
                <p className="error-message show">Space(s) not allowed</p>
              )}
            {submitAttempted &&
              newPassword.trim().length > 0 &&
              newPassword.trim().length < 8 && (
                <p className="error-message">Min 8 characters required</p>
              )}
          </div>
          <div className="label-input">
            <label>
              Confirm password<span className="required-marker">*</span>
            </label>
            <div className="input-eye">
              <input
                type={showConfirmPassword ? "text" : "password"}
                onChange={handleConfirmPasswordChange}
                required
                value={confirmPassword}
              />
              <button
                className="password-toggle"
                onClick={toggleShowConfirmPassword}
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            {submitAttempted && confirmPassword.trim() === "" && (
              <p className="error-message show">Required</p>
            )}
          </div>
        </div>
        <div className="cp-footer">
          <button
            className={"cp-close-btn"}
            onClick={(ev) => {
              ev.preventDefault();
              onCloseWindow();
            }}
          >
            CANCEL
          </button>
          <button
            className="cp-confirm-btn"
            onClick={(ev) => {
              ev.preventDefault();
              onClickSubmit();
            }}
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  );
};
