import React, { useEffect, useState } from 'react';
import './index.scss';
import ShowBeneModel from '../../models/showBene';
import { useSelector, useDispatch } from 'react-redux';
import { UpdateMyBeneficiaries } from '../../../../store/actions/beneficiaryAction';
import { TiBookmark, TiDelete } from 'react-icons/ti';
import { request } from '../../../../helper/request';
import { updateBenesInUserHost } from '../../../../config/endpoints';

export default () => {
    const { myBeneficiaries } = useSelector(benes => benes.BeneficiaryReducer);
    const dispatch = useDispatch();
    const [showBeneViewModel, toggleBeneViewModel] = useState(false);
    const [choosenBene, setChoosenBene] = useState(null);
    const [user, setUser] = useState(null)

    useEffect(() => {
        setUser(JSON.parse(sessionStorage.getItem("User")));
    }, []);

    const deleteButtonCLicked = async (_id) => {
        //this has only list of beneficiary ids
        let benes = user.benes;
        benes = benes.filter(x => x !== _id);
        sessionStorage.setItem("User", JSON.stringify({ ...user, "benes": benes }));
        setUser(usr => ({ ...usr, "benes": benes }));

        //updating my Beneficiaries state(delete item)
        let beneficiaries = myBeneficiaries.filter(x => x._id !== _id);
        dispatch(UpdateMyBeneficiaries(beneficiaries));

        await request("POST_AUTH", updateBenesInUserHost, { _id: user._id, benes });
    }

    return <div className="host_my_bene">
        <div>
            {
                myBeneficiaries.map((bene, i) => {
                    return <div className={bene.user[0].status === "Active" ? "" : "inactive"} key={i}>
                        <div></div>
                        <div onClick={() => {
                            toggleBeneViewModel(true);
                            setChoosenBene(bene);
                        }
                        }>
                            <label>{bene.user[0].name}{bene.user[0].status === "Active" ? "" : " (This beneficiary not intersted to run a raffle at this moment)"}</label>
                            <label>{bene.description}</label>
                        </div>
                        <div>
                            <TiDelete onClick={() => deleteButtonCLicked(bene._id)} />
                        </div>
                    </div>
                })
            }
        </div>
        {
            showBeneViewModel ?
                <ShowBeneModel
                    onClose={() => toggleBeneViewModel(false)}
                    data={choosenBene}
                /> : null
        }
    </div>
}