import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-logger';
import logger from 'redux-thunk';
import BeneficiaryReducer from './reducers/beneficiaryReducer';
import RaffleReducer from './reducers/raffleReducer';
import LoadReducer from './reducers/loadReducer';
import ConfigReducer from './reducers/configReducer';
import InboxReducer from './reducers/inboxReducer';

const middleWare = applyMiddleware(thunk, logger)

const reducers = combineReducers({ BeneficiaryReducer, RaffleReducer, LoadReducer, ConfigReducer, InboxReducer });

const store = createStore(reducers, middleWare)

export default store;