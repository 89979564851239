import React, { useState } from 'react';
import './updateAddress.scss';
import { MdClose } from 'react-icons/md';
import { BsExclamationTriangle } from 'react-icons/bs';

export default ({ onClose, onProceed }) => {
    const [address, setAddress] = useState("");

    return <div className="update_address">
        <div>
            {/* header */}
            <div>
                <div>Shipping Address</div>
                <label onClick={onClose}>X</label>
            </div>
            {/* body */}
            <div>
                <div>
                    <BsExclamationTriangle />
                    <p>Looks's like you are missing shipping address, type it below</p>
                </div>
                <textarea onChange={(e) => {
                    const text = e.currentTarget.value;
                    setAddress(text);
                }} placeholder="Type your full address..."></textarea>
            </div>
            {/* footer */}
            <div>
                <div onClick={() => onProceed(address)}>Claim It</div>
                <div onClick={onClose}>Close</div>
            </div>
        </div>
    </div>
}