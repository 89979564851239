import React, { useEffect, useState } from 'react';
import './index.scss';
import { BsChat, BsSearch } from 'react-icons/bs';
import { AiOutlineSend } from 'react-icons/ai';
import { IoIosCloseCircle } from 'react-icons/io';
import { FaUserCircle } from 'react-icons/fa';
import { TiMessage } from 'react-icons/ti';
import { CgSortAz } from 'react-icons/cg';
import firebase from '../../helper/firebase';
import { useLocation } from 'react-router-dom';

import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// dev setup
let email;
let project;
const userTypes = ['All', 'Sponsors', 'Beneficiaries', 'Event Managers'];

export default () => {
    const location = useLocation();

    const [myFriends, setMyFriedns] = useState([]);
    const [myTempFriends, setMyTempFriedns] = useState([]);
    const [activeReciever, setActiveReciever] = useState("");
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");

    const [userTypeMenu, toggleUserTypeMenu] = useState(false);

    const [showLoader, toggleLoader] = useState(false);

    // load all of my groups
    useEffect(() => {
        project = location.pathname.split("/")[2];
        email = location.pathname.split("/")[3];
        toggleLoader(true);
        firebase.database().ref('Users').child(project).child(email).once('value').then((snapshot) => {
            toggleLoader(false)
            if (snapshot.val() != null) {
                setMyFriedns(Object.values(snapshot.val()["friends"]));
                setMyTempFriedns(Object.values(snapshot.val()["friends"]));
            }
        }).catch((e) => {
            toggleLoader(false);
        });
    }, []);

    // load messages on selecting a receiever
    useEffect(() => {
        firebase.database().ref("Messages").child(project).child(email).on('value', (snapshot) => {
            if (snapshot.exists()) {
                setMessages(Object.values(snapshot.val()).reverse())
            }
        });
    }, [activeReciever]);

    const _sendMessage = async () => {
        setMessage("")
        const data = { sender: email, receiver: activeReciever, message, datetime: new Date().getTime(), status: "pending" }
        try {
            await firebase.database().ref("Messages").child(project).child(activeReciever).push(data);
            await firebase.database().ref("Messages").child(project).child(email).push(data);
        } catch (e) {

        }
    }

    const _sortByUserType = (type) => {
        toggleUserTypeMenu(false)
        if (type === "All") {
            setMyFriedns([...myTempFriends])
        } else if (type === "Sponsors") {

        } else if (type === "Beneficiaries") {

        } else if (type === "Event Managers") {

        }
    }

    // aside
    const _renderGroup = () => {
        return <aside>
            <div className="search">
                <div className="conatiner">
                    <BsSearch />
                    <input
                        onChange={(e) => {
                            const text = e.currentTarget.value.toLowerCase();
                            if (text.length > 0) {
                                setMyFriedns([...myTempFriends.filter(x => x.toLowerCase().includes(text))])
                            } else {
                                setMyFriedns([...myTempFriends]);
                            }
                        }}
                        placeholder="..." />
                    {/* <div>
                        <CgSortAz onClick={(e) => {
                            toggleUserTypeMenu(true);
                        }} />
                        {
                            userTypeMenu ?
                                <div>
                                    {userTypes.map((x, index) => <div id={`sort_account_type_list_${x}`} onClick={() => _sortByUserType(x)} key={index}>{x}</div>)}
                                </div> : null
                        }
                    </div> */}
                </div>
            </div>
            <div className="conatent">
                {
                    messages.length > 0 ? myFriends.map((x, i) => {
                        const latest_msg = messages.find(y => y.sender === x || y.receiver === x)['message'];
                        return <div key={i} onClick={() => setActiveReciever(x.toString())}>
                            <FaUserCircle />
                            {/* content */}
                            <div>
                                <strong>{x.split('@')[0].replace(/\^/g, '.')}</strong>
                                <p>{latest_msg.length > 0 ? latest_msg.substring(0, 40)+"..." : "..."}</p>
                            </div>
                        </div>
                    }) : null
                }
            </div>
        </aside>
    }

    const _renderMessages = () => {
        return <div className="receiever_selected">
            {
                messages.map(({ sender, message, receiver }, index) => {
                    if (sender === activeReciever || receiver === activeReciever) {
                        return <div className={sender === email ? "left_align" : "right_align"} key={index}><label>{message}</label></div>
                    }
                })
            }
        </div>
    }

    return <div id="collaborate">
        <nav>
            <TiMessage />
            <label>Rafflerra Connect</label>
            <IoIosCloseCircle onClick={() => window.close()} />
        </nav>
        {_renderGroup()}
        <div id="conatiner">
            {/* header */}
            <div>
                <label>{activeReciever.split('@')[0].replace(/\^/g, '.')}</label>
            </div>
            {
                activeReciever.length > 0 ? _renderMessages() : <div className="no_receiever_selected">Welcome back to rafflerra connect</div>
            }
            {/* message type conatiner */}
            {
                activeReciever.length > 0 ?
                    <div>
                        <input value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Type here ..." />
                        {/* send button */}
                        <AiOutlineSend onClick={_sendMessage} />
                    </div> : null
            }
        </div>
        {
            showLoader ?
                <div id="collaborate_loader">
                    <Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={40}
                        width={40}
                    />
                </div> : null
        }
    </div>
}