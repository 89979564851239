import React, { useEffect, useState } from "react";
import './raffleViewAsGuest.scss'
import { getRaffleById, getUserByEmail } from "../../config/endpoints";
import { request } from "../../helper/request";
import RaffleTicketViewModel from "../workSpace/drawerPages/hostedRaffles/RaffleTicketViewModel";
import Loader from "react-loader-spinner";
import { CRYPTER_SECRET } from "../../helper/appService";
import Cryptr from 'cryptr';
import TicketViewModel from "../workSpace/models/TicketViewModel";
import SocialShareModel from "../workSpace/models/socialShareModel";
const cryptr = new Cryptr(CRYPTER_SECRET);

const RaffleViewAsGuest = () => {

    const [raffle, setRaffle] = useState(null);
    const [choosenRafflePrizes, setChoosenRafflePrizes] = useState([]);
    const [raffleId, setRaffleId] = useState("");
    const [guestInfo, setGestInfo] = useState(null);
    const [loading, toggleLoading] = useState(false);
    const [activeModel, setActiveModel] = useState("");
    const [socialShareLink, setSocialShareLink] = useState("");

    // validation page
    useEffect(() => {
        const url = window.location.href;
        const encryptedParams = url.split('?')[1];
        const decyptedParams = cryptr.decrypt(encryptedParams);
        const searchParams = new URLSearchParams(new URL(`${url.split('?')[0]}?${decyptedParams}`).search);
        const _id = searchParams.get('id');
        const guestEmail = searchParams.get('email');
        const guestMobile = searchParams.get('mobile');
        setGestInfo({ email: guestEmail, mobile: guestMobile });
        console.log("params::::::::::::::::::::::::", _id, guestEmail, guestMobile)
        setRaffleId(_id);
    }, []);

    // getRaffle details
  useEffect(() => {
    if (raffleId.length > 0) {
      toggleLoading(true);
      request("POST_AUTH", getRaffleById, { _id: raffleId })
        .then(async (res) => {
          setRaffle(res.payload.raffle);
          setChoosenRafflePrizes(res.payload.prizes);
          toggleLoading(false);
        })
        .catch((e) => {
          toggleLoading(false);
          alert.removeAll();
          alert.show(e.message);
        });
    }
  }, [raffleId]);

  const _renderModel = () => {
    if (activeModel.length > 0) {
      switch(activeModel) {
        case "SocialShareModel":
          return (
            <SocialShareModel
              onClose={() => setActiveModel("")}
              url={socialShareLink}
            />
          );
      }
    }
  }

    return (
        <div className="gues-raff-view-container">
            <div className="header">
                <img src={require("../../assets/img/logo.png")} className="logo-png"/>
                <label className="app-title">Rafflerra</label>
            </div>
            <div className="content">
                {raffle && <RaffleTicketViewModel
                  userType={"participant"}
                  asParticipant={true}
                  choosenRaffle={raffle}
                  choosenRafflePrizes={choosenRafflePrizes}
                  toggleShareModel={(link) => {
                    setActiveModel("SocialShareModel");
                    setSocialShareLink(link);
                  }}
                  // onClose={() => setActiveModel("")}
                  type={"GUEST-VIEW"}
                  guestInfo={guestInfo}
                />}
            </div>
            {_renderModel()}
            {loading ? (
                <div className="loadingspinner">
                  <Loader type="TailSpin" color="rgb(11, 70, 85)" width={60} />
                </div>
            ) : null}
        </div>
    )
}

export default RaffleViewAsGuest;