import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './index.scss';

let hours = new Date().getHours();
let introMsg = hours < 12 ? "Good Morning!" : hours < 18 ? "Good Afternoon!" : 'Good Evening!';

export default () => {
    const [bgImageAnimation, bgImageAnimate] = useState({});
    const [rootAnimation, rootAnimate] = useState({});
    const [animation1, animate1] = useState({});
    const [animation2, animate2] = useState({});
    const [animation3, animate3] = useState({});
    const history = useHistory();

    // const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => { animate1({ fontSize: 60 }) }, 1000);
        setTimeout(() => { animate1({ top: '6%', fontSize: 20 }) }, 1500);
        setTimeout(() => { animate2({ fontSize: 48 }) }, 2000);
        setTimeout(() => { animate2({ top: '12%', fontSize: 20 }) }, 2500);
        setTimeout(() => { animate3({ fontSize: 60 }) }, 3000);
        setTimeout(() => { animate3({ top: '18%', fontSize: 20 }) }, 3500);
        setTimeout(() => { bgImageAnimate({ height: 0 }); rootAnimate({ opacity: 0 }); }, 5000);
        // setTimeout(() => { dispatch(toggleScreenAction("LandingPage")) }, 6000);
        setTimeout(() => { history.push('/home')}, 6000);
    }, []);

    return <div style={rootAnimation} className="splash_page">
        <img style={bgImageAnimation} src={require('../../assets/img/background.jpg')} />
        <div style={animation1}>{"Hello, " + introMsg}</div>
        <div style={animation2}>Welcome to the Raffle Headquarters of the World!</div>
        <div style={animation3}>The future of raffling is here...</div>
    </div>
}