/* eslint-disable default-case */
import React, { useState, useEffect, Fragment } from "react";
import "./index.scss";
import {
  GoSearch,
  GoDashboard,
  GoListUnordered,
} from "react-icons/go";
import {
  AiOutlineLogout,
  AiOutlineHistory,
  AiOutlineSetting,
} from "react-icons/ai";
import { IoIosPaper } from "react-icons/io";
import { MdAddBox, MdMenu } from "react-icons/md";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FiMail } from 'react-icons/fi';
import { FcDocument } from "react-icons/fc";
import BeneficiarySettings from "./drawerPages/beneficiarySettings";
import BeneciaryDashboard from "./drawerPages/beneciaryDashboard";
import HostBeneSearch from "./drawerPages/hostBeneSearch";
import HostMyBene from "./drawerPages/hostMyBene";
import HostedRaffles from "./drawerPages/hostedRaffles";
import MyAccount from "./drawerPages/myAccount";
import RaffleCatalogue from "./drawerPages/raffleCatalogue";
import ParticipatedRaffles from "./drawerPages/participatedRaffles";
import Dashboard from "./drawerPages/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import CreateRaffleModel from "./models/createRaffleModel";
import CustomizeRaffleModel from "./models/customizeRaffle";
import SocialShareModel from "./models/socialShareModel";
import ShowParticipantsListModel from "./models/showParticipantsList";
import QrCodeModel from "./models/showQrCodeModel";
import ActivateDeactivateRaffleModel from "./models/activateDeactivateRaffle";
import Loader from "react-loader-spinner";
import ReactTooltip from "react-tooltip";
import firebase from "../../helper/firebase";
import { LoadMyRaffles } from "../../store/actions/raffleAction";
// model
import ConfirmationDialouge from "./models/comfirmationDialouge";
// react alert
import { useAlert } from "react-alert";
// endpoints
import { web_url, pickWinners, cancelRaffleUrl, sidePanelList, getRaffleById, getRafflePrizes } from "../../config/endpoints";
// redux
import { LoadBeneficiaries } from "../../store/actions/beneficiaryAction";
import {
  RaffleWinnerPicked,
} from "../../store/actions/raffleAction";
import { togggleLoad } from "../../store/actions/loadAction";
// api call
import { request } from "../../helper/request";
// icons
import { HiTemplate } from "react-icons/hi";
// components
import RaffleTemplates from "./drawerPages/raffleTemplates";
import CancelRaffleModel from "./drawerPages/hostedRaffles/CancelRaffleModel";
import RaffleTicketViewModel from "./drawerPages/hostedRaffles/RaffleTicketViewModel";
import RaffleNotifications from "../workSpace/drawerPages/raffleNotifications";
import { FaRegCalendarCheck, FaTicketAlt } from "react-icons/fa";
import { TiThMenu } from "react-icons/ti";
import { RiCloseCircleLine } from "react-icons/ri";
import MonthlyRaffle from "./models/seriesRaffleComponents/monthlyRaffle";
import InvitationsList from "./drawerPages/raffleInvitations/invitationsList";

export default (props) => {
  const [toggleDrawer, setToggleDrawer] = useState(true);
  // const [notificationView, setNotificationView] = useState(2);
  const [user, setUser] = useState(null);
  const [activeDrawerItem, setActiveDrawerItem] = useState("Participated Raffles");
  const [userRole, setUserRole] = useState("participant");
  const [activeAccountType, setAccountType] = useState("");
  const [activeModel, setActiveModel] = useState("");
  const dispatch = useDispatch();
  const [customizeCreateRaffleModel, setCustomizeCreateRaffleModel] = useState(
    {}
  );
  const [timeDelay, setTimeDelay] = useState(false);

  //To activate drumroll
  const [activeDrumroll, setActiveDrumroll] = useState(false);

  // raffle, holds instant draw raffle
  const [drawRaffle, setDrawRaffle] = useState(null);

  // react alert
  const alert = useAlert();

  //for Host account
  const { status, myRaffles } = useSelector(
    ({ RaffleReducer }) => RaffleReducer
  );
  //for host and participant
  const [choosenRaffle, setChoosenRaffle] = useState({});
  const [choosenRafflePrizes, setChoosenRafflePrizes] = useState([]);

  //global state for progressbar
  // const { loading } = useSelector(({ LoadReducer }) => LoadReducer);

  // profileImage
  const [profileImage, setProfileImage] = useState(null);

  // social share link
  const [socialShareLink, setSocialShareLink] = useState("");

  // to list the participnats in a raffle
  const [particpnats, setParticipants] = useState([]);

  // to hold qr code link from hosted raffles
  const [qrlink, setQrlink] = useState("");
  const [activeRafflename, setActiveRaffleName] = useState("");

  // customize theme in create/ update raffle model
  const [raffleFormType, setRaffleFormType] = useState("New");
  const [raffleData, setRaffleData] = useState(null);

  // create raffle template
  const [createRaffleTemplate, setCreateRaffleTemplate] = useState(false);
  const [templateChoosen, setTemplateChoosen] = useState(null);

  // to cary if series edit or occur edit
  const [seriesEdit, setSeriesEdit] = useState(false);

  const history = useHistory();
  const [toggleAnimation, setToggleAnimation] = useState(false);
  const [winners, setWinners] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [winnersPrizeType, setWinnersPrizeType] = useState([]);
  const [winnersCurrencyType, setWinnersCurrencyType] = useState([]);

  const [winnersTab, setWinnersTab] = useState(false);

  const [sidePanelRaffle, setSidePanelRaffles] = useState({
    instantRaffles: [],
    drawAtRaffles: [],
    onDemandRaffles: []
  });

  const [drawMode, setDrawMode] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.openPage === "invitations") {
      setActiveDrawerItem("Invitations");
      props.setOpenPage("")
    }
  }, [])

  // load profile picture
  useEffect(() => {
    if (user !== null) {
      firebase
        .storage()
        .ref()
        .child("profile_pics/" + user.email)
        .getDownloadURL()
        .then((url) => {
          if (url) {
            setProfileImage(url);
          }
        })
        .catch((e) => { });
    }
  }, [user]);

  //customize workspace according to the user type and loading raffles & redirecting back to authentication page if not authorized
  useEffect(() => {
    let usr = sessionStorage.getItem("User");
    if (usr) {
      usr = JSON.parse(usr);
      setUser(usr);
      setAccountType(usr?.accountType);
      //load all beneficiaries
      dispatch(LoadBeneficiaries());
    } else {
      history.push("/authentication");
    }
  }, []);

  //all the drawer views
  const _renderBody = () => {
    switch (activeDrawerItem) {
      case "BeneficiarySettings":
        return <BeneficiarySettings />;
      case "BeneficiaryDashboard":
        return <BeneciaryDashboard />;
      case "HostBeneSearch":
        return <HostBeneSearch />;
      case "HostMyBene":
        return <HostMyBene />;
      case "Hosted Raffles":
        return (
          <HostedRaffles
            setActiveRaffleName={setActiveRaffleName}
            setQrlink={setQrlink}
            setActiveModel={setActiveModel}
            setChoosenRaffle={setChoosenRaffle}
            setChoosenRafflePrizes={setChoosenRafflePrizes}
            setSeriesEdit={setSeriesEdit}
            toRefreshSidePanel={onRefresh}
            resFromCreateWindow={resFromCreateWindow}
          // setParticipants={setParticipants}
          // asHost={true}
          />
        );
      //host and participant settings
      case "My Account":
        return <MyAccount setUsr={setUser} setProfileImage={setProfileImage} profileImage={profileImage} />;
      case "Rafflera Catalogue":
        return (
          <RaffleCatalogue
            setActiveModel={setActiveModel}
            setChoosenRaffle={setChoosenRaffle}

          />
        );
      case "Participated Raffles":
        return (
          <ParticipatedRaffles
            setActiveModel={setActiveModel}
            setChoosenRaffle={setChoosenRaffle}
            setChoosenRafflePrizes={setChoosenRafflePrizes}
            toRefreshSidePanel={onRefresh}
          />
        );

      case "Raffle Notifications":
        return (
          <RaffleNotifications
            // itemsPerPage={12}
            userRole={userRole}
            activeDrawerItem={activeDrawerItem}
          />
        );

      case "Invitations":
        return (
          <InvitationsList />
        );

      case "Raffle Dashboard":
        return <Dashboard />;

      case "Raffle Templates":
        return (
          <RaffleTemplates
            setCreateRaffleTemplate={setCreateRaffleTemplate}
            setActiveRaffleName={setActiveRaffleName}
            setQrlink={setQrlink}
            setParticipants={setParticipants}
            setActiveModel={setActiveModel}
            setChoosenRaffle={setChoosenRaffle}
          />
        );
      default:
        return;
    }
  };


  const _renderModel = () => {
    if (activeModel.length > 0) {
      switch (activeModel) {
        case "CreateRaffleModel":
          return (
            <CreateRaffleModel
              onClose={() => {
                setActiveModel("");
                setRaffleData(null);
                setCreateRaffleTemplate(false);
                setTemplateChoosen(null);
                setSeriesEdit(false);
              }}
              customize={customizeCreateRaffleModel}
              type="NEW"
              onCustomizeTheme={(data) => {
                setRaffleFormType("New");
                setActiveModel("CustomizeRaffleTicketModel");
                setRaffleData(data);
              }}
              raffleData={raffleData}
              createRaffleTemplate={createRaffleTemplate}
              templateChoosen={templateChoosen}
              onCreateOrUpdateHostedRaffs={onCreateOrUpdateHostedRaffs}
            />
          );
        case "UpdateRaffleModel":
          return (
            <CreateRaffleModel
              onClose={() => {
                setActiveModel("");
                setRaffleData(null);
                setCreateRaffleTemplate(false);
                setTemplateChoosen(null);
                setSeriesEdit(false);
              }}
              customize={customizeCreateRaffleModel}
              type="EDIT"
              choosenRaffle={choosenRaffle}
              choosenRafflePrizes={choosenRafflePrizes}
              onCustomizeTheme={(data) => {
                setRaffleFormType("Edit");
                setActiveModel("CustomizeRaffleTicketModel");
                setRaffleData(data);
              }}
              onActivateDeactivate={(data) => {
                setActiveModel("ActivateDeactivateRaffleModel");
                setRaffleData(data);
              }}
              raffleData={raffleData}
              createRaffleTemplate={createRaffleTemplate}
              templateChoosen={templateChoosen}
              seriesEdit={seriesEdit}
              setSeriesEdit={setSeriesEdit}
              onCreateOrUpdateHostedRaffs={onCreateOrUpdateHostedRaffs}
            />
          );
        case "CustomizeRaffleModel":
          return (
            <CustomizeRaffleModel
              response={(data) => setCustomizeCreateRaffleModel(data)}
              onChange={(view) => setActiveModel(view)}
              createRaffleTemplate={createRaffleTemplate}
              onClose={() => {
                setActiveModel("");
                setCreateRaffleTemplate(false);
                setTemplateChoosen(null);
              }}
              setTemplateChoosen={setTemplateChoosen}
            />
          );
        case "ParticipantRaffleViewJoinModel":
          return (
            <RaffleTicketViewModel
              userType={"participant"}
              asParticipant={true}
              choosenRaffle={choosenRaffle}
              toggleShareModel={(link) => {
                setActiveModel("SocialShareModel");
                setSocialShareLink(link);
              }}
              onClose={() => setActiveModel("")}
              type={"VIEWJOIN"}
            />
          );
        case "ParticipantRaffleViewModel":
          return (
            <RaffleTicketViewModel
              userType={"participant"}
              asParticipant={true}
              choosenRaffle={choosenRaffle}
              choosenRafflePrizes={choosenRafflePrizes}
              toggleShareModel={(link) => {
                setActiveModel("SocialShareModel");
                setSocialShareLink(link);
              }}
              onClose={() => setActiveModel("")}
              type={"VIEW"}
            />
          );
        case "SocialShareModel":
          return (
            <SocialShareModel
              onClose={() => setActiveModel("")}
              url={socialShareLink}
            />
          );
        case "ShowParticipantsListModel":
          return (
            <ShowParticipantsListModel
              onClose={() => setActiveModel("")}
              title="Participants"
              raffle={particpnats}
            />
          );
        case "ShowWinnersListModel":
          return (
            <ShowParticipantsListModel
              onClose={() => {
                setActiveModel("");
                // dispatch(LoadRaffles());
                // dispatch(LoadMyRaffles());
              }}
              title="Winners"
              raffle={particpnats}
            />
          );
        case "QrCodeModel":
          return (
            <QrCodeModel
              title={activeRafflename}
              onClose={() => setActiveModel("")}
              link={qrlink}
            />
          );
        case "ActivateDeactivateRaffleModel":
          return (
            <ActivateDeactivateRaffleModel
              onProceed={(raffle_data) => {
                setRaffleFormType("Edit");
                setRaffleData(raffle_data);
                setActiveModel("UpdateRaffleModel");
              }}
              raffleData={raffleData}
              onClose={() => {
                setActiveModel("");
                setRaffleData(null);
              }}
            />
          );
        case "DrawConfirmationDialouge":
          return (
            <ConfirmationDialouge
              onClose={() => {
                setActiveModel("");
                setDrawRaffle(null);
                setActiveDrumroll(false);
              }}
              onProceed={() => {
                _pickWinners(drawRaffle);
              }}
              raffle={drawRaffle}
              choosenRafflePrizes={choosenRafflePrizes}
              activeDrumroll={activeDrumroll}
              toggleAnimation={toggleAnimation}
              winners={winners}
              timeDelay={timeDelay}
              isPlaying={isPlaying}
              winnersCurrencyType={winnersCurrencyType}
              winnersPrizeType={winnersPrizeType}
            />
          );

        case "CancleRaffleModel":
          return (
            <CancelRaffleModel
              onClose={() => {
                setActiveModel("");
              }}
              onProceed={() => {
                cancelProceed(choosenRaffle);
              }}
              raffle={choosenRaffle}
            />
          );

        case "HostRaffleTicketViewModel":
          return (
            <RaffleTicketViewModel
              userType={"host"}
              asParticipant={false}
              choosenRaffle={choosenRaffle}
              choosenRafflePrizes={choosenRafflePrizes}
              drawMode={drawMode}
              toggleAnimation={toggleAnimation}
              timeDelay={timeDelay}
              isPlaying={isPlaying}
              activeDrumroll={activeDrumroll}
              winnersTab={winnersTab}
              setWinnersTab={setWinnersTab}
              onProceed={() => {
                _pickWinners(choosenRaffle);
              }}
              toggleShareModel={(link) => {
                setActiveModel("SocialShareModel");
                setSocialShareLink(link);
              }}
              onClose={() => {
                setActiveModel("");
                setDrawMode(false);
                setActiveDrumroll(false);
                setWinnersTab(false);
              }}
              type={"VIEW"}
            />
          );

      }
    } else {
      return null;
    }
  };

  const _renderDrawer = () => {
    return (
      <div style={toggleDrawer ? {} : { maxWidth: 0, minWidth: 0 }}>
        <div>
          <IoMdArrowRoundBack
            onClick={() => setToggleDrawer(false)}
            size={23}
          />
        </div>
        {
          user !== null ? (
            activeAccountType === "Beneficiary" ? (
              <Fragment>
                <div className="bene_drawerItem_raffle_dashboard">
                  <GoDashboard
                    onClick={() => setActiveDrawerItem("BeneficiaryDashboard")}
                    size={19}
                  />
                </div>
                <div className="drawerItem_settings">
                  <AiOutlineSetting
                    onClick={() => setActiveDrawerItem("BeneficiarySettings")}
                    size={19}
                  />
                </div>
                <div>

                </div>
              </Fragment>
            ) : activeAccountType === "Sponsor" ? (
              <Fragment>
                <div className="drawerItem_beneficiary_search">
                  <GoSearch size={19} />
                </div>
                <div className="drawerItem_raffle_history">
                  <AiOutlineHistory size={19} />
                </div>
                <div className="drawerItem_list_of_beneficiaries">
                  <GoListUnordered size={19} />
                </div>
                <div className="drawerItem_settings">
                  <AiOutlineSetting size={19} />
                </div>
              </Fragment>
            ) : (
              // activeAccountType === "Host" || activeAccountType === "Participant" ?
              <Fragment>
                <div className="drawerItem_create_raffle">
                  <MdAddBox
                    onClick={() => setActiveModel("CreateRaffleModel")}
                    size={19}
                    className="createRaffle"
                  />
                </div>
                <div className="drawerItem_invitation">
                  <FaRegCalendarCheck
                    onClick={() => setActiveDrawerItem("Invitations")}
                    size={19}
                    className="invitations-icon"
                  />
                </div>
                <div className="dashboard">
                  <GoDashboard
                    // onClick={() => setActiveDrawerItem("Raffle Dashboard")}
                    onClick={() => {
                      alert.removeAll();
                      alert.show("Coming soon");
                    }}
                    size={19}
                  />
                </div>
                <div className="drawerItem_raffle_catalogue">
                  <IoIosPaper
                    onClick={() => {
                      // window.open(web_url + "catalouge", "_blank")
                      alert.removeAll();
                      alert.show("Coming soon");
                    }}
                    size={19}
                  />
                </div>
                <div className="drawerItem_raffles_list">
                  <FaTicketAlt
                    onClick={() => {
                      setActiveDrawerItem("Participated Raffles");
                      setUserRole("participant");
                    }}
                    size={19}
                  />
                </div>
                <div className="drawerItem_templates">
                  <HiTemplate
                    // onClick={() => setActiveDrawerItem("Raffle Templates")}
                    onClick={() => {
                      alert.removeAll();
                      alert.show("Coming soon");
                    }}
                    size={19}
                  />
                </div>
                <div className="drawerItem_notification">
                  <FiMail
                    onClick={() => {
                      setActiveDrawerItem("Raffle Notifications");
                      setUserRole("participant");
                    }}
                    size={19}
                  />
                </div>
                <div className="drawerItem_settings">
                  <AiOutlineSetting
                    onClick={() => setActiveDrawerItem("My Account")}
                    size={19}
                  />
                </div>
                <div className="drawerItem_guide">
                  <FcDocument
                    // onClick={() =>
                    //   window.open(
                    //     web_url +
                    //     `guide?page=${user.accountType.toLowerCase()}`,
                    //     "_blank"
                    //   )
                    // }
                    onClick={() => {
                      alert.removeAll();
                      alert.show("Coming soon");
                    }}
                    size={19}
                  />
                </div>
              </Fragment>
            )
          ) : null
        }
        <div className="signout">
          <AiOutlineLogout
            onClick={() => {
              sessionStorage.removeItem("User");
              sessionStorage.removeItem("Token");
              history.push("/");
            }}
            size={19}
          />
        </div>
        <p style={{ color: "white", fontFamily: "Roboto", marginBottom: "10px", fontSize: "14px" }}>v0.1.2</p>
      </div>
    );
  };

  // useEffect(() => {
  //   const handleResize = () => {
  //     setToggleDrawer(window.innerWidth >= 720);
  //   };

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  const [toggleDrawer1, setToggleDrawer1] = useState(true);

  const [isMobile, toggleMobile] = useState(false);

  const [showMenu1, setShowMenu1] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const screenWidth = window.innerWidth;

  const toggleMenu = () => {
    setShowMenu1(true)
    setToggleDrawer1((prevState) => !prevState);
  };


  const _renderHeader = () => {
    return (
      <div className="header">
        {toggleDrawer ? null :
          <MdMenu className="toggle-drawer" onClick={() => setToggleDrawer(true)} />
        }
        <div className="logo-container">
          <img src={require("../../assets/img/logo.png")} alt="logo" className="logo-img" />
          {window.innerWidth > 720 &&
            <label className="logo-name">Rafflerra</label>
          }
        </div>
        <div className="tabs">
          {activeDrawerItem === "Hosted Raffles" ||
            activeDrawerItem === "Participated Raffles" ||
            activeDrawerItem === "Raffle Notifications" ? (
            <div className="tab_bar">
              <label
                onClick={() => {
                  setUserRole("participant");
                  if (!(activeDrawerItem === "Raffle Notifications")) {
                    setActiveDrawerItem("Participated Raffles");
                  }
                }}
                className={userRole === "participant" ? "active_item" : "noactive"}
              >
                AS A PARTICIPANT
              </label>
              {/* <div className="logo-container">
              <img src={require("../../assets/img/logo.png")} alt="logo" className="logo-img" />
              {window.innerWidth > 720 &&
                <label className="logo-name">Rafflerra</label>
              }
            </div> */}
              <label
                onClick={() => {
                  setUserRole("Host");
                  if (!(activeDrawerItem === "Raffle Notifications")) {
                    setActiveDrawerItem("Hosted Raffles");
                  }
                }}
                className={userRole === "Host" ? "active_item" : ""}
              >
                AS A HOST
              </label>

            </div>
          ) : (
            <Fragment>
              <label style={{ display: "none", opacity: "0.7" }}>{activeDrawerItem}</label>
            </Fragment>
          )}
        </div>
        {screenWidth > 720 || activeDrawerItem === 'My Account' ? null :
          <MdMenu className="toggle-right-drawer" onClick={toggleMenu} />
        }
      </div>
    );
  };

  const _pickWinners = (raffle, event) => {
    if (raffle.participants.length === 0) {
      alert.removeAll();
      alert.show("No participants have joined in your raffle");
    } else {
      setToggleAnimation(true);
      setActiveDrumroll(true);
      setTimeout(() => {
        setTimeDelay(true);
      }, 3000);
      setIsPlaying(true);
      setTimeout(() => {
        request("POST", pickWinners, { raffleid: raffle._id })
          .then((res) => {
            if (res.status === 0) {
              setChoosenRafflePrizes(res.payload.prizes)
              setChoosenRaffle(res.payload.raffle)
              alert.removeAll();
              if (res.payload.drawStatus === "Draw-cancelled") {
                alert.show("Draw cancelled");
              } else {
                alert.show("Winners picked");
                setWinnersTab(true);
              }
              setParticipants(res.payload);
              onCreateOrUpdateHostedRaffs(res.payload);
            } else {
              alert.removeAll();
              alert.show(res.msg);
            }
            setToggleAnimation(false);
            setIsPlaying(false);
          })
          .catch((e) => {
            dispatch(togggleLoad());
          });
      }, 7000);
    }
  };

  const cancelProceed = (raffle) => {
    console.log("cancelProceed........................");
    setActiveModel("");
    dispatch(togggleLoad());
    request("POST", cancelRaffleUrl, { id: raffle._id })
      .then((res) => {
        dispatch(togggleLoad());
        alert.removeAll();
        alert.show(res.msg);
      })
      .catch((e) => {
        dispatch(togggleLoad());
      });
  };

  const loadRafflePrizesToBeDrawn = (raffleId) => {
    dispatch(togggleLoad());
    request("GET_AUTH", getRafflePrizes + `?_id=${raffleId}`)
      .then((res) => {
        const prizesList = res.payload;
        setChoosenRafflePrizes(prizesList);
        dispatch(togggleLoad());
      })
      .catch((e) => {
        dispatch(togggleLoad());
      });
  }

  const onClickInstantRaffleToBeDrawn = async (raffleId) => {
    setLoading(true);
    request("POST_AUTH", getRaffleById, { _id: raffleId })
      .then((res) => {
        if (res.status === 0) {
          const raff = res.payload.raffle;
          const prizesList = res.payload.prizes;
          setChoosenRafflePrizes(prizesList);
          setChoosenRaffle(raff);

          if (userRole === "participant") {
            setActiveModel("ParticipantRaffleViewModel");
          }
          if (userRole === "Host") {
            setActiveModel("HostRaffleTicketViewModel");
            setDrawMode(true);
          }
          setLoading(false);
        }
        else {
          alert.removeAll();
          alert.show("Error loading raffle by id");
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });

      
      if (userRole === "Host") {
        setDrawMode(true);
      }
  }

  const countParticipantsByEmail = (participants) => {
    let emailCounts = {};
    for (let participant of participants) {
      const email = participant.email;
      emailCounts[email] = (emailCounts[email] || 0) + 1;
    }
    return emailCounts;
  }

  useEffect(() => {
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    apiSidePanelList(email, userRole)
  }, [userRole])

  const apiSidePanelList = (email, role) => {
    request("GET_AUTH", sidePanelList + `?email=${email}&role=${role}`).then((res) => {
      if (res.status === 0) {
        const payload = res.payload;
        setSidePanelRaffles(payload);
      } else {
        console.log("error-res", res);
      }
    }).catch((e) => {
      console.log("error in apiSidePanelList")
    })
  }

  // on click refresh button
  const onRefresh = () => {
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    apiSidePanelList(email, userRole)
  };

  const [resFromCreateWindow, setResFromCreateWindow] = useState({});

  const onCreateOrUpdateHostedRaffs = (rff) => {
    console.log("some thing created or updated");
    setResFromCreateWindow(rff);
  }

  return (
    <div className="work_space">
      {_renderDrawer()}
      {user !== null ? (
        <div className="body-container" style={toggleDrawer ? {} : { borderRadius: 0 }}>
          <div className="center-part">
            {_renderHeader()}
            <div>{_renderBody()}</div>
          </div>
          {activeDrawerItem !== 'My Account' &&
            <div className={showMenu1 ? "rightside1" : "rightside"} >
              <div>
                <button className={showMenu1 ? "closebtn" : "closebtn1"} onClick={() => setShowMenu1(false)}>X</button>
                <img
                  src={
                    profileImage === null
                      ? require("../../assets/img/cup.png")
                      : profileImage
                  }
                  alt="img"
                />
              </div>
              <div>{user.name}</div>
              {/* Instant raffles to drawn */}
              <div className="section-header">
                {
                  userRole === "participant" ? "Participating Instant Raffles" : "Instant Raffles to be Drawn"
                }
              </div>
              {/* list of raffles to be drawn */}
              <div style={{ flex: 1, overflowY: "auto" }} className="capsules-list" >
                {sidePanelRaffle.instantRaffles.map((raff, index) => {
                  return (
                    <div key={index}>
                      <div
                        className={"capsule"}
                        onClick={() => { onClickInstantRaffleToBeDrawn(raff._id); }}
                        data-tip data-for={"short_desc" + index}
                      >
                        <p className="raff-title" style={{ paddingRight: 5 }}>{raff.title}</p>
                        <p className="date-time">
                          {new Date(
                            raff.participationOpens
                          ).toLocaleDateString("en-US")}
                        </p>
                      </div>
                      <ReactTooltip place="right" id={"short_desc" + index}>
                        {raff.title}
                      </ReactTooltip>
                    </div>
                  );
                })}
              </div>

              {/* <div className="section-header">
                Upcoming Draws
              </div> */}
              {/* list of raffles to be scheduled drawn */}
              {/* <div style={{ flex: 1, overflowY: "auto" }} className="capsules-list" >
                {sidePanelRaffle.drawAtRaffles.map((raff, index) => {
                  let asHost =
                    raff.type === "Instant"
                      ?
                      false
                      :
                      true
                  let partObj = raff.participants.find(obj => obj.email === user.email);
                  let asParticipant =
                    raff.type === "Instant"
                      ?
                      false
                      :
                      //Today's Scheduled Draws as a Participant
                      new Date(raff.drawAt).toDateString() === new Date().toDateString() &&
                      raff.status !== "DRAWN" &&
                      raff.status !== "CANCEL"
                      && partObj?.email === user.email;

                  let isTodayScheduledToBeDrawn = userRole === "Host" ? asHost : asParticipant;
                  if (isTodayScheduledToBeDrawn) {
                    return (
                      <div key={index}>
                        <div
                          className="capsule"
                          onClick={() => {
                            setChoosenRaffle(raff);
                            { userRole === "Host" ? setActiveModel("HostRaffleTicketViewModel") : setActiveModel("ParticipantRaffleViewModel") }
                          }}
                          data-tip data-for={"short_desc" + index}
                        >
                          <p className="raff-title" style={{ paddingRight: 5 }}>{raff.title}</p>
                          <p className="date-time">
                            {new Date(
                              raff.drawAt
                            ).toLocaleString('en-US', {
                              hour: "2-digit",
                              minute: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            })}
                          </p>
                        </div>
                        <ReactTooltip place="left" id={"short_desc" + index}>
                          {raff.title}
                        </ReactTooltip>
                      </div>
                    );
                  }
                })}
              </div> */}

              <div className="section-header">
                {
                  userRole === "participant" ? "Participating On Demand Raffles" : "On Demand Raffles to be Drawn"
                }
              </div>
              {/* list of raffles to be scheduled drawn */}
              <div style={{ flex: 1, overflowY: "auto" }} className="capsules-list" >
                {sidePanelRaffle.onDemandRaffles.map((raff, index) => {
                  return (
                    <div key={index}>
                      <div
                        className={"capsule"}
                        onClick={() => { onClickInstantRaffleToBeDrawn(raff._id); }}
                        data-tip data-for={"short_desc" + index}
                      >
                        <p className="raff-title" style={{ paddingRight: 5 }}>{raff.title}</p>
                        <p className="date-time">
                          {new Date(
                            raff.participationOpens
                          ).toLocaleDateString("en-US")}
                        </p>
                      </div>
                      <ReactTooltip place="right" id={"short_desc" + index}>
                        {raff.title}
                      </ReactTooltip>
                    </div>
                  );
                })}
              </div>
            </div>}
        </div>
      ) : null}
      {_renderModel()}
      {loading ? (
        <div className="notif-loader">
          <Loader type="TailSpin" color="rgb(11, 70, 85)" width={60} />
        </div>
      ) : null}
    </div>
  );
};
