import * as React from 'react';

export default ({ backgroundColor }) => {
    return <svg width={716} height={386.104} viewBox="0 0 716 386.104">
        <defs>
            <filter
                id="prefix__a"
                x={0}
                y={0}
                width={716}
                height={386.104}
                filterUnits="userSpaceOnUse"
            >
                <feOffset dy={3} />
                <feGaussianBlur stdDeviation={3} result="blur" />
                <feFlood floodOpacity={0.78} />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g data-name="Group 11">
            <g filter="url(#prefix__a)">
                <path
                    data-name="Subtraction 4"
                    d="M645 374.1H70.3v-4.34a102.934 102.934 0 00-4.955-17.176 88.581 88.581 0 00-12.436-22.453 22.052 22.052 0 00-18.272-8.9 42.052 42.052 0 00-17.168 4.209A15.755 15.755 0 0019 318.566c0-7.122-4.486-12.916-10-12.916v-6.458c5.515 0 10-5.795 10-12.916S14.514 273.36 9 273.36v-6.46c5.515 0 10-5.795 10-12.916S14.514 241.07 9 241.07v-6.457c5.515 0 10-5.795 10-12.916S14.514 208.78 9 208.78v-6.457c5.515 0 10-5.795 10-12.916s-4.486-12.916-10-12.916v-6.458c5.515 0 10-5.795 10-12.916S14.514 144.2 9 144.2v-6.458c5.515 0 10-5.795 10-12.916S14.514 111.91 9 111.91v-6.457c5.515 0 10-5.8 10-12.917S14.514 79.62 9 79.62V64.049a64.538 64.538 0 007.939 3.225 57.606 57.606 0 0017.7 3.225c7.554 0 13.7-2.17 18.272-6.45 5.009-4.69 9.194-12.894 12.436-24.385A172.375 172.375 0 0070.3 13.51V6H645a190.546 190.546 0 003.234 23.582 115.792 115.792 0 005.492 19.274c2.679 6.771 5.83 11.882 9.366 15.192 4.571 4.28 10.719 6.45 18.272 6.45a57.607 57.607 0 0017.7-3.225A65.673 65.673 0 00707 64.049v12.988c-5.516 0-10 5.795-10 12.916s4.486 12.916 10 12.916v6.458c-5.516 0-10 5.795-10 12.916s4.486 12.916 10 12.916v6.459c-5.516 0-10 5.795-10 12.916s4.486 12.916 10 12.916v6.457c-5.516 0-10 5.8-10 12.917s4.486 12.916 10 12.916v6.46c-5.516 0-10 5.795-10 12.916s4.486 12.916 10 12.916v6.458c-5.516 0-10 5.795-10 12.916s4.486 12.916 10 12.916v6.459c-5.516 0-10 5.795-10 12.916s4.486 12.916 10 12.916v6.459c-5.516 0-10 5.795-10 12.916a15.9 15.9 0 001.35 6.483 12.023 12.023 0 003.549 4.628c-5.054-2.673-12.718-5.862-20.534-5.862a22.052 22.052 0 00-18.272 8.9 85.088 85.088 0 00-9.366 15.322 101.27 101.27 0 00-5.493 14.175A89.267 89.267 0 00645 374.1zm62-43.969c-.042-.03-1.058-.74-2.746-1.729a7.926 7.926 0 002.746.498v1.237z"
                    fill={backgroundColor}
                />
            </g>
            <g
                data-name="Rectangle 6"
                transform="translate(75 32)"
                fill="none"
                // stroke={props.styles.textColor}
                strokeWidth={8}
            >
                <rect width={566} height={318} rx={39} stroke="none" />
                <rect x={4} y={4} width={558} height={310} rx={35} />
            </g>
        </g>
    </svg>
}