import React, { useState, useEffect } from 'react';
import './DrumRoll.scss'

export default (timeDelay) => {
  const [displayGif, setDisplayGif] = useState(true);
  // const [participants, setParticipants] = useState([])
  const [confettiOn, setConfettiOn] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setConfettiOn(true);
    }, 3000)
    const timeoutId = setTimeout(() => {
      setDisplayGif(false);
    }, 5000); // 3000ms = 3 seconds

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className='container'>
      {displayGif && (
        <div className='drum-confetti-container'>
          <div
            className='drum-confetti-container-child'
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
              {confettiOn && <div
              className='confetti-container'
                style={{
                  display: confettiOn ? "block" : "none",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  zIndex: 99,
                }}>
                  <img className='confetti' src="Confettie2.gif" alt="Confetti" style={{height: "390px"}}/>
              </div>}
              <div className='drum-roll-container'>
                <img className='drum-roll-gif' src="DrumRoll2.gif" alt="GIF DrumRoll" />
              </div>
              {/* <audio src="/drum-roll-sound-effect.mp3" autoPlay={soundPlay} /> */}
          </div>
        </div>
      )}
    </div>
  );
}


