import React, { Fragment, useEffect, useState } from "react";
import './drawTabContent.scss'
import DrumRoll from "../../models/DrumRoll";

const options = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
}

const DrawsTabContent = ({
    choosenRaffle,
    onProceed,
    drawMode,
    winnersTab,
    toggleAnimation,
    timeDelay,
    isPlaying,
    activeDrumroll,
}) => {

    const [drawOccurances, setDrawOccurances] = useState([]);
    const [playConfirm, setPlayConfirm] = useState(false);
    const [confettiOn, setConfettiOn] = useState(false);

    useEffect(() => {
        setConfettiOn(true);
    }, [winnersTab]);

    useEffect(() => {
        setTimeout(() => {
            setConfettiOn(false);
        }, 15000)
    }, []);

    useEffect(() => {
        setPlayConfirm(isPlaying);
    }, [isPlaying]);

    useEffect(() => {
        if (!choosenRaffle.extras.seriesRaffle.isMultipleRaffles) { // single raffle with multiple draws
            if (choosenRaffle.extras.seriesRaffle.recurrencePattern === "Hourly") {
                setDrawOccurances([...choosenRaffle.extras.seriesRaffle.hourlyPattern]);
            } else if (
                choosenRaffle.extras.seriesRaffle.recurrencePattern === "Daily"
            ) {
                setDrawOccurances([
                    ...choosenRaffle.extras.seriesRaffle.dailyPattern.days,
                ]);
            } else if (
                choosenRaffle.extras.seriesRaffle.recurrencePattern === "Weekly"
            ) {
                setDrawOccurances([
                    ...choosenRaffle.extras.seriesRaffle.weeklyPattern.days,
                ]);
            } else if (choosenRaffle.extras.seriesRaffle.recurrencePattern === "On Demand") {
                setDrawOccurances([...new Array(choosenRaffle.extras.seriesRaffle.onDemandDraws || 0).keys()])
            } else {
                setDrawOccurances([]);
            }
        } else {
            if (choosenRaffle.type === "Instant") {
                setDrawOccurances([0]);
            } else {
                setDrawOccurances([choosenRaffle.drawAt]);
            }
        }
    }, [choosenRaffle])
    return (
        <Fragment>
            {playConfirm &&
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 9999,
                }}>
                    <div
                        style={{
                            display: activeDrumroll ? "block" : "none",
                            justifyContent: "center",
                            alignItems: 'center',
                            position: "relative",
                        }}
                    >
                        <DrumRoll
                            timeDelay={timeDelay}
                        />
                        <audio src="/drum-roll-sound-effect.mp3" autoPlay={isPlaying} />
                    </div>
                </div>
            }
            {/* {confettiOn &&
                <div style={{
                    position: "fixed",
                    zIndex: 99999999,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <img width={"100%"} src="Confettie2.gif" alt="Confetti" />
                </div>
            } */}
            <div className="draws-list">
                <table>
                    <thead>
                        <tr>
                            <th style={{ textAlign: "left" }}>#</th>
                            <th style={{ textAlign: "left" }}>Time Stamp</th>
                            <th style={{ textAlign: "left" }}>Status</th>
                            {drawMode &&
                                <th style={{ textAlign: "right" }}>Action</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {drawOccurances.map((date, j) => {
                            const drawIndex = choosenRaffle.extras.seriesRaffle.numofDrawnTimes.findIndex((obj) => obj.occurrenceNum === j + 1
                            );
                            const cancelIndex = choosenRaffle.extras.seriesRaffle.numofCancelledDraws.findIndex((obj) => obj.occurrenceNum === j + 1
                            )

                            const pastDrawsAndCancels = choosenRaffle.extras.seriesRaffle.numofDrawnTimes.length + choosenRaffle.extras.seriesRaffle.numofCancelledDraws.length;
                            return (
                                <tr key={j}>
                                    <td style={{ textAlign: "left" }}>{j + 1}</td>
                                    <td style={{ textAlign: "left" }}>
                                        {drawIndex === -1
                                            ? cancelIndex === -1 ?
                                                choosenRaffle.type === "Instant" ||
                                                    choosenRaffle.extras.seriesRaffle.recurrencePattern === "On Demand" ?
                                                    "-" //Await
                                                    :
                                                    new Date(date).toLocaleString("en-US", options)
                                                :
                                                new Date(choosenRaffle.extras.seriesRaffle.numofCancelledDraws[cancelIndex]?.timeStamp).toLocaleString("en-US", options)
                                            : new Date(choosenRaffle.extras.seriesRaffle.numofDrawnTimes[drawIndex]?.timeStamp).toLocaleString("en-US", options)}</td>
                                    <td style={{ textAlign: "left" }}>
                                        {drawIndex === -1
                                            ? cancelIndex === -1 ? "Yet to be drawn" :
                                                "Cancelled"
                                            : "Drawn"}
                                    </td>
                                    {drawMode &&
                                        <td style={{ textAlign: "right" }}>
                                            <button
                                                disabled={pastDrawsAndCancels !== j || toggleAnimation}
                                                className={pastDrawsAndCancels === j ? "draw-btn" : "draw-btn-disabled"}
                                                onClick={onProceed}
                                            >
                                                Draw
                                            </button>
                                        </td>
                                    }
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}

export default DrawsTabContent;