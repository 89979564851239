import React, { useEffect, useState } from 'react';
import './index.scss';
import { GoSearch } from 'react-icons/go';
import { useSelector, useDispatch } from 'react-redux';
import { LoadRaffles } from '../../../../store/actions/raffleAction';

import RaffleTicket from '../../../../assets/svg_components/raffle_ticket';


const sortRafflesList = ["ACTIVE", "COMPLETED"]

export default ({ setActiveModel, setChoosenRaffle }) => {
    const { raffles, status } = useSelector(({ RaffleReducer }) => RaffleReducer);
    const [localRaffles, setLocalRaffles] = useState([]);
    const dispatch = useDispatch();
    const [sortBy, setSortBy] = useState("title");
    const [sortRaffle, setSortRaffle] = useState("ACTIVE");

    //load to local raffles
    useEffect(() => {
        if (raffles.length > 0) {
            let sortedList = raffles.sort((a, b) => new Date(a.participationCloses) - new Date(b.participationCloses));
            console.log("sortedList::::::::::::::::::::::::::::::::::::::::::::", sortedList)
            setLocalRaffles([...sortedList.filter(x => (("private" in x && !x.private) || x['private'] === undefined) && new Date(x.drawnAt) > new Date())]);
        }
    }, [raffles]);

    //load all raffles
    useEffect(() => {
        if (raffles.length === 0) {
            if ((status !== "FETCHED_MY_EMPTY" && status !== "FETCHED_EMPTY")) {
                dispatch(LoadRaffles());
            }
        }
    }, [raffles]);

    return <div className="participant_raffle_search">
        {/* Header */}
        <div>
            <div>
                <GoSearch size={16} color="rgba(0,0,0,0.3)" />
                <input onChange={async (e) => {
                    const text = e.currentTarget.value.toLowerCase();
                    if (text.length > 0) {
                        if (raffles.length > 0) {
                            if (sortRaffle === "COMPLETED") {
                                let tempRaffles = await raffles.filter(x => (("private" in x && !x.private) || x['private'] === undefined) && x[sortBy] !== undefined && x[sortBy].toLowerCase().includes(text) && new Date(x.drawnAt).getTime() < new Date().getTime());
                                let sortedList = tempRaffles.sort((a, b) => new Date(a.participationCloses) - new Date(b.participationCloses));
                                setLocalRaffles(sortedList);
                            } else if (sortRaffle === "ACTIVE") {
                                let tempRaffles = await raffles.filter(x => (("private" in x && !x.private) || x['private'] === undefined) && x[sortBy] !== undefined && x[sortBy].toLowerCase().includes(text) && new Date(x.drawnAt).getTime() > new Date().getTime());
                                let sortedList = tempRaffles.sort((a, b) => new Date(a.participationCloses) - new Date(b.participationCloses));
                                setLocalRaffles(sortedList);
                            }
                        }
                    } else {
                        if (sortRaffle === "COMPLETED") {
                            let tempRaffles = await raffles.filter(x => (("private" in x && !x.private) || x['private'] === undefined) && new Date(x.drawnAt).getTime() < new Date().getTime());
                            let sortedList = tempRaffles.sort((a, b) => new Date(a.participationCloses) - new Date(b.participationCloses));
                            setLocalRaffles(sortedList);
                        } else if (sortRaffle === "ACTIVE") {
                            let tempRaffles = await raffles.filter(x => (("private" in x && !x.private) || x['private'] === undefined) && new Date(x.drawnAt).getTime() > new Date().getTime());
                            let sortedList = tempRaffles.sort((a, b) => new Date(a.participationCloses) - new Date(b.participationCloses));
                            setLocalRaffles(sortedList);
                        }
                    }
                }} placeholder={`Search here...`} />
                {
                    raffles.length > 0 ?
                        <select onChange={(e) => {
                            const option = e.currentTarget.value;
                            setSortBy(option);
                        }}>
                            <option key="title" value="title">Title</option>
                            <option key="rafflerType" value="rafflerType">Raffle Type</option>
                            <option key="createdBy" value="createdBy">Hosted By</option>
                            {/* <option key="participationOpens" value="participationOpens">Participation Opens Date</option>
                            <option key="participationCloses" value="participationCloses">Participation Closes Date</option>
                            <option key="drawnAt" value="drawnAt">Drawing Date</option> */}
                        </select> : null
                }
            </div>
        </div>
        {/* Sort list of raffles */}
        <div>
            <div>
                {
                    sortRafflesList.map((item, i) => (<label onClick={async () => {
                        let user = JSON.parse(sessionStorage.getItem("User"));
                        let email = user.email;

                        setSortRaffle(item);
                        if (item === "COMPLETED") {
                            let tempRaffles = await raffles.filter(x => (("private" in x && !x.private) || x['private'] === undefined) && new Date(x.drawnAt).getTime() < new Date().getTime());
                            let sortedList = tempRaffles.sort((a, b) => new Date(a.participationCloses) - new Date(b.participationCloses));
                            setLocalRaffles(sortedList);
                        } else if (item === "ACTIVE") {
                            let tempRaffles = await raffles.filter(x => (("private" in x && !x.private) || x['private'] === undefined) && new Date(x.drawnAt).getTime() > new Date().getTime());
                            let sortedList = tempRaffles.sort((a, b) => new Date(a.participationCloses) - new Date(b.participationCloses));
                            setLocalRaffles(sortedList);
                        }
                    }} className={sortRaffle === item ? "active" : ""} key={i}>{item}</label>))
                }
            </div>
        </div>
        {/* List of available raffles */}
        <div>
            {
                localRaffles.map((raffle, i) => {
                    return <RaffleTicket
                        raffleData={raffle}
                        dates={{ start: raffle.participationOpens, end: raffle.participationCloses, drawn: raffle.drawAt }}
                        shortDescription={raffle.shortDescription}
                        id={raffle.id}
                        fee={raffle.fee}
                        title={raffle.title}
                        image={null}
                        createdBy={raffle.createdBy}
                        styles={raffle.styles}
                        onClick={() => {
                            setChoosenRaffle(raffle);
                            setActiveModel("ParticipantRaffleViewModel");
                        }} />
                })
            }
        </div>
    </div>
}