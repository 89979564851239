import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import "./weeklyRaffle.scss";
import { createLogger } from 'redux-logger';
import { useAlert } from 'react-alert';

export default ({
    setRaffleFields,
    raffle,
    freqStartinTz,
    freqEndinTz,
    setMinEndPeriodDate,
    setSelectEndbyPeriod,
    getMilliSecFromTime,
    getFormattedDateTime,
    options,
    seriesEdit,
    type,
    choosenRaffle,
    setRaffle,
    remainingDays,
    getDatePartTZ,
    getDateStrWithTZ,
    getTimeStrWithTZ,
    selectedDays }) => {

    const dateFormat = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    }
    const day = 24 * 60 * 60 * 1000;
    const alert = useAlert();

    const daysInWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const [availableInstances, setAvailableInstances] = useState();
    const [remainingWeeks, setRemainingWeeks] = useState();
    const [masterWeeksSelect, setMasterWeeksSelect] = useState([]);
    const [selectedWeeks, setSelectedWeeks] = useState([]);

    const calculateNumWeeks = (startDate, endDate) => {
        const weeks = [];
        const currentDate = new Date(startDate);
        const finalDate = new Date(endDate);

        while (currentDate <= finalDate) {
            if (type === "EDIT") {
                const raffleDays = raffle.extras.seriesRaffle.weeklyPattern.days.map(day => {
                    return new Date(day).toLocaleDateString();
                })
                if (
                    raffleDays.includes(new Date(currentDate).toLocaleDateString())
                ) {
                    weeks.push(new Date(currentDate));
                }
            } else {
                const diffDays = (new Date(finalDate).getTime() - new Date(currentDate).getTime()) / 86400000;
                if (diffDays >= 6) {
                    weeks.push(new Date(currentDate))
                }
            }
            currentDate.setDate(currentDate.getDate() + 7);
        }

        setAvailableInstances(weeks.length);
        setRemainingWeeks(weeks);

        const allDrawDtsWithTz = [];
        weeks.forEach((date) => {
            allDrawDtsWithTz.push(getDatePartTZ(date));
        })
        setMasterWeeksSelect(allDrawDtsWithTz);
    }

    useEffect(() => {
        const startDateInTz = freqStartinTz(new Date(raffle.extras.seriesRaffle.frequencyRange.startDate), raffle.extras.timeZone);
        const endByDateInTz = freqEndinTz(new Date(raffle.extras.seriesRaffle.frequencyRange.endByDate), raffle.extras.timeZone);
        calculateNumWeeks(new Date(startDateInTz), new Date(endByDateInTz));
    }, [
        raffle.extras.seriesRaffle.frequencyRange.startDate,
        raffle.extras.seriesRaffle.frequencyRange.endByDate,
        raffle.extras.timeZone,
        raffle.extras.seriesRaffle.isMultipleRaffles
    ])

    useEffect(() => {
        if (raffle.extras.seriesRaffle.recurrencePattern === "Weekly" &&
            !raffle.extras.seriesRaffle.isMultipleRaffles) {
            let weeklyDraws = [...raffle.extras.seriesRaffle.weeklyPattern.days];
            weeklyDraws.sort((a, b) => new Date(a) - new Date(b));
            if (weeklyDraws.length !== 0) {
                const drawAtinTz = getDateStrWithTZ(new Date(raffle.drawAt));
                const singleRaffleStart = new Date(getDateStrWithTZ(weeklyDraws[0]));
                const firstSelectedDtinTz = getDateStrWithTZ(new Date(getDateofWeekday(weeklyDraws[0], raffle.extras.seriesRaffle.weeklyPattern.drawDay)));
                const lastSelectedDtinTz = getDateStrWithTZ(new Date(getDateofWeekday(weeklyDraws[weeklyDraws.length - 1], raffle.extras.seriesRaffle.weeklyPattern.drawDay)));
                const firstDrawDt = new Date(firstSelectedDtinTz.split(',')[0] + drawAtinTz.split(',')[1]);
                const lastDrawDt = new Date(lastSelectedDtinTz.split(',')[0] + drawAtinTz.split(',')[1])
                let openWeeklyTime = new Date(new Date(firstDrawDt).getTime() - 6 * day);
                console.log("openDailyTime::::::::::::::::::::::::::::::::::", openWeeklyTime);
                let closeWeeklyTime = new Date(new Date(lastDrawDt).getTime() - 5 * 60000);
                setRaffleFields(singleRaffleStart, "participationOpens");
                setRaffleFields(closeWeeklyTime, "participationCloses");
                setWeekDay(openWeeklyTime.getDay(), "openDay")
                setWeekDay(closeWeeklyTime.getDay(), "closeDay")
            }
        }
    }, [raffle.extras.seriesRaffle.weeklyPattern.days,
    raffle.extras.seriesRaffle.weeklyPattern.drawDay,
    raffle.drawAt])

    useEffect(() => {
        setSelectedWeeks(raffle.extras.seriesRaffle.weeklyPattern.days)

        //Initiating open time with current time incase of selection of current date as start
        const dateTime = new Date();
        const weeks = raffle.extras.seriesRaffle.weeklyPattern.days.sort((a, b) => new Date(a) - new Date(b));
        const weekStart = new Date(weeks[0]);
        if ((weekStart.toLocaleDateString() === new Date().toLocaleDateString()) &&
            weekStart.getDay() == raffle.extras.seriesRaffle.weeklyPattern.openDay) {
            dateTime.setSeconds(0, 0);
            const timeString = getTimeStrWithTZ(dateTime);
            const timeMill = getMilliSecFromTime(timeString);
            setWeekTimes(timeMill, "openTime");
            setRaffleFields(new Date(dateTime), "participationOpens");
        }

    }, [raffle.extras.seriesRaffle.weeklyPattern.days])

    // to initialize the weeklyPattern.openTime/closeTime/drawTime with 12am, 11:55pm and 11:59pm
    useEffect(() => {
        setWeekTimes(0, "openTime");
        setWeekTimes(86100000, "closeTime");
        setWeekTimes(86340000, "drawTime");
    }, [])

    // Week day selection function to set day to raffle
    const setWeekDay = (day, ocd) => {
        setRaffle((rff) => ({
            ...rff,
            extras: {
                ...rff.extras,
                seriesRaffle: {
                    ...rff.extras.seriesRaffle,
                    weeklyPattern: {
                        ...rff.extras.seriesRaffle.weeklyPattern,
                        [ocd === "openDay" ? "openDay" : ocd === "closeDay" ? "closeDay" : "drawDay"]: day
                    },
                },
            },
        }));
    }

    // Open time, close time & draw time setting to weeklyPattern.open/close/draw times
    const setWeekTimes = (time, ocd) => {
        setRaffle((rff) => ({
            ...rff,
            extras: {
                ...rff.extras,
                seriesRaffle: {
                    ...rff.extras.seriesRaffle,
                    weeklyPattern: {
                        ...rff.extras.seriesRaffle.weeklyPattern,
                        [ocd === "openTime" ? "openTime" : ocd === "closeTime" ? "closeTime" : "drawTime"]: time
                    },
                },
            },
        }));
    }

    //Function of selecting all available instances
    const selectAllInstances = (checked) => {
        //masterWeeksSelect
        let toAddMasterWeeks = masterWeeksSelect.filter(
            (x) => {
                const dayTime = new Date(x).toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                });
                const existingDayTimes = raffle.extras.seriesRaffle.weeklyPattern.days.map(x => new Date(x).toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                }));
                return (
                    existingDayTimes.indexOf(dayTime) === -1
                )
            }
        )

        let toRemoveWeeksChoosed = (type === "EDIT") ? choosenRaffle.extras.seriesRaffle.weeklyPattern.days.map(day => new Date(day).toISOString()) : [];

        if (checked) {
            setRaffle((rff) => ({
                ...rff,
                extras: {
                    ...rff.extras,
                    seriesRaffle: {
                        ...rff.extras.seriesRaffle,
                        weeklyPattern: {
                            ...rff.extras.seriesRaffle
                                .weeklyPattern,
                            days: [
                                ...rff.extras.seriesRaffle
                                    .weeklyPattern.days,
                                ...toAddMasterWeeks,
                            ],
                        },
                    },
                },
            }));
        } else {
            setRaffle((rff) => ({
                ...rff,
                extras: {
                    ...rff.extras,
                    seriesRaffle: {
                        ...rff.extras.seriesRaffle,
                        weeklyPattern: {
                            ...rff.extras.seriesRaffle
                                .weeklyPattern,
                            days: type === "EDIT" ? toRemoveWeeksChoosed : [],
                        },
                    },
                },
            }));
        }
    }

    //Function of selecting individual instance from available instances
    const selectInstance = (checked, day) => {
        if (
            raffle.extras.seriesRaffle.weeklyPattern.days.findIndex(
                (x) => new Date(x).toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                }) === new Date(day).toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                })
            ) >= 0 &&
            !checked
        ) {
            setRaffle((rff) => ({
                ...rff,
                extras: {
                    ...rff.extras,
                    seriesRaffle: {
                        ...rff.extras
                            .seriesRaffle,
                        weeklyPattern: {
                            ...rff.extras
                                .seriesRaffle
                                .weeklyPattern,
                            days: rff.extras.seriesRaffle.weeklyPattern.days.filter(
                                (x) => new Date(x).toLocaleDateString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",
                                }) !== new Date(day).toLocaleDateString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",
                                })
                            ),
                        },
                    },
                },
            }));
        } else {
            setRaffle((rff) => ({
                ...rff,
                extras: {
                    ...rff.extras,
                    seriesRaffle: {
                        ...rff.extras
                            .seriesRaffle,
                        weeklyPattern: {
                            ...rff.extras
                                .seriesRaffle
                                .weeklyPattern,
                            days: [
                                ...rff.extras
                                    .seriesRaffle
                                    .weeklyPattern.days,
                                getDatePartTZ(day),
                            ],
                        },
                    },
                },
            }));
        }
    }

    // To get date in the week of weekstart based selected week day
    const getDateofWeekday = (ws, wd) => {
        let dateOfWeekday;
        let weekStart = new Date(ws);
        let weekEnd = new Date(weekStart.getTime() + day * 6);
        while (weekStart <= weekEnd) {
            if (weekStart.getDay() === parseInt(wd)) {
                dateOfWeekday = weekStart
            }
            weekStart = new Date(weekStart.getTime() + day)
        }
        return new Date(dateOfWeekday).toLocaleDateString("en-US", {
            weekday: 'short',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    }

    // To get time alone from a new Date()
    const getTimeofDate = (date) => {
        return new Date(date).toLocaleTimeString("en-US", {
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    return (
        <div className='weekly-raffle'>
            <div className='raffle-day-time'>
                <label style={{ fontWeight: "700", textAlign: "left", width: "115px" }}>
                    RAFFLE DAY-TIME:
                </label>
                <label>
                    OPEN
                    <div className='week-day-picker'>
                        <select
                            disabled={
                                !raffle.extras.seriesRaffle.isMultipleRaffles ||
                                type === "EDIT"
                            }
                            value={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                                raffle.extras.seriesRaffle.weeklyPattern.days.length !== 0 ?
                                    raffle.extras.seriesRaffle.weeklyPattern.openDay :
                                    ""
                                : raffle.extras.seriesRaffle.weeklyPattern.openDay}
                            onChange={(e) => {
                                const weekStart = new Date(raffle.extras.seriesRaffle.weeklyPattern.days[0]);
                                if ((weekStart.toLocaleDateString() === new Date().toLocaleDateString()) &&
                                    weekStart.getDay() == e.target.value &&
                                    new Date(raffle.participationOpens) < new Date()
                                ) {
                                    alert.removeAll();
                                    alert.show("Please select a valid time in the future")
                                } else {
                                    setWeekDay(e.target.value, "openDay")
                                }
                            }}
                        >
                            {
                                daysInWeek.map((day, index) => {
                                    return (
                                        <option value={index}>{day}</option>
                                    )
                                })
                            }
                        </select>
                        <DatePicker
                            dateFormat={
                                !raffle.extras.seriesRaffle.isMultipleRaffles
                                    ? "MM/dd/yyyy hh:mm aa"
                                    : "hh:mm aa"
                            }
                            showTimeInput
                            showTimeSelectOnly
                            disabled={
                                !raffle.extras.seriesRaffle.isMultipleRaffles ||
                                type === "EDIT"
                            }
                            value={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                                raffle.extras.seriesRaffle.weeklyPattern.days.length !== 0 ?
                                    new Date(getFormattedDateTime(new Date(raffle.participationOpens))) :
                                    ""
                                : new Date(getFormattedDateTime(new Date(raffle.participationOpens)))}
                            selected={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                                raffle.extras.seriesRaffle.weeklyPattern.days.length !== 0 ?
                                    new Date(getFormattedDateTime(new Date(raffle.participationOpens))) :
                                    ""
                                : new Date(getFormattedDateTime(new Date(raffle.participationOpens)))}
                            onChange={(_e) => {
                                const dateTime = _e;
                                const weekStart = new Date(raffle.extras.seriesRaffle.weeklyPattern.days[0]);
                                if ((weekStart.toLocaleDateString() === new Date().toLocaleDateString()) &&
                                    weekStart.getDay() == raffle.extras.seriesRaffle.weeklyPattern.openDay &&
                                    new Date(dateTime) < new Date()) {
                                    alert.removeAll();
                                    alert.show("Please select a valid time in the future")
                                } else {
                                    dateTime.setSeconds(0, 0);
                                    const timeString = getTimeStrWithTZ(dateTime);
                                    const timeMill = getMilliSecFromTime(timeString);
                                    setWeekTimes(timeMill, "openTime");
                                    setRaffleFields(new Date(dateTime), "participationOpens");
                                }
                            }}
                        />
                    </div>
                </label>
                <label>
                    CLOSE
                    <div className='week-day-picker'>
                        <select
                            disabled={
                                !raffle.extras.seriesRaffle.isMultipleRaffles ||
                                type === "EDIT"
                            }
                            value={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                                raffle.extras.seriesRaffle.weeklyPattern.days.length !== 0 ?
                                    raffle.extras.seriesRaffle.weeklyPattern.closeDay :
                                    ""
                                : raffle.extras.seriesRaffle.weeklyPattern.closeDay}
                            onChange={(e) => {
                                if (new Date(raffle.participationCloses) < new Date(raffle.participationOpens) &&
                                    (raffle.extras.seriesRaffle.weeklyPattern.openDay == e.target.value)) {
                                    alert.removeAll();
                                    alert.show("Close time cannot be less than open time")
                                } else {
                                    setWeekDay(e.target.value, "closeDay")
                                }
                            }}
                        >
                            {
                                daysInWeek.map((day, index) => {
                                    return (
                                        <option value={index}>{day}</option>
                                    )
                                })
                            }
                        </select>
                        <DatePicker
                            dateFormat={
                                !raffle.extras.seriesRaffle.isMultipleRaffles
                                    ? "MM/dd/yyyy hh:mm aa"
                                    : "hh:mm aa"
                            }
                            showTimeInput
                            showTimeSelectOnly
                            disabled={
                                !raffle.extras.seriesRaffle.isMultipleRaffles ||
                                type === "EDIT"
                            }
                            value={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                                raffle.extras.seriesRaffle.weeklyPattern.days.length !== 0 ?
                                    new Date(getFormattedDateTime(new Date(raffle.participationCloses))) :
                                    ""
                                : new Date(getFormattedDateTime(new Date(raffle.participationCloses)))}
                            selected={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                                raffle.extras.seriesRaffle.weeklyPattern.days.length !== 0 ?
                                    new Date(getFormattedDateTime(new Date(raffle.participationCloses))) :
                                    ""
                                : new Date(getFormattedDateTime(new Date(raffle.participationCloses)))}
                            onChange={(_e) => {
                                const dateTime = _e;
                                if (new Date(dateTime) < new Date(raffle.participationOpens) &&
                                    (raffle.extras.seriesRaffle.weeklyPattern.openDay == raffle.extras.seriesRaffle.weeklyPattern.closeDay)) {
                                    alert.removeAll();
                                    alert.show("Close time cannot be less than open time");
                                } else {
                                    dateTime.setSeconds(0, 0);
                                    const timeString = getTimeStrWithTZ(dateTime);
                                    const timeMill = getMilliSecFromTime(timeString);
                                    setWeekTimes(timeMill, "closeTime");
                                    setRaffleFields(new Date(dateTime), "participationCloses");
                                }
                            }}
                        />
                    </div>
                </label>
                <label>
                    DRAW
                    <div className='week-day-picker'>
                        <select
                            value={raffle.extras.seriesRaffle.weeklyPattern.drawDay}
                            disabled={type === "EDIT"}
                            onChange={(e) => {
                                if (new Date(raffle.drawAt) < new Date(raffle.participationCloses) &&
                                    (raffle.extras.seriesRaffle.weeklyPattern.closeDay == e.target.value)) {
                                    alert.removeAll();
                                    alert.show("Draw time cannot be less than close time")
                                } else {
                                    setWeekDay(e.target.value, "drawDay")
                                }
                            }}
                        >
                            {
                                daysInWeek.map((day, index) => {
                                    return (
                                        <option value={index}>{day}</option>
                                    )
                                })
                            }
                        </select>
                        <DatePicker
                            dateFormat="hh:mm aa"
                            showTimeInput
                            showTimeSelectOnly
                            disabled={type === "EDIT"}
                            value={new Date(getFormattedDateTime(new Date(raffle.drawAt)))}
                            selected={new Date(getFormattedDateTime(new Date(raffle.drawAt)))}
                            onChange={(_e) => {
                                const dateTime = _e;
                                if (new Date(dateTime) < new Date(raffle.participationCloses) &&
                                    (raffle.extras.seriesRaffle.weeklyPattern.closeDay == raffle.extras.seriesRaffle.weeklyPattern.drawDay)) {
                                    alert.removeAll();
                                    alert.show("Draw time cannot be less than close time")
                                } else {
                                    dateTime.setSeconds(0, 0);
                                    const timeString = getTimeStrWithTZ(dateTime);
                                    const timeMill = getMilliSecFromTime(timeString);
                                    setWeekTimes(timeMill, "drawTime");
                                    setRaffleFields(new Date(dateTime), "drawAt");
                                }
                            }}
                        />
                    </div>
                </label>
            </div>
            <div className="series-sub-raffle">
                <div className="available-instances">
                    <div className='weekly-available-instances'>
                        <span>{`AVAILABLE INSTANCES`}</span>
                        <input
                            type="checkbox"
                            checked={
                                masterWeeksSelect.length ===
                                raffle.extras.seriesRaffle.weeklyPattern.days
                                    .length
                            }
                            disabled={
                                (!seriesEdit &&
                                    type === "EDIT" &&
                                    raffle.extras.seriesRaffle.isMultipleRaffles
                                ) ||
                                (type === "EDIT" &&
                                    choosenRaffle.extras.seriesRaffle.weeklyPattern.days.length ===
                                    masterWeeksSelect.length
                                )
                            }
                            ref={(input) => {
                                if (input) {
                                    input.indeterminate =
                                        masterWeeksSelect.length >
                                        raffle.extras.seriesRaffle.weeklyPattern
                                            .days.length &&
                                        raffle.extras.seriesRaffle.weeklyPattern.days
                                            .length !== 0;
                                }
                            }}
                            onChange={(e) => selectAllInstances(e.target.checked)}
                        />
                        <span>
                            Select All
                        </span>
                        <span>({availableInstances})</span>
                    </div>
                    <div className={"occurrences"}>
                        {remainingWeeks?.map((weekStart, index) => {
                            return (
                                <div
                                    className="each-checkbox"
                                    key={index}
                                >
                                    <input
                                        type="checkbox"
                                        checked={
                                            raffle.extras.seriesRaffle.weeklyPattern.days.findIndex(
                                                (x) =>
                                                    new Date(
                                                        x
                                                    ).toLocaleDateString() ===
                                                    new Date(
                                                        weekStart
                                                    ).toLocaleDateString()
                                            ) >= 0
                                        }
                                        disabled={
                                            (type === "EDIT" && !seriesEdit && raffle.extras.seriesRaffle.isMultipleRaffles
                                            ) ||
                                            (type === "EDIT" &&
                                                new Date(weekStart).setHours(
                                                    new Date(raffle.participationOpens).getHours(),
                                                    new Date(raffle.participationOpens).getMinutes(), 0, 0,
                                                ) < new Date())
                                        }

                                        onChange={(e) => selectInstance(e.target.checked, weekStart)}
                                    />
                                    <label>
                                        {raffle.extras.seriesRaffle.isMultipleRaffles ?
                                            `Week-${index + 1} ${new Date(weekStart).toLocaleDateString("en-US", dateFormat)} - ${new Date(new Date(weekStart).getTime() + 6 * day).toLocaleDateString("en-US", dateFormat)} 
                                            [Open @ ${getDateofWeekday(weekStart, raffle.extras.seriesRaffle.weeklyPattern.openDay)}, ${getTimeofDate(new Date(raffle.participationOpens))},
                                            Close @ ${getDateofWeekday(weekStart, raffle.extras.seriesRaffle.weeklyPattern.closeDay)}, ${getTimeofDate(new Date(raffle.participationCloses))},
                                            Draw @ ${getDateofWeekday(weekStart, raffle.extras.seriesRaffle.weeklyPattern.drawDay)}, ${getTimeofDate(new Date(raffle.drawAt))}]`
                                            :
                                            `Week-${index + 1} ${new Date(weekStart).toLocaleDateString("en-US", dateFormat)} - ${new Date(new Date(weekStart).getTime() + 6 * day).toLocaleDateString("en-US", dateFormat)} Draw @ ${getDateofWeekday(weekStart, raffle.extras.seriesRaffle.weeklyPattern.drawDay)}, ${getTimeofDate(new Date(raffle.drawAt))}]`
                                        }
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="selected-instances">
                    <label className="selected-instances-title">
                        {`SELECTED INSTANCES (${raffle.extras.seriesRaffle.weeklyPattern.days.length})`}
                    </label>
                    <div className="instances-content">
                        {
                            selectedWeeks.length !== 0 ?
                                selectedWeeks.map(
                                    (dayObj, index) => {
                                        const weekStart = new Date(dayObj);
                                        return (
                                            <div key={index}>
                                                <label>
                                                    {raffle.extras.seriesRaffle.isMultipleRaffles ?
                                                        `# Week-${index + 1} ${new Date(weekStart).toLocaleDateString("en-US", dateFormat)} - ${new Date(new Date(weekStart).getTime() + 6 * day).toLocaleDateString("en-US", dateFormat)} 
                                                        [Open @ ${getDateofWeekday(weekStart, raffle.extras.seriesRaffle.weeklyPattern.openDay)}, ${getTimeofDate(new Date(raffle.participationOpens))},
                                                        Close @ ${getDateofWeekday(weekStart, raffle.extras.seriesRaffle.weeklyPattern.closeDay)}, ${getTimeofDate(new Date(raffle.participationCloses))},
                                                        Draw @ ${getDateofWeekday(weekStart, raffle.extras.seriesRaffle.weeklyPattern.drawDay)}, ${getTimeofDate(new Date(raffle.drawAt))}]`
                                                        :
                                                        `# Week-${index + 1} ${new Date(weekStart).toLocaleDateString("en-US", dateFormat)} - ${new Date(new Date(weekStart).getTime() + 6 * day).toLocaleDateString("en-US", dateFormat)} Draw @ ${getDateofWeekday(weekStart, raffle.extras.seriesRaffle.weeklyPattern.drawDay)}, ${getTimeofDate(new Date(raffle.drawAt))}]`
                                                    }
                                                </label>
                                            </div>
                                        );
                                    }
                                )
                                :
                                <label>No instances selected</label>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
