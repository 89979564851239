import React, { useEffect, useState } from "react";
import './forgotPassword.scss'
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { togggleLoad } from "../../store/actions/loadAction";
import { updatePassword } from "../../config/endpoints";
import { request } from "../../helper/request";
import { useLocation, useHistory } from "react-router-dom";
import cryptoJS from "crypto-js";
import Cryptr from 'cryptr';
import Loader from "react-loader-spinner";
const str_pass = "8isahnf9saipfsafnlkjaslfkj";
const CRYPTER_SECRET = "98ahkiuay7siajbsiyasas78ays8oiauhsbysai7ys79754aw4s6d"
const cryptr = new Cryptr(CRYPTER_SECRET);


export default () => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const [loading, toggleLoading] = useState(false);

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [email, setEmail] = new useState("");

    const [submitAttempted, setSubmitAttempted] = useState(false);

    const [success, setSuccess] = useState(false);

    // validation page
    useEffect(() => {
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
        const email = searchParams.get('email');
        const encryptedKey = searchParams.get('key');

        // Use the email and key parameters in your logic
        console.log('Email:', email);
        console.log('Key:', encryptedKey);
        
        const decryptedKey = cryptr.decrypt(encryptedKey);
        console.log('Decrypted Key:', decryptedKey);

        if (decryptedKey === email) {
            setEmail(email);
        } else  {
            setEmail(decryptedKey);
        }
    }, []);

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
      };
    
      const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
      };
    
      const toggleShowNewPassword = (ev) => {
        ev.preventDefault();
        setShowNewPassword(!showNewPassword);
      };
    
      const toggleShowConfirmPassword = (ev) => {
        ev.preventDefault();
        setShowConfirmPassword(!showConfirmPassword);
      };

      
    const onClickSubmit = async () => {
        setSubmitAttempted(true);
        if (
            newPassword.trim() === "" ||
            confirmPassword.trim() === ""
        ) {
            alert.removeAll();
            alert.show("Please fill all required fields");
            return;
        }
        if (/\s/.test(newPassword)) {
            alert.removeAll();
            alert.show("Spaces are not allowed in the new password");
            return;
        }
        if (newPassword.trim().length > 0 && newPassword.trim().length < 8) {
            alert.removeAll();
            alert.show("New password should have minimum of 8 characters");
            return;
        }
        if (newPassword !== confirmPassword) {
            alert.removeAll();
            alert.show("New password and confirm password do not match");
            return;
        }
        toggleLoading(true);
        if (newPassword === confirmPassword) {
            // change password logic
            const payload = {
                email: email,
                newPassword: newPassword,
            };
            request("POST", updatePassword, payload).
            then(async (changePassResp) => {
                if (changePassResp.status === "SUCCESS") {
                    // success
                        alert.removeAll();
                        alert.show("Password updated successfully");
                        toggleLoading(false);
                        setSuccess(true);
                        setTimeout(() => {
                            history.push("authentication");
                        }, 2000);
                    } else {
                        alert.removeAll();
                        alert.show("Error in updating password");
                        toggleLoading(false);
                    }
            }).catch((e) => {
                toggleLoading(false);
            })
        } else {
            alert.removeAll();
            alert.show("New password and confirm password do not match");
            toggleLoading(false);
        }
    };
    return (
        <div className="fcp-page">
            {/* header */}
            <div className="header">
                <img src={require("../../assets/img/logo.png")} className="logo-png"/>
                <label className="app-title">Rafflerra</label>
            </div>
            {/* body */}
            <div className="fcp-body">
                <div className="fcp-form-title">
                    Change Password
                </div>
                {
                    !success ? (
                        <form className="fcp-form" 
                            onSubmit={(e) => {
                            e.preventDefault(); // Prevent default form submission behavior
                            onClickSubmit();
                            }}>
                            <div className="label-input">
                            <label>
                                New password<span className="required-marker">*</span>
                            </label>
                            <div className="input-eye">
                            <input
                                disabled={email===""}
                                type={showNewPassword ? "text" : "password"}
                                onChange={handleNewPasswordChange}
                                required
                                minLength={6}
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|\\:;'<>,.?/~])(?!.*\s).{8,}$"
                                title="Password must be atleast 8 characters with uppercase, lowercase, digit, and special character."
                            />
                            <button
                                type="button"
                                className="password-toggle"
                                onClick={toggleShowNewPassword}
                            >
                                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                            </div>
                            {submitAttempted && newPassword === "" && (
                            <p className="error-message show">Required</p>
                            )}
                            {submitAttempted &&
                            newPassword.length > 0 &&
                            /\s/.test(newPassword) && (
                                <p className="error-message show">Space(s) not allowed</p>
                            )}
                            {submitAttempted &&
                            newPassword.trim().length > 0 &&
                            newPassword.trim().length < 8 && (
                                <p className="error-message">Min 8 characters required</p>
                            )}
                            </div>
                            <div className="label-input">
                                <label>
                                    Confirm password<span className="required-marker">*</span>
                                </label>
                                <div className="input-eye">
                                <input
                                    disabled={email===""}
                                    type={showConfirmPassword ? "text" : "password"}
                                    onChange={handleConfirmPasswordChange}
                                    required
                                />
                                <button
                                    type="button"
                                    className="password-toggle"
                                    onClick={toggleShowConfirmPassword}
                                >
                                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                </button>
                                </div>
                                {submitAttempted && confirmPassword.trim() === "" && (
                                <p className="error-message show">Required</p>
                                )}
                            </div>
                            <button className={email==="" ? "submit-btn-disabled" : "submit-btn" }
                                disabled={email===""}
                                onSubmit={(e) => {
                                    e.preventDefault(); // Prevent default form submission behavior
                                    onClickSubmit();
                                }}
                                type="submit">Continue</button>
                        </form>
                    ) : (
                        <div className="fcp-success">
                            Password saved successfully. You can now sign In.
                        </div>
                    )
                }
            </div>
            {loading ? (
                <div className="fcp-loader">
                    <Loader type="TailSpin" color="rgb(11, 70, 85)" width={60} />
                </div>
            ) : null}
        </div>
    )
}