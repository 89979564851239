import React, { useState, useEffect, Fragment } from "react";
import "./index.scss";
import { useSelector, useDispatch } from "react-redux";
import { LoadMyRaffles } from "../../../../store/actions/raffleAction";
import { GiEmptyHourglass } from "react-icons/gi";
import { GoSearch, GoInfo } from "react-icons/go";
import { FaShare, FaEdit, FaQrcode } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import RaffleInvitationModel from "../../models/raffleInvitation";
import GetDate from "../../../../helper/getDate";
import { useAlert } from "react-alert";
import { web_url } from "../../../../config/endpoints";

const sortRafflesList = ["ALL", "ONGOING", "COMPLETED"];
const sortRafflesList1 = ["ALL", "PRIVATE"];

export default ({
  setCreateRaffleTemplate,
  setActiveRaffleName,
  setQrlink,
  subActiveMyRaffles,
  setActiveModel,
  setChoosenRaffle,
  setParticipants,
}) => {
  const [hostedRaffles, setHostedRaffles] = useState([]);
  const { myRaffles, status } = useSelector(
    ({ RaffleReducer }) => RaffleReducer
  );
  const { raffleEditsBefore } = useSelector(
    ({ ConfigReducer }) => ConfigReducer
  );
  const dispatch = useDispatch();
  const [localRaffles, setLocalRaffles] = useState([]);
  const [sortBy, setSortBy] = useState("title");
  const [sortRaffle, setSortRaffle] = useState("ALL");
  const [sortRaffle1, setSortRaffle1] = useState("ALL");

  const [showInvitationModel, toggleInvitationModel] = useState(false);
  const [shareRaffleId, setShareRaffleId] = useState("");
  const [shareRaffleName, setShareRaffleName] = useState("");

  const alert = useAlert();

  //loading or filtering hosted raffles
  useEffect(() => {
    if (myRaffles.length > 0) {
      let user = JSON.parse(sessionStorage.getItem("User"));
      let email = user.email;
      let filterdRaffles = myRaffles.filter((x) => x.createdBy === email);
      setLocalRaffles(filterdRaffles);
      setHostedRaffles(filterdRaffles);
    } else {
      if (status !== "FETCHED_MY_EMPTY" && status !== "FETCHED_EMPTY") {
        dispatch(LoadMyRaffles());
      }
    }
  }, [myRaffles]);

  //sort raffles based on profile side buttons
  useEffect(() => {
    if (subActiveMyRaffles.length > 0) {
      let user = JSON.parse(sessionStorage.getItem("User"));
      let email = user.email;

      setSortRaffle(subActiveMyRaffles);
      if (subActiveMyRaffles === "COMPLETED") {
        let tempRaffles = myRaffles.filter(
          (x) => GetDate(x.drawnAt) < new Date() && x.createdBy === email
        );
        setLocalRaffles(tempRaffles);
      } else if (subActiveMyRaffles === "ONGOING") {
        let tempRaffles = myRaffles.filter(
          (x) => GetDate(x.drawnAt) > new Date() && x.createdBy === email
        );
        setLocalRaffles(tempRaffles);
      } else if (subActiveMyRaffles === "ALL") {
        setLocalRaffles(myRaffles.filter((x) => x.createdBy === email));
      }
    }
  }, [subActiveMyRaffles]);

  const _renderRaffles = () => {
    return (
      <div>
        <div>
          <div
            onClick={() => {
              setActiveModel("CustomizeRaffleModel");
              setCreateRaffleTemplate(true);
            }}
            className="create_template"
          >
            <div>+</div>
            <div>Create Template</div>
          </div>
          {localRaffles.map((raffle, i) => {
            const differ =
              (new Date(raffle.drawnAt).getTime() - new Date().getTime()) /
              (24 * 60 * 60 * 1000);
            if ("isTemplate" in raffle.extras && raffle.extras.isTemplate) {
              return (
                <div
                  style={raffle.extras.active ? {} : { opacity: 0.4 }}
                  className="raffle_item"
                  key={i}
                >
                  <div></div>
                  <div
                    style={{
                      backgroundColor: raffle.styles.background,
                      color:
                        "titleColor" in raffle.styles
                          ? raffle.styles.titleColor
                          : raffle.styles.textColor,
                    }}
                  >
                    {raffle.title[0].toUpperCase()}
                  </div>
                  <div className="options">
                    <FaEdit
                      onClick={() => {
                        setChoosenRaffle(raffle);
                        setCreateRaffleTemplate(true);
                        setActiveModel("UpdateRaffleModel");
                      }}
                      data-tip
                      data-for={"edit_icons_tt" + i}
                      className={`edit_icon`}
                    />
                  </div>
                  <div className="fileds">
                    <label>{raffle.rafflerType}</label>
                    <label>{raffle.title}</label>
                    {raffle.winners.length === 0 &&
                    raffle.participants.length < raffle.maxNumParticipants ? (
                      <Fragment>
                        <ReactTooltip place="bottom" id={"share_icons_tt" + i}>
                          Email invitaions
                        </ReactTooltip>
                        <ReactTooltip place="bottom" id={"edit_icons_tt" + i}>
                          Edit Raffle
                        </ReactTooltip>
                        <ReactTooltip place="bottom" id={"qr_icons_tt" + i}>
                          QR code
                        </ReactTooltip>
                      </Fragment>
                    ) : null}
                    <label>
                      Fee :{" "}
                      {raffle.fee === 0
                        ? "FREE"
                        : raffle.fee + " " + raffle.currency}
                    </label>
                    <label>
                      Prizes :{" "}
                      {raffle.prizeType === "Money"
                        ? raffle.numPrizes
                            .map((x) => raffle.currency + " " + x)
                            .join(", ")
                        : raffle.numPrizes.join(", ")}
                    </label>
                    <label>{raffle.shortDescription}</label>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="raffle_templates">
      {/* Header */}
      <div>
        <div>
          <GoSearch size={16} color="rgba(0,0,0,0.3)" />
          <input
            onChange={(e) => {
              const text = e.currentTarget.value.toLowerCase();
              if (text.length > 0) {
                if (hostedRaffles.length > 0) {
                  if (
                    sortBy === "participationOpens" ||
                    sortBy === "participationCloses" ||
                    sortBy === "drawnAt"
                  ) {
                    const sortedRaffles = hostedRaffles.filter(
                      (x) =>
                        new Date(x[sortBy])
                          .toDateString()
                          .toLowerCase()
                          .includes(text) &&
                        (sortRaffle === "COMPLETED"
                          ? GetDate(x.drawnAt) < new Date()
                          : sortRaffle === "ONGOING"
                          ? GetDate(x.drawnAt) > new Date()
                          : true)
                    );
                    setLocalRaffles(sortedRaffles);
                  } else {
                    const sortedRaffles = hostedRaffles.filter(
                      (x) =>
                        x[sortBy] !== undefined &&
                        x[sortBy].toLowerCase().includes(text) &&
                        (sortRaffle === "COMPLETED"
                          ? GetDate(x.drawnAt) < new Date()
                          : sortRaffle === "ONGOING"
                          ? GetDate(x.drawnAt) > new Date()
                          : true)
                    );
                    setLocalRaffles(sortedRaffles);
                  }
                }
              } else {
                let user = JSON.parse(sessionStorage.getItem("User"));
                let sortedRaffles = myRaffles.filter(
                  (x) =>
                    x.createdBy === user.email &&
                    (sortRaffle === "COMPLETED"
                      ? GetDate(x.drawnAt) < new Date()
                      : sortRaffle === "ONGOING"
                      ? GetDate(x.drawnAt) > new Date()
                      : true)
                );
                setLocalRaffles(sortedRaffles);
              }
            }}
            placeholder={`Search here...`}
          />
          {hostedRaffles.length > 0 ? (
            <select
              onChange={(e) => {
                const option = e.currentTarget.value;
                setSortBy(option);
              }}
            >
              <option key="title" value="title">
                Title
              </option>
              <option key="rafflerType" value="rafflerType">
                Raffle Type
              </option>
              <option key="createdBy" value="createdBy">
                Created By
              </option>
              {/* <option key="participationOpens" value="participationOpens">Participation Opens Date</option> */}
              {/* <option key="participationCloses" value="participationCloses">Participation Closes Date</option> */}
              {/* <option key="drawnAt" value="drawnAt">Drawing Date</option> */}
            </select>
          ) : null}
        </div>
      </div>
      {/* raffles list */}
      {_renderRaffles()}

      {/* render models */}
      {showInvitationModel && (
        <RaffleInvitationModel
          raffleTitle={shareRaffleName}
          raffleId={shareRaffleId}
          onClose={() => toggleInvitationModel(false)}
        />
      )}
    </div>
  );
};
