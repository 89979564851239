import React, { Fragment, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import "./invitationsList.scss";
import { useDispatch, useSelector } from "react-redux";
import { GoSearch, GoInfo } from "react-icons/go";
import Loader from "react-loader-spinner";
import {
  invitaionsList,
  getRaffleById,
  joinRaffle,
  deleteTrashInv,
  invCatCount,
  invSubCatCount,
  emptytrashinv,
  movetotrashinv,
  restoretrashinv,
  InvCatSearchCount,
  InvSubCatSearchCount,
  invitaionSearch,
  acceptInv,
  getInvById,
} from "../../../../config/endpoints";
import { togggleLoad } from "../../../../store/actions/loadAction";
import { request } from "../../../../helper/request";
import { useAlert } from "react-alert";
// import EmptyTrash from "./emptyTrash";
import {
  FaBullseye,
  FaExchangeAlt,
  FaEye,
  FaRegEye,
  FaSearch,
  FaRedoAlt,
} from "react-icons/fa";
import DeleteInvitations from "./deleteInvitations";
import JoinInvitations from "./joinInvitations";
import RestoreInvitations from "./restoreInvitations";
import DeletePermanentlyInvitations from "./deletePermanentlyInvitations";
import EmptyTrashInvitations from "./emptyTrashInvitations";
import RaffleTicketViewModel from "../hostedRaffles/RaffleTicketViewModel";
import SocialShareModel from "../../models/socialShareModel";

const InvitationsList = () => {
  const [raffle, setRaffle] = useState(null);
  const [rafflePrizes, setRafflePrizes] = useState([]);
  const [activeModel, setModel] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterBy, setFilterBy] = useState("raffleTitle");
  const [itemsCount, setItemsCount] = useState({
    NEW: 0,
    JOINED: 0,
    EXPIRED: 0,
    TRASH: 0,
  });
  const [subCategoryCount, setSubCategoryCount] = useState({
    FREE: 0,
    PAID: 0,
    ALL: 0,
  });
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [
    confirmDetPermanentlyInvitations,
    setConfirmDetPermanentlyInvitations,
  ] = useState(false);
  const [confirmRestoreInvitations, setConfirmRestoreInvitations] =
    useState(false);
  const [confirmDeleteInvitations, setDeleteInvitations] = useState(false);
  const [confirmEmptyTrash, setConfirmEmptyTrash] = useState(false);
  const [joinConfirmDilog, setJoinConfirmDilog] = useState(false);
  const [displayInvitationList, setDisplayInvitationList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showTrashData, setShowTrashData] = useState(false);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sort, setSort] = useState("");
  const [loggedUser, setLoggedUse] = useState(null);

  const [category, setCategory] = useState("NEW");
  // const [page, setPage] = useState(0);
  const [subCategory, setSubCategory] = useState("FREE");
  const [searchResults, setSearchResults] = useState([]);
  const [socialShareLink, setSocialShareLink] = useState("");

  const alert = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    if (sessionStorage.getItem("invitationId")) {
      setLoading(true);
      request("GET_AUTH", getInvById + sessionStorage.getItem("invitationId"))
      .then((res) => {
        if (res.status === 0) {
          onClickView(res.payload.raffleInfo.raffleId, "view")
          // sessionStorage.removeItem("invitationId")
        } else {
          alert.removeAll();
          alert.show("Error finding invitation by id");
        }
      })
      .catch((e) => {
        setLoading(false);
      });
    }
  }, [])

  useEffect(()=>{
    apiInvitationList(category, subCategory, currentPage, itemsPerPage);
    apiSubCategoryCount(category);
    apiCategoryCount();
  }, [])

  useEffect(() => {
    setSelectedItems([]);
  }, [category, subCategory]);

  useEffect(() => {
    const tp = calculateTotalPages(
      subCategoryCount[subCategory]
    );
    if (tp > 0) {
      if (searchQuery.length === 0) {
        apiInvitationList(
          category,
          subCategory,
          tp > currentPage ? currentPage : tp,
          itemsPerPage
        );
      }
    }
  }, [currentPage, itemsPerPage, subCategory]);

  //api
  const apiInvitationList = (category, subCategory, page, itemsPerFetch) => {
    let unMounted = false;
    setLoading(true);
    setCurrentPage(page);
    console.log("Category:", category);
    console.log("Subcategory:", subCategory);
    const user = JSON.parse(sessionStorage.getItem("User"));
    const email = user?.email;
    request(
      "GET_AUTH",
      invitaionsList +
      `?email=${email}&category=${category}&itemsPerFetch=${itemsPerFetch}&page=${page}&subCategory=${subCategory}`
    )
      .then((res) => {
        if (res.status === 0 && !unMounted) {
          const payload = res.payload;
          setDisplayInvitationList(payload);
          setSearchResults(payload);
          setLoading(false);
        } else {
          console.log("invitation error res", res);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("error in apiInvitationList");
        setLoading(false);
      });
  };

  const onClickView = async (raffleId, choosenOp) => {
    setLoading(true);
    request("POST_AUTH", getRaffleById, { _id: raffleId })
      .then((res) => {
        if (res.status === 0) {
          const raff = res.payload.raffle;
          const prizes = res.payload.prizes;
          setRaffle(raff);
          setRafflePrizes(prizes);
          if (choosenOp === "view") {
            setModel("ParticipantRaffleViewJoinModel");
            setLoading(false);
          }
        } else {
          alert.removeAll();
          alert.show("Error loading raffle by id");
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const apiCategoryCount = () => {
    // Inside your useEffect where you fetch category and subcategory counts
    const user = JSON.parse(sessionStorage.getItem("User"));
    const email = user?.email;
    request("GET_AUTH", invCatCount + `?email=${email}`)
      .then((res) => {
        if (res.status === 0) {
          setItemsCount(res.payload);
        } else {
          console.log("notifications count error res", res);
        }
      })
      .catch((e) => {
        console.log("error in apiNotificationsCount");
      });
  };

  const apiSubCategoryCount = (category) => {
    // Inside your useEffect where you fetch category and subcategory counts
    const user = JSON.parse(sessionStorage.getItem("User"));
    const email = user?.email;
    request("GET_AUTH", invSubCatCount + `?email=${email}&category=${category}`)
      .then((res) => {
        if (res.status === 0) {
          setSubCategoryCount(res.payload);
          calculateTotalPages(res.payload[subCategory]);
        } else {
          console.log("notifications count error res", res);
        }
      })
      .catch((e) => {
        console.log("error in apiNotificationsCount");
      });
  };

  const calculateTotalPages = (count) => {
    const pages = Math.ceil(count / itemsPerPage);
    setTotalPages(pages);
    return pages;
  };

  const pagination = () => {
    const totalCount =
      category === "TRASH"
        ? itemsCount.TRASH
        : subCategory === "ALL"
          ? itemsCount.JOINED + itemsCount.EXPIRED + itemsCount.TRASH
          : subCategoryCount[subCategory];
  };

  const onClickSearchbtn = () => {
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    invitationSearch(
      category,
      subCategory,
      filterBy,
      searchQuery,
      1,
      itemsPerPage
    );
    apiSearchCatCount(filterBy, searchQuery);
    apiSearchSubCatCount(filterBy, searchQuery, category);
    // api of search list
    // api of search count
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onClickSearchbtn(
        category,
        subCategory,
        searchQuery,
        currentPage,
        itemsPerPage
      ); // Call your search action function here
    }
  };

  const onClickXbtn = () => {
    setSearchQuery("");
    apiInvitationList(category, subCategory, currentPage, itemsPerPage);
    apiCategoryCount();
    apiSubCategoryCount(category);
    // api of list
    // api of count
  };

  const onRefresh = () => {
    setSearchQuery("");
    apiInvitationList(category, subCategory, currentPage, itemsPerPage);
    apiCategoryCount();
    apiSubCategoryCount(category);
    // api of list
    // api of count
  };

  const handlePageChange = (page) => {
    // apiInvitationList(category, subCategory, currentPage, itemsPerPage);
    setCurrentPage(page);

    // api of list
  };

  const onClickConfirmEmptyTrash = () => {
    setConfirmEmptyTrash(true);
  };

  const onClickDltPermanently = () => {
    if (selectedItems.length > 0) {
      setDeleteInvitations(true);
    } else {
      alert.removeAll();
      alert.show("No item(s) is/are selected to restore");
    }
  };

  // api delete permanently
  const onClickDeletePermanetly = () => {
    if (selectedItems.length > 0) {
      dispatch(togggleLoad());
      request("POST_AUTH", deleteTrashInv, {
        trash: selectedItems,
      })
        .then((res) => {
          dispatch(togggleLoad());
          if (res.status === 0) {
            alert.removeAll();
            alert.show("Deleted permanently");
            apiInvitationList(currentPage, itemsPerPage);
            apiInvitationList();
            setConfirmDetPermanentlyInvitations(false);
            setSelectedItems([]);
          } else if (res.status === 1) {
            alert.removeAll();
            alert.show(res.payload);
          } else {
            alert.removeAll();
            alert.show("Error deleting permanently");
          }
        })
        .catch((e) => {
          dispatch(togggleLoad());
          alert.removeAll();
          alert.show("Error deleting permanently" + e.message);
        });
    } else {
      alert.removeAll();
      alert.show("No item(s) is/are selected to delete");
    }
  };

  const onClickRestoreInvitations = () => {
    if (selectedItems.length > 0) {
      setJoinConfirmDilog(true);
    } else {
      alert.removeAll();
      alert.show("No item(s) is/are selected to restore");
    }
    setJoinConfirmDilog(true);
    setSearchQuery("");
  };

  // move selected items to trash api
  const deleteInvitations = () => {
    if (selectedItems.length > 0) {
      dispatch(togggleLoad());
      console.log("Sending data to server:", selectedItems);
      request("POST_AUTH", movetotrashinv, {
        invitation: selectedItems,
      })
        .then((res) => {
          dispatch(togggleLoad());
          console.log("Response from server:", res); // Log the response for debugging
          if (res.status === 0) {
            alert.removeAll();
            alert.show("Moved to trash successfully.");
            setDeleteInvitations(false);
            apiInvitationList(category, subCategory, currentPage, itemsPerPage);
            apiCategoryCount();
            apiSubCategoryCount(category);
            setSelectedItems([]);
          } else if (res.status === 1) {
            alert.removeAll();
            alert.show(
              res.payload || "Error: No payload provided by the server"
            );
          } else {
            alert.removeAll();
            alert.show("Error moving to trash");
          }
        })
        .catch((e) => {
          dispatch(togggleLoad());
          console.error("Error moving to trash:", e); // Log any catch errors for debugging
          alert.removeAll();
          alert.show("Error moving to trash" + e.message);
        });
    } else {
      alert.removeAll();
      alert.show("No item(s) is/are selected to delete");
    }
    setSelectedItems([]);
    setSearchQuery("");
  };

  const onClickDelete = () => {
    setDeleteInvitations(true);
  };

  // emptry trash api
  const onConfirmEmptyTrash = () => {
    dispatch(togggleLoad());
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    request("GET_AUTH", emptytrashinv + `?${email}`, {})
      .then((res) => {
        dispatch(togggleLoad());
        if (res.status === 0) {
          alert.removeAll();
          alert.show(res.payload);
          apiInvitationList(1, itemsPerPage);
          apiCategoryCount();
          setConfirmEmptyTrash(false);
          setSelectedItems([]);
        } else if (res.status === 1) {
          alert.removeAll();
          alert.show(res.msg);
        } else {
          alert.removeAll();
          alert.show("Error Emptying trash");
        }
      })
      .catch((e) => {
        dispatch(togggleLoad());
        alert.removeAll();
        alert.show("Error Emptying trash" + e.message);
      });
  };

  // restore selected items api
  const onClickRestore = () => {
    console.log("Restor::::::::::::::", selectedItems);
    if (selectedItems.length > 0) {
      dispatch(togggleLoad());
      request("POST_AUTH", restoretrashinv, {
        trash: selectedItems,
      })
        .then((res) => {
          dispatch(togggleLoad());
          if (res.status === 0) {
            alert.removeAll();
            alert.show("Restored successfully.");
            apiInvitationList(category, subCategory, currentPage, itemsPerPage);
            apiCategoryCount();
            setConfirmRestoreInvitations(false);
            setSelectedItems([]);
          } else if (res.status === 1) {
            alert.removeAll();
            alert.show(res.payload);
          } else {
            alert.removeAll();
            alert.show("Error restroing.");
          }
        })
        .catch((e) => {
          dispatch(togggleLoad());
          alert.removeAll();
          alert.show("Error restoring" + e.message);
        });
    } else {
      alert.removeAll();
      alert.show("No item(s) is/are selected to restore");
    }
  };

  const deletePermanentlyInvitations = () => {
    setConfirmDetPermanentlyInvitations(true);
  };

  const onCheckItem = (_e, _id) => {
    if (_e.target.checked) {
      const tempItems = [...selectedItems, _id];
      setSelectedItems(tempItems);
    } else {
      const tempItems = selectedItems.filter((item) => item !== _id);
      setSelectedItems(tempItems);
    }
  };

  // on click select all
  const onSelectAll = (_e) => {
    if (_e.target.checked) {
      const tempList = [];
      displayInvitationList.map((item) => {
        tempList.push(item._id);
      });
      setSelectedItems([...tempList]);
    } else {
      setSelectedItems([]);
    }
  };

  const onAcceptInv = (_id) => {
    request("POST_AUTH", acceptInv, { _id: _id })
      .then((response) => {
        if (response.status === 0) {
        } else {
        }
      })
      .catch((e) => {
      });
  }

  const handleCategoryClick = (cate, isTrash) => {
    setShowTrashData(isTrash);
    setCurrentPage(1);
    if (searchQuery.length === 0) {
      apiInvitationList(cate, subCategory, 1, itemsPerPage);
      apiSubCategoryCount(cate);
    } else {
      invitationSearch(
        cate,
        subCategory,
        filterBy,
        searchQuery,
        1,
        itemsPerPage
      );
      apiSearchCatCount(filterBy, searchQuery);
      apiSearchSubCatCount(filterBy, searchQuery, cate);
    }
  };

  const handleSubCategoryClick = (subcat) => {
    setCurrentPage(1);
    if (searchQuery.length === 0) {
      apiInvitationList(category, subcat, 1, itemsPerPage);

    } else {
      invitationSearch(
        category,
        subcat,
        filterBy,
        searchQuery,
        1,
        itemsPerPage
      );
      apiSearchCatCount(filterBy, searchQuery);
      apiSearchSubCatCount(filterBy, searchQuery, category);
    }
    calculateTotalPages(subCategoryCount[subcat]);
  };

  const onClickJoinInvitation = () => {
    setJoinConfirmDilog(true);
  };

  // multiple & single raffles join api
  const multipleSingleRaffJoin = (raffId, invObjId, email) => {
    request("POST_AUTH", joinRaffle, { _id: raffId, uid: email })
      .then((response) => {
        if (response.status === 0) {
          onAcceptInv(invObjId);
          alert.removeAll();
          alert.show("Joined successfully");
        } else {
          alert.removeAll();
          alert.show(response.msg);
        }
      })
      .catch((e) => {
        alert.removeAll();
        alert.show(e.msg);
      });
  };

  // api selected multiple join raffle
  const joinMultipleFreeRaff = () => {
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    console.log(selectedItems);
    // cal api to accept multiple invitations | payload = array of invitation _ids
    
  };

  const sortAlphabetically = (sortBy, dataType) => {
    console.log("Sorting by:", sortBy);
    console.log("Data type:", dataType);
    setSort(sortBy);
    const sortedList = [...displayInvitationList].sort((a, b) => {
      if (dataType === "date") {
        const dateA = new Date(Date.parse(a[sortBy]));
        const dateB = new Date(Date.parse(b[sortBy]));
        if (isNaN(dateA) || isNaN(dateB)) {
          return sortDirection === "asc" ? 1 : -1;
        }
        console.log("Date A:", dateA);
        console.log("Date B:", dateB);
        return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        const compareResult = a[sortBy].localeCompare(b[sortBy]);
        console.log("Compare Result:", compareResult);
        return sortDirection === "asc" ? compareResult : -compareResult;
      }
    });

    console.log("Sorted List:", sortedList);

    setDisplayInvitationList(sortedList);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };



  // api serach invitation invitaionsSearch
  const invitationSearch = (
    category,
    subCategory,
    sortBy,
    search,
    page,
    itemsPerFetch
  ) => {
    setLoading(true);
    const user = JSON.parse(sessionStorage.getItem("User"));
    const email = user?.email;
    setCurrentPage(page);

    request(
      "GET_AUTH",
      invitaionSearch +
      `?email=${email}&sortBy=${sortBy}&search=${search}&category=${category}&subCategory=${subCategory}&page=${page}&itemsPerFetch=${itemsPerFetch}`
    )
      .then((res) => {
        if (res.status === 0) {
          const payload = res.payload.reverse();
          setDisplayInvitationList(payload);
          setLoading(false);
        } else {
          console.log("error res", res);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("error in invitationSearch");
        setLoading(false);
      });
  };

  //api search category count
  const apiSearchCatCount = (sortBy, search) => {
    const user = JSON.parse(sessionStorage.getItem("User"));
    const email = user?.email;
    request(
      "GET_AUTH",
      InvCatSearchCount + `?email=${email}&sortBy=${sortBy}&search=${search}`
    )
      .then((res) => {
        if (res.status === 0) {
          setItemsCount(res.payload);
          calculateTotalPages(res.payload[category]);
        } else {
          console.log("error res", res);
        }
      })
      .catch((e) => {
        console.log("error in apiSearchInvCount");
      });
  };

  //api search SubCategory count
  const apiSearchSubCatCount = (sortBy, search, category) => {
    const user = JSON.parse(sessionStorage.getItem("User"));
    const email = user?.email;
    request(
      "GET_AUTH",
      InvSubCatSearchCount +
      `?email=${email}&category=${category}&sortBy=${sortBy}&search=${search}`
    )
      .then((res) => {
        if (res.status === 0) {
          setSubCategoryCount(res.payload);
          calculateTotalPages(res.payload[subCategory]);
        } else {
          console.log("error res", res);
        }
      })
      .catch((e) => {
        console.log("error in apiSearchNotifsCount");
      });
  };

  const user = JSON.parse(sessionStorage.getItem("User"));
  const email = user?.email;
  const startIndex = (currentPage - 1) * itemsPerPage;

  const options = { 
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true 
  };

  return (
    <div className="invitations-container">
      <div className="sub-header">
        <div className="search-container">
          <div className="inv-input-wrapper">
            <div className="search-here">
              <GoSearch size={16} color="rgba(0,0,0,0.3)" />
              <input
                className={
                  filterBy === "participationOpens" ||
                    filterBy === "participationCloses" ||
                    filterBy === "drawAt"
                    ? "host-date-filter"
                    : "host-search-bar"
                }
                onChange={(e) => {
                  const a = e.target.value;
                  setSearchQuery(a);
                }}
                placeholder={`Search here...`}
                type={
                  filterBy === "participationOpens" ||
                    filterBy === "participationCloses" ||
                    filterBy === "drawAt"
                    ? "date"
                    : "text"
                }
                onKeyDown={handleKeyPress}
                value={searchQuery}
              />
            </div>
            <div className="sortby">
              {searchQuery.length > 0 && (
                <button className="search-x-btn" onClick={onClickXbtn}>
                  X
                </button>
              )}
              <button
                className={
                  searchQuery.length === 0 ? "inv-search-btn-dsbld" : "inv-search-btn"
                }
                onClick={onClickSearchbtn}
                disabled={searchQuery.length === 0}
              >
                 <FaSearch /> 
              </button>
              <select
                className="select"
                value={filterBy}
                onChange={(e) => {
                  setSearchQuery("");
                  setFilterBy(e.target.value);
                }}
              >
                <option key="raffleTitle" value="raffleTitle">
                  Raffle Title
                </option>
                <option key="type" value="type">
                  Type
                </option>
                <option key="hostName" value="hostName">
                  Host Name
                </option>
                <option key="participationOpens" value="participationOpens">
                  Opens At
                </option>
                <option key="participationCloses" value="participationCloses">
                  Closes At
                </option>
                <option key="drawAt" value="drawAt">
                  Draws At
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className="categories">
          <div className="inv-page">Invitations</div>
          <div className="items">
            {!showTrashData && Object.keys(subCategoryCount).map((subItem, i) => (
              <label
                onClick={async () => {
                  setCurrentPage(1);
                  setSubCategory(subItem);
                  handleSubCategoryClick(subItem);
                  console.log("subItem:::::::", subItem);
                }}
                className={subCategory === subItem ? "active2" : "inactive1"}
                key={i}
              >
                {`${subItem} (${subCategoryCount[subItem]})`}
              </label>
            ))}
            <div className="refresh-icon">
              <FaRedoAlt className="refresh-icon" onClick={onRefresh} />
            </div>
            {Object.keys(itemsCount).map((item, i) => (
              <label
                onClick={async () => {
                  console.log("item:::::::", item);
                  setCurrentPage(1);
                  setCategory(item);
                  handleCategoryClick(item, item === "TRASH");
                }}
                className={category === item ? "active2" : "inactive1"}
                key={i}
              >
                {`${item} (${itemsCount[item]})`}
              </label>
            ))}
          </div>
        </div>
      </div>
      {displayInvitationList.length > 0 ? (
        <div className="list-view">
          <table className="table-container">
            <thead className="table-head">
              <tr className="header-row">
                <th className="snum">#</th>
                <th className="check-box">
                  <input
                    type="checkbox"
                    checked={
                      selectedItems.length === displayInvitationList.length
                    }
                    onChange={(_e) => {
                      onSelectAll(_e);
                    }}
                  />
                </th>
                <th className="raff-title">
                  RAFFLE NAME{" "}
                  <FaExchangeAlt
                    style={{
                      transform:
                        sort === "raffleTitle"
                          ? sortDirection === "asc"
                            ? "rotate(90deg)"
                            : "rotate(90deg) scaleX(-1)"
                          : "rotate(90deg)",
                    }}
                    onClick={() => sortAlphabetically("raffleTitle", "string")}
                  />
                </th>
                <th className="from">
                  Host{" "}
                  <FaExchangeAlt
                    style={{
                      transform:
                        sort === "hostName"
                          ? sortDirection === "asc"
                            ? "rotate(90deg)"
                            : "rotate(90deg) scaleX(-1)"
                          : "rotate(90deg)",
                    }}
                    onClick={() => sortAlphabetically("hostName", "string")}
                  />
                </th>
                {subCategory === "PAID" && (
                  <th>
                    Fee{" "}
                    <FaExchangeAlt
                      style={{
                        transform:
                          sort === "fee"
                            ? sortDirection === "asc"
                              ? "rotate(90deg)"
                              : "rotate(90deg) scaleX(-1)"
                            : "rotate(90deg)",
                      }}
                      onClick={() => sortAlphabetically("fee", "date")}
                    />
                  </th>
                )}
                <th className="sub">
                  Opens At{" "}
                  <FaExchangeAlt
                    style={{
                      transform:
                        sort === "participationOpens"
                          ? sortDirection === "asc"
                            ? "rotate(90deg)"
                            : "rotate(90deg) scaleX(-1)"
                          : "rotate(90deg)",
                    }}
                    onClick={() =>
                      sortAlphabetically("participationOpens", "date")
                    }
                  />
                </th>
                <th className="closesat">
                  Closes At{" "}
                  <FaExchangeAlt
                    style={{
                      transform:
                        sort === "participationCloses"
                          ? sortDirection === "asc"
                            ? "rotate(90deg)"
                            : "rotate(90deg) scaleX(-1)"
                          : "rotate(90deg)",
                    }}
                    onClick={() =>
                      sortAlphabetically("participationCloses", "date")
                    }
                  />
                </th>
                <th className="time-stamp">
                  Draw At{" "}
                  <FaExchangeAlt
                    style={{
                      transform:
                        sort === "drawAt"
                          ? sortDirection === "asc"
                            ? "rotate(90deg)"
                            : "rotate(90deg) scaleX(-1)"
                          : "rotate(90deg)",
                    }}
                    onClick={() => sortAlphabetically("drawAt", "date")}
                  />
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {displayInvitationList.map((item, index) => (
                <tr key={index} className="tb-row">
                  <td className="td-snum">
                    {startIndex + index + 1}
                  </td>
                  <td className="td-check-box">
                    <input
                      type="checkbox"
                      checked={selectedItems.includes(item._id)}
                      onChange={(_e) => {
                        onCheckItem(_e, item._id);
                      }}
                    />
                  </td>
                  <td className="raff-title">{item.raffleInfo.raffleTitle} ({item.raffleInfo.type})</td>
                  <td className="from">{item.hostName}</td>
                  {subCategory === "PAID" && (
                    <td>{`${item.raffleInfo.feeCurrency}${item.raffleInfo.fee}`}</td>
                  )}
                  <td>
                    {new Date(
                      item.participationOpens
                    ).toLocaleString("en-US", options)}
                  </td>
                  <td>
                    {new Date(
                      item.participationCloses
                    ).toLocaleString("en-US", options)}
                  </td>
                  <td>
                    {item.raffleInfo.type === "Instant" ? new Date().toLocaleString("en-US", options) : new Date(item.drawAt).toLocaleString("en-US", options)}
                  </td>
                  <td className="actions">
                    <FaRegEye
                      className="eye"
                      onClick={() => {
                        onClickView(item.raffleInfo.raffleId, "view");
                      }}
                    />
                    <FaBullseye
                      className="bulls-eye"
                      style={{ paddingLeft: "10px" }}
                      onClick={() => {
                        if (new Date(item.participationOpens) > new Date()) {
                          alert.removeAll();
                          alert.show("Raffle is not open yet. Please check back after " +
                            new Date(item.participationOpens).toLocaleString("en-US"))
                        } else {
                          multipleSingleRaffJoin(item.raffleInfo.raffleId, item._id, email);
                        }
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        !loading && (
          <div className="no-items">
            <img
              className="img"
              src={require("../../../../../src/assets/img/notification.jpg")}
              alt="No Notifications to display"
            />
            <p style={{ paddingTop: "10px", fontFamily: "Roboto, sans-serif" }}>
              No Invitations
            </p>
          </div>
        )
      )}
      {loading ? (
        <div className="notif-loader">
          <Loader type="TailSpin" color="rgb(11, 70, 85)" width={60} />
        </div>
      ) : null}
      {
        activeModel === "ParticipantRaffleViewJoinModel" ?
          <RaffleTicketViewModel
              userType={"participant"}
              asParticipant={false}
              asCatalogue={true}
              choosenRaffle={raffle}
              choosenRafflePrizes={rafflePrizes}
              toggleShareModel={(link) => {
                setModel("SocialShareModel");
                  setSocialShareLink(link);
              }}
              onClose={() => {
                setModel("");
                sessionStorage.removeItem("invitationId") // clear out from login/register to join page or invitation link is clcked by participant.
              }}
              type={"VIEWJOIN"}
              invAccepted={(invId) => onAcceptInv(invId)}
          /> :
        activeModel === "SocialShareModel" ?
          <SocialShareModel
              onClose={() => setModel("")}
              url={socialShareLink}
          />
          : null
      }
      <div
        style={{
          display: confirmDeleteInvitations ? "block" : "none",
          position: "absolute",
          zIndex: 9,
        }}
      >
        <DeleteInvitations
          setDeleteInvitations={setDeleteInvitations}
          onProceed={deleteInvitations}
        />
      </div>
      <div
        style={{
          display: joinConfirmDilog ? "block" : "none",
          position: "absolute",
          zIndex: 9,
        }}
      >
        <JoinInvitations
          setJoinConfirmDilog={setJoinConfirmDilog}
          displayInvitationList={displayInvitationList}
          selectedItems={selectedItems}
          onProceed={() => joinMultipleFreeRaff()}
        />
      </div>
      <div
        style={{
          display: confirmRestoreInvitations ? "block" : "none",
          position: "absolute",
          zIndex: 9,
        }}
      >
        <RestoreInvitations
          setConfirmRestoreInvitations={setConfirmRestoreInvitations}
          onProceed={onClickRestore}
        />
      </div>
      <div
        style={{
          display: confirmDetPermanentlyInvitations ? "block" : "none",
          position: "absolute",
          zIndex: 9,
        }}
      >
        <DeletePermanentlyInvitations
          setConfirmDetPermanentlyInvitations={
            setConfirmDetPermanentlyInvitations
          }
          onProceed={onClickDeletePermanetly}
        />
      </div>
      <div
        style={{
          display: confirmEmptyTrash ? "block" : "none",
          position: "absolute",
          zIndex: 9,
        }}
      >
        <EmptyTrashInvitations
          setConfirmEmptyTrash={setConfirmEmptyTrash}
          onProceed={onConfirmEmptyTrash}
        />
      </div>
      {!showTrashData && displayInvitationList.length > 0 && (
        <div className="btn-container">
          {subCategory === "FREE" ? (
            <button
              className={selectedItems.length > 0 ? "dlt-btn" : "disablebtn"}
              onClick={onClickJoinInvitation}
            >
              Join
            </button>
          ) : null}
          {subCategory !== "trash" && (
            <button
              disabled={selectedItems.length === 0}
              className={selectedItems.length > 0 ? "dlt-btn" : "disablebtn"}
              onClick={onClickDelete}
            >
              DELETE
            </button>
          )}
        </div>
      )}
      {category === "TRASH" && displayInvitationList.length > 0 && (
        <div className="trashbtns">
          <div>
            <button
              disabled={selectedItems.length === 0}
              className={
                selectedItems.length > 0 ? "restorebtn" : "disablebtn2"
              }
              onClick={() => setConfirmRestoreInvitations(true)}
            >
              RESTORE
            </button>
          </div>
          <div>
            <button
              disabled={selectedItems.length === 0}
              className={
                selectedItems.length > 0 ? "restorebtn" : "disablebtn2"
              }
              onClick={deletePermanentlyInvitations}
            >
              DELETE
            </button>
          </div>
          {displayInvitationList.length > 0 && (
            <div>
              <button onClick={onClickConfirmEmptyTrash} className="restorebtn">
                EMPTY TRASH
              </button>
            </div>
          )}
        </div>
      )}

      <div className="notif-footer">
        <div htmlFor="itemsperpage" className="itemsperpage">
          Items per page
          <select
            id="itemsperpage"
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(e.target.value)}
          >
            <option key={10} value={10}>
              10
            </option>
            <option key={20} value={20}>
              20
            </option>
            <option key={30} value={30}>
              30
            </option>
            <option key={40} value={40}>
              40
            </option>
            <option key={50} value={50}>
              50
            </option>
          </select>
        </div>
        {subCategoryCount[subCategory] > itemsPerPage && (
          <div className="pagination">
            {currentPage > 1 && (
              <button
                className="pagination-number"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                &lt;
              </button>
            )}
            <button
              className={`pagination-number ${currentPage === 1 ? "active" : ""
                }`}
              onClick={() => {
                console.log("currentPage::::::", currentPage);
                handlePageChange(1);
              }}
            >
              1
            </button>
            {currentPage > 3 && <span className="ellipsis">...</span>}

            {Array.from({ length: totalPages }).map((_, index) => {
              // Determine the range of pages to display
              const startPage = Math.max(2, currentPage - 2);
              const endPage = Math.min(totalPages - 1, startPage + 3);

              // Display page buttons within the range, excluding the first and last page buttons
              if (index + 1 >= startPage && index + 1 <= endPage) {
                return (
                  <button
                    key={index + 1}
                    className={`pagination-number ${currentPage === index + 1 ? "active" : ""
                      }`}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                );
              }

              return null;
            })}

            {currentPage < totalPages - 2 && (
              <div className="ellipsis">...</div>
            )}

            {/* Render the last page number button always */}
            <button
              className={`pagination-number ${currentPage === totalPages ? "active" : ""
                }`}
              onClick={() =>
                handlePageChange(isNaN(totalPages) ? 1 : totalPages)
              }
            >
              {isNaN(totalPages) ? currentPage + 1 : totalPages.toString()}
            </button>

            {currentPage < totalPages && (
              <button
                className="pagination-number"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                &gt;
              </button>
            )}
          </div>
        )}

        <div className="empty-container"></div>
      </div>
    </div>
  );
};

export default InvitationsList;
