const initialState = {
    loading: false
}

export default (state = initialState, { type }) => {
    switch (type) {
        case "TOGGLE_LOAD":
            return { ...state, loading: !state.loading }
        default:
            return state;
    }
}