import { getAllBeneficiariesHost } from '../../config/endpoints';

export const LoadBeneficiaries = () => {
    return (dispatch) => {
        dispatch({ "type": "LOAD_BENEFICIARIES" });
        fetch(getAllBeneficiariesHost, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Content-Type": "application/json",
                "authorization": `Brearer ${sessionStorage.getItem("Token")}`
            },
            body: JSON.stringify({})
        }).then((res) => res.json()).then((res) => {
            const my_benes = JSON.parse(sessionStorage.getItem("User")).benes;
            let sorted = res.map((r, i) => {
                if (my_benes.includes(r._id)) {
                    return r;
                }
            }).filter(x => x !== undefined);
            dispatch({ "type": "LOADED_BENEFICIARIES", "payload": res, 'payload_my_bene': sorted });
        }).catch((e) => {
            dispatch({ "type": "ERROR_BENEFICIARIES", "payload": e });
        })
    }
}

export const UpdateBeneficiaries = (benes) => {
    return { "type": "UPDATE_BENEFICIARIES", "payload": benes }
};
export const UpdateMyBeneficiaries = (benes) => ({ "type": "UPDATE_MY_BENEFICIARIES", "payload": benes });