import React from 'react';
import './showBene.scss';
import { MdClose } from 'react-icons/md';

export default ({ onClose, data }) => {
    return <div className="show_bene">
        <div>
            <div>
                <div>Contact</div>
                <div onClick={onClose}>X</div>
            </div>
            <div>
                <div>
                    <div>
                        <label>POC</label>
                        <text>{data.poc}</text>
                    </div>
                    <div>
                        <label>Contact</label>
                        <text>{data.contact}</text>
                    </div>
                </div>
                <div>
                    <label>Address</label>
                    <text>{data.address}</text>
                </div>
            </div>
            <div>
                <div onClick={onClose}>CLOSE</div>
            </div>
        </div>
    </div>
}