export const request = (method, endpoint, data) => {
    if (method === "POST_AUTH") {
        let token = sessionStorage.getItem("Token");
        return fetch(endpoint, {
            method:"POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "authorization": "Bearer " + token
            },
            body: JSON.stringify(data)
        }).then((res) => res.json());
    }
    else if (method === "GET_AUTH") {
        let token = sessionStorage.getItem("Token");
        return fetch(endpoint,{
            method : "GET",
            headers: {
                "authorization": "Bearer " + token
            },
        }).then((res) => res.json());
    }
    else if (method === "GET") {
        return fetch(endpoint).then((res) => res.json());
    } else {
        return fetch(endpoint, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then((res) => res.json());
    }
}