import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  emailValidation,
  passwordValidation,
  nameValidation,
} from "../../../../helper/formValidation";
import { useAlert } from "react-alert";
import { updateUserHost } from "../../../../config/endpoints";
import { request } from "../../../../helper/request";
import { useDispatch, connect } from "react-redux";
import { togggleLoad } from "../../../../store/actions/loadAction";
import firebase from "../../../../helper/firebase";
import { FaEdit, FaPlusSquare } from "react-icons/fa";
import PasswordChange from "./passwordChange";

export default ({ setProfileImage, setUsr, profileImage }) => {
  const alert = useAlert();
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(profileImage);
  const [newProfileUploaded, setNewProfileUploaded] = useState(null);
  const [showcpDilog, setShowcpDilog] = useState(false);
  const [selectedImageLoaded, setSelectedImageLoaded] = useState(false);
  useEffect(() => {
    let usr = JSON.parse(sessionStorage.getItem("User", null));
    usr["password"] = "";
    setUser(usr);
    console.log("Usr::::::::::::::", usr);
    if (profileImage) {
      setSelectedImage(profileImage);
    }
  }, []);

  const passwordChanged = (str) => {
    let usr = JSON.parse(sessionStorage.getItem("User", null));
    // usr["password"] = str;
    setUser(usr);
  };

  const saveButtonClicked = async () => {
    // setFileName(newProfileUploaded.name);
    if (user.mobile.length < 10) {
      alert.removeAll();
      alert.show("Please enter a valid phone number");
      return; // Stop further execution
    }
    if (user.address.length <= 0) {
      alert.removeAll();
      alert.show("Please fill your address");
      return;
    }
    dispatch(togggleLoad());
    request("POST_AUTH", updateUserHost, user)
      .then(async ({ status, msg, payload }) => {
        if (status === 0) {
          alert.removeAll();
          alert.show("Saved successfully");
          sessionStorage.setItem("User", JSON.stringify(user));
          setUsr(user);
          if (newProfileUploaded !== null) {
            const profilePicsRef = firebase
              .storage()
              .ref()
              .child("profile_pics/" + user.email);
            await profilePicsRef.put(newProfileUploaded);
            setProfileImage(URL.createObjectURL(newProfileUploaded));
            // alert.removeAll();
            // alert.show("Profile Picture Changed successfully");
          } else if (selectedImage === null) {
            // Handle image removal logic here
            const profilePicsRef = firebase
              .storage()
              .ref()
              .child("profile_pics/" + user.email);
            await profilePicsRef.delete();
            setProfileImage(null);
            setSelectedImage(null);
            // alert.removeAll();
            // alert.show("Profile Picture Removed successfully");
          }
        } else {
          alert.removeAll();
          alert.show("Error : " + payload);
        }
        dispatch(togggleLoad());
      })
      .catch((e) => {
        dispatch(togggleLoad());
      });
  };

  const proflieRemoveImage = () => {
    // setProfileImage(null);
    setSelectedImage(null);
    // alert.removeAll();
    // alert.show("Removed successfully");
  };

  const onTyping = (event) => {
    const file = event.currentTarget.files[0];
    // setNewProfileUploaded(file);
    // if (file === newProfileUploaded) {
    //   // Set the selectedImage state to the same URL to trigger re-rendering
    //   setSelectedImage(URL.createObjectURL(file));
    // } else {
    //   setSelectedImage(URL.createObjectURL(file));
    // }
    setNewProfileUploaded(file);
    setSelectedImage(URL.createObjectURL(file));
    const reader = new FileReader();
    reader.readAsDataURL(file);
  };

  const onClose = () => {
    setShowcpDilog(false);
  };

  const handleImageLoad = () => {
    // Update the state to hide the plus icon when the image is loaded
    setSelectedImageLoaded(true);
  };

  return user !== null ? (
    <div className="host_settings_view">
      <form>
        <label className="profile-page">PROFILE</label>
        <div>
          <div>
            <label>Email</label>
            <input value={user.email} disabled />
          </div>

          <div>
            <label>Name</label>
            <input
              disabled
              value={user.name}
              onChange={(e) => {
                const text = e.currentTarget.value;
                setUser((usr) => ({ ...usr, name: text }));
              }}
            />
          </div>
        </div>
        <div>
          <div>
            <label>Phone Number</label>
            <input
              maxLength={10}
              value={user.mobile}
              onChange={(e) => {
                const text = e.currentTarget.value;
                setUser((usr) => ({ ...usr, mobile: text }));
              }}
            />
          </div>
          <div>
            <label>Password</label>
            <div className="password">
              <input
                type="password"
                disabled
                value={"********"}
                onChange={(e) => {
                  const text = e.currentTarget.value;
                  setUser((usr) => ({ ...usr, password: text }));
                }}
              />
              <button
                className={`edit-btn`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setShowcpDilog(true);
                }}
              >
                <FaEdit className={`edit-icon`} />
              </button>
            </div>
            <div
              style={{
                display: showcpDilog ? "block" : "none",
                position: "absolute",
                zIndex: 9,
              }}
            >
              <PasswordChange
                onClose={onClose}
                userEmail={user?.email}
                passwordChanged={passwordChanged}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <label>Profile Picture</label>
            {/* <input onChange={onTyping} type="file" value={""} /> */}
            <div className="container">
              <form className="form">
              </form>
            </div>
            <div className="image-preview">
              {/* {selectedImage && (
                <div className="image-container">
                  <img src={selectedImage} alt="Preview" className="image"  />
                  <button className="close-button" onClick={proflieRemoveImage}>X</button>
                </div>
              )} */}
              {selectedImage ? (
                <div className="image-container">
                  <img
                    src={selectedImage}
                    alt="Preview"
                    className="image"
                    onLoad={handleImageLoad}
                  />
                  <button
                    className="close-button"
                    // onClick={proflieRemoveImage}
                    onClick={(ev) => {
                      ev.preventDefault();
                      proflieRemoveImage();
                      console.log("Remove button is clicked!!!");
                    }}
                  >
                    X
                  </button>
                </div>
              ) : (
                <label htmlFor="chooseImg">
                  <FaPlusSquare size={19} className="plus-icon" />
                </label>
              )}
              <input
                type="file"
                accept="image/*"
                id="chooseImg"
                onChange={onTyping}
                className="choose-file"
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
              {/* {selectedImage && (
                <button
                  className="remove-image-button"
                  onClick={(ev) => {
                    ev.preventDefault();
                    proflieRemoveImage();
                    console.log("Remove button is clicked!!!");
                  }}
                >
                  Remove Image
                </button>
              )} */}
            </div>
          </div>
        </div>
        <label>Shipping Address</label>
        <div>
          <div>
            <textarea
              value={user.address}
              onChange={(e) => {
                const text = e.currentTarget.value;
                setUser((usr) => ({ ...usr, address: text }));
              }}
            ></textarea>
          </div>
        </div>
      </form>
      <div className="save-btn" onClick={saveButtonClicked}>SAVE</div>
    </div>
  ) : null;
};
