import { request } from '../../helper/request';
import { getAllNotification, getRaffles, myRaffles } from '../../config/endpoints';


export const LoadRaffles = () => {
    return (dispatch) => {
        dispatch({ type: "FETCHING_RAFFLES" });
        request("GET_AUTH", getRaffles, {}).then((res) => {
            if (res.status === 0) {
                const payload = res.payload.reverse();
                if (payload.length > 0) {
                    dispatch({ type: "FETCHED_RAFFLES", payload });
                } else {
                    dispatch({ type: "FETCHED_EMPTY", payload });
                }
            } else {
                dispatch({ type: "FETCH_ERROR" });
            }
        }).catch((e) => {
            dispatch({ type: "FETCH_ERROR" });
        })
    }
}



export const LoadAllRaffles = (page) => {
    return (dispatch) => {
        dispatch({ type: "FETCHING_RAFFLES" });
        request("GET_AUTH", getRaffles + `?page=${page}`, {}).then((res) => {
            if (res.status === 0) {
                const payload = res.payload.reverse();
                if (payload.length > 0) {
                    dispatch({ type: "FETCHED_RAFFLES", payload });
                } else {
                    dispatch({ type: "FETCHED_EMPTY", payload });
                }
            } else {
                dispatch({ type: "FETCH_ERROR" });
            }
        }).catch((e) => {
            dispatch({ type: "FETCH_ERROR" });
        })
    }
}

export const LoadMyRaffles = () => {
    return (dispatch) => {
        const user = JSON.parse(sessionStorage.getItem("User"));
        const email = user.email
        dispatch({ type: "FETCHING_MY_RAFFLES" });
        request("POST_AUTH", myRaffles, {
            uid: email
        }).then((res) => {
            if (res.status === 0) {
                const payload = res.payload.reverse();
                if (payload.length > 0) {
                    dispatch({ type: "FETCHED_MY_RAFFLES", payload });
                } else {
                    dispatch({ type: "FETCHED_MY_EMPTY", payload });
                }
            } else {
                dispatch({ type: "FETCH_MY_ERROR" });
            }
        }).catch((e) => {
            dispatch({ type: "FETCH_MY_ERROR" });
        })
    }
}

export const LoadNotificatons = () => {
    return (dispatch) => {
        dispatch({ type: "FETCHING_RAFFLES" });
        request("GET_AUTH", getAllNotification, {}).then((res) => {
            if (res.status === 0) {
                const payload = res.payload.reverse();
                if (payload.length > 0) {
                    dispatch({ type: "FETCHED_RAFFLES", payload });
                } else {
                    dispatch({ type: "FETCHED_EMPTY", payload });
                }
            } else {
                dispatch({ type: "FETCH_ERROR" });
            }
        }).catch((e) => {
            dispatch({ type: "FETCH_ERROR" });
        })
    }
}

export const AddRaffle = (raffle) => ({ type: "ADD_RAFFLE", payload: raffle })

export const DelRaffle = (id) => ({ type: "DEL_RAFFLE", payload: id });

export const UpdateRaffle = (raffle) => ({ type: "UPDATE_RAFFLE", payload: raffle });

export const ActivateDeactivateRaffle = (payload) => ({ type: "ACTIVATE_DEACTIVATE_RAFFLE", payload })

export const JoinRaffle = (raffleId, uid) => ({ type: "JOIN_RAFFLE", payload: { raffleId, uid } })

export const SwapSeriesRaffles = (rafflesToRemoveList, newRafflesList) => ({ type: "SWAP_SERIES_RAFFLES", payload: { rafflesToRemoveList, newRafflesList } })

export const RaffleWinnerPicked = (raffleid, winners) => ({ type: "RAFFLE_WINNER_PICKED", payload: { raffleid, winners } })