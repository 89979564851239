import React, { useState, useEffect } from "react";
import "./joinInvitations.scss";

export default ({
  setJoinConfirmDilog,
  onProceed,
  displayInvitationList,
  selectedItems,
  tempList,
}) => {
  return (
    <div className="Confirm-delete">
      <div className="container1">
        <div className="container1-child1">
          <div className="container1-child1-child1">
            <div className="header">
              <img
                className="del-cup"
                src={require("../../../../assets/img/cup.png")}
                alt="Cup"
              />
              <div>ARE YOU SURE WANT TO JOIN THE RAFFLE(S)</div>
              <button
                className="x-close-btn"
                onClick={() => {
                  setJoinConfirmDilog(false);
                }}
              >
                X
              </button>
            </div>
            <div className="selecteditems-container">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Raffle Title</th>
                    <th>Host Name</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {selectedItems.map((itemId, index) => {
                    const selectedItem = displayInvitationList.find(
                      (item) => item._id === itemId
                    );
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{selectedItem.raffleInfo.raffleTitle}</td>
                        <td>{selectedItem.hostName}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="footer">
              <button
                className={"close-btn"}
                onClick={() => {
                  setJoinConfirmDilog(false);
                }}
              >
                Cancel
              </button>
              <button className={"confirm-btn"} onClick={onProceed}>
                Join
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
