import React, { useEffect, useState } from 'react';
import './index.scss';
import { GoSearch } from 'react-icons/go';
import { AiOutlinePoweroff } from 'react-icons/ai';
import { FaSortAmountDownAlt, FaSortAmountUpAlt, FaSortAlphaDown, FaSortAlphaDownAlt, FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import SocialShareModel from '../workSpace/models/socialShareModel';
import NoRafflesFound from '../../assets/NoRafflesFound';
import { useAlert } from 'react-alert';

import RaffleTicket from '../../assets/svg_components/raffle_ticket';
import RaffleTicketViewModel from '../workSpace/drawerPages/hostedRaffles/RaffleTicketViewModel';
import { request } from '../../helper/request';
import { getAllCount, getRaffleById, getRaffles, searchAllRaffles, searchCount } from '../../config/endpoints';
import Loader from 'react-loader-spinner';

// const sortRafflesList = ["FREE", "PAID"];
const sortOrderList = ["ASCENDING", "DESCENDING"];
// const itemsPerPage = 21;

export default () => {
    const [localRaffles, setLocalRaffles] = useState([]);
    const [sortBy, setSortBy] = useState("participationCloses");
    const [sortRaffle, setSortRaffle] = useState("FREE");
    const [sortRafflesList, setSortRafflesList] = useState({ FREE: 1, PAID: 1 })
    const [sortOrder, setSortOrder] = useState("");
    const [activeModel, setActiveModel] = useState("");
    const [choosenRaffle, setChoosenRaffle] = useState(null);
    const [choosenRafflePrizes, setChoosenRafflePrizes] = useState([]);
    const [socialShareLink, setSocialShareLink] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(20);

    const [user, setUser] = useState(null);

    const history = useHistory();

    const alert = useAlert();

    // search input
    const [searchInput, setSearchInput] = useState("");
    const [loading, setLoading] = useState(false);

    const calculateTotalPages = (count) => {
        const pages = Math.ceil(count / itemsPerPage);
        setTotalPages(pages);
        return pages;
    }

    const apiAllList = (isFree, page, itemsPerFetch) => {
        setLoading(true);
        setCurrentPage(page);
        request("GET_AUTH", getRaffles + `?isFree=${isFree}&page=${page}&itemsPerFetch=${itemsPerFetch}`).then((res) => {
            if (res.status === 0) {
                const payload = res.payload.reverse();
                console.log(payload);
                let sortedList = payload.sort((a, b) => new Date(a.participationCloses) - new Date(b.participationCloses));
                setLocalRaffles([...sortedList]);
                setLoading(false);
            } else {
                console.log("error res", res);
                setLoading(false);
            }
        }).catch((e) => {
            console.log("error in apiAllList");
            setLoading(false);
        })
    }

    const apiAllSearch = (isFree, sortBy, search, page, itemsPerFetch) => {
        setLoading(true);
        request("GET_AUTH", searchAllRaffles + `?isFree=${isFree}&sortBy=${sortBy}&search=${search}&page=${page}&itemsPerFetch=${itemsPerFetch}`).then((res) => {
            if (res.status === 0) {
                const payload = res.payload.reverse();
                console.log(payload);
                let sortedList = payload.sort((a, b) => new Date(a.participationCloses) - new Date(b.participationCloses));
                setLocalRaffles([...sortedList]);
                setLoading(false);
            } else {
                console.log("error res", res);
                setLoading(false);
            }
        }).catch((e) => {
            console.log("error in apiAllSearch");
            setLoading(false);
        })
    }

    const apiAllcount = () => {
        request("GET_AUTH", getAllCount).then((res) => {
            if (res.status === 0) {
                setSortRafflesList(res.payload);
                calculateTotalPages(sortRaffle === "FREE" ? res.payload.FREE : res.payload.PAID)
            } else {
                console.log("error res", res);
            }
        }).catch((e) => {
            console.log("error in apiAllcount");
        })
    }

    const apiSearchcount = (sortBy, search) => {
        request("GET_AUTH", searchCount + `?sortBy=${sortBy}&search=${search}`).then((res) => {
            if (res.status === 0) {
                setSortRafflesList(res.payload);
                calculateTotalPages(sortRaffle === "FREE" ? res.payload.FREE : res.payload.PAID)
            } else {
                console.log("error res", res);
            }
        }).catch((e) => {
            console.log("error in apiAllList");
        })
    }

    // get user session
    useEffect(() => {
        const usr = sessionStorage.getItem("User")
        if (usr) {
            setUser(JSON.parse(usr));
        }
        apiAllList(sortRaffle === "FREE", currentPage, itemsPerPage);
        apiAllcount();
    }, [])

    useEffect(() => {
        const tp = calculateTotalPages(sortRafflesList[sortRaffle]);
        if (tp > 0) {
            if (searchInput.length === 0) {
                apiAllList(sortRaffle === "FREE", tp > currentPage ? currentPage : tp, itemsPerPage);
            } else {
                apiAllSearch(sortRaffle === "FREE", sortBy, searchInput, tp > currentPage ? currentPage : tp, itemsPerPage);
            }
        }
    }, [currentPage, sortRaffle, itemsPerPage]);

    // Handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const sortingBySelectedOrder = (list, order, sortOption) => {
        let sortedList = [];
        if (order === "ASCENDING") {
            // do ascending
            if (sortOption === "drawAt") {
                sortedList = list.sort((a, b) => {
                    let aDate = new Date(a?.drawAt);
                    let bDate = new Date(b?.drawAt);
                    if (!a.drawAt) {
                        aDate = new Date(a.drawAt);
                    }
                    if (!b.drawAt) {
                        bDate = new Date(b.drawAt);
                    }
                    return aDate - bDate;
                });
            } else if (sortOption === "participationOpens" || sortOption === "participationCloses") {
                sortedList = list.sort((a, b) => new Date(a[sortOption]) - new Date(b[sortOption]));
            } else if (sortOption === "recurrencePattern") {
                sortedList = list.sort((a, b) => a.extras.seriesRaffle[sortOption].localeCompare(b.extras.seriesRaffle[sortOption]));
            }
            else {
                sortedList = list.sort((a, b) => a[sortOption].localeCompare(b[sortOption]));
            }
        } else {
            // do descending
            if (sortOption === "drawAt") {
                sortedList = list.sort((a, b) => {
                    let aDate = new Date(a?.drawAt);
                    let bDate = new Date(b?.drawAt);
                    if (!a.drawAt) {
                        aDate = new Date(a.drawAt);
                    }
                    if (!b.drawAt) {
                        bDate = new Date(b.drawAt);
                    }
                    return bDate - aDate;
                });
            } else if (sortOption === "participationOpens" || sortOption === "participationCloses") {
                sortedList = list.sort((a, b) => new Date(b[sortOption]) - new Date(a[sortOption]));
            } else if (sortOption === "recurrencePattern") {
                sortedList = list.sort((a, b) => b.extras.seriesRaffle[sortOption].localeCompare(a.extras.seriesRaffle[sortOption]));
            }
            else {
                sortedList = list.sort((a, b) => b[sortOption].localeCompare(a[sortOption]));
            }
        }
        setLocalRaffles(sortedList);
        return sortedList;
    }

    const onToggleLifeState = (lifeCycleState) => {
        setSortRaffle(lifeCycleState);
        calculateTotalPages(lifeCycleState === "FREE" ? sortRafflesList.FREE : sortRafflesList.PAID);
    }

    const onChangeSearch = (text) => {
        setSearchInput(text);
        console.log(text);
    }

    const onClickXbtn = () => {
        setSearchInput("");
        setCurrentPage(1);
        apiAllList(sortRaffle === "FREE", 1, itemsPerPage);
        apiAllcount();
    }

    const onClickSearchbtn = (sortby, search) => {
        setCurrentPage(1);
        apiAllSearch(sortRaffle === "FREE", sortBy, searchInput, 1, itemsPerPage);
        apiSearchcount(sortBy, searchInput);
    }

    const onClickTicket = (_id, img) => {
        setLoading(true);
        request("POST_AUTH", getRaffleById, { _id: _id })
            .then((res) => {
                if (res.status === 0) {
                    const raff = res.payload.raffle;
                    const prizesList = res.payload.prizes;
                    setChoosenRaffle({ ...raff, image: img });
                    setChoosenRafflePrizes(prizesList);
                    setActiveModel("ParticipantRaffleViewJoinModel");
                    setLoading(false);
                } else {
                    alert.removeAll();
                    alert.show("Error loading raffle by id")
                    setLoading(false);
                }
            })
            .catch((e) => {
                setLoading(false);
            });
    }
    
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          // Call your search action function here
          // Example: performSearch();
          event.preventDefault(); // Prevent the default Enter key behavior
        }
      };
      

    return (
        // <div>some content</div>
        <div className="catalouge">
            {/* Header */}
            <div className='header'>
                <div>
                    <img src={require('../../assets/img/catalog_logo.png')} />
                    <label>Rafflerra Catalogue</label>
                </div>
                {
                    user ? <div className="logout">
                        <p>{user.name}</p>
                        <AiOutlinePoweroff onClick={() => {
                            sessionStorage.removeItem("User");
                            sessionStorage.removeItem("Token");
                            setUser(null);
                            alert.removeAll();
                            alert.show("Logged out successfully")
                        }} />
                    </div> :
                        <div onClick={() => history.push('authentication/catalouge', {})} className="login">Login</div>
                }
            </div>
            {/* Sort list of raffles */}
            <div className='catelog-operations'>
                {/* search bar */}
                <div>
                    <GoSearch size={16} color="rgba(0,0,0,0.3)" />
                    <input
                        onKeyDown={handleKeyDown}
                        onChange={async (e) => {
                            const text = e.currentTarget.value.toLowerCase();
                            onChangeSearch(text);
                        }}
                        placeholder={`Search by ${sortBy.substr(0, 1).toUpperCase() + sortBy.substr(1)}`}
                        type={(sortBy === "participationOpens" || sortBy === "participationCloses" || sortBy === "drawAt") ? 'date' : 'text'}
                        
                        value={searchInput}
                        // style={{ width: "100%" }}
                        className={sortBy === "participationOpens" || sortBy === "participationCloses" || sortBy === "drawAt" ? "date-filter-search" : "search-bar"}
                    />
                    {searchInput.length > 0 && <button className='cat-x-btn'
                        onClick={onClickXbtn}>
                        X
                    </button>}
                    <button className={searchInput.length === 0 ? 'cat-search-btn-dsbld' : 'cat-search-btn'}
                        onClick={onClickSearchbtn}
                        disabled={searchInput.length === 0}>
                       <FaSearch className='icon' /> 
                    </button>
                    <select
                        disabled={sortRafflesList[sortRaffle] === 0}
                        onChange={(e) => {
                            const option = e.currentTarget.value;
                            setSortBy(option);
                        }}
                        value={sortBy}
                    >
                        <option key="title" value="title">Title</option>
                        <option key="rafflerType" value="rafflerType">Category</option>
                        <option key="createdBy" value="createdBy">Hosted By</option>
                        <option key="participationOpens" value="participationOpens">Open Date</option>
                        <option key="participationCloses" value="participationCloses">Close Date</option>
                        <option key="drawAt" value="drawAt">Draw Date</option>
                        <option key="recurrencePattern" value="recurrencePattern">Recurrence</option>
                    </select>
                    <div className='sortorder-switch'>
                        {
                            sortOrderList.map((item, i) => (
                                <label
                                    onClick={async () => {
                                        setSortOrder(item);
                                        sortingBySelectedOrder(localRaffles, item, sortBy);
                                    }}
                                    className={sortOrder === item ? "active" : ""}
                                    key={i}>
                                    <span>
                                        {item === "ASCENDING" ? (
                                            sortBy === "participationOpens" ||
                                                sortBy === "participationCloses" ||
                                                sortBy === "drawnAt" ? (
                                                <FaSortAmountDownAlt />
                                            ) : (
                                                <FaSortAlphaDown />
                                            )
                                        ) : sortBy === "participationOpens" ||
                                            sortBy === "participationCloses" ||
                                            sortBy === "drawnAt" ? (
                                            <FaSortAmountUpAlt />
                                        ) : (
                                            <FaSortAlphaDownAlt />
                                        )}
                                    </span>
                                </label>
                            ))
                        }
                    </div>
                </div>
                <div>
                    {
                        Object.keys(sortRafflesList).map((item, i) => (
                            <label
                                onClick={async () => {
                                    onToggleLifeState(item);
                                }}
                                className={sortRaffle === item ? "active" : ""}
                                key={i}>
                                {item} ({sortRafflesList[item]})
                            </label>
                        ))
                    }
                </div>
            </div>
            {/* List of available raffles */}
            {
                sortRafflesList[sortRaffle] === 0 ? <NoRafflesFound /> :
                    <div className='raffs-list'>
                        {
                            localRaffles.map((raffle, i) => {
                                if (!("isTemplate" in raffle.extras && raffle.extras.isTemplate) && raffle.extras.active) {
                                    return <RaffleTicket
                                        key={i}
                                        raffleData={raffle}
                                        dates={{ start: raffle.participationOpens, end: raffle.participationCloses, drawn: raffle?.drawAt }}
                                        shortDescription={raffle.shortDescription}
                                        seriesId={raffle.extras.seriesRaffle.seriesId}
                                        id={raffle.id}
                                        title={raffle.title}
                                        fee={raffle.fee}
                                        feeCurrency={raffle.feeCurrency}
                                        image={null}
                                        createdBy={raffle.createdBy}
                                        styles={raffle.styles}
                                        onClick={(img) => {
                                            onClickTicket(raffle._id, img)
                                        }} />
                                }
                            })
                        }
                    </div>
            }
            <div className='catalog-footer'>
                <div htmlFor='itemsperpage' className='itemsperpage'>
                    Items per page
                    <select
                        id="itemsperpage"
                        value={itemsPerPage}
                        onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                        <option key={10} value={10}>10</option>
                        <option key={20} value={20}>20</option>
                        <option key={30} value={30}>30</option>
                        <option key={40} value={40}>40</option>
                        <option key={50} value={50}>50</option>
                    </select>
                </div>
                {sortRafflesList[sortRaffle] > itemsPerPage && <div className="catelog-pagination">
                    {currentPage > 1 && (
                        <button className="ctlg-page-num"
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            &lt;
                        </button>
                    )}
                    {currentPage >= 1 && (
                        <button
                            className={`ctlg-page-num ${currentPage === 1 ? "active" : ""
                                }`}
                            onClick={() => handlePageChange(1)}>
                            1
                        </button>
                    )}
                    {currentPage > 3 && <span className="ellipsis">...</span>}
                    {Array.from({ length: totalPages }).map((_, index) => {
                        // Determine the range of pages to display
                        const startPage = Math.max(2, currentPage - 2);
                        const endPage = Math.min(totalPages - 1, startPage + 4);

                        // Display page buttons within the range
                        if (index + 1 >= startPage && index + 1 <= endPage) {
                            return (
                                <button
                                    key={index + 1}
                                    className={`ctlg-page-num ${currentPage === index + 1 ? "active" : ""
                                        }`}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            );
                        }
                        return null;
                    })}
                    {currentPage < totalPages - 2 && <div className="ellipsis">...</div>}

                    {currentPage <= totalPages && (
                        <button
                            className={`ctlg-page-num ${currentPage === totalPages ? "active" : ""
                                }`}
                            onClick={() => handlePageChange(totalPages)}
                        >
                            {totalPages}
                        </button>
                    )}

                    {currentPage < totalPages && (
                        <button
                            className="ctlg-page-num"
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            &gt;
                        </button>
                    )}
                </div>}
                <div className="empty-container">
                </div>
            </div>
            {loading ?
                <div className="notif-loader">
                    <Loader type="TailSpin" color="rgb(11, 70, 85)" width={60} />
                </div> : null
            }
            {
                activeModel === "ParticipantRaffleViewJoinModel" ?
                    <RaffleTicketViewModel
                        userType={"participant"}
                        asParticipant={false}
                        asCatalogue={true}
                        choosenRaffle={choosenRaffle}
                        choosenRafflePrizes={choosenRafflePrizes}
                        toggleShareModel={(link) => {
                            setActiveModel("SocialShareModel");
                            setSocialShareLink(link);
                        }}
                        onClose={() => setActiveModel("")}
                        type={"VIEWJOIN"}

                    /> :
                    activeModel === "SocialShareModel" ?
                        <SocialShareModel
                            onClose={() => setActiveModel("")}
                            url={socialShareLink}
                        />
                        : null
            }
        </div>
    )
}