import React from 'react';
import './socialShareModel.scss';
import { MdClose } from 'react-icons/md';
import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
} from "react-share";

export default ({ onClose, url }) => {
    return <div className="socialShareModel">
        <div>
            {/* header */}
            <div>
                <img src={require('../../../assets/img/cup.png')} />
                <div>Share</div>
                <label onClick={onClose}>X</label>
            </div>
            {/* body */}
            <div>
                {/* share icons container */}
                <div>
                    <EmailShareButton subject="raffle invitation" url={url}><EmailIcon size={32} round={true} /></EmailShareButton>
                    <FacebookShareButton url={url}><FacebookIcon size={32} round={true} /></FacebookShareButton>
                    <InstapaperShareButton url={url}><InstapaperIcon size={32} round={true} /></InstapaperShareButton>
                    <LineShareButton url={url}><LineIcon size={32} round={true} /></LineShareButton>
                    <LinkedinShareButton url={url}><LinkedinIcon size={32} round={true} /></LinkedinShareButton>
                    <LivejournalShareButton url={url}><LivejournalIcon size={32} round={true} /></LivejournalShareButton>
                    <MailruShareButton url={url}><MailruIcon size={32} round={true} /></MailruShareButton>
                    <OKShareButton url={url}><OKIcon size={32} round={true} /></OKShareButton>
                    <PinterestShareButton url={url}><PinterestIcon size={32} round={true} /></PinterestShareButton>
                    <PocketShareButton url={url}><PocketIcon size={32} round={true} /></PocketShareButton>
                    <RedditShareButton url={url}><RedditIcon size={32} round={true} /></RedditShareButton>
                    <TelegramShareButton url={url}><TelegramIcon size={32} round={true} /></TelegramShareButton>
                    <TumblrShareButton url={url}><TumblrIcon size={32} round={true} /></TumblrShareButton>
                    <TwitterShareButton url={url}><TwitterIcon size={32} round={true} /></TwitterShareButton>
                    <ViberShareButton url={url}><ViberIcon size={32} round={true} /></ViberShareButton>
                    <VKShareButton url={url}><VKIcon size={32} round={true} /></VKShareButton>
                    <WhatsappShareButton url={url}><WhatsappIcon size={32} round={true} /></WhatsappShareButton>
                    <WorkplaceShareButton url={url}><WorkplaceIcon size={32} round={true} /></WorkplaceShareButton>

                </div>
            </div>
            {/* footer */}
            <div>
                <div onClick={onClose}>CLOSE</div>
            </div>
        </div>
    </div>
}