export const emailValidation = (text) => {
    if (text.length > 0 && text.includes("@") && text.includes(".") && text.split("@").length === 2) {
        return true;
    } else {
        return false;
    }
}

export const nameValidation = (text) => (text.length > 0 ? true : false);

export const passwordValidation = (text) => {
    if (text.length === 0) {
        return false;
    } else if (text.length >= 8) {
        if (/\s/.test(text)) {
            return false;
        } else {
            return true;
        }
    }
}