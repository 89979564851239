import { joinRaffle } from '../config/endpoints';
import { togggleLoad } from '../store/actions/loadAction';
import { JoinRaffle } from '../store/actions/raffleAction';
import { request } from './request';

export default (raffleId, email) => {
    return new Promise((resolve, reject) => {
        request('POST_AUTH', joinRaffle, { _id: raffleId, uid: email }).then((response) => {
            if (response.status === 0) {
                resolve({ 'msg': "Joined successfully" })
            } else {
                reject(response.msg);
            }
        }).catch((e) => {
            reject(e.msg);
        });
    })
}