import React, { useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import "./yearlyRaffle.scss";
import {
    getDateStrWithTZ,
    getDateStrWithoutTZ,
    getMilliSecFromTime,
} from "../../../../helper/appService";

const months = [
    { name: 'Jan', days: 31 },
    { name: 'Feb', days: 28 },
    { name: 'Mar', days: 31 },
    { name: 'Apr', days: 30 },
    { name: 'May', days: 31 },
    { name: 'Jun', days: 30 },
    { name: 'Jul', days: 31 },
    { name: 'Aug', days: 31 },
    { name: 'Sep', days: 30 },
    { name: 'Oct', days: 31 },
    { name: 'Nov', days: 30 },
    { name: 'Dec', days: 31 },
];

const numDaysOfMonth = [
    { key: "1", value: 1 },
    { key: "2", value: 2 },
    { key: "3", value: 3 },
    { key: "4", value: 4 },
    { key: "5", value: 5 },
    { key: "6", value: 6 },
    { key: "7", value: 7 },
    { key: "8", value: 8 },
    { key: "9", value: 9 },
    { key: "10", value: 10 },
    { key: "11", value: 11 },
    { key: "12", value: 12 },
    { key: "13", value: 13 },
    { key: "14", value: 14 },
    { key: "15", value: 15 },
    { key: "16", value: 16 },
    { key: "17", value: 17 },
    { key: "18", value: 18 },
    { key: "19", value: 19 },
    { key: "20", value: 20 },
    { key: "21", value: 21 },
    { key: "22", value: 22 },
    { key: "23", value: 23 },
    { key: "24", value: 24 },
    { key: "25", value: 25 },
    { key: "26", value: 26 },
    { key: "27", value: 27 },
    { key: "28", value: 28 },
    { key: "29", value: 29 },
    { key: "30", value: 30 },
    { key: "31", value: 31 },
];

export default ({
    raffle,
    choosenRaffle,
    setRaffle,
    setRaffleFields,
    seriesEdit,
    type,
    freqStartinTz,
    freqEndinTz,
    getDatePartTZ,
    getTimeStrWithTZ,
    getFormattedDateTime,
}) => {
    const [remainingYears, setRemainingYears] = useState([]);
    const [availableInstances, setAvailableInstances] = useState([]);
    const [masterYearsSelect, setMasterYearsSelect] = useState([]);
    const [selectedYears, setSelectedYears] = useState([]);

    const calculateNumYears = (startYear, endYear) => {
        const years = [];
        let currentDate = new Date(startYear);

        while (currentDate <= endYear) {
            years.push(new Date(currentDate));

            currentDate.setFullYear(currentDate.getFullYear() + 1);
        }
        setRemainingYears(years);
        setAvailableInstances(years.length);

        // for master select
        const allDrawDtsWithTz = [];
        years.forEach((date) => {
            allDrawDtsWithTz.push(getDatePartTZ(date));
        })
        setMasterYearsSelect(allDrawDtsWithTz);
    };

    useEffect(() => {
        const startDateInTz = freqStartinTz(new Date(raffle.extras.seriesRaffle.frequencyRange.startDate), raffle.extras.timeZone);
        const endByDateInTz = freqEndinTz(new Date(raffle.extras.seriesRaffle.frequencyRange.endByDate), raffle.extras.timeZone);

        calculateNumYears(new Date(startDateInTz), new Date(endByDateInTz));
    }, [
        raffle.extras.seriesRaffle.frequencyRange.startDate,
        raffle.extras.seriesRaffle.frequencyRange.endByDate,
        raffle.extras.timeZone,
        raffle.extras.seriesRaffle.isMultipleRaffles
    ]);

    useEffect(() => {
        if (raffle.type === "Series" && raffle.extras.seriesRaffle.recurrencePattern === "Yearly") {
            setSelectedYears(raffle.extras.seriesRaffle.yearlyPattern.days)
        }

    }, [raffle.extras.seriesRaffle.yearlyPattern.days])

    // to initialize the yearlyPattern.openTime/closeTime/drawTime with 12am, 11:55pm and 11:59pm
    useEffect(() => {
        setYearOCD(0, "openTime");
        setYearOCD(86100000, "closeTime");
        setYearOCD(86340000, "drawTime");
    }, [])

    // Week day selection function to set day to raffle
    const setYearOCD = (open, ocd) => {
        setRaffle((rff) => ({
            ...rff,
            extras: {
                ...rff.extras,
                seriesRaffle: {
                    ...rff.extras.seriesRaffle,
                    yearlyPattern: {
                        ...rff.extras.seriesRaffle.yearlyPattern,
                        [ocd === "openMonth" ? "openMonth" :
                            ocd === "closeMonth" ? "closeMonth" :
                                ocd === "drawMonth" ? "drawMonth" :
                                    ocd === "openTime" ? "openTime" :
                                        ocd === "closeTime" ? "closeTime" :
                                            ocd === "drawTime" ? "drawTime" :
                                                ocd === "openDay" ? "openDay" :
                                                    ocd === "closeDay" ? "closeDay" :
                                                        "drawDay"]: open
                    },
                },
            },
        }));
    }

    // Get in date format to display in available instances
    const getDateofYear = (year, month, day) => {
        let dateOfYear = new Date(new Date(new Date(year).setMonth(parseInt(month))).setDate(day));
        return new Date(dateOfYear).toLocaleDateString("en-US", {
            month: 'short',
            day: '2-digit'
        });
    }

    // To get time alone from a new Date()
    const getTimeofDate = (date) => {
        return new Date(date).toLocaleTimeString("en-US", {
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    //Function of selecting all available instances
    const selectAllInstances = (checked) => {
        //masterYearsSelect
        let toAddMasterYears = masterYearsSelect.filter(
            (x) => {
                const dayTime = new Date(x).toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                });
                const existingDayTimes = raffle.extras.seriesRaffle.yearlyPattern.days.map(x => new Date(x).toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                }));
                return (
                    existingDayTimes.indexOf(dayTime) === -1
                )
            }
        )

        let toRemoveYearsChoosed = (type === "EDIT") ? choosenRaffle.extras.seriesRaffle.yearlyPattern.days.map(day => new Date(day).toISOString()) : [];

        if (checked) {
            setRaffle((rff) => ({
                ...rff,
                extras: {
                    ...rff.extras,
                    seriesRaffle: {
                        ...rff.extras.seriesRaffle,
                        yearlyPattern: {
                            ...rff.extras.seriesRaffle.yearlyPattern,
                            days: [
                                ...rff.extras.seriesRaffle.yearlyPattern.days,
                                ...toAddMasterYears,
                            ],
                        },
                    },
                },
            }));
        } else {
            setRaffle((rff) => ({
                ...rff,
                extras: {
                    ...rff.extras,
                    seriesRaffle: {
                        ...rff.extras.seriesRaffle,
                        yearlyPattern: {
                            ...rff.extras.seriesRaffle.yearlyPattern,
                            days: type === "EDIT" ? toRemoveYearsChoosed : [],
                        },
                    },
                },
            }));
        }
    }

    //Function of selecting individual instance from available instances
    const selectInstance = (checked, year) => {
        if (
            raffle.extras.seriesRaffle.yearlyPattern.days.findIndex(
                (x) => new Date(x).toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                }) === new Date(year).toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                })
            ) >= 0 &&
            !checked
        ) {
            setRaffle((rff) => ({
                ...rff,
                extras: {
                    ...rff.extras,
                    seriesRaffle: {
                        ...rff.extras.seriesRaffle,
                        yearlyPattern: {
                            ...rff.extras.seriesRaffle.yearlyPattern,
                            days: rff.extras.seriesRaffle.yearlyPattern.days.filter(
                                (x) => new Date(x).toLocaleDateString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",
                                }) !== new Date(year).toLocaleDateString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",
                                })
                            ),
                        },
                    },
                },
            }));
        } else {
            setRaffle((rff) => ({
                ...rff,
                extras: {
                    ...rff.extras,
                    seriesRaffle: {
                        ...rff.extras.seriesRaffle,
                        yearlyPattern: {
                            ...rff.extras.seriesRaffle.yearlyPattern,
                            days: [
                                ...rff.extras.seriesRaffle.yearlyPattern.days,
                                getDatePartTZ(year),
                            ],
                        },
                    },
                },
            }));
        }
    }

    return (
        <div className='yearly-raffle'>
            <div className="raffle-year-time">
                <label style={{ fontWeight: "700", textAlign: "left" }}>
                    RAFFLE MONTH-TIME:
                </label>
                <label>
                    OPEN
                    <div className="year-day-picker">
                        <select
                            disabled={
                                !raffle.extras.seriesRaffle.isMultipleRaffles ||
                                type === "EDIT"
                            }
                            value={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                                raffle.extras.seriesRaffle.yearlyPattern.days.length !== 0 ?
                                    raffle.extras.seriesRaffle.yearlyPattern.openMonth :
                                    ""
                                : raffle.extras.seriesRaffle.yearlyPattern.openMonth}
                            onChange={(_e) => setYearOCD(parseInt(_e.target.value), "openMonth")}
                        >
                            {months.map((month, index) => {
                                return (
                                    <option key={month.name} value={index}>
                                        {month.name}
                                    </option>
                                );
                            })}
                        </select>
                        <select
                            disabled={
                                !raffle.extras.seriesRaffle.isMultipleRaffles ||
                                type === "EDIT"
                            }
                            value={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                                raffle.extras.seriesRaffle.yearlyPattern.days.length !== 0 ?
                                    raffle.extras.seriesRaffle.yearlyPattern.openDay :
                                    ""
                                : raffle.extras.seriesRaffle.yearlyPattern.openDay}
                            onChange={(_e) => setYearOCD(parseInt(_e.target.value), "openDay")}
                        >
                            {numDaysOfMonth.map((day, index) => {
                                return (
                                    <option
                                        key={day.key}
                                        value={day.value}
                                        disabled={day.value > months[raffle.extras.seriesRaffle.yearlyPattern.openMonth].days}
                                    >
                                        {day.key}
                                    </option>
                                );
                            })}
                        </select>
                        <DatePicker
                            dateFormat={
                                !raffle.extras.seriesRaffle.isMultipleRaffles
                                    ? "MM/dd/yyyy hh:mm aa"
                                    : "hh:mm aa"
                            }
                            showTimeInput
                            showTimeSelectOnly
                            disabled={
                                !raffle.extras.seriesRaffle.isMultipleRaffles ||
                                type === "EDIT"
                            }
                            value={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                                raffle.extras.seriesRaffle.yearlyPattern.days.length !== 0 ?
                                    new Date(getFormattedDateTime(new Date(raffle.participationOpens))) :
                                    ""
                                : new Date(getFormattedDateTime(new Date(raffle.participationOpens)))}
                            selected={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                                raffle.extras.seriesRaffle.yearlyPattern.days.length !== 0 ?
                                    new Date(getFormattedDateTime(new Date(raffle.participationOpens))) :
                                    ""
                                : new Date(getFormattedDateTime(new Date(raffle.participationOpens)))}
                            onChange={(_e) => {
                                const dateTime = _e;
                                dateTime.setSeconds(0, 0);
                                const timeString = getTimeStrWithTZ(dateTime);
                                const timeMill = getMilliSecFromTime(timeString);
                                setYearOCD(timeMill, "openTime");
                                setRaffleFields(new Date(dateTime), "participationOpens");
                            }}
                        />
                    </div>
                </label>
                <label>
                    CLOSE
                    <div className="year-day-picker">
                        <select
                            disabled={
                                !raffle.extras.seriesRaffle.isMultipleRaffles ||
                                type === "EDIT"
                            }
                            value={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                                raffle.extras.seriesRaffle.yearlyPattern.days.length !== 0 ?
                                    raffle.extras.seriesRaffle.yearlyPattern.closeMonth :
                                    ""
                                : raffle.extras.seriesRaffle.yearlyPattern.closeMonth}
                            onChange={(_e) => setYearOCD(parseInt(_e.target.value), "closeMonth")}
                        >
                            {months.map((month, index) => {
                                return (
                                    <option key={month.name} value={index}>
                                        {month.name}
                                    </option>
                                );
                            })}
                        </select>
                        <select
                            disabled={
                                !raffle.extras.seriesRaffle.isMultipleRaffles ||
                                type === "EDIT"
                            }
                            value={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                                raffle.extras.seriesRaffle.yearlyPattern.days.length !== 0 ?
                                    raffle.extras.seriesRaffle.yearlyPattern.closeDay :
                                    ""
                                : raffle.extras.seriesRaffle.yearlyPattern.closeDay}
                            onChange={(_e) => setYearOCD(parseInt(_e.target.value), "closeDay")}
                        >
                            {numDaysOfMonth.map((day, index) => {
                                return (
                                    <option
                                        key={day.key}
                                        value={day.value}
                                        disabled={day.value > months[raffle.extras.seriesRaffle.yearlyPattern.closeMonth].days}
                                    >
                                        {day.key}
                                    </option>
                                );
                            })}
                        </select>
                        <DatePicker
                            dateFormat={
                                !raffle.extras.seriesRaffle.isMultipleRaffles
                                    ? "MM/dd/yyyy hh:mm aa"
                                    : "hh:mm aa"
                            }
                            showTimeInput
                            showTimeSelectOnly
                            disabled={
                                !raffle.extras.seriesRaffle.isMultipleRaffles ||
                                type === "EDIT"
                            }
                            value={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                                raffle.extras.seriesRaffle.yearlyPattern.days.length !== 0 ?
                                    new Date(getFormattedDateTime(new Date(raffle.participationCloses))) :
                                    ""
                                : new Date(getFormattedDateTime(new Date(raffle.participationCloses)))}
                            selected={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                                raffle.extras.seriesRaffle.yearlyPattern.days.length !== 0 ?
                                    new Date(getFormattedDateTime(new Date(raffle.participationCloses))) :
                                    ""
                                : new Date(getFormattedDateTime(new Date(raffle.participationCloses)))}
                            onChange={(_e) => {
                                const dateTime = _e;
                                dateTime.setSeconds(0, 0);
                                const timeString = getTimeStrWithTZ(dateTime);
                                const timeMill = getMilliSecFromTime(timeString);
                                setYearOCD(timeMill, "closeTime");
                                setRaffleFields(new Date(dateTime), "participationCloses");
                            }}
                        />
                    </div>
                </label>
                <label>
                    DRAW
                    <div className="year-day-picker">
                        <select
                            disabled={
                                !raffle.extras.seriesRaffle.isMultipleRaffles ||
                                type === "EDIT"
                            }
                            value={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                                raffle.extras.seriesRaffle.yearlyPattern.days.length !== 0 ?
                                    raffle.extras.seriesRaffle.yearlyPattern.drawMonth :
                                    ""
                                : raffle.extras.seriesRaffle.yearlyPattern.drawMonth}
                            onChange={(_e) => setYearOCD(parseInt(_e.target.value), "drawMonth")}
                        >
                            {months.map((month, index) => {
                                return (
                                    <option key={month.name} value={index}>
                                        {month.name}
                                    </option>
                                );
                            })}
                        </select>
                        <select
                            disabled={
                                !raffle.extras.seriesRaffle.isMultipleRaffles ||
                                type === "EDIT"
                            }
                            value={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                                raffle.extras.seriesRaffle.yearlyPattern.days.length !== 0 ?
                                    raffle.extras.seriesRaffle.yearlyPattern.drawDay :
                                    ""
                                : raffle.extras.seriesRaffle.yearlyPattern.drawDay}
                            onChange={(_e) => setYearOCD(parseInt(_e.target.value), "drawDay")}
                        >
                            {numDaysOfMonth.map((day, index) => {
                                return (
                                    <option
                                        key={day.key}
                                        value={day.value}
                                        disabled={day.value > months[raffle.extras.seriesRaffle.yearlyPattern.drawMonth].days}
                                    >
                                        {day.key}
                                    </option>
                                );
                            })}
                        </select>
                        <DatePicker
                            dateFormat={
                                !raffle.extras.seriesRaffle.isMultipleRaffles
                                    ? "MM/dd/yyyy hh:mm aa"
                                    : "hh:mm aa"
                            }
                            showTimeInput
                            showTimeSelectOnly
                            disabled={
                                !raffle.extras.seriesRaffle.isMultipleRaffles ||
                                type === "EDIT"
                            }
                            value={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                                raffle.extras.seriesRaffle.yearlyPattern.days.length !== 0 ?
                                    new Date(getFormattedDateTime(new Date(raffle.drawAt))) :
                                    ""
                                : new Date(getFormattedDateTime(new Date(raffle.drawAt)))}
                            selected={!raffle.extras.seriesRaffle.isMultipleRaffles ?
                                raffle.extras.seriesRaffle.yearlyPattern.days.length !== 0 ?
                                    new Date(getFormattedDateTime(new Date(raffle.drawAt))) :
                                    ""
                                : new Date(getFormattedDateTime(new Date(raffle.drawAt)))}
                            onChange={(_e) => {
                                const dateTime = _e;
                                dateTime.setSeconds(0, 0);
                                const timeString = getTimeStrWithTZ(dateTime);
                                const timeMill = getMilliSecFromTime(timeString);
                                setYearOCD(timeMill, "drawTime");
                                setRaffleFields(new Date(dateTime), "drawAt");
                            }}
                        />
                    </div>
                </label>
            </div>
            <div className="series-sub-raffle">
                <div className="available-instances">
                    <label
                        style={{
                            fontWeight: 700,
                            textAlign: "left",
                            display: "flex",
                            gap: "10px",
                        }}
                    >
                        <span>{`AVAILABLE INSTANCES`}</span>
                        <span>
                            <input
                                type="checkbox"
                                checked={
                                    masterYearsSelect.length ===
                                    raffle.extras.seriesRaffle.yearlyPattern.days
                                        .length
                                }
                                disabled={
                                    (!seriesEdit &&
                                        type === "EDIT" &&
                                        raffle.extras.seriesRaffle.isMultipleRaffles
                                    ) ||
                                    (type === "EDIT" &&
                                        choosenRaffle.extras.seriesRaffle.yearlyPattern.days.length ===
                                        masterYearsSelect.length
                                    )
                                }
                                ref={(input) => {
                                    if (input) {
                                        input.indeterminate =
                                            masterYearsSelect.length >
                                            raffle.extras.seriesRaffle.yearlyPattern
                                                .days.length &&
                                            raffle.extras.seriesRaffle.yearlyPattern.days
                                                .length !== 0;
                                    }
                                }}
                                onChange={(e) => selectAllInstances(e.target.checked)}
                            />
                            Select All
                        </span>
                        <span>({availableInstances})</span>
                    </label>
                    <div className={"occurrences"}>
                        {remainingYears?.map((year, index) => {
                            return (
                                <div
                                    className="each-checkbox"
                                    key={index}
                                >
                                    <input
                                        type="checkbox"
                                        checked={
                                            raffle.extras.seriesRaffle.yearlyPattern.days.findIndex(
                                                (x) =>
                                                    new Date(
                                                        x
                                                    ).toLocaleDateString() ===
                                                    new Date(
                                                        year
                                                    ).toLocaleDateString()
                                            ) >= 0
                                        }
                                        disabled={
                                            (type === "EDIT" && !seriesEdit && raffle.extras.seriesRaffle.isMultipleRaffles
                                            ) ||
                                            (type === "EDIT" &&
                                                new Date(year).setHours(
                                                    new Date(raffle.participationOpens).getHours(),
                                                    new Date(raffle.participationOpens).getMinutes(), 0, 0,
                                                ) < new Date())
                                        }

                                        onChange={(e) => selectInstance(e.target.checked, year)}
                                    />
                                    <p className="months-name">
                                        {new Date(year).getFullYear()}
                                    </p>
                                    <lable>
                                        {raffle.extras.seriesRaffle.isMultipleRaffles ?
                                            `[Open @ ${getDateofYear(new Date(year), raffle.extras.seriesRaffle.yearlyPattern.openMonth, raffle.extras.seriesRaffle.yearlyPattern.openDay)}, ${getTimeofDate(new Date(raffle.participationOpens))},
                                            Close @ ${getDateofYear(new Date(year), raffle.extras.seriesRaffle.yearlyPattern.closeMonth, raffle.extras.seriesRaffle.yearlyPattern.closeDay)}, ${getTimeofDate(new Date(raffle.participationCloses))},
                                            Draw @ ${getDateofYear(new Date(year), raffle.extras.seriesRaffle.yearlyPattern.drawMonth, raffle.extras.seriesRaffle.yearlyPattern.drawDay)}, ${getTimeofDate(new Date(raffle.drawAt))}]`
                                            :
                                            `[Draw @ ${getDateofYear(new Date(year), raffle.extras.seriesRaffle.yearlyPattern.drawMonth, raffle.extras.seriesRaffle.yearlyPattern.drawDay)}, ${getTimeofDate(new Date(raffle.drawAt))}]`}
                                    </lable>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="selected-instances">
                    <label className="selected-instances-title">
                        {`SELECTED INSTANCES (${raffle.extras.seriesRaffle.yearlyPattern.days.length})`}
                    </label>
                    <div className="instances-content">
                        {
                            selectedYears.length !== 0 ?
                                selectedYears.map(
                                    (x, index) => {
                                        const year = new Date(x);
                                        return (
                                            <div key={index} className='each-select-instance'>
                                                <span>#</span>
                                                <p>
                                                    {new Date(year).getFullYear()}
                                                </p>
                                                <lable>
                                                    {raffle.extras.seriesRaffle.isMultipleRaffles ?
                                                        `[Open @ ${getDateofYear(new Date(year), raffle.extras.seriesRaffle.yearlyPattern.openMonth, raffle.extras.seriesRaffle.yearlyPattern.openDay)}, ${getTimeofDate(new Date(raffle.participationOpens))},
                                                        Close @ ${getDateofYear(new Date(year), raffle.extras.seriesRaffle.yearlyPattern.closeMonth, raffle.extras.seriesRaffle.yearlyPattern.closeDay)}, ${getTimeofDate(new Date(raffle.participationCloses))},
                                                        Draw @ ${getDateofYear(new Date(year), raffle.extras.seriesRaffle.yearlyPattern.drawMonth, raffle.extras.seriesRaffle.yearlyPattern.drawDay)}, ${getTimeofDate(new Date(raffle.drawAt))}]`
                                                        :
                                                        `[Draw @ ${getDateofYear(new Date(year), raffle.extras.seriesRaffle.yearlyPattern.drawMonth, raffle.extras.seriesRaffle.yearlyPattern.drawDay)}, ${getTimeofDate(new Date(raffle.drawAt))}]`}
                                                </lable>
                                            </div>
                                        );
                                    }
                                )
                                :
                                <label>No instances selected</label>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};