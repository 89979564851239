import React, { useState, useEffect, Fragment } from "react";
import "./index.scss";
import Loader from "react-loader-spinner";
import {
  emailValidation,
  passwordValidation,
  nameValidation,
} from "../../helper/formValidation";
import { useAlert } from "react-alert";
import {
  registerUser,
  loginUser,
  sendForgetPassword,
  resendVerificationlinkHost,
} from "../../config/endpoints";
import { request } from "../../helper/request";
import { GoogleLogin } from "react-google-login";
import { FacebookProvider, LoginButton } from "react-facebook";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useHistory, useLocation } from "react-router-dom";
import AddUserToRaffle from "../../helper/addUserToRaffle";
import firebase from "../../helper/firebase";
import cryptoJS from "crypto-js";
import { IoLogoFacebook } from "react-icons/io";
// time zone
import momentTz from "moment-timezone";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch } from "react-redux";

const str_pass = "8isahnf9saipfsafnlkjaslfkj";

// participents, host, sponsors, beneficiries

export default () => {
  const [activeAuthPage, toggleAuthPage] = useState("SignIn");
  const [loading, toggleLoading] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [formValidation, setFormValidations] = useState({
    email: false,
    password: false,
  });
  const dispatch = new useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const [invited, setInvited] = useState(false);
  const [showResendRegistrationLink, setShowResendRegistrationLink] =
    useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const timeZonesList = momentTz.tz.names();

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const [rememberMe, setRememberMe] = useState(false);


  const toggleShowPassword = (ev) => {
    ev.preventDefault();
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = (ev) => {
    ev.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };
  // checking if user is already authenticated
  useEffect(() => {
    if (localStorage.getItem("username") && activeAuthPage === "SignIn") {
      formData.email = localStorage.getItem("username");
      formValidation.email = true;
      setRememberMe(true);
    } else {
      formData.email = "";
      formValidation.email = false;
      setRememberMe(false);
    }
    setFormData(formData);
    setFormValidations(formValidation);
    const user = sessionStorage.getItem("User");
    if (user) {
      history.push("/workspace");
    }
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("toJoinEmail")) {
      formData.email = sessionStorage.getItem("toJoinEmail");
      formValidation.email = true;
      setFormData(formData);
      setFormValidations(formValidation);
      sessionStorage.removeItem("toJoinEmail");
    }
  }, [])

  // check if landing page signup button clicked
  useEffect(() => {
    if (location.pathname.split("/")[2] === "register") {
      toggleAuthPage("Register");
    }
  }, []);

  useEffect(() => {
    if (activeAuthPage === "Register") {
      setFormData((fd) => ({ ...fd, timeZone: momentTz.tz.guess() }));
    } else {
      const nFD = { ...formData };
      delete nFD["timeZone"];
      setFormData(nFD);
    }
  }, [activeAuthPage]);

  // check if url has raffle id and email
  useEffect(() => {
    if (location.search.length > 0) {
      toggleAuthPage("Register");

      const cipher = location.search.split("?")[1];
      const params = cryptoJS.AES.decrypt(cipher, str_pass).toString(
        cryptoJS.enc.Utf8
      );

      const email = params.split("&")[1].split("=")[1];
      setInvited(true);
      setFormData((fData) => ({
        ...fData,
        confirmPassword: "",
        name: "",
        email,
      }));
      setFormValidations((fVData) => ({
        ...fVData,
        confirmPassword: false,
        name: false,
        email: true,
      }));
    }
  }, [location]);

  const googleAuthHandler = async (data) => {
    const name = data.profileObj.name;
    const email = data.profileObj.email;
    const pwd = data.profileObj.email.substring(0, 10) + data.profileObj.name;

    const newData = {
      ...formData,
      email,
      name,
      password: pwd,
      confirmPassword: pwd,
      type: "Google",
    };

    if (activeAuthPage === "Register") {
      toggleLoading(true);
      // creating user
      const response = await request("POST", registerUser, newData);
      if (response.status === 0) {
        // adding user to raffle if raffle id exist in url
        if (location.search.length > 0) {
          const cipher = location.search.split("?")[1];
          const params = cryptoJS.AES.decrypt(cipher, str_pass).toString(
            cryptoJS.enc.Utf8
          );

          const raffle_id = params.split("&")[0].split("=")[1];
          await AddUserToRaffle(raffle_id, newData.email);
        }

        alert.removeAll();
        alert.show("Account created");
        // alert.removeAll();
        alert.show("Confirmation link sent to your email");
        toggleAuthPage("SignIn");
        setFormData((form) => ({ email: "", password: "" }));
        setFormValidations((form) => ({ email: false, password: false }));
        toggleLoading(false);
      } else {
        toggleLoading(false);
        alert.removeAll();
        alert.show(JSON.stringify(response.msg));
      }
    } else {
      toggleLoading(true);
      const response = await request("POST", loginUser, {
        ...formData,
        email,
        type: "Google",
      });
      if (response.status === 0) {
        sessionStorage.setItem("User", JSON.stringify(response.payload));
        sessionStorage.setItem("Token", response.token);
        if (location.pathname.split("/")[2] === "catalouge") {
          history.push("/catalouge");
        } else {
          history.push("/workspace");
          alert.removeAll();
          alert.show("Hi, Welcome to the WorkSpace");
        }
        toggleLoading(false);
      } else {
        alert.removeAll();
        alert.show(JSON.stringify(response.msg));
        toggleLoading(false);
      }
    }
  };

  const fbSuccessHandler = async (data) => {
    const name = data.profile.name;
    const email = data.profile.email;
    const pwd = data.profile.email.substring(0, 10) + data.profile.name;

    const newData = {
      ...formData,
      email,
      name,
      password: pwd,
      confirmPassword: pwd,
      type: "Facebbok",
    };

    if (activeAuthPage === "Register") {
      toggleLoading(true);
      // creating user
      const response = await request("POST", registerUser, newData);
      if (response.status === 0) {
        // adding user to raffle if raffle id exist in url
        if (location.search.length > 0) {
          const cipher = location.search.split("?")[1];
          const params = cryptoJS.AES.decrypt(cipher, str_pass).toString(
            cryptoJS.enc.Utf8
          );

          const raffle_id = params.split("&")[0].split("=")[1];
          await AddUserToRaffle(raffle_id, newData.email);
        }

        alert.removeAll();
        alert.show("Account created");
        alert.removeAll();
        alert.show("Confirmation link sent to your email");
        toggleAuthPage("SignIn");
        setFormData((form) => ({ email: "", password: "" }));
        setFormValidations((form) => ({ email: false, password: false }));
        toggleLoading(false);
      } else {
        toggleLoading(false);
        alert.removeAll();
        alert.show(JSON.stringify(response.msg));
      }
    } else {
      toggleLoading(true);
      const response = await request("POST", loginUser, {
        ...formData,
        email,
        type: "Facebook",
      });
      if (response.status === 0) {
        sessionStorage.setItem("User", JSON.stringify(response.payload));
        sessionStorage.setItem("Token", response.token);
        if (location.pathname.split("/")[2] === "catalouge") {
          history.push("/catalouge");
        } else {
          history.push("/workspace");
          alert.removeAll();
          alert.show("Hello, Welcome to the Rafflerra workspace");
        }
        toggleLoading(false);
      } else {
        alert.removeAll();
        alert.show(JSON.stringify(response.msg));
        toggleLoading(false);
      }
    }
  };

  const resendVerificationLink = () => {
    toggleLoading(true);
    request("POST", resendVerificationlinkHost, { email: formData.email })
      .then((res) => {
        toggleLoading(false);
        alert.removeAll();
        alert.show("Verification link sent");
      })
      .catch((e) => {
        toggleLoading(false);
        alert.removeAll();
        alert.show("Failed to send verification link");
      });
  };

  const submitForm = async () => {
    setSubmitAttempted(true);
    console.log(formValidation);
    let isFormValid =
      Object.values(formValidation).find((x) => x === false) === undefined;
    if (isFormValid) {
      var len = Object.keys(formData).length;
      //forget password form
      if (len === 1) {
        toggleLoading(true);
        const response = await request(
          "GET",
          `${sendForgetPassword}/${formData.email}`,
          ""
        );
        if (response.status === 0) {
          alert.removeAll();
          alert.show(JSON.stringify(response.msg));
        } else {
          alert.removeAll();
          alert.show(JSON.stringify(response.msg));
        }
        toggleLoading(false);
      }
      //login form
      else if (len === 2) {
        toggleLoading(true);
        const response = await request("POST", loginUser, formData);
        if (response.status === 0) {
          sessionStorage.setItem("User", JSON.stringify(response.payload));
          sessionStorage.setItem("Token", response.token);
          if (rememberMe) {
            localStorage.setItem('username', response.payload.email);
            // localStorage.setItem('rememberMe', true);
          } else {
            localStorage.removeItem('username');
            // localStorage.setItem('rememberMe', false);
          }
          if (location.pathname.split("/")[2] === "catalouge") {
            history.push("/catalouge");
          } else {
            history.push("/workspace");
            alert.removeAll();
            alert.show("Hi, Welcome to the WorkSpace");
          }
          toggleLoading(false);
        } else {
          alert.removeAll();
          alert.show(response.msg);
          if (response.msg == "Please confirm the email id to proceed") {
            alert.removeAll();
            alert.show(
              <label
                onClick={resendVerificationLink}
                style={{
                  fontSize: 13,
                  color: "gold",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Click here to resend verification link
              </label>,
              {
                timeout: 6000,
              }
            );
          }
          toggleLoading(false);
        }
      }
      //register form
      else {
        registerUserFun();
      }
    } else {
      if (activeAuthPage === "Register") {
        if (
          formData.name.trim() === "" ||
          formData.password.length === 0 ||
          formData.confirmPassword.length === 0 ||
          formData.email.trim() === ""
        ) {
          alert.removeAll();
          alert.show("Please fill all required fields");
          return;
        }
        if (!emailRegex.test(formData.email)) {
          alert.removeAll();
          alert.show("Invalid email");
          return;
        }
        if (/\s/.test(formData.password)) {
          alert.removeAll();
          alert.show("Spaces are not allowed in password");
          return;
        }
        if (formData.password.trim().length > 0 && formData.password.trim().length < 8) {
          alert.removeAll();
          alert.show("Password should have minimum of 8 characters");
          return;
        }
        if (formData.password !== formData.confirmPassword) {
          alert.removeAll();
          alert.show("Password and confirm password do not match");
          return;
        }
      } else {
        alert.removeAll();
        // alert.show("SOMETHING WENT WRONG")
      }
    }
  };

  const registerUserFun = async () => {
    setSubmitAttempted(false);
    toggleLoading(true);
    if (sessionStorage.getItem("joinChoice") && sessionStorage.getItem("invitationId")) {
      formData.joinChoice = sessionStorage.getItem("joinChoice"); // for register to join
      formData.invId = sessionStorage.getItem("invitationId"); // for register to join
      setFormData(formData);
      sessionStorage.removeItem("joinChoice");
      sessionStorage.removeItem("invitationId")
    }
    // creating user
    const response = await request("POST", registerUser, formData);
    if (response.status === 0) {
      // adding user to raffle if raffle id exist in url
      if (location.search.length > 0) {
        const cipher = location.search.split("?")[1];
        const params = cryptoJS.AES.decrypt(cipher, str_pass).toString(
          cryptoJS.enc.Utf8
        );

        const raffle_id = params.split("&")[0].split("=")[1];
        await AddUserToRaffle(raffle_id, formData.email);
      }
      setFormData((form) => ({ email: "", password: "" }));
      setFormValidations((form) => ({ email: false, password: false }));
      alert.removeAll();
      alert.show("Account created");
      alert.removeAll();
      alert.show("Confirmation link sent to your email");
      toggleAuthPage("SignIn");
      toggleLoading(false);
    } else {
      toggleLoading(false);
      alert.removeAll();
      alert.show(JSON.stringify(response.msg));
    }
  };

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  return (
    <div className="auth_page">
      <div>
        <img
          src={
            activeAuthPage === "Register"
              ? require("../../assets/img/login.svg")
              : activeAuthPage === "SignIn"
              ? require("../../assets/img/welcome.svg")
              : require("../../assets/img/forget_password.svg")
          }
        />
      </div>
      <div>
        <img
          onClick={() => history.push("/")}
          src={require("../../assets/img/logo.png")}
        />
        <div onClick={() => history.push("/")}>Rafflerra</div>
        <div>
          <div>
            {activeAuthPage === "SignIn"
              ? "Account Login"
              : activeAuthPage === "Register"
              ? "Register"
              : "Forgot Password"}
          </div>
          <div>
            {activeAuthPage === "SignIn"
              ? "Sign In to proceed with your account"
              : activeAuthPage === "Register"
              ? "Provide the details to create an account"
              : "Provide email to send a reset password link"}
          </div>
          {
            <Fragment>
              <form className="form"
                onSubmit={(e) => {
                  e.preventDefault(); // Prevent default form submission behavior
                  submitForm();}}
                >
                {activeAuthPage === "Register" ? (
                  <div className="input-error">
                    <input
                      required
                      value={formData["name"]}
                      onChange={(e) => {
                        let text = e.currentTarget.value;
                        setFormData((form) => ({ ...form, name: text }));
                        //for validation
                        if (nameValidation(text)) {
                          setFormValidations((form) => ({ ...form, name: true }));
                        } else {
                          setFormValidations((form) => ({
                            ...form,
                            name: false,
                          }));
                        }
                      }}
                      placeholder="Profile Name"
                      autoFocus="autofocus"
                    />
                    {submitAttempted && formData.name?.length <= 0 && (
                      <p className="error-message">Required</p>
                    )}
                  </div>
                ) : null}
                {/* Email field visible in all the screens (login, register, and forget password)*/}
                <div className="input-error">
                  <input
                    required
                    disabled={invited && activeAuthPage === "Register"}
                    value={formData["email"]}
                    onChange={(e) => {
                      let text = e.currentTarget.value;
                      setFormData((form) => ({ ...form, email: text }));
                      //for validation
                      if (emailValidation(text)) {
                        setFormValidations((form) => ({ ...form, email: true }));
                      } else {
                        setFormValidations((form) => ({ ...form, email: false }));
                      }
                    }}
                    type="email"
                    placeholder="Enter your email"
                    autoFocus="autofocus"
                  />
                  {submitAttempted && formData.email.length <= 0 && (
                    <p className="error-message">Required</p>
                  )}
                  {submitAttempted &&
                    formData.email.length > 0 &&
                    !emailRegex.test(formData.email) && (
                    <p className="error-message show">Invalid email</p>
                  )}
                </div>
                {/* account type, mobile options shows only in registrationview */}
                {activeAuthPage === "Register" ? (
                  <div className="mobile-num">
                    <PhoneInput
                      required
                      containerClass="conatiner_input"
                      inputClass="_input"
                      country={"us"}
                      dropdownStyle={{ textAlign: "start" }}
                      value={
                        formData["mobile"] !== undefined ? formData["mobile"] : ""
                      }
                      onChange={(phone) => {
                        setFormData((form) => ({ ...form, mobile: phone }));
                        //for validation
                        if (phone.length > 0) {
                          setFormValidations((form) => ({ ...form, mobile: true }));
                        } else {
                          setFormValidations((form) => ({
                            ...form,
                            mobile: false,
                          }));
                        }
                      }}
                      placeholder="9999999999"
                    />
                    {submitAttempted && formData.mobile?.length <= 0 && (
                      <p className="error-message">Required</p>
                    )}
                  </div>
                ) : null}
                {activeAuthPage === "Register" && (
                  <Fragment>
                    <select className="dropdown"
                      required
                      value={formData.timeZone}
                      onChange={(e) => {
                        const text = e.currentTarget.value;
                        setFormData((rff) => ({ ...rff, timeZone: text }));
                      }}
                    >
                      {timeZonesList.map((tzone, index) => {
                        return (
                          <option key={index} value={tzone}>
                            {tzone}
                          </option>
                        );
                      })}
                    </select>
                    {submitAttempted && formData.timeZone.length <= 0 && (
                      <p className="error-message">Required</p>
                    )}
                  </Fragment>
                )}
                {activeAuthPage !== "ForgetPassword" ? (
                  <div className="input-error">
                    <div className="input-eye">
                      <input
                        required
                        value={formData["password"]}
                        onChange={(e) => {
                          let text = e.currentTarget.value;
                          setFormData((form) => ({ ...form, password: text }));
                          //for validation
                          if (activeAuthPage === "SignIn") {
                            setFormValidations((form) => ({
                              ...form,
                              password: true,
                            }));
                          } else {
                            if (passwordValidation(text)) {
                              setFormValidations((form) => ({
                                ...form,
                                password: true,
                              }));
                            } else {
                              setFormValidations((form) => ({
                                ...form,
                                password: false,
                              }));
                            }
                          }
                        }}
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                      />
                      <button
                        type="button"
                        className="password-toggle"
                        onClick={toggleShowPassword}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                    {submitAttempted && formData.password.length <= 0 && (
                      <p className="error-message">Required</p>
                    )}
                    {submitAttempted && activeAuthPage !== "SignIn" &&
                      formData.password.length > 0 &&
                      /\s/.test(formData.password) && (
                        <p className="error-message show">Space(s) not allowed</p>
                      )}
                      {submitAttempted && activeAuthPage !== "SignIn" &&
                        formData.password.trim().length > 0 &&
                        formData.password.trim().length < 8 && (
                        <p className="error-message">Min 8 characters required</p>
                    )}
                  </div>
                ) : null}
                {activeAuthPage === "Register" ? (
                  <div className="input-error">
                    <div className="input-eye">
                      <input
                        required
                        value={formData["confirmPassword"]}
                        onChange={(e) => {
                          let text = e.currentTarget.value;
                          setFormData((form) => ({
                            ...form,
                            confirmPassword: text,
                          }));
                          //for validation
                          if (
                            passwordValidation(text) &&
                            formData["password"] === text
                          ) {
                            setFormValidations((form) => ({
                              ...form,
                              confirmPassword: true,
                            }));
                          } else {
                            setFormValidations((form) => ({
                              ...form,
                              confirmPassword: false,
                            }));
                          }
                        }}
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm Password"
                      />
                      <button
                        type="button"
                        className="password-toggle"
                        onClick={toggleShowConfirmPassword}
                      >
                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                    {submitAttempted && formData.confirmPassword?.length <= 0 && (
                      <p className="error-message">Required</p>
                    )}
                  </div>
                ) : null}
                {activeAuthPage === "SignIn" ? (
                  <div className="rememberme-forget">
                    <input type="checkbox" 
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                        />
                          <div>Remember Me</div>
                    <button
                      type="button"
                      onClick={() => {
                        setFormData((form) => ({ email: "" }));
                        setFormValidations((form) => ({ email: false }));
                        toggleAuthPage("ForgetPassword");
                      }}
                    >
                      Forgot Password ?
                    </button>
                  </div>
                ) : (
                  null
                )}
                {activeAuthPage === "Register" && showResendRegistrationLink ? (
                  <label className="verification_link">
                    Resend verification link
                  </label>
                ) : null}
                <button type="submit" className="submit-btn"
                  onSubmit={(e) => {
                    e.preventDefault(); // Prevent default form submission behavior
                    submitForm();}}
                >
                  {activeAuthPage === "Register" ? "Register" : "Continue"}
                </button>
              </form>
              {activeAuthPage !== "ForgetPassword" ? (
                <Fragment>
                  {/* to show google signup/signin */}

                  <GoogleLogin
                    className="google-btn"
                    clientId="793818625064-bifm6hog8fm4pe8cesmltirmf8vl5um5.apps.googleusercontent.com"
                    buttonText={
                      activeAuthPage === "Register"
                        ? "Signup with Google"
                        : "Login with Google"
                    }
                    cookiePolicy={"single_host_origin"}
                    onSuccess={googleAuthHandler}
                    onFailure={(res) => {
                      console.log(res);
                    }}
                  />

                  {/* face book login/signup */}
                  <FacebookProvider appId="935291793651974">
                    <LoginButton
                      className="facebook-btn"
                      scope="email"
                      onCompleted={fbSuccessHandler}
                      // onError={(e) => {
                      //     console.log(e)
                      // }}
                    >
                      <IoLogoFacebook />
                      <span>Facebook</span>
                    </LoginButton>
                  </FacebookProvider>
                </Fragment>
              ) : (
                <Fragment>
                  <div style={{ display: "none" }}></div>
                  <div style={{ display: "none" }}></div>
                </Fragment>
              )}

              {activeAuthPage === "SignIn" ? (
                <button
                  disabled
                  type="button"
                  className="hyperlink"
                  onClick={() => {
                    toggleAuthPage("Register");
                    setSubmitAttempted(false);
                    setFormData((form) => ({
                      email: "",
                      password: "",
                      confirmPassword: "",
                      name: "",
                      timeZone: momentTz.tz.guess()
                    }));
                    setFormValidations((form) => ({
                      email: false,
                      password: false,
                      confirmPassword: false,
                      name: false,
                      timeZone: true,
                    }));
                  }}
                >
                  Don't have an account? Create one here
                </button>
              ) : (
                <button
                  type="button"
                  className="account-exist"
                  onClick={() => {
                    history.push("/authentication");
                    toggleAuthPage("SignIn");
                    setInvited(false);
                    setSubmitAttempted(false);
                    setFormData((form) => ({ email: "", password: "" }));
                    setFormValidations((form) => ({
                      email: false,
                      password: false,
                    }));
                  }}
                >
                  Account exist? Sign In here
                </button>
              )}
            </Fragment>
            // : null
          }
        </div>
      </div>
      {loading ? (
        <div>
          <Loader type="TailSpin" color="rgb(11, 70, 85)" width={60} />
        </div>
      ) : null}
    </div>
  );
};
