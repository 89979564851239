import React, { useState, useEffect, Fragment } from "react";
import "./index.scss";
import { useSelector, useDispatch } from "react-redux";
import { LoadMyRaffles } from "../../../../store/actions/raffleAction";
import { GiEmptyHourglass } from "react-icons/gi";
import { GoSearch, GoInfo } from "react-icons/go";
import {
  FaShare,
  FaEdit,
  FaQrcode,
  FaFileAlt,
  FaCalendarTimes,
  FaRegCalendarTimes,
  FaSortAmountDownAlt,
  FaSortAmountUpAlt,
  FaRegTimesCircle,
  FaSortAlphaUpAlt,
  FaSortAlphaDown,
  FaSortAlphaDownAlt,
  FaRegEye,
  FaSearch,
  FaList,
  FaRedoAlt,
} from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import RaffleInvitationModel from "../../models/raffleInvitation";
import GetDate from "../../../../helper/getDate";
import { useAlert } from "react-alert";
import {
  web_url,
  pickWinners,
  mySearch,
  mySearchCount,
  hostCalRaffles,
  getRaffleById,
  calRaffles,
} from "../../../../config/endpoints";
import firebase from "../../../../helper/firebase";
import { hostCount, hostRaffles } from "../../../../config/endpoints";
// redux
import { RaffleWinnerPicked } from "../../../../store/actions/raffleAction";
// icons
import { BsCalendar } from "react-icons/bs";
import { BiGridAlt, BiCalendarWeek } from "react-icons/bi";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { GiCardPickup } from "react-icons/gi";
// date picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { request } from "../../../../helper/request";
import { togggleLoad } from "../../../../store/actions/loadAction";
// tooltip
import ReactToolTip from "react-tooltip";
import Loader from "react-loader-spinner";

import NoRafflesFound from "../../../../assets/NoRafflesFound";
import WorkspaceWelcome from "../../../../assets/WorkspaceWelcome";

const options = {
  hour: "2-digit",
  minute: "numeric",
  month: "2-digit",
  day: "2-digit",
  year: "numeric",
};

// calender view
const daysList = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const monthsList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const sortOrderList = ["ASCENDING", "DESCENDING"];

export default ({
  setActiveRaffleName,
  setQrlink,
  setActiveModel,
  setChoosenRaffle,
  setChoosenRafflePrizes,
  setSeriesEdit,
  toRefreshSidePanel,
  resFromCreateWindow,
}) => {
  const [localRaffles, setLocalRaffles] = useState([]);
  const [sortBy, setSortBy] = useState("title");
  const [sortRaffle, setSortRaffle] = useState("OPEN");
  const [sortRaffle3, setSortRaffle3] = useState("OPEN");

  const [localTempRaffles, setLocalTempRaffles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [showEditChoosePopup, setEditChoosePopup] = useState("");

  // list of raffle view
  const [raffleView, setRaffleView] = useState("Grid");
  // calender view
  const [currentDay, setCurrentDay] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(new Date());

  const [showInvitationModel, toggleInvitationModel] = useState(false);
  const [shareRaffleId, setShareRaffleId] = useState("");
  const [shareRaffleName, setShareRaffleName] = useState("");

  const [selectedRaff, setSelectedRaff] = useState(null);

  const alert = useAlert();

  // search input
  const [searchInput, setSearchInput] = useState("");
  const [sortOrder, setSortOrder] = useState(null);

  const [sortRafflesList, setSortRafflesList] = useState({
    ALL: "0",
    OPEN: "0",
    DRAWN: "0",
    COMPLETE: "0",
    NEW: "0",
    CANCEL: "0",
    CLOSE: "0",
    INACTIVE: "0",
    DRAFT: "0",
  });

  // const itemsPerPage = 10;
  const [itemsPerPage, setItemsPerPage] = useState(20);


  const calculateTotalPages = (count) => {
    const pages = Math.ceil(count / itemsPerPage);
    console.log("total pages:::::::::::::::::", pages);
    setTotalPages(pages);
    return pages;
  };
  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    const tp = calculateTotalPages(sortRafflesList[sortRaffle]);
    if (searchInput.length === 0) {
      apiHostedList(email, sortRaffle, tp > currentPage ? currentPage : tp, itemsPerPage);
    } else {
      apiSearchList(
        email,
        "host",
        sortRaffle,
        sortBy,
        searchInput,
        tp > currentPage ? currentPage : tp, // ensure to go to last page if number of pages is less than previous current page
        itemsPerPage
      );
    }
  }, [currentPage, itemsPerPage]);


  useEffect(() => {
    onRefresh();
  }, [resFromCreateWindow])

  // listing to windows events
  useEffect(() => {
    window.addEventListener("mousedown", (e) => {
      if (
        !e.target.id.includes("edit_icon") &&
        e.target.className !== "edit_choose_popup" &&
        e.target.className !== "edit_choose_popup_item"
      ) {
        setEditChoosePopup("");
      }
    });
  }, []);

  // to get hosted raffle of host email alone
  const apiHostedList = (email, status, page, itemsPerFetch) => {
    setLoading(true);
    setCurrentPage(page);
    request(
      "GET_AUTH",
      hostRaffles + `?email=${email}&status=${status}&page=${page}&itemsPerFetch=${itemsPerFetch}`
    )
      .then((res) => {
        if (res.status === 0) {
          const payload = res.payload;
          setLocalRaffles(payload);
          setLocalTempRaffles(payload);
          setLoading(false);
        } else {
          console.log("error res", res);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("error in apiHostedList");
        setLoading(false);
      });
  };

  // to get hosted raffle of host email alone
  const apiHostedCalList = (email, from, to, role) => {
    setLoading(true);
    request(
      "GET_AUTH",
      calRaffles + `?email=${email}&from=${from}&to=${to}&role=${role}`
    )
      .then((res) => {
        if (res.status === 0) {
          const payload = res.payload;
          setLocalRaffles(payload);
          setLocalTempRaffles(payload);
          setLoading(false);
        } else {
          console.log("error res", res);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("error in apiHostedCalList");
        setLoading(false);
      });
  };

  // to get life state counts
  const apiHostcount = (email) => {
    request("GET_AUTH", hostCount + `?email=${email}`)
      .then((res) => {
        if (res.status === 0) {
          setSortRafflesList(res.payload);
          calculateTotalPages(res.payload[sortRaffle]);
        } else {
          console.log("error res", res);
        }
      })
      .catch((e) => {
        console.log("error in apiHostcount");
      });
  };

  const apiSearchList = (email, role, status, sortBy, search, page, itemsPerFetch) => {
    setLoading(true);
    setCurrentPage(page);
    request(
      "GET_AUTH",
      mySearch +
      `?email=${email}&role=${role}&lifeState=${status}&sortBy=${sortBy}&search=${search.trim()}&page=${page}&itemsPerFetch=${itemsPerFetch}`
    )
      .then((res) => {
        if (res.status === 0) {
          const payload = res.payload;
          setLocalRaffles(payload);
          setLocalTempRaffles(payload);
          setLoading(false);
        } else {
          console.log("error res", res);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("error in apiSearchList");
        setLoading(false);
      });
  };

  const apiSearchcount = (email, role, sortBy, search) => {
    request(
      "GET_AUTH",
      mySearchCount +
      `?email=${email}&role=${role}&sortBy=${sortBy}&search=${search}`
    )
      .then((res) => {
        if (res.status === 0) {
          setSortRafflesList(res.payload);
          calculateTotalPages(res.payload[sortRaffle]);
        } else {
          console.log("error res", res);
        }
      })
      .catch((e) => {
        console.log("error in apiSearchcount");
      });
  };

  //load raffles or to localRaffles
  useEffect(() => {
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    apiHostedList(email, sortRaffle, currentPage, itemsPerPage);
    apiHostcount(email);
  }, []);

  const onToggleLifeState = async (lifeCycleState) => {
    setSortRaffle(lifeCycleState);
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    calculateTotalPages(sortRafflesList[lifeCycleState]);
    if (searchInput.length === 0) {
      apiHostedList(email, lifeCycleState, 1, itemsPerPage);
    } else {
      apiSearchList(email, "host", lifeCycleState, sortBy, searchInput, 1, itemsPerPage);
    }
  };

  const onChangeSearch = (text) => {
    setSearchInput(text);
  };

  const sortingBySelectedOrder = (list, order, sortOption) => {
    let sortedList = [];
    if (order === "DESCENDING") {
      // do descending
      if (sortOption === "drawAt") {
        sortedList = list.sort((a, b) => {
          let aDate = new Date(a?.drawAt);
          let bDate = new Date(b?.drawAt);
          if (!a.drawAt) {
            aDate = new Date(a.drawAt);
          }
          if (!b.drawAt) {
            bDate = new Date(b.drawAt);
          }
          return aDate - bDate;
        });
      } else if (
        sortOption === "participationOpens" ||
        sortOption === "participationCloses"
      ) {
        sortedList = list.sort(
          (a, b) => new Date(a[sortOption]) - new Date(b[sortOption])
        );
      } else {
        sortedList = list.sort((a, b) =>
          a[sortOption] && b[sortOption]
            ? b[sortOption].localeCompare(a[sortOption])
            : 0
        );
      }
    } else {
      // do ascending
      if (sortOption === "drawAt") {
        sortedList = list.sort((a, b) => {
          let aDate = new Date(a?.drawAt);
          let bDate = new Date(b?.drawAt);
          if (!a.drawAt) {
            aDate = new Date(a.drawAt);
          }
          if (!b.drawAt) {
            bDate = new Date(b.drawAt);
          }
          return bDate - aDate;
        });
      } else if (
        sortOption === "participationOpens" ||
        sortOption === "participationCloses" ||
        sortOption === "drawnAt"
      ) {
        sortedList = list.sort(
          (a, b) => new Date(b[sortOption]) - new Date(a[sortOption])
        );
      } else {
        sortedList = list.sort((a, b) =>
          a[sortOption] && b[sortOption]
            ? a[sortOption].localeCompare(b[sortOption])
            : 0
        );
      }
    }
    if (sortedList.length > 0) {
      setLocalRaffles(sortedList);
    } else {
      setLocalRaffles(null);
    }
    return sortedList;
  };

  const filterForCalView = (currentDate) => {
    let todayRaffle = [];
    if (sortRaffle3 === "ALL") {
      todayRaffle = localRaffles.filter(
        (x) =>
          ((x.type !== "Instant" &&
            new Date(x?.drawAt).toLocaleDateString() == currentDate) ||
            new Date(x.participationOpens).toLocaleDateString() ==
            currentDate ||
            new Date(x.participationCloses).toLocaleDateString() ==
            currentDate) &&
          !x.extras.isTemplate
      );
    }
    if (sortRaffle3 === "OPEN") {
      todayRaffle = localRaffles.filter(
        (x) =>
          new Date(x.participationOpens).toLocaleDateString() == currentDate &&
          !x.extras.isTemplate
      );
    }
    if (sortRaffle3 === "CLOSE") {
      todayRaffle = localRaffles.filter(
        (x) =>
          new Date(x.participationCloses).toLocaleDateString() == currentDate &&
          !x.extras.isTemplate
      );
    }
    if (sortRaffle3 === "DRAW") {
      todayRaffle = localRaffles.filter(
        (x) =>
          x.type !== "Instant" &&
          new Date(x?.drawAt).toLocaleDateString() == currentDate &&
          !x.extras.isTemplate
      );
    }
    return todayRaffle;
  };

  const _renderCalenderView = () => {
    return (
      <div className="calender_view">
        {/* days in a week */}
        <div className="daysList">
          {daysList.map((_day, index) => (
            <div key={index}>{_day}</div>
          ))}
        </div>
        {/* days list */}
        <div className="datesList">
          {[...new Array(42).keys()]
            .map((x) => x + 1)
            .map((index, key) => {
              const tempDate = currentDay;
              tempDate.setDate(1);
              let startIndex = tempDate.getDay();
              if (startIndex === 0) {
                startIndex = 7;
              }
              currentDay.setMonth(currentDay.getMonth() + 1);
              currentDay.setDate(0);
              const endIndex = currentDay.getDate();
              if (index >= startIndex && index < endIndex + startIndex) {
                const cDate = index - startIndex + 1;
                const currentDate = new Date(
                  currentDay.getFullYear(),
                  currentDay.getMonth(),
                  cDate
                ).toLocaleDateString();

                let todayRaffle = filterForCalView(currentDate);

                return (
                  <div key={key}>
                    <div className="index">{cDate + ""}</div>
                    {todayRaffle.map((raff, index) => {
                      let redDot = false;
                      let greenDot = false;
                      let brownDot = false;
                      if (
                        raff.type === "Instant"
                      ) {
                        redDot =
                          new Date(
                            raff.participationCloses
                          ).toLocaleDateString() == currentDate;
                        greenDot =
                          new Date(
                            raff.participationOpens
                          ).toLocaleDateString() == currentDate;
                      } else {
                        brownDot =
                          new Date(raff.drawAt).toLocaleDateString() ==
                          currentDate;
                        redDot =
                          new Date(
                            raff.participationCloses
                          ).toLocaleDateString() == currentDate;
                        greenDot =
                          new Date(
                            raff.participationOpens
                          ).toLocaleDateString() == currentDate;
                      }
                      return (
                        <div data-tip data-for={raff._id} className="capsule" key={index}>
                          <span className="raff-title">
                            {raff.title.substring(0, 4)}
                          </span>
                          {sortRaffle3 === "OPEN" && (
                            <span className="dot greendot"></span>
                          )}
                          {sortRaffle3 === "CLOSE" && (
                            <span className="dot reddot"></span>
                          )}
                          {sortRaffle3 === "DRAW" && (
                            <span className="dot browndot"></span>
                          )}
                          {sortRaffle3 === "ALL" && greenDot && (
                            <span className="dot greendot"></span>
                          )}
                          {sortRaffle3 === "ALL" && redDot && (
                            <span className="dot reddot"></span>
                          )}
                          {sortRaffle3 === "ALL" && brownDot && (
                            <span className="dot browndot"></span>
                          )}
                          <span className="asterisk">
                            {raff.extras.seriesRaffle.isMultipleRaffles
                              ? ""
                              : "*"}
                          </span>
                        </div>
                      );
                    })}
                    {todayRaffle.map((raff, index) => {
                      return (
                        <ReactTooltip
                          key={index}
                          className="card_event_tooltip"
                          place="right"
                          id={raff._id}
                          clickable
                        >
                          {_renderRaffle(
                            raff,
                            key,
                            (new Date(raff.drawnAt).getTime() -
                              new Date().getTime()) /
                            (24 * 60 * 60 * 1000)
                          )}
                        </ReactTooltip>
                      );
                    })}
                  </div>
                );
              } else {
                return <div key={key} className="none"></div>;
              }
            })}
        </div>
      </div>
    );
  };

  const countParticipantsByEmail = (participants) => {
    let emailCounts = {};
    let ticketNumbers = {};
    for (let participant of participants) {
      const email = participant.email;
      const ticketNumber = participant.ticketNumber;
      emailCounts[email] = (emailCounts[email] || 0) + 1;
      if (!ticketNumbers[email]) {
        ticketNumbers[email] = [];
      }
      ticketNumbers[email].push(ticketNumber);
    }
    return { emailCounts, ticketNumbers };
  };

  const onClickViewOrCancelOrEdit = async (raffleId, choosenOp) => {
    setLoading(true);
    request("POST_AUTH", getRaffleById, { _id: raffleId })
      .then((res) => {
        if (res.status === 0) {
          const raff = res.payload.raffle;
          const prizesList = res.payload.prizes;
          setChoosenRafflePrizes(prizesList);
          setChoosenRaffle(raff);
          if (choosenOp === "view") {
            setActiveModel("HostRaffleTicketViewModel");
          } else if (choosenOp === "cancel") {
            setActiveModel("CancleRaffleModel");
          } else if (choosenOp === "share") {
            if (
              !raff.extras.draft &&
              raff.status !== "DRAWN" &&
              raff.status !== "CANCEL" &&
              raff.status !== "COMPLETE" &&
              raff.status !== "CLOSE" &&
              raff.extras.active
            ) {
              setShareRaffleId(raff._id);
              setShareRaffleName(raff.title);
              setSelectedRaff(raff);
              toggleInvitationModel(true);
            }
          } else if (choosenOp === "editView") {
            setActiveModel("UpdateRaffleModel");
          } else {
            // choosenOp === "edit"
            if (raff.type !== "Series") {
              setActiveModel("UpdateRaffleModel");
            } else if (
              raff.type === "Series" &&
              !raff.extras.seriesRaffle.isMultipleRaffles
            ) {
              setActiveModel("UpdateRaffleModel");
            } else {
              setEditChoosePopup(raff._id);
            }
          }
          setLoading(false);
        } else {
          alert.removeAll();
          alert.show("Error loading raffle by id");
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const _renderRaffle = (raffle, i, differ) => {
    const pastDrawsAndCancels = raffle.extras.seriesRaffle.numofDrawnTimes.length + raffle.extras.seriesRaffle.numofCancelledDraws.length;
    let numOfDrawsLeft =
      raffle.extras.seriesRaffle.recurrencePattern === "Hourly"
        ? raffle.extras.seriesRaffle.hourlyPattern.length -
        pastDrawsAndCancels
        : raffle.extras.seriesRaffle.recurrencePattern === "Daily"
          ? raffle.extras.seriesRaffle.dailyPattern.days.length - pastDrawsAndCancels
          : raffle.extras.seriesRaffle.recurrencePattern === "Weekly"
            ? raffle.extras.seriesRaffle.weeklyPattern.days.length -
            pastDrawsAndCancels
            : raffle.extras.seriesRaffle.recurrencePattern === "Monthly"
              ? raffle.extras.seriesRaffle.monthlyPattern.days.length -
              pastDrawsAndCancels
              : raffle.extras.seriesRaffle.recurrencePattern === "Yearly"
                ? raffle.extras.seriesRaffle.yearlyPattern.days.length -
                pastDrawsAndCancels
                : raffle.extras.seriesRaffle.recurrencePattern === "On Demand"
                  ? raffle.extras.seriesRaffle.onDemandDraws -
                  pastDrawsAndCancels
                    : 0;
    const isNowRaffle =
      raffle.extras.seriesRaffle.recurrencePattern === "Now" 
      // && raffle.winners.length === 0; //now raffle and winner not picked then its true;
    // const no_of_instant_participants = seriesRafflesList.length()
    let editIconDisable =
      raffle.status === "CLOSE" ||
      raffle.status === "DRAWN" ||
      raffle.status === "CANCEL" ||
      raffle.status === "COMPLETE";

    let cancelIconEnable =
      // new Date() <= new Date(raffle.participationCloses) &&
      // raffle.extras.active
      !(
        raffle.status === "CLOSE" ||
        raffle.status === "DRAWN" ||
        raffle.status === "CANCEL" ||
        raffle.status === "COMPLETE"
      );

    const { emailCounts, ticketNumbers } = countParticipantsByEmail(
      raffle.participants
    );
    return (
      <div className="raffle_card" key={i}>
        <div>
          {raffle.status === "DRAFT" ? (
            <p >Status: <span className="draft">DRAFT</span></p>
          ) : raffle.status === "CREATED" && raffle.extras.active ? (
            <p >Status: <span className="new">NEW</span></p>
          ) : raffle.status === "OPEN" && raffle.extras.active ? (
            <p>Status: <span className="open">OPEN</span></p>
          ) : raffle.status === "CLOSE" && raffle.extras.active ? (
            <p>Status: <span className="close">CLOSED</span></p>
          ) : raffle.status === "DRAWN" ? (
            <p >Status: <span className="draw">DRAWN</span></p>
          ) : raffle.status === "COMPLETE" ? (
            <p>Status: <span className="completed">COMPLETED</span></p>
          ) : raffle.status === "CANCEL" ? (
            <p>Status: <span className="cancelled">CANCELLED</span></p>
          ) : (
            <p>Status: <span className="inactive">INACTIVE</span></p>
          )}
        </div>
        <div
          style={{
            backgroundColor: raffle.styles.background,
            cursor: "pointer",
            color:
              "titleColor" in raffle.styles
                ? raffle.styles.titleColor
                : raffle.styles.textColor,
          }}
        >
          {raffle.title[0].toUpperCase()}
        </div>
        <div className="options">
          <FaRegEye
            onClick={() => {
              onClickViewOrCancelOrEdit(raffle._id, "view");
            }}
            data-tip
            data-for={"view_icons_tt" + i}
            className={"share_icon VieW"}
          />
          {!raffle.extras.draft && (
            <FaShare
              onClick={() => {
                onClickViewOrCancelOrEdit(raffle._id, 'share');
              }}
              data-tip
              data-for={"share_icons_tt" + i}
              className={`${raffle.extras.draft ||
                raffle.status === "DRAWN" ||
                raffle.status === "CANCEL" ||
                raffle.status === "COMPLETE" ||
                raffle.status === "CLOSE" ||
                !raffle.extras.active
                ? "disable"
                : ""
                } share_icon SharE`}
            />
          )}
          {!raffle.extras.draft && (
            <FaQrcode
              onClick={() => {
                if (
                  !raffle.extras.draft &&
                  raffle.status !== "DRAWN" &&
                  raffle.status !== "CANCEL" &&
                  raffle.status !== "COMPLETE" &&
                  raffle.status !== "CLOSE" &&
                  raffle.extras.active
                ) {
                  setQrlink(web_url + "joinRaffle?id=" + raffle._id);
                  setActiveModel("QrCodeModel");
                  setActiveRaffleName(raffle.title);
                }
              }}
              data-tip
              data-for={"qr_icons_tt" + i}
              className={`${!(
                !raffle.extras.draft &&
                raffle.status !== "DRAWN" &&
                raffle.status !== "CANCEL" &&
                raffle.status !== "COMPLETE" &&
                raffle.status !== "CLOSE" &&
                raffle.extras.active
              )
                ? "disable"
                : ""
                } qr_code QrCode`}
            />
          )}
          {!editIconDisable ? (
            <FaEdit
              id={`edit_icon${i}`}
              onClick={() => {
                if (raffle.extras.draft) {
                  // if a draft is being edited
                  onClickViewOrCancelOrEdit(raffle._id, "edit");
                } else if (!editIconDisable) {
                  // not series raffle and not draft
                  onClickViewOrCancelOrEdit(raffle._id, "edit");
                } else {
                  alert.removeAll();
                  alert.show("Can't edit the raffle at this moment");
                }
              }}
              data-tip
              data-for={"edit_icons_tt" + i}
              className={`${editIconDisable ? "disable" : ""} edit_icon EdiT`}
            />
          ) : (
            <FaFileAlt
              id={`edit_icon${i}`}
              className="edit_icon EdiT"
              data-tip
              data-for={"edit_icons_tt" + i}
              onClick={() => {
                onClickViewOrCancelOrEdit(raffle._id, "editView");
              }}
            />
          )}
          {/* , FaRegCalendarTimes */}
          {!raffle.extras.draft && (
            <FaRegTimesCircle
              onClick={() => {
                if (!cancelIconEnable) {
                  alert.removeAll();
                  alert.show("Raffle cannot be cancelled.");
                } else {
                  onClickViewOrCancelOrEdit(raffle._id, "cancel");
                }
              }}
              data-tip
              data-for={"cancle_icons_tt" + i}
              className={`${!cancelIconEnable ? "disable" : ""
                } edit_icon CanceL`}
            />
          )}
          {showEditChoosePopup === raffle._id && (
            <div className="edit_choose_popup">
              <div
                onClick={() => {
                  setActiveModel("UpdateRaffleModel");
                }}
                className="edit_choose_popup_item"
              >
                Edit occurrence
              </div>
              <div
                onClick={() => {
                  if (raffle.status !== "OPEN" && raffle.status !== "CLOSE") {
                    setActiveModel("UpdateRaffleModel");
                    setSeriesEdit(true);
                  } else {
                    alert.show(
                      "Selected raffle instance is already open. Cannot be edited as series."
                    );
                  }
                }}
                className={
                  raffle.status !== "OPEN" && raffle.status !== "CLOSE"
                    ? "edit_choose_popup_item"
                    : "disable"
                }
              >
                Edit series
              </div>
            </div>
          )}
        </div>
        <div className="fields">
          {/* <label>{raffle.rafflerType}</label> */}
          <label data-tip data-for={"short_description" + i}>
            {(raffle.title.length > 16
              ? raffle.title.substring(0, 16) + "..."
              : raffle.title) + ` (${raffle.rafflerType})`}
          </label>

          <Fragment>
            {/* <ReactTooltip place="bottom" id={"share_icons_tt" + i}>Email invitaions</ReactTooltip> */}
            {/* <ReactTooltip place="bottom" id={"edit_icons_tt" + i}>Edit Raffle</ReactTooltip> */}
            {/* <ReactTooltip place="bottom" id={"qr_icons_tt" + i}>QR code</ReactTooltip> */}
            {/* {raffle.winners.length === 0 &&
              raffle.participants.length < raffle.maxNumParticipants ? (
              <ReactTooltip place="bottom" id={"pick_a_ainner_icons_tt" + i}>
                Pick winner(s)
              </ReactTooltip>
            ) : null} */}
            <ReactTooltip place="bottom" id={"short_description" + i}>
              {raffle.shortDescription}
            </ReactTooltip>
          </Fragment>
          <label>
            Type : {raffle?.type}
          </label>
          <label>
            Recurrence : {raffle.extras.seriesRaffle.recurrencePattern}
          </label>
          <label>
            Fee :{" "}
            {raffle.fee === 0 ? "FREE" : raffle.fee + " " + raffle.feeCurrency}
          </label>
          {/* <label>
                    Prizes : {raffle.prizeType === "Money" ? raffle.numPrizes.map(x => raffle.currency + " " + x).join(", ") : raffle.numPrizes.join(", ")}
                </label> */}
          <label>
            Open at:{" "}
            {new Date(raffle.participationOpens).toLocaleString(
              "en-US",
              options
            )}
          </label>
          <label>
            Close at:{" "}
            {!isNowRaffle
              ? new Date(raffle.participationCloses).toLocaleString(
                "en-US",
                options
              )
              : "-"}
          </label>
          {!isNowRaffle &&
          raffle.type === "Instant" || raffle.extras.seriesRaffle.recurrencePattern === "On Demand" ? (
            raffle.status === "DRAWN" ? (
              <label>
                Drawn at:{" "}
                {new Date(raffle.drawAt).toLocaleString("en-US", options)}
              </label>
            ) : (
              ""
            )
          ) : (
            <label>
              {raffle.status === "DRAWN"
                ? "Drawn at"
                : "Draw at"}
              :{" "}
              {raffle.status === "DRAWN"
                ? new Date(raffle.drawnAt).toLocaleString("en-US", options)
                : new Date(raffle.drawAt).toLocaleString("en-US", options)}
            </label>
          )}
        </div>

        {raffle.extras.seriesRaffle.recurrencePattern === "One Time" ||
        raffle.extras.draft ? null : raffle.extras.seriesRaffle
            .isMultipleRaffles ? (
          <div className="series_raffle">
            {`Instance # ${raffle.extras.seriesRaffle.sno}`}
          </div>
        ) : (
          <div className="series_raffle">
            {`Draws left ${numOfDrawsLeft} / ${pastDrawsAndCancels + numOfDrawsLeft}`}
          </div>
        )}
      </div>
    );
  };

  const _renderWeeklyView = () => {
    return (
      <div className="weekly_view">
        {/* days in a week */}
        <div className="weekly-columns">
          {[...new Array(7).keys()].map((x) => {
            const day = 24 * 60 * 60 * 1000 * x;
            const cdate = new Date(currentWeek.getTime() + day);
            let todayRaffle = filterForCalView(cdate.toLocaleDateString());
            return (
              <div className="day-column" key={x}>
                <div className="date-day">
                  <div className="date">{cdate.toLocaleDateString()}</div>
                  <div className="day">
                    {daysList[cdate.getDay() === 0 ? 6 : cdate.getDay() - 1]}
                  </div>
                </div>
                <div className="raffleslist-col">
                  {todayRaffle.map((raff, index) => {
                    let redDot = false;
                    let greenDot = false;
                    let brownDot = false;
                    if (
                      raff.type === "Instant"
                    ) {
                      redDot =
                        new Date(
                          raff.participationCloses
                        ).toLocaleDateString() == cdate.toLocaleDateString();
                      greenDot =
                        new Date(
                          raff.participationOpens
                        ).toLocaleDateString() == cdate.toLocaleDateString();
                    } else {
                      brownDot =
                        new Date(raff.drawAt).toLocaleDateString() ==
                        cdate.toLocaleDateString();
                      redDot =
                        new Date(
                          raff.participationCloses
                        ).toLocaleDateString() == cdate.toLocaleDateString();
                      greenDot =
                        new Date(
                          raff.participationOpens
                        ).toLocaleDateString() == cdate.toLocaleDateString();
                    }
                    return (
                      <div
                        data-tip
                        data-for={raff._id}
                        className="capsule"
                        // className={
                        //   sortRaffle3 === "OPEN" ? "rafflera_open" :
                        //     sortRaffle3 === "CLOSE" ? "rafflera_close" :
                        //       sortRaffle3 === "DRAW" ? "rafflera_draw_activity" :
                        //         new Date(raff.drawAt).toLocaleDateString() == cdate.toLocaleDateString()
                        //           ? "rafflera_draw_activity"
                        //           : new Date(raff.participationCloses).toLocaleDateString() == cdate.toLocaleDateString()
                        //             ? "rafflera_close"
                        //             : "rafflera_open"

                        // }
                        key={index}
                      >
                        <span className="raff-title">
                          {raff.title.substring(0, 4)}
                        </span>
                        {sortRaffle3 === "OPEN" && (
                          <span className="dot greendot"></span>
                        )}
                        {sortRaffle3 === "CLOSE" && (
                          <span className="dot reddot"></span>
                        )}
                        {sortRaffle3 === "DRAW" && (
                          <span className="dot browndot"></span>
                        )}
                        {sortRaffle3 === "ALL" && greenDot && (
                          <span className="dot greendot"></span>
                        )}
                        {sortRaffle3 === "ALL" && redDot && (
                          <span className="dot reddot"></span>
                        )}
                        {sortRaffle3 === "ALL" && brownDot && (
                          <span className="dot browndot"></span>
                        )}
                        <span className="asterisk">
                          {raff.extras.seriesRaffle.isMultipleRaffles
                            ? ""
                            : "*"}
                        </span>
                      </div>
                    );
                  })}
                  {todayRaffle.map((raff, index) => {
                    return (
                      <ReactTooltip
                        className="card_event_tooltip"
                        place="right"
                        id={raff._id}
                        clickable
                        key={index}
                      >
                        {_renderRaffle(
                          raff,
                          x,
                          (new Date(raff.drawnAt).getTime() -
                            new Date().getTime()) /
                          (24 * 60 * 60 * 1000)
                        )}
                      </ReactTooltip>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const _renderPagination = () => {
    return (
      (raffleView === "Grid" || raffleView === "List") && <div className='host-footer'>
        <div htmlFor='itemsperpage' className='itemsperpage'>
          Items per page
          <select
            id="itemsperpage"
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(e.target.value)}
          >
            <option key={10} value={10}>10</option>
            <option key={20} value={20}>20</option>
            <option key={30} value={30}>30</option>
            <option key={40} value={40}>40</option>
            <option key={50} value={50}>50</option>
          </select>
        </div>
        {sortRafflesList[sortRaffle] > itemsPerPage && (raffleView === "Grid" || raffleView === "List") && (
          <div className="host-pagination">
            {currentPage > 1 && (
              <button
                className="page-num"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                &lt;
              </button>
            )}
            {currentPage >= 1 && (
              <button
                className={`page-num ${currentPage === 1 ? "active" : ""}`}
                onClick={() => handlePageChange(1)}
              >
                1
              </button>
            )}
            {currentPage > 3 && <span className="ellipsis">...</span>}
            {Array.from({ length: totalPages }).map((_, index) => {
              // Determine the range of pages to display
              const startPage = Math.max(2, currentPage - 2);
              const endPage = Math.min(totalPages - 1, startPage + 4);

              // Display page buttons within the range
              if (index + 1 >= startPage && index + 1 <= endPage) {
                return (
                  <button
                    key={index + 1}
                    className={`page-num ${currentPage === index + 1 ? "active" : ""
                      }`}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                );
              }
              return null;
            })}
            {currentPage < totalPages - 2 && <div className="ellipsis">...</div>}

            {currentPage <= totalPages && (
              <button
                className={`page-num ${currentPage === totalPages ? "active" : ""
                  }`}
                onClick={() => handlePageChange(totalPages)}
              >
                {totalPages}
              </button>
            )}

            {currentPage < totalPages && (
              <button
                className="page-num"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                &gt;
              </button>
            )}
          </div>
        )}
        <div className="empty-container">
        </div>
      </div>
    );
  };

  const onChangeView = (view) => {
    setRaffleView(view);
  };

  useEffect(() => {
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    setLoading(true);
    if (raffleView === "Calender") {
      // call from, to dates api
      const selectedDate = new Date(currentDay);
      const from = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        1,
        0,
        0,
        0,
        0
      ).toISOString();
      const to = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        0,
        23,
        59,
        0
      ).toISOString();
      apiHostedCalList(email, from, to, 'host');
    } else if (raffleView === "WeeklyCalender") {
      // call from, to dates api
      console.log("currentWeek::::::::::::::::::", currentWeek);
      const selectedDate = new Date(currentWeek);
      const from = new Date(selectedDate.setHours(0, 0, 0, 0)).toISOString();
      const to = new Date(
        new Date(from).getTime() + 7 * 24 * 3599999
      ).toISOString();
      apiHostedCalList(email, from, to, 'host');
    } else {
      apiHostedList(email, sortRaffle, 1, itemsPerPage);
      apiHostcount(email);
    }
  }, [raffleView, currentDay, currentWeek]);

  const _renderListView = (Raffslist) => {
    return (
      <div className="raffs-list-view">
        <table className="raffs-table">
          <thead className="raffs-thead">
            <tr className="raffs-thead-tr">
              <th className="rth-snum">#</th>
              <th className="rth-title">Title</th>
              <th className="rth-t">Type</th>
              <th className="rth-actions">Actions</th>
              <th className="rth-rt">Recurrence Type</th>
              <th className="rth-oa">Opens At</th>
              <th className="rth-ca">Closes At</th>
              <th className="rth-da">Draw At</th>
              <th className="rth-ef">Entry Fee</th>
            </tr>
          </thead>
          <tbody className="raffs-tbody">
            {Raffslist.map((raffle, i) => {
              let editIconDisable =
                raffle.status === "CLOSE" ||
                raffle.status === "DRAWN" ||
                raffle.status === "CANCEL" ||
                raffle.status === "COMPLETE"

              let cancelIconEnable =
                // new Date() <= new Date(raffle.participationCloses) &&
                // raffle.extras.active
                !(raffle.status === "CLOSE" ||
                  raffle.status === "DRAWN" ||
                  raffle.status === "CANCEL" ||
                  raffle.status === "COMPLETE")
              return (
              <tr className="raffs-tbody-tr" key={i}>
                <td className="rtd-snum">{((currentPage - 1) * itemsPerPage) + (i + 1)}</td>
                <td className="rtd-title"
                  data-tip data-for={"short_description" + i}>{(raffle.title.length > 16
                  ? raffle.title.substring(0, 16) + "..."
                  : raffle.title) + ` (${raffle.rafflerType})` +
                  (raffle.extras.seriesRaffle.isMultipleRaffles ? "" : "*")}
                  <ReactTooltip place="bottom" id={"short_description" + i}>
                    {raffle.shortDescription}
                  </ReactTooltip>
                </td>
                <td className="rtd-t">{raffle?.type}</td>
                <td className="rtd-actions">
                  <div className="options">
                    <FaRegEye
                        onClick={() => {
                          onClickViewOrCancelOrEdit(raffle._id, "view")
                        }}
                        data-tip
                        data-for={"view_icons_tt" + i}
                        className={"share_icon VieW"}
                      />
                      {!raffle.extras.draft && (
                        <FaShare
                          onClick={() => {
                            if (
                              !raffle.extras.draft &&
                              raffle.status !== "DRAWN" &&
                              raffle.status !== "CANCEL" &&
                              raffle.status !== "COMPLETE" &&
                              raffle.status !== "CLOSE" &&
                              raffle.extras.active
                            ) {
                              setShareRaffleId(raffle._id);
                              setShareRaffleName(raffle.title);
                              toggleInvitationModel(true);
                            }
                          }}
                          data-tip
                          data-for={"share_icons_tt" + i}
                          className={`${(
                            raffle.extras.draft ||
                            raffle.status === "DRAWN" ||
                            raffle.status === "CANCEL" ||
                            raffle.status === "COMPLETE" ||
                            raffle.status === "CLOSE" ||
                            !raffle.extras.active
                          )
                            ? "disable"
                            : ""
                            } share_icon SharE`}
                        />
                      )}
                      {!raffle.extras.draft && (
                        <FaQrcode
                          onClick={() => {
                            if (
                              !raffle.extras.draft &&
                              raffle.status !== "DRAWN" &&
                              raffle.status !== "CANCEL" &&
                              raffle.status !== "COMPLETE" &&
                              raffle.status !== "CLOSE" &&
                              raffle.extras.active
                            ) {
                              setQrlink(web_url + "joinRaffle?id=" + raffle._id);
                              setActiveModel("QrCodeModel");
                              setActiveRaffleName(raffle.title);
                            }
                          }}
                          data-tip
                          data-for={"qr_icons_tt" + i}
                          className={`${!(
                            !raffle.extras.draft &&
                            raffle.status !== "DRAWN" &&
                            raffle.status !== "CANCEL" &&
                            raffle.status !== "COMPLETE" &&
                            raffle.status !== "CLOSE" &&
                            raffle.extras.active
                          )
                            ? "disable"
                            : ""
                            } qr_code QrCode`}
                        />
                      )}
                      {!editIconDisable ? <FaEdit
                        id={`edit_icon${i}`}
                        onClick={() => {
                          if (raffle.extras.draft) {
                            // if a draft is being edited
                            onClickViewOrCancelOrEdit(raffle._id, "edit");
                          } else if (!editIconDisable) {
                            // not series raffle and not draft
                            onClickViewOrCancelOrEdit(raffle._id, "edit");
                          } else {
                            alert.removeAll();
                            alert.show("Can't edit the raffle at this moment");
                          }
                        }}
                        data-tip
                        data-for={"edit_icons_tt" + i}
                        className={`${editIconDisable ? "disable" : ""} edit_icon EdiT`}
                      /> :
                        <FaFileAlt
                          id={`edit_icon${i}`}
                          className="edit_icon EdiT"
                          data-tip
                          data-for={"edit_icons_tt" + i}
                          onClick={() => {
                            onClickViewOrCancelOrEdit(raffle._id, "editView");
                          }}
                        />
                      }
                      {!raffle.extras.draft && (
                        <FaRegTimesCircle
                          onClick={() => {
                            if (!cancelIconEnable) {
                              alert.removeAll();
                              alert.show("Raffle cannot be cancelled.");
                            }
                            else {
                              onClickViewOrCancelOrEdit(raffle._id, "cancel");
                            }
                          }}
                          data-tip
                          data-for={"cancle_icons_tt" + i}
                          className={`${!cancelIconEnable ? "disable" : ""
                            } edit_icon CanceL`}
                        />
                      )}
                      {showEditChoosePopup === raffle._id && (
                        <div className="edit_choose_popup">
                          <div
                            onClick={() => {
                              setActiveModel("UpdateRaffleModel");
                            }}
                            className="edit_choose_popup_item"
                          >
                            Edit occurrence
                          </div>
                          <div
                            onClick={() => {
                              if (raffle.status !== "OPEN" && raffle.status !== "CLOSE") {
                                setActiveModel("UpdateRaffleModel");
                                setSeriesEdit(true);
                              } else {
                                alert.show("Selected raffle instance is already open. Cannot be edited as series.")
                              }
                            }}
                            className={(raffle.status !== "OPEN" && raffle.status !== "CLOSE") ? "edit_choose_popup_item" : "disable"}
                          >
                            Edit series
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                  <td>{raffle.extras.seriesRaffle.recurrencePattern}</td>
                  <td>{new Date(raffle.participationOpens).toLocaleString('en-US', options)}</td>
                  <td>{new Date(raffle.participationCloses).toLocaleString('en-US', options)}</td>
                  <td>
                    {
                      raffle.status === "DRAWN" || raffle.status === "COMPLETE"
                        ? new Date(raffle.drawnAt).toLocaleString("en-US", options) :
                        raffle.type === "Instant"
                          ? "Draw Time On Demand" :
                          new Date(raffle.drawAt).toLocaleString("en-US", options)
                    }
                  </td>
                  <td>{raffle.fee}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  };

  const _renderRaffles = () => {
    return (
      <div>
        {raffleView === "Grid" ? (
          <div className="card_view">
            {localRaffles.map((raffle, i) => {
              const differ =
                (new Date(raffle.drawnAt).getTime() - new Date().getTime()) /
                (24 * 60 * 60 * 1000);
              if (
                !("isTemplate" in raffle.extras && raffle.extras.isTemplate)
              ) {
                return _renderRaffle(raffle, i, differ);
              }
            })}
          </div>
        ) : raffleView === "List" ? (
          _renderListView(localRaffles)
        ) : raffleView === "Calender" ? (
          _renderCalenderView()
        ) : (
          _renderWeeklyView()
        )}
      </div>
    );
  };

  // on click refresh button
  const onRefresh = () => {
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    setSearchInput("");
    toRefreshSidePanel();
    if (raffleView === "Calender") {
      // call from, to dates api
      const selectedDate = new Date(currentDay);
      const from = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        1,
        0,
        0,
        0,
        0
      ).toISOString();
      const to = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        0,
        23,
        59,
        0
      ).toISOString();
      apiHostedCalList(email, from, to, 'host');
    } else if (raffleView === "WeeklyCalender") {
      // call from, to dates api
      console.log("currentWeek::::::::::::::::::", currentWeek);
      const selectedDate = new Date(currentWeek);
      const from = new Date(selectedDate.setHours(0, 0, 0, 0)).toISOString();
      const to = new Date(
        new Date(from).getTime() + 7 * 24 * 3599999
      ).toISOString();
      apiHostedCalList(email, from, to, 'host');
    } else {
      apiHostedList(email, sortRaffle, currentPage, itemsPerPage);
      apiHostcount(email);
    }
  };

  const onClickXbtn = () => {
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    setSearchInput("");
    apiHostedList(email, sortRaffle, 1, itemsPerPage);
    apiHostcount(email);
  };

  const onClickSearchbtn = () => {
    const email = JSON.parse(sessionStorage.getItem("User")).email;
    apiSearchList(email, "host", sortRaffle, sortBy, searchInput, 1, itemsPerPage);
    apiSearchcount(email, "host", sortBy, searchInput);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onClickSearchbtn(); // Call your search action function here
    }
  };

  return (
    <div className="hosted_raffles">
      {/* Header */}
      <div>
        <div>
          <GoSearch size={16} color="rgba(0,0,0,0.3)" />
          <input
            className={
              sortBy === "participationOpens" ||
                sortBy === "participationCloses" ||
                sortBy === "drawAt"
                ? "host-date-filter"
                : "host-search-bar"
            }
            onChange={(e) => {
              const text = e.currentTarget.value;
              onChangeSearch(text);
            }}
            placeholder={`Search here...`}
            type={
              sortBy === "participationOpens" ||
                sortBy === "participationCloses" ||
                sortBy === "drawAt"
                ? "date"
                : "text"
            }
            onKeyDown={handleKeyDown}
            value={searchInput}
            disabled={raffleView === "WeeklyCalender" || raffleView === "Calender"}
          />
          {searchInput.length > 0 && (
            <button className="search-x-btn" onClick={onClickXbtn}>
              X
            </button>
          )}
          <button
            className={
              searchInput.length === 0 ? "search-btn-dsbld" : "search-btn"
            }
            onClick={onClickSearchbtn}
            disabled={searchInput.length === 0}
          >
          <FaSearch /> 
          </button>
          <select
            value={sortBy}
            onChange={(e) => {
              const option = e.currentTarget.value;
              setSortBy(option);
              setLocalRaffles([...localTempRaffles]);
              setSortOrder(null);
            }}
          >
            <option key="title" value="title">
              Title
            </option>
            <option key="rafflerType" value="rafflerType">
              Category
            </option>
            <option key="participationOpens" value="participationOpens">
              Open Date
            </option>
            <option key="participationCloses" value="participationCloses">
              Close Date
            </option>
            <option key="drawnAt" value="drawAt">
              Draw Date
            </option>
            <option key="recurrencePattern" value="recurrencePattern">
              Recurrence
            </option>
          </select>
          <div className="sortorder-switch">
            {sortOrderList.map((item, i) => (
              <label
                onClick={async () => {
                  setSortOrder(item);
                  sortingBySelectedOrder(localTempRaffles, item, sortBy);
                }}
                className={sortOrder === item ? "active" : ""}
                key={i}
              >
                <span>
                  {item === "ASCENDING" ? (
                    sortBy === "participationOpens" ||
                      sortBy === "participationCloses" ||
                      sortBy === "drawnAt" ? (
                      <FaSortAmountDownAlt />
                    ) : (
                      <FaSortAlphaDown />
                    )
                  ) : sortBy === "participationOpens" ||
                    sortBy === "participationCloses" ||
                    sortBy === "drawnAt" ? (
                    <FaSortAmountUpAlt />
                  ) : (
                    <FaSortAlphaDownAlt />
                  )}
                </span>
              </label>
            ))}
          </div>
        </div>
      </div>
      {/* Sort list of raffles */}
      <div className="tabs-container">
        {raffleView === "WeeklyCalender" || raffleView === "Calender" ? (
          <>
            <div className="view-tabs">
              <button className="refresh-btn" onClick={onRefresh}>
                <FaRedoAlt className="refresh-icon" />
              </button>
              <div className="text_weekly_monthly">
                <button
                  onClick={() => onChangeView("WeeklyCalender")}
                  className={raffleView === "WeeklyCalender" ? "active" : "inactive"}
                >
                  WEEKLY
                </button>
                <button
                  disabled={window.innerWidth < 720}
                  onClick={() => {
                    onChangeView("Calender");
                  }}
                  className={raffleView === "Calender" ? "active" : (window.innerWidth < 720 ? "disable" : "inactive")}
                >
                  MONTHLY
                </button>
              </div>
            </div>
            <div className="cv-tabs-container">
              <Fragment>
                <div
                  className={sortRaffle3 === "DRAW" ? "active" : ""}
                  onClick={() => setSortRaffle3("DRAW")}
                >
                  <div style={{ backgroundColor: "goldenrod" }}></div>
                  <label>DRAW</label>
                </div>
                <div
                  className={sortRaffle3 === "CLOSE" ? "active" : ""}
                  onClick={() => setSortRaffle3("CLOSE")}
                >
                  <div style={{ backgroundColor: "#FF5E5F" }}></div>
                  <label className="close">CLOSE</label>
                </div>
                <div
                  className={sortRaffle3 === "OPEN" ? "active" : ""}
                  onClick={() => setSortRaffle3("OPEN")}
                >
                  <div style={{ backgroundColor: "#20E39C" }}></div>
                  <label className="open">OPEN</label>
                </div>
                <div
                  className={sortRaffle3 === "ALL" ? "active" : ""}
                  onClick={() => setSortRaffle3("ALL")}
                >
                  <div
                    className="_all"
                    style={{ backgroundColor: "goldenrod" }}
                  ></div>
                  <div
                    className="_all"
                    style={{ backgroundColor: "#FF5E5F" }}
                  ></div>
                  <div
                    className="_all"
                    style={{ backgroundColor: "#20E39C" }}
                  ></div>
                  <label className="all">ALL</label>
                </div>
              </Fragment>
            </div>
          </>
        ) : (
          <div className="ls-tabs">
            <button className="refresh-btn" onClick={onRefresh}>
              <FaRedoAlt className="refresh-icon" />
            </button>
            {Object.keys(sortRafflesList).map((item, i) => (
              <label
                onClick={async () => {
                  // sortFilterRaffles(item);
                  onToggleLifeState(item);
                }}
                className={sortRaffle === item ? "active" : ""}
                key={i}
              >
                {`${item} ${sortRafflesList[item] === "0"
                  ? ""
                  : "(" + sortRafflesList[item] + ")"
                  }`}
              </label>
            ))}
          </div>
        )}

        <div className="cards_view">
          <BiGridAlt
            onClick={() => {
              onChangeView("Grid");
              // sortFilterRaffles("OPEN");
            }}
            className={raffleView === "Grid" ? "active_view" : ""}
            size="23"
          />
          <BsCalendar
            onClick={() => {
              onChangeView(window.innerWidth < 720 ? "WeeklyCalender" : "Calender");
            }}
            className={raffleView === "WeeklyCalender" || raffleView === "Calender" ? "active_view" : ""}
            size="18"
          />
          <FaList onClick={() => {
            onChangeView("List");
          }}
            className={raffleView === "List" ? "active_view" : ""}
            size="18" />
        </div>
      </div>
      {/* legends */}
      <div className="cv-legend-container">
        {raffleView === "Calender" && (
          <div className="cv-controls-container">
            {/* month */}
            <div className="caret">
              <AiFillCaretLeft
                onClick={() => {
                  let currentMonth = currentDay.getMonth();
                  console.log(
                    "currentMonth::::::::::::::::::::::::::::",
                    currentMonth
                  );
                  if (currentMonth >= 0) {
                    // let newDate = new Date();
                    const newDate = new Date(
                      currentDay.getFullYear(),
                      currentDay.getMonth(),
                      1,
                      0,
                      0,
                      0,
                      0
                    );
                    newDate.setMonth(--currentMonth);
                    setCurrentDay(newDate);
                  }
                }}
              />
              <p>{monthsList[currentDay.getMonth()]}</p>
              <AiFillCaretRight
                onClick={() => {
                  let currentMonth = currentDay.getMonth();
                  console.log(
                    "currentMonth::::::::::::::::::::::::::::",
                    currentMonth
                  );
                  if (currentMonth <= 11) {
                    // let newDate = new Date();
                    const newDate = new Date(
                      currentDay.getFullYear(),
                      currentDay.getMonth(),
                      1,
                      0,
                      0,
                      0,
                      0
                    );
                    newDate.setMonth(++currentMonth);
                    setCurrentDay(newDate);
                  }
                }}
              />
            </div>
            <div className="caret">
              <AiFillCaretLeft
                onClick={() => {
                  let currentYear = currentDay.getFullYear();
                  if (currentYear >= 1920) {
                    let newDate = new Date();
                    newDate.setFullYear(--currentYear);
                    setCurrentDay(newDate);
                  }
                }}
              />
              <p>{currentDay.getFullYear()}</p>
              <AiFillCaretRight
                onClick={() => {
                  let currentYear = currentDay.getFullYear();
                  let newDate = new Date();
                  newDate.setFullYear(++currentYear);
                  setCurrentDay(newDate);
                }}
              />
            </div>
          </div>
        )}
        {raffleView === "WeeklyCalender" && (
          <div className="weekly_view_controlls">
            <label className="datepicker-lable">Start date</label>
            <DatePicker
              selected={currentWeek}
              onChange={(date) => {
                setCurrentWeek(date);
              }}
            />
          </div>
        )}
        {/* {raffleView !== "Grid" && (
          <div className="info">
            <span className="asterisk">*</span> Single raffle with multiple
            draws
          </div>
        )} */}
      </div>
      {/* raffles list */}
      {!loading && sortRafflesList[sortRaffle] === 0 ? (
        <NoRafflesFound />
      ) : (
        _renderRaffles()
      )}
      {raffleView !== "Grid" && (
        <div className="info">
          <span className="asterisk">*</span> Single raffle with multiple
          draws
        </div>
      )}
      {_renderPagination()}

      {/* render models */}
      {showInvitationModel && (
        <RaffleInvitationModel
          raffleTitle={shareRaffleName}
          raffleId={shareRaffleId}
          onClose={() => toggleInvitationModel(false)}
          raffleData={selectedRaff}
        />
      )}
      {loading ? (
        <div className="notif-loader">
          <Loader type="TailSpin" color="rgb(11, 70, 85)" width={60} />
        </div>
      ) : null}
    </div>
  );
};
