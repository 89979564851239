import React from 'react'

function NoRafflesFound() {
    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
            <img src="/noraffles.png" alt='no image found' style={{ height: "300px" }} />
            No Raffles to show.
        </div>
    )
}

export default NoRafflesFound



// const NoRafflesFound = () => {
//     return (
//         <div
//             className="no-data"
//             style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 flexDirection: "column",
//                 alignItems: "center",
//                 height: "80vh",
//             }}
//         >
//             <img src="images/—Pngtree—not found_5408094.png" width={256} alt="logo" />
//             <p variant="xLarge" style={{ marginTop: "20px" }}>
//                 No results found.
//             </p>
//         </div>
//     );
// };

// export default NoRafflesFound;