import React, { useState, useEffect } from "react";
import "./index.scss";
import { FaCaretDown, FaCaretUp, FaCross } from "react-icons/fa";
import {
  IoIosCloseCircleOutline,
  IoIosNotificationsOutline,
} from "react-icons/io";
import { useHistory } from "react-router-dom";
import { TiThMenu } from "react-icons/ti";
import { RiCloseCircleLine } from "react-icons/ri";
import Loader from "react-loader-spinner";
import { loginUser, configHost, web_url } from "../../config/endpoints";
import { request } from "../../helper/request";
import { useAlert } from "react-alert";
// icons
import { AiOutlineCopyrightCircle } from "react-icons/ai";

// const raffleTypes = [
//     { "Title": "Fun", "Description": "It is used to spend some fun time with family or friends and win some prizes in the form of money, service, or a product. It’s a Straight forward raffle in which people can sign up for free to look-over the end-to-end flow of the raffle." },
//     //{ "Title": "Regular", "Description": "Straight forward raffle for people to sign up for a fee to win a prize in the form of money, serice, or a product" },
//     { "Title": "Promotional", "Description": "This is one of the Rafflerra’s free services that are used by Marketers and Manufacturers for promoting their products or services on different platforms, for this the organization is required to create a Raffle in Rafflerra and share the link of the raffle to the public." },
//     //{ "Title": "Tiered", "Description": "Rafflerra’s innovative type of raffle to get participants more engaged and entertained. Instead of drawing a winner in a single round, the raffle is conducted in several tiers by eliminating some in every round" },
//     // { "Title": "Instant", "Description": "On-The-Go Raffles for events, conventions, gatherings, etc. Create a raffle and it is ready instantly for signup and will conduct raffles effortlessly and efficiently." },
//     { "Title": "Fundraising", "Description": "This raffle is a paid raffle which will aid in funding for Charities and Trusts, any company with any foundation can create a raffle and fix a certain amount of fee as contribution fund." },
//     { "Title": "Sponsored", "Description": "This is another type of fundraising raffle where the prize sponsored by a Sponsor, requiring a participant to join for a fee to win the prize and the proceeds are given to the Beneficiary." },
// ];
const raffleTypes = [
  {
    Title: "Fun",
    Description:
      "Fun raffles are designed to be used casually with family or friends. It is a straight-forward way to create a raffle for whoever you desire and win some amazing prizes in the form of money, services, or products.  Nothing is better than sending joy-filled prizes in an entertaining way!",
  },
  //{ "Title": "Regular", "Description": "Straight forward raffle for people to sign up for a fee to win a prize in the form of money, serice, or a product" },
  {
    Title: "Promotional",
    Description:
      "One of Rafflerra’s innovative features that can be used by startups and established companies to promote new products and services. This is a powerful marketing tool to flaunt their newest products and services, while fortifying engagement with their prospective consumers. For participants, this is a great way to be introduced to the latest products and gain market awareness, so get in on the Raffling!",
  },
  {
    Title: "Gift Exchange",
    Description:
      "This raffle type offers s a trendy way to do a virtual gift exchange with friends and family. Just register the participants and the gifts and let Rafflerra Draw take care of the rest.",
  },
  //{ "Title": "Tiered", "Description": "Rafflerra’s innovative type of raffle to get participants more engaged and entertained. Instead of drawing a winner in a single round, the raffle is conducted in several tiers by eliminating some in every round" },
  // { "Title": "Instant", "Description": "On-The-Go Raffles for events, conventions, gatherings, etc. Create a raffle and it is ready instantly for signup and will conduct raffles effortlessly and efficiently." },
  {
    Title: "Fundraising",
    Description:
      "A raffle in which hosts can raise money for charitable or non-charitable causes. All organizations, regardless of shape or size, can utilize this feature. After reaching the goal, the winning participant will receive prize money (not a product).",
  },
  {
    Title: "Sponsored",
    Description:
      "Sponsored raffles are a variation of Fundraising raffles. Any entity can sponsor a product or service as a prize in order to raise funds for a certain organization (the Beneficiary). Participants join the raffle to try and win the prize, while all proceeds benefit the Beneficiary.",
  },
];

const services = [
  {
    Title: "Self Service",
    Description:
      "Create and manage raffles on your own using our feature-rich web and mobile apps.",
  },
  {
    Title: "Full Service",
    Description:
      "A total turnkey offering – Team Rafflerra will support all aspects of your raffles from creation to closing out.",
  },
];

const platform = [
  {
    Title: "Software as a Service",
    Description:
      "We provide our customized application service to your organization so that you can create and manage raffles according to your requirements.",
  },
  {
    Title: "Rafflerra Service API",
    Description:
      "Our API accesses the functions and data that your service shares.",
  },
  {
    Title: "Rafflerra Web & Mobile Apps",
    Description:
      "We provide both web and mobile applications so players can access Rafflerra on their computers or phones.",
  },
];
let hours = new Date().getHours();
let introMsg =
  hours < 12 ? "Good Morning" : hours < 18 ? "Good Afternoon" : "Good Evening";
const welcomeMessage = `${introMsg}, Welcome to the  Raffle Headquarters of the Universe!`;

export default () => {
  const [animation, startAnimation] = useState({
    blue_card_position: {},
    yellow_card_position: {},
    cup_image_position: {},
    white_top_right_position: {},
    dots_view_opacity: {},
    header_view_opacity: {},
    bottom_left_blue_box: {},
    body_animation: {},
    why_refflera_page_animation: { width: "100%", height: "100%" },
    raffle_types_page_opacity: { opacity: 1 },
    services_page_size: {},
    notifications_animation_main_page: {},
  });
  const history = useHistory();
  const alert = useAlert();

  const [loading, toggleLoading] = useState(false);

  // welcome text
  const [welcomeText, setWelcomeText] = useState("");

  // mobile device states
  const [showMenu, toggleMenu] = useState(false);
  const [isMobile, toggleMobile] = useState(false);
  useEffect(() => {
    const value = window.matchMedia("(max-width: 720px)");
    toggleMobile(value.matches);

  }, []);

  // animation for welcome message
  useEffect(() => {
    let start = 0;
    const max = welcomeMessage.length;

    const interval = setInterval(() => {
      if (start === max) {
        clearInterval(interval);
      } else {
        setWelcomeText((txt) => txt + welcomeMessage.split("")[start]);
        start++;
      }
    }, 40);
  }, []);

  //for handling active scrolled animation
  const onScrolling = () => {
    console.log("Scrolled:::::::::::::")
    if (window.outerHeight / 2 > window.scrollY) {
      console.log("Scrolled:::::::::::::Condition")
      startAnimation((anim) => ({
        ...anim,
        why_refflera_page_animation: { width: "100%", height: "100%" },
        raffle_types_page_opacity: { opacity: 1 },
      }));
    }
  };

  //to load start animations
  useEffect(() => {
    window.addEventListener("scroll", onScrolling);
    setTimeout(() => {
      console.log("usingeffecting")
      startAnimation((anim) => ({
        ...anim,
        blue_card_position: { top: -175, right: -100 },
        yellow_card_position: { top: 125, right: 200 },
        cup_image_position: { top: 250, right: 260 },
        white_top_right_position: { top: -60, right: -60 },
        dots_view_opacity: { opacity: 0.4 },
        header_view_opacity: { opacity: 1 },
        bottom_left_blue_box: { left: -130 },
        body_animation: { opacity: 1 },
        services_page_size: { width: "100%", height: "100%" },
        notifications_animation_main_page: {
          root: { width: "85%", opacity: 1 },
          leftIcon: { fontSize: 24 },
          text: { fontSize: 13, marginLeft: 6 },
          rightIcon: { fontSize: 20 },
        },
      }));
    }, 40);
    return () => {
      window.removeEventListener("scroll", onScrolling);
    };
  }, []);

  const tryClicked = async (role) => {
    toggleLoading(true);
    let response;
    if (role === "participant") {
      // {
      //     response = await request("POST", loginUser, {
      //         "email": "pft.student01@gmail.com",
      //         "password": "12345678"
      //     });
      // } else
      response = await request("POST", loginUser, {
        email: "pft.professor1@gmail.com",
        password: "12345678",
      });
    }
    if (response.status === 0) {
      alert.removeAll();
      alert.show("Hi, Welcome to the WorkSpace");
      sessionStorage.setItem("User", JSON.stringify(response.payload));
      sessionStorage.setItem("Token", response.token);
      history.push({
        pathname: "/workspace",
        state: { role },
      });
      toggleLoading(false);
    } else {
      alert.removeAll();
      alert.show(JSON.stringify(response.msg));
      toggleLoading(false);
    }
  };

  const [showMenu1, setShowMenu1] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);




  //header
  const _header = () => {

    const toggleMenu = () => {
      setShowMenu1((prevShowMenu) => !prevShowMenu);
      setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
    };

    return (
      <div className="main-container">
        <div className="logo-container">
          <img src={require("../../assets/img/logo.png")} />
          <label>Rafflerra</label>
          <small>beta</small>
        </div>
        <div className="sub-container" style={animation.header_view_opacity}>
          <nav
            style={isMobile ? (showMenu1 ? { display: "flex" } : { display: "none" }) : {}}
          >
            <button
              className="login-btn"
              onClick={() => history.push("/authentication")}
            >
              LOGIN
            </button>
            <a
              target="_blank"
              // href="/catalouge"
              onClick={() => {
                alert.removeAll();
                alert.show("Coming soon");
              }}
            >
              <img src={require("../../assets/img/catalog_logo.png")} />
              <p>Rafflerra Catalogue</p>
            </a>
            <a
              onClick={() => {
                alert.removeAll();
                alert.show("Coming soon");
              }}
            >
              <img src={require("../../assets/img/marketplace_logo.png")} />
              <p>Rafflerra Marketplace</p>
            </a>
            <a>Try Us</a>
            <a
              onClick={() => {
                startAnimation((anim) => ({
                  ...anim,
                  services_page_size: { width: "100%", height: "100%" },
                }));
                if (isMobile) {
                  toggleMenu(false);
                }
              }}
              href="#platform"
            >
              PLATFORM
            </a>
            <a
              onClick={() => {
                startAnimation((anim) => ({
                  ...anim,
                  services_page_size: { width: "100%", height: "100%" },
                }));
                if (isMobile) {
                  toggleMenu(false);
                }
              }}
              href="#services"
            >
              SERVICES
            </a>
            <a
              onClick={() => {
                startAnimation((anim) => ({
                  ...anim,
                  raffle_types_page_opacity: { opacity: 1 },
                }));
                if (isMobile) {
                  toggleMenu(false);
                }
              }}
              href="#raffleTypes"
            >
              RAFFLE TYPES
            </a>
            <a
              onClick={() => {
                startAnimation((anim) => ({
                  ...anim,
                  why_refflera_page_animation: { width: "100%", height: "100%" },
                }));
                if (isMobile) {
                  toggleMenu(false);
                }
              }}
              href="#whyRafflera"
            >
              WHY RAFFLERRA?
            </a>
            {/* only mobile starts */}
            {/* {isMobile && (
            <RiCloseCircleLine onClick={() => toggleMenu((st) => !st)} />
          )} */}
            {/* only mpobile ends */}
          </nav>
          <div className="hamburger-menu" onClick={toggleMenu}>
            {isMenuOpen ? <RiCloseCircleLine /> : <TiThMenu />}
          </div>
        </div>
      </div>
    );
  };

  //screen 1
  const _renderMainSection = () => {
    return (
      <div>
        <div className="hero">
          <div style={animation.body_animation}>
            <div>Start a raffle anytime, anywhere!</div>
            <div>
              {/* Raffle like never before... Rafflerra creatively brings innovative features that take raffling to higher levels, enabling the use of raffles universally - when and where needed. Our service stands out, our features and user experience are far beyond what our competitors offer. Rafflera is the only Raffle platform that offers abilities to create your own virtual raffle ceremonies and lets you invite your favorite celebrities to host and draw the raffles. It lets you unleash and push your creativity to higher levels. */}
              {/* Raffles are usually organized for good causes, like never before, Rafflerra creatively brings innovative
                        features that take raffling to higher levels, enabling the use of raffles universally - when and where
                        needed. Our service stands out, our features and user experience are far beyond what our competitors
                        offer. Rafflerra is the only Raffle platform that offers abilities to create your virtual raffle ceremonies and
                        lets you invite your favorite celebrities to host and draw the raffles. It lets you unleash and push your
                        creativity to higher levels. In Rafflerra we have different types of raffling such as Fun, Promotional, Fund
                        Raising, Sponsored, and Instant. */}
              Rafflerra allows you to effortlessly create raffles for any
              occasion. We creatively introduce innovative features that let you
              unleash your creativity and boost the overall Raffling experience,
              enabling the use of our platform, wherever and whenever.
            </div>
            {/* <div>To know more about the Rafflerra's platform use <a target="blank" href={web_url + "/guide?page=host"}>Rafflerra Interactive Guide</a>. Experience our service as a Host and as a Participant by using the following built-in accounts.</div> */}
            <div>
              <button disabled onClick={() => history.push("/authentication/register")}>
                Sign Up
              </button>
              <div onClick={() => (window.location.href = "#whyRafflera")}>
                Learn More
              </div>
            </div>
          </div>
          <div>
            <div style={animation.blue_card_position}></div>
            <div style={animation.yellow_card_position}></div>
            <img
              style={animation.cup_image_position}
              src={require("../../assets/img/cup.png")}
            />
            <div style={animation.white_top_right_position}></div>
            <div style={animation.dots_view_opacity}>
              {[...Array(25).keys()].map((item, key) => (
                <div key={key}>
                  <div></div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div style={animation.bottom_left_blue_box}></div> */}
        <div></div>
        <div></div>
        {/* notification slider bar */}
        {/* <div style={animation.notifications_animation_main_page.root}>
                    <IoIosNotificationsOutline onClick={() => {
                        startAnimation(anim => ({
                            ...anim,
                            notifications_animation_main_page: {
                                root: { width: '85%', opacity: 1 },
                                leftIcon: { fontSize: 24 },
                                text: { fontSize: 0 },
                                rightIcon: { fontSize: 20 }
                            }
                        }));
                        setTimeout(() => {
                            startAnimation(anim => ({
                                ...anim,
                                notifications_animation_main_page: {
                                    root: { width: '85%', opacity: 1 },
                                    leftIcon: { fontSize: 24 },
                                    text: { fontSize: 13, marginLeft: 6 },
                                    rightIcon: { fontSize: 20 }
                                }
                            }));
                        }, 600);
                    }} style={animation.notifications_animation_main_page.leftIcon} />
                    <div style={animation.notifications_animation_main_page.text}>Coming soon, please Sign up for updates.</div>
                    <IoIosCloseCircleOutline onClick={() => {
                        startAnimation(anim => ({
                            ...anim,
                            notifications_animation_main_page: {
                                root: { width: 44, opacity: 1, borderRadius: 40, height: 13 },
                                leftIcon: { fontSize: 24 },
                                text: { fontSize: 0 },
                                rightIcon: { fontSize: 0 }
                            }
                        }));
                    }} style={animation.notifications_animation_main_page.rightIcon} />
                </div> */}
        {/* {window.innerWidth > 720 ? */}
        <div className="welcomeText">{welcomeText}</div>
        {/* //   : <marquee className="welcomeText">{welcomeText}</marquee>
        // } */}
      </div>
    );
  };

  //screen 2
  const _renderWhyRaffleraSection = () => {
    return (
      <div style={animation.why_refflera_page_animation}>
        <img src={require("../../assets/img/explain.svg")} />
        <div>
          <div>Why Rafflerra?</div>
          <div></div>
          {/* <div>Our philosophy is to make things simple and easy by building a comprehensive Raffle platform that provides a workflow-based approach and the feature set for all types of hosts, participants, sponsors, and beneficiaries. Unlimited possibilities to conduct Fundraisers, On-The-Go Raffles, Product Promotions, etc. Our complimentary services include Raffle(ra) Catalog, Sponsor Registry, and Beneficiary Registry. Rafflerra – an end to end platform offering the future of raffling, now!</div> */}
          <div>
            <p>
              Our philosophy is to make things simple and easy. We have built a
              comprehensive Raffle platform that provides a enhanced feature set
              for all types of users - hosts, participants, sponsors, and
              beneficiaries. As a host you can create and efficiently manage
              on-demand or scheduled raffles in which participants can easily
              take part and track their raffles.
            </p>
            <p>
              Our complimentary services include the Rafflerra Catalogue and
              Rafflerra Marketplace, Sponsor Registry and Beneficiary Registry.
              We are an end-to-end platform to bring the future of raffling now!
              Our ecosystem includes versatile web and mobile apps along with
              integration APIs.
            </p>
          </div>
          {/* <div>
            <p>
              Experience our service as a Host and as a Participant by using the
              following built-in account.
            </p>
          </div> */}
          {/* <div>
            <div onClick={() => tryClicked("host")}>Try As a Host</div>
            <div onClick={() => tryClicked("participant")}>Try Rafflera</div>
          </div> */}
          <div>
            To learn more about Rafflerra’s platform, check out our detailed
            <a target="blank" onclick="return false;" style={{ pointerEvents: "none", opacity: 0.3 }} href={web_url + "/guide?page=host"} >
              Interactive Guide.
            </a>
          </div>
        </div>
      </div>
    );
  };

  //screen 3
  const _renderRaffleTypes = () => {
    return (
      <div style={animation.raffle_types_page_opacity}>
        <div>
          {raffleTypes.map((type, i) => {
            return (
              <div key={i}>
                <div>
                  <div>
                    <img
                      src={require(`../../assets/img/ticket_bg${i + 1}.png`)}
                    />
                    <label>{i + 1}</label>
                  </div>
                  <div>{type.Title}</div>
                </div>
                <div>{type.Description}</div>
                {i === 3 || i === 4 ? (
                  <div className="disable_card">COMING SOON</div>
                ) : null}
              </div>
            );
          })}
        </div>
        <div>
          <div>Raffle Types</div>
          <div></div>
          {/* Description */}
          {/* <div>Rafflerra covers it all! We offer a set of raffle types to meet the needs of savvy rafflers. Each type is tailored with a custom workflow process to let the Raffle Hosts/Managers manage the raffles efficiently and effectively.</div> */}
          <div>
            We offer four raffle types to meet the needs of any raffler. For
            each raffle type, our platform provides customized features that
            allow hosts to manage their raffles effectively.
          </div>
        </div>
        {/* <div></div>
            <div></div> */}
      </div>
    );
  };

  //screen 4
  const _renderServices = () => {
    return (
      <div style={animation.services_page_size}>
        <div>Our Services</div>
        <div></div>
        <div>
          Pick between our self-service option, which allows Raffle Hosts to
          host and manage their own raffles, or our full-service option in which
          Rafflerra will manage the raffle.
        </div>
        <div>
          <div>
            <img src={require("../../assets/img/self_service.svg")} />
            <div>
              <div>{services[0].Title}</div>
              <div>{services[0].Description}</div>
            </div>
          </div>
          <div>
            <img src={require("../../assets/img/full_service.svg")} />
            <div>
              <div>{services[1].Title}</div>
              <div>{services[1].Description}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //screen 5
  const _renderPlatforms = () => {
    return (
      <div style={animation.services_page_size}>
        <div>Our Platform</div>
        <div></div>
        <div>
          Rafflerra creates an end-to-end ecosystem for raffling. We offer
          innovative, customized solutions for individuals and organizations.
        </div>
        <div>
          <div>
            <img src={require("../../assets/img/service.svg")} />
            <div>
              <div>{platform[1].Title}</div>
              <div>{platform[1].Description}</div>
            </div>
          </div>
          <div>
            <img src={require("../../assets/img/apps.svg")} />
            <div>
              <div>{platform[2].Title}</div>
              <div>{platform[2].Description}</div>
            </div>
          </div>
          <div>
            <img src={require("../../assets/img/saas.svg")} />
            <div>
              <div>{platform[0].Title}</div>
              <div>{platform[0].Description}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //footer
  const _renderFooter = () => {
    return (
      <div>
        <label>
          <AiOutlineCopyrightCircle />
          Rafflerra.com 2020. All Rights Reserved.
        </label>
        <label>
          Powered by Techgrity LLC{" "}
          <img src={require("../../assets/img/techgrity_logo.png")} />
        </label>
      </div>
    );
  };

  //Page scroller
  const _renderPageScroller = () => {
    return (
      <div onClick={() => { }}>
        <a href="#/">
          <FaCaretUp />
        </a>
      </div>
    );
  };

  return (
    <div className="lp_view">
      <div className="header">{_header()}</div>
      <div className="body">
        <section id="/">{_renderMainSection()}</section>
        <section id="whyRafflera">{_renderWhyRaffleraSection()}</section>
        {/* <section id="experiecneOurSite">{_renderExperienceOurSite()}</section> */}
        <section id="raffleTypes">{_renderRaffleTypes()}</section>
        <section id="services">{_renderServices()}</section>
        <section id="platform">{_renderPlatforms()}</section>
        <div>{_renderFooter()}</div>
        <div>{_renderPageScroller()}</div>
      </div>
      {loading ? (
        <div>
          <Loader type="TailSpin" color="rgb(11, 70, 85)" width={60} />
        </div>
      ) : null}
    </div>
  );
};
