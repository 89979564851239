export const CRYPTER_SECRET = "98ahkiuay7siajbsiyasas78ays8oiauhsbysai7ys79754aw4s6d";
export const dateFormatter = (tz, dateStr) => {
    const istFormattedDatetime = new Intl.DateTimeFormat("en-US", {
      timeZone: tz,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(new Date(dateStr));
    return istFormattedDatetime;
}

export const getDateStrWithTZ = (tz, date) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: tz,
    timeZoneName: 'short',
  };
  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
}

export const getMilliSecFromTime = (timeStr) => {
  // Parse the time string
  const timeComponents = timeStr.split(/[:\s]/);
  const hours = parseInt(timeComponents[0]);
  const minutes = parseInt(timeComponents[1]);
  const period = timeComponents[2].toUpperCase(); // AM or PM

  // Calculate total milliseconds
  let totalMilliseconds = (hours % 12) * 60 * 60 * 1000 + minutes * 60 * 1000;

  // Adjust for AM/PM
  if (period === "PM") {
    totalMilliseconds += 12 * 60 * 60 * 1000;
  }
  return totalMilliseconds;
}

export const getDatePartTZ = (date, tz) => {

  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    timeZone: tz,
    timeZoneName: 'short',
  };
  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
}

export const generateRandomCode = (length) => {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let code = '';
  
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters.charAt(randomIndex);
  }
  
  return code;
}

export const getFormattedDateTime = (date, tz) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "numeric",
    hour12: true,
    timeZone: tz,
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};
