import React, { useState, Fragment, useEffect } from 'react';
import './customizeRaffle.scss';
import { MdClose } from 'react-icons/md';
import { useAlert } from 'react-alert';
import { GoInfo } from 'react-icons/go';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { LoadMyRaffles } from '../../../store/actions/raffleAction';

export default ({ onClose, onChange, response, createRaffleTemplate, setTemplateChoosen }) => {

    // for loading templates
    // const { myRaffles, status } = useSelector(({ RaffleReducer }) => RaffleReducer);
    const [localRaffles, setLocalRaffles] = useState([]);

    const dispatch = useDispatch();

    const { myBeneficiaries } = useSelector(({ BeneficiaryReducer }) => (BeneficiaryReducer))
    const alert = useAlert();
    const [choosenOptions, setChoosenOptions] = useState({
        "Raffle Type": "Fun",//"Raffle Type",
        "Service Type": "Self Service",//"Service Type",
        "Private": false
        // "Announce Type": "In Site",//"Announce Type",
        // "Prize": "Prize"
    });

    //loading or filtering hosted raffles
    // useEffect(() => {
    //     if (myRaffles.length > 0) {
    //         let user = JSON.parse(sessionStorage.getItem("User"));
    //         let email = user.email;
    //         let filterdRaffles = myRaffles.filter(x => x.createdBy === email);
    //         setLocalRaffles(filterdRaffles);
    //     } else {
    //         if (status !== "FETCHED_MY_EMPTY" && status !== "FETCHED_EMPTY") {
    //             dispatch(LoadMyRaffles())
    //         }
    //     }
    // }, [myRaffles]);

    const proceedButtonClicked = () => {
        let keys = Object.keys(choosenOptions);
        if (keys.length === 4 || keys.length === 5) {
            if (keys[0] === choosenOptions[keys[0]]) {
                alert.removeAll();
                alert.show("Select Raffle Type");
            } else if (keys[1] === choosenOptions[keys[1]]) {
                alert.removeAll();
                alert.show("Select Service Type");
            } else if (keys[2] === choosenOptions[keys[2]]) {
                alert.removeAll();
                alert.show("Select Announce Type");
            }
            // else if (keys[3] === choosenOptions[keys[3]]) {
            //     alert.removeAll();
            // alert.show("Select Prize");
            // }
            //if raffle type is fundraising or sponsored
            else if ("Beneficiary" in choosenOptions && choosenOptions['Beneficiary'] === "Select Beneficiary") {
                alert.removeAll();
                alert.show("Please select a beneficiary!");
            }
            else {
                onChange("CreateRaffleModel")
                response(choosenOptions);
            }
        } else {
            onChange("CreateRaffleModel")
            response(choosenOptions);
        }
    }

    return <div className="customize_raffle_view">
        <div>
            <div>
                <img src={require('../../../assets/img/cup.png')} />
                <div>Create {createRaffleTemplate ? "Template" : "Raffle"}</div>
                {/* <div>
                    <GoInfo data-test="Hello" />
                </div> */}
                <label onClick={onClose}>X</label>
            </div>
            <div>
                <label>SELECT A RAFFLE TYPE</label>
                <select value={choosenOptions["Raffle Type"]} onChange={(e) => {
                    let option = e.currentTarget.value;
                    if (option === "Fundraising" || option === "Sponsored") {
                        setChoosenOptions(opt => ({ ...opt, "Beneficiary": "Select Beneficiary", "Raffle Type": option }))
                    } else {
                        let tempChoosenOptions = { ...choosenOptions };
                        delete tempChoosenOptions['Beneficiary'];
                        setChoosenOptions(opt => ({ ...tempChoosenOptions, "Raffle Type": option }));
                    }
                }}>
                    {/* <option key="Raffle Type" value="Raffle Type">Raffle Type</option> */}
                    {/* <option key="Regular" value="Regular">Regular</option> */}
                    <option key="Fun" value="Fun">Fun Raffle</option>
                    <option key="Promotional" value="Promotional">Promotional Raffle</option>
                    {/* <option key="Fundraising" value="Fundraising">Fundraising</option> */}
                    {/* <option key="Sponsored" value="Sponsored">Sponsored</option> */}
                    {/* <option key="Tierd" value="Tierd">Tierd</option> */}
                    {/* <option key="Instant" value="Instant">Instant Raffle</option> */}
                    {/* <option key="Series" value="Series">Series</option> */}
                </select>
                {
                    "Beneficiary" in choosenOptions ?
                        <Fragment>
                            <label>Choose a beneficiary to run a raffle</label>
                            <select onChange={(e) => {
                                let text = e.currentTarget.value;
                                setChoosenOptions(opt => ({ ...opt, "Beneficiary": text }));
                            }}>
                                <option value={"Select Beneficiary"}>Select Beneficiary</option>
                                {
                                    myBeneficiaries.map((my_bene, i) => {
                                        return my_bene.user[0].status === "Active" ? <option value={my_bene._id} key={i}>{my_bene.user[0].name}</option> : null
                                    })
                                }
                            </select>
                        </Fragment> : null
                }
                {/* if create raffle form */}
                {
                    !createRaffleTemplate &&
                    <Fragment>
                        <label>TEMPLATE</label>
                        <select onChange={(e) => {
                            const value = e.currentTarget.value;
                            if (value !== "none") {
                                const choosenTemplate = localRaffles.find(x => x._id == value);
                                setTemplateChoosen(choosenTemplate)
                                setChoosenOptions(options => ({ ...options, 'Raffle Type': choosenTemplate.rafflerType, Private: choosenTemplate.private }))
                            } else {
                                setTemplateChoosen(null);
                            }
                        }}>
                            <option value="none">None</option>
                            {
                                localRaffles.map((lRaffle, index) => {
                                    if ("isTemplate" in lRaffle.extras && lRaffle.extras.isTemplate && lRaffle.extras.active) {
                                        return choosenOptions["Raffle Type"] === lRaffle.rafflerType && <option key={index} value={lRaffle._id}>{lRaffle.title}</option>
                                    }
                                })
                            }
                        </select>
                    </Fragment>
                }
                {/* <label>Please select service type</label>
                <select value={choosenOptions["Service Type"]} onChange={(e) => { let option = e.currentTarget.value; setChoosenOptions(opt => ({ ...opt, "Service Type": option })) }}>
                    <option key="Service Type" value="Service Type">Service Type</option>
                    <option key="Full Service" value="Full Service">Full Service</option>
                    <option key="Self Service" value="Self Service">Self Service</option>
                </select> */}
                {/* <label>Choose announcement type</label> */}
                {/* <select value={choosenOptions["Announce Type"]} onChange={(e) => { let option = e.currentTarget.value; setChoosenOptions(opt => ({ ...opt, "Announce Type": option })) }}> */}
                {/* <option key="Announce Type" value="Announce Type">Announce Type</option> */}
                {/* <option key="In Site" value="In Site">In Site</option> */}
                {/* <option key="Virtual Meet" value="Virtual Meet">Virtual Meet</option> */}
                {/* <option key="Physical Meet" value="Physical Meet">Physical Meet</option> */}
                {/* </select> */}
                {/* <label>Pick a Prize Type for the raffle</label>
                <select value={choosenOptions["Prize"]} onChange={(e) => { let option = e.currentTarget.value; setChoosenOptions(opt => ({ ...opt, "Prize": option })) }}>
                    <option key="Prize" value="Prize">Prize</option>
                    <option key="Money" value="Money">Money</option>
                    <option key="Product" value="Product">Product</option>
                    <option key="Service" value="Service">Service</option>
                </select> */}
            </div>
            <div>
                <input checked={!choosenOptions['Private']} onClick={(e) => {
                    setChoosenOptions(opts => ({ ...opts, 'Private': !opts.Private }))
                }} type="checkbox" />
                <label>Add in Rafflerra Catalouge</label>
            </div>
            <div>
                <div onClick={proceedButtonClicked}>PROCEED</div>
                <div onClick={onClose}>CANCEL</div>
            </div>
        </div>
    </div>
}