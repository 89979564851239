import React, { useRef, useEffect, useState } from "react";
import "./index.scss";
import PieChart from "./charts/pie";
import BarChart from "./charts/bar";
import LineCart from "./charts/line";

import { LoadMyRaffles } from "../../../../store/actions/raffleAction";
import { useSelector, useDispatch } from "react-redux";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default () => {
  const { myRaffles } = useSelector(({ RaffleReducer }) => RaffleReducer);
  const dispatch = useDispatch();

  const [hostedRaffles, setHostedRaffles] = useState([]);
  const [participatedRaffles, setParticipatedRaffles] = useState([]);

  const [chartsRole, toggleChartsRole] = useState("Hosted");

  // chart data
  const [pieData, setPieData] = useState([]);
  const [barData, setBarData] = useState([]);
  const [lineData, setLineData] = useState([]);

  // load data
  useEffect(() => {
    if (myRaffles.length > 0) {
      const { email } = JSON.parse(sessionStorage.getItem("User"));
      const hosted_raffles = myRaffles.filter((x) => x.createdBy === email);
      const participated_raffles = myRaffles.filter((x) =>
        x.participants.includes(email)
      );
      setHostedRaffles(hosted_raffles);
      setParticipatedRaffles(participated_raffles);

      // Pie chart data
      setPieData([
        {
          id: "Hosted",
          label: "Hosted",
          value: hosted_raffles.length,
          color: "hsl(330, 70%, 50%)",
        },
        {
          id: "Participated",
          label: "Participated",
          value: participated_raffles.length,
          color: "hsl(329, 70%, 50%)",
        },
      ]);

      // bar chart data
      if (chartsRole === "Participated") {
        const bar_data = participated_raffles.map((x) => ({
          ...x,
          Participants: x.maxNumParticipants,
          "No. participantsColor": "hsl(101, 70%, 50%)",
          "No. winners": x.winners.length,
          "No. winnersColor": "hsl(51, 70%, 50%)",
        }));
        setBarData(
          bar_data
            .sort(
              (a, b) =>
                new Date(b.drawnAt).getTime() - new Date(a.drawnAt).getTime()
            )
            .slice(0, 5)
        );
      } else {
        const bar_data = hosted_raffles.map((x) => ({
          ...x,
          Participants: x.maxNumParticipants,
          "No. participantsColor": "hsl(101, 70%, 50%)",
          "No. winners": x.winners.length,
          "No. winnersColor": "hsl(51, 70%, 50%)",
        }));
        setBarData(
          bar_data
            .sort(
              (a, b) =>
                new Date(b.participationOpens).getTime() -
                new Date(a.participationOpens).getTime()
            )
            .slice(0, 5)
        );
      }

      // line chart data
      if (chartsRole === "Participated") {
        const line_data = participated_raffles.map((x, i) => {
          const data = {
            id: x.title,
            color: "hsl(105, 70%, 50%)",
            data: [
              {
                x: monthNames[new Date(x.participationOpens).getMonth()],
                y: 10 + 10 * (i + 1),
              },
              {
                x: monthNames[new Date(x.drawnAt).getMonth()],
                y: 10 + 10 * (i + 1),
              },
            ],
          };

          return data;
        });
        setLineData(line_data);
      } else {
        const line_data = hosted_raffles.map((x, i) => {
          const data = {
            id: x.title,
            color: "hsl(105, 70%, 50%)",
            data: [
              {
                x: monthNames[new Date(x.participationOpens).getMonth()],
                y: 10 + 10 * (i + 1),
              },
              {
                x: monthNames[new Date(x.drawnAt).getMonth()],
                y: 10 + 10 * (i + 1),
              },
            ],
          };

          return data;
        });
        setLineData(line_data);
      }
    } else {
      dispatch(LoadMyRaffles());
    }
  }, [myRaffles, chartsRole]);

  return (
    <div className="_dashboard">
      <div>
        <label>My Raffles</label>
        <PieChart
          onClick={(e) => toggleChartsRole(e.label)}
          pieData={pieData}
        />
      </div>
      <div>
        <label>Last Five {chartsRole} Raffles</label>
        <BarChart barData={barData} />
      </div>
      <div>
        <label>Yearly {chartsRole} Raffles</label>
        <LineCart lineData={lineData} />
      </div>
    </div>
  );
};
